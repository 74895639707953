import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popper } from 'react-popper';
import './Popover.scss';

class Popover extends Component {
	constructor(props) {
		super(props);
	}

	closePopover(e) {
		e?.stopPropagation();
		if (!e.currentTarget.contains(e.relatedTarget)) {
			this && this.props.closePopover(e);
		}
	}

	adjustPlacement(placement) {
		const windowHeight = window.innerHeight;
		const popperElement = this.props.referenceElement;

		if (popperElement) {
			const popperRect = popperElement.getBoundingClientRect();
			const popperBottom = popperRect?.top + popperRect?.height + 150;
			const spaceToBottom = windowHeight - popperBottom;

			if (spaceToBottom < 0) {
				return 'top';
			}
		}

		return placement;
	}

	render() {
		return (
			<Popper
				referenceElement={this.props.referenceElement}
				placement={this.adjustPlacement(this.props.placement)} // Adjust the placement
				modifiers={{ offset: { offset: '10px, 10px' } }}
			>
				{({ ref, style, placement, arrowProps }) => (
					<button
						className={'popover'}
						id={this.props.id}
						ref={ref}
						style={style}
						data-placement={placement}
						onBlur={(e) => this.closePopover(e)}
						data-testid={this.props.testid}
					>
						<div
							className={'popover-body-container'}
							data-popover-body-container="true"
							style={{ height: this.props.height, width: this.props.width }}
						>
							{this.props.body}
						</div>

						{this.props.showPointingArrow && (
							<div
								className={`${'popover-arrow placement'}`}
								ref={arrowProps.ref}
								style={arrowProps.style}
							/>
						)}
					</button>
				)}
			</Popper>
		);
	}
}

Popover.propTypes = {
	// id attribute that will be applied to the outermost div of the popoover
	id: PropTypes.string.isRequired,
	// function to be called when the popover is closed
	closePopover: PropTypes.func.isRequired,
	// content to render in the popover body
	body: PropTypes.node.isRequired,
	// element that the popover should be pointing towards and positioned relative to
	referenceElement: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
	]),
	// String used to denote how popover should be positioned relative to the reference element (see react-popper docs for list of accepted values)
	placement: PropTypes.string,
	// Boolean value to show/hide the pointing arrow
	showPointingArrow: PropTypes.bool,
	// Height of the popover
	height: PropTypes.string,
	// width of the popover
	width: PropTypes.string,
	testid: PropTypes.string,
};

export default Popover;
