import React, { useState, useEffect } from 'react';
import ChartsPrice from '../../../shared/chartsPrice/ChartsPrice';
import { DEFAULT_CHARTS_PRICE_OBJ } from '../../../shared/chartsPrice/ChartsPriceConstants';
import { IIndiceDetail } from '../../../types/interfaces/IMarketsIndices';
import './indices-chart.scss';
import { Heading, Label, NumberLabel } from '../../../@core-ui';
import {
	formatNumber,
	FormatType,
	getFormattedValuesInMeasures,
} from '../../../utilities/formatter';

interface IndicesChartProps {
	data: IIndiceDetail;
	enablePreviousClose?: boolean;
	isMobileView?: boolean;
}

const IndicesChart = (props: IndicesChartProps) => {
	const [chartConfig, setChartConfig] = useState<any>(null);
	useEffect(() => {
		setChartConfig(getChartConfig(props.data.venueXid));
	}, [props.data.venueXid]);

	const getChartConfig = (venueXid: number) => {
		const chartInfo: any = JSON.parse(JSON.stringify(DEFAULT_CHARTS_PRICE_OBJ));
		chartInfo['instruments'][0].venueXid = venueXid;
		chartInfo['panels'][0].height = 227;
		chartInfo['panels'][0].axisY.label.format = '#0';

		let width: any = document?.getElementsByClassName(
			'indices-chart-container',
		)[0]?.clientWidth;

		width =
			width ||
			document
				?.getElementsByTagName('md-quotes-news')[0]
				?.shadowRoot?.querySelector('div.indices-chart-container')?.clientWidth;

		if (props.enablePreviousClose) {
			chartInfo['styles'].markers['reference'] = {
				colorIndex: 3,
				dash: [4, 4],
			};

			chartInfo['markers']['reference'] = {
				basis: 'previousClose',
				showLabel: false,
			};
		}
		if (props.isMobileView) {
			chartInfo['width'] = width;
		} else {
			chartInfo['width'] = width - 50;
		}

		return chartInfo;
	};
	const formattedVolume = (volume: string) => {
		if (volume && volume !== '--') {
			if (parseFloat(volume) > 0) {
				return formatNumber(
					`${getFormattedValuesInMeasures(
						volume?.toString(),
						FormatType.MILLION,
					)}`,
					{ postFixValue: 'M' },
				).value;
			} else {
				return formatNumber(volume)?.value;
			}
		} else {
			return '--';
		}
	};

	return (
		<div className="indices-chart-container">
			<div className="header">
				<Heading type={'h1'} content={props.data.name} />
			</div>
			<div className="charts-price">
				{chartConfig && <ChartsPrice inputObj={chartConfig} />}
			</div>
			<div className="indices-details-keys">
				<Label text={'LAST'} />
				<Label text={'CHANGE'} />
				<Label text={'VOLUME'} />
			</div>
			<div className="indices-details-values">
				<div className="col-last-value">
					<Label
						testId="indexLastValue"
						text={
							formatNumber(`${props.data.last}`, {
								commaSeparated: true,
							}).value
						}
					/>
				</div>
				<div>
					<span>
						<NumberLabel
							testId="indexDollarValue"
							value={formatNumber(`${props.data.change}`)?.value}
							numberConfig={{
								showColor: true,
								preFix: true,
							}}
						/>
						<br />
						<NumberLabel
							testId="indexPercentValue"
							value={formatNumber(`${props.data.changePercent}`)?.value}
							numberConfig={{
								showColor: true,
								preFix: true,
								postFixValue: '%',
							}}
						/>
					</span>
				</div>
				<div className="indices-details-volume">
					<Label text={'Below 10 day avg'} />
					<br />
					<Label
						text={formattedVolume(`${props.data.volume}`)}
						testId="indexVolumeValue"
					/>
				</div>
			</div>
		</div>
	);
};

export default IndicesChart;
