export const HEADLINES_HEADER = 'Headline News';
const FIELD_VALUES = {
	WALL_STREET_JOURNAL: 'WallStreetJournal',
	US_STOCKS_MID_DAY_REPORTS: 'USStocks_MidDayReports',
	US_STOCKS_WEEKLY_OUTLOOK: 'USStocks_WeeklyOutlook',
	TODAY: '1',
	PAST7DAYS: '7',
	PAST1MONTH: '30',
	ECONOMIC: 'Economic',
	GOVERNMENT: 'Government',
	MARKETS: 'Markets',
	BONDS: 'Bonds',
	FUNDS: 'Funds',
	COMMODITIES: 'Commodities',
	PRESSDIGESTS: 'PressDigests',
	SECTORS: 'Sector',
	INDUSTRIES: 'Industry',
	REGIONS: 'Region',
	COMPANIES: 'Company',
	SELECT_ALL: 'All',
	GENERAL: 'General',
	GOVERNMENTS: 'Governments',
	MUNIS: 'Munis',
	CORPORATES: 'Corporates',
	CONVERTIBLES: 'Convertibles',
	COLLATERAL_BACKED: 'CollateralBacked',
	CREDIT_RATINGS_AND_CREDIT_MARKET: 'CreditRatingsAndCreditMarket',
	USINTERESTRATES: 'USInterestRates',
	INTERNATIONALINTERESTRATES: 'InternationalInterestRates',
	ECONOMICINDICATORS: 'EconomicIndicators',
	ENERGY: 'Energy',
	REGULATIONS: 'Regulations',
	TAXPOLICY: 'TaxPolicy',
	USGOVERNMENTANDPOLITICS: 'USGovernmentAndPolitics',
	INTERNATIONALGOVERNMENTANDPOLITICS: 'InternationalGovernmentAndPolitics',
	USSTOCKS_DAILYCLOSINGREPORT: 'USStocks_DailyClosingReport',
	USSTOCKS_MIDDAYREPORTS: 'USStocks_MidDayReports',
	USSTOCKS_PREOPENINGREPORT: 'USStocks_PreOpeningReport',
	USSTOCKS_WEEKLYOUTLOOK: 'USStocks_WeeklyOutlook',
	ALLUSMARKETSCOVERAGE: 'AllUSMarketsCoverage',
	EUROPEANMARKETS: 'EuropeanMarkets',
	LONDONMARKETS: 'LondonMarkets',
	TOKYOMARKETS: 'TokyoMarkets',
	TORONTOMARKETS: 'TorontoMarkets',
	IPOSLISTINGSANDDELISTINGS: 'IPOsListingsAndDelistings',
	CALENDARS: 'Calendars',
	MISCELLANEOUSMARKETS: 'MiscellaneousMarkets',
	MONEYMARKETFUNDS: 'MoneyMarketFunds',
	METALSANDMINING: 'MetalsAndMining',
	AGRICULTURAL: 'Agricultural',
	SELECT_PRESS_DIGESTS_ALL: 'AllPressDigests',
	BASICMATERIALS: 'BasicMaterials',
	CONSUMERCYCLICAL: 'ConsumerCyclical',
	CONSUMERNONCYCLICAL: 'ConsumerNonCyclical',
	HEALTHCARE: 'Healthcare',
	INDUSTRIAL: 'Industrial',
	SERVICES: 'Services',
	TECHNOLOGY: 'Technology',
	TELECOMMUNICATIONS: 'Telecommunications',
	UTILITIES: 'Utilities',
	ADVERTISING: 'Advertising',
	AEROSPACE: 'Aerospace',
	AIRTRANSPORTATION: 'AirTransportation',
	AUTOMOBILESANDAUTOMOTIVEEQUIPMENT: 'AutomobilesAndAutomotiveEquipment',
	BANKING: 'Banking',
	BEVERAGESANDTOBACCO: 'BeveragesAndTobacco',
	BIOTECHNOLOGY: 'Biotechnology',
	BROADCASTINGANDPUBLISHINGMEDIA: 'BroadcastingAndPublishingMedia',
	BUILDINGMATERIALS: 'BuildingMaterials',
	BUSINESSPUBLICSERVICES: 'BusinessPublicServices',
	CHEMICALS: 'Chemicals',
	COMPUTERHARDWARE: 'ComputerHardware',
	COMPUTERSOFTWARE: 'ComputerSoftware',
	CONSTRUCTIONANDPROPERTY: 'ConstructionAndProperty',
	DATAPROCESSINGANDREPRODUCTION: 'DataProcessingAndReproduction',
	ELECTRICALAPPLIANCES: 'ElectricalAppliances',
	ELECTRONICANDTELECOMMEQUIPMENT: 'ElectronicAndTelecommEquipment',
	ENERGYANDRESOURCES: 'EnergyAndResources',
	ENERGYEQUIPMENT: 'EnergyEquipment',
	FINANCIALANDBUSINESSSERVICES: 'FinancialAndBusinessServices',
	FOOD: 'Food',
	FORESTRYANDTIMBER: 'ForestryAndTimber',
	GAMESANDSOFTWARE: 'GamesAndSoftware',
	GOLDANDPRECIOUSMATERIALS: 'GoldAndPreciousMaterials',
	INDUSTRIALCOMPONENTS: 'IndustrialComponents',
	INSURANCE: 'Insurance',
	INTERNET: 'Internet',
	IRONANDSTEEL: 'IronAndSteel',
	ISLAMICFINANCE: 'IslamicFinance',
	METALGOODSANDENGINEERING: 'MetalGoodsAndEngineering',
	MISCELLANOUSMATERIALS: 'MiscellanousMaterials',
	MULTIINDUSTRY: 'MultiIndustry',
	NONFERROUSMETALS: 'NonFerrousMetals',
	PHARMACEUTICALSANDPERSONALCARE: 'PharmaceuticalsAndPersonalCare',
	PLASTICS: 'Plastics',
	REALESTATE: 'RealEstate',
	RECREATIONALANDSPORTSGOODS: 'RecreationalAndSportsGoods',
	RETAIL: 'Retail',
	ROADANDRAIL: 'RoadAndRail',
	SEMICONDUCTORSANDCOMPONENTS: 'SemiconductorsAndComponents',
	SHIPPING: 'Shipping',
	TELECOMMUNICATIONSSERVICES: 'TelecommunicationsServices',
	TELEVISIONBROADCASTING: 'TelevisionBroadcasting',
	TEXTILESANDCLOTHING: 'TextilesAndClothing',
	TOURISMANDLEISURE: 'TourismAndLeisure',
	WHOLESALE: 'Wholesale',
	EUROPE: 'Europe',
	ASIA: 'Asia',
	MIDDLEEAST: 'MiddleEast',
	SOUTHANDCENTRALAMERICA: 'SouthAndCentralAmerica',
	AFRICA: 'Africa',
	DIVIDENDS: 'Dividends',
	EARNINGSANDFORECASTS: 'EarningsAndForecasts',
	BANKRUPTCIESCORPORATEFINANCE: 'BankruptciesCorporateFinance',
	MERGERSANDACQUISITIONS: 'MergersAndAcquisitions',
	MANAGEMENTANNOUNCEMENTS: 'ManagementAnnouncements',
	ANALYSIS: 'Analysis',
	PRESSRELEASES: 'PressReleases',
};
export const SUB_CATEGORY_DROPDOWN_DATA: any = {
	Economic: [
		{
			key: FIELD_VALUES.SELECT_ALL,
			value: 'Select All',
		},
		{
			key: FIELD_VALUES.USINTERESTRATES,
			value: 'US Interest Rates',
		},
		{
			key: FIELD_VALUES.INTERNATIONALINTERESTRATES,
			value: 'International Interest Rates',
		},
		{
			key: FIELD_VALUES.ECONOMICINDICATORS,
			value: 'Economic Indicators',
		},
		{
			key: FIELD_VALUES.ENERGY,
			value: 'Energy',
		},
	],
	Government: [
		{
			key: FIELD_VALUES.SELECT_ALL,
			value: 'Select All',
		},
		{
			key: FIELD_VALUES.REGULATIONS,
			value: 'Regulations',
		},
		{
			key: FIELD_VALUES.TAXPOLICY,
			value: 'Tax Policy',
		},
		{
			key: FIELD_VALUES.USGOVERNMENTANDPOLITICS,
			value: 'US Government and Politics',
		},
		{
			key: FIELD_VALUES.INTERNATIONALGOVERNMENTANDPOLITICS,
			value: 'International Government and Politics',
		},
	],
	Markets: [
		{
			key: FIELD_VALUES.SELECT_ALL,
			value: 'Select All',
		},
		{
			key: FIELD_VALUES.USSTOCKS_DAILYCLOSINGREPORT,
			value: 'US Stocks Daily Closing Report',
		},
		{
			key: FIELD_VALUES.USSTOCKS_MIDDAYREPORTS,
			value: 'US Stocks Mid-Day Report',
		},
		{
			key: FIELD_VALUES.USSTOCKS_PREOPENINGREPORT,
			value: 'US Stocks Pre-Opening Report',
		},
		{
			key: FIELD_VALUES.USSTOCKS_WEEKLYOUTLOOK,
			value: 'US Stocks Weekly Outlook',
		},
		{
			key: FIELD_VALUES.ALLUSMARKETSCOVERAGE,
			value: 'All US Markets Coverage',
		},
		{
			key: FIELD_VALUES.EUROPEANMARKETS,
			value: 'European Markets',
		},
		{
			key: FIELD_VALUES.LONDONMARKETS,
			value: 'London Markets',
		},
		{
			key: FIELD_VALUES.TOKYOMARKETS,
			value: 'Tokyo Markets',
		},
		{
			key: FIELD_VALUES.TORONTOMARKETS,
			value: 'Toronto Markets',
		},
		{
			key: FIELD_VALUES.IPOSLISTINGSANDDELISTINGS,
			value: 'IPO Listings and Delistings',
		},
		{
			key: FIELD_VALUES.CALENDARS,
			value: 'Calendars',
		},
		{
			key: FIELD_VALUES.MISCELLANEOUSMARKETS,
			value: 'Miscellaneous Markets',
		},
	],
	Bonds: [
		{
			key: FIELD_VALUES.SELECT_ALL,
			value: 'Select All',
		},
		{
			key: FIELD_VALUES.GENERAL,
			value: 'General',
		},
		{
			key: FIELD_VALUES.GOVERNMENTS,
			value: 'Governments',
		},
		{
			key: FIELD_VALUES.MUNIS,
			value: 'Munis',
		},
		{
			key: FIELD_VALUES.CORPORATES,
			value: 'Corporates',
		},
		{
			key: FIELD_VALUES.CONVERTIBLES,
			value: 'Convertibles',
		},
		{
			key: FIELD_VALUES.COLLATERAL_BACKED,
			value: 'Collateral Backed',
		},
		{
			key: FIELD_VALUES.CREDIT_RATINGS_AND_CREDIT_MARKET,
			value: 'Credit Ratings and Credit Market',
		},
	],
	Funds: [
		{
			key: FIELD_VALUES.SELECT_ALL,
			value: 'Select All',
		},
		{
			key: FIELD_VALUES.GENERAL,
			value: 'General',
		},
		{
			key: FIELD_VALUES.MONEYMARKETFUNDS,
			value: 'Money Market Funds',
		},
	],
	Commodities: [
		{
			key: FIELD_VALUES.SELECT_ALL,
			value: 'Select All',
		},
		{
			key: FIELD_VALUES.GENERAL,
			value: 'General',
		},
		{
			key: FIELD_VALUES.ENERGY,
			value: 'Energy',
		},
		{
			key: FIELD_VALUES.METALSANDMINING,
			value: 'Metals and Mining',
		},
		{
			key: FIELD_VALUES.AGRICULTURAL,
			value: 'Agricultural',
		},
	],
	PressDigests: [
		{
			key: FIELD_VALUES.SELECT_PRESS_DIGESTS_ALL,
			value: 'Select All',
		},
		{
			key: FIELD_VALUES.WALL_STREET_JOURNAL,
			value: 'Wall Street Journal',
		},
	],
	Sector: [
		{
			key: FIELD_VALUES.SELECT_ALL,
			value: 'Select All',
		},
		{
			key: FIELD_VALUES.BASICMATERIALS,
			value: 'Basic Materials',
		},
		{
			key: FIELD_VALUES.CONSUMERCYCLICAL,
			value: 'Consumer Cyclical',
		},
		{
			key: FIELD_VALUES.CONSUMERNONCYCLICAL,
			value: 'Consumer NonCyclical',
		},
		{
			key: FIELD_VALUES.ENERGY,
			value: 'Energy',
		},
		{
			key: FIELD_VALUES.HEALTHCARE,
			value: 'Healthcare',
		},
		{
			key: FIELD_VALUES.INDUSTRIAL,
			value: 'Industrial',
		},
		{
			key: FIELD_VALUES.SERVICES,
			value: 'Services',
		},
		{
			key: FIELD_VALUES.TECHNOLOGY,
			value: 'Technology',
		},
		{
			key: FIELD_VALUES.TELECOMMUNICATIONS,
			value: 'Telecommunications',
		},
		{
			key: FIELD_VALUES.UTILITIES,
			value: 'Utilities',
		},
	],
	Industry: [
		{
			key: FIELD_VALUES.SELECT_ALL,
			value: 'Select All',
		},
		{
			key: FIELD_VALUES.ADVERTISING,
			value: 'Advertising',
		},
		{
			key: FIELD_VALUES.AEROSPACE,
			value: 'Aerospace',
		},
		{
			key: FIELD_VALUES.AIRTRANSPORTATION,
			value: 'Air Transportation',
		},
		{
			key: FIELD_VALUES.AUTOMOBILESANDAUTOMOTIVEEQUIPMENT,
			value: 'Automobiles and Automotive Equipment',
		},
		{
			key: FIELD_VALUES.BANKING,
			value: 'Banking',
		},
		{
			key: FIELD_VALUES.BEVERAGESANDTOBACCO,
			value: 'Beverages and Tobacco',
		},
		{
			key: FIELD_VALUES.BIOTECHNOLOGY,
			value: 'Biotechnology',
		},
		{
			key: FIELD_VALUES.BROADCASTINGANDPUBLISHINGMEDIA,
			value: 'Broadcasting and Publishing Media',
		},
		{
			key: FIELD_VALUES.BUILDINGMATERIALS,
			value: 'Building Materials',
		},
		{
			key: FIELD_VALUES.BUSINESSPUBLICSERVICES,
			value: 'Business Public Services',
		},
		{
			key: FIELD_VALUES.CHEMICALS,
			value: 'Chemicals',
		},
		{
			key: FIELD_VALUES.COMPUTERHARDWARE,
			value: 'Computer Hardware',
		},
		{
			key: FIELD_VALUES.COMPUTERSOFTWARE,
			value: 'Computer Software',
		},
		{
			key: FIELD_VALUES.CONSTRUCTIONANDPROPERTY,
			value: 'Construction and Property',
		},
		{
			key: FIELD_VALUES.DATAPROCESSINGANDREPRODUCTION,
			value: 'Data Processing and Reproduction',
		},
		{
			key: FIELD_VALUES.ELECTRICALAPPLIANCES,
			value: 'Electrical Appliances',
		},
		{
			key: FIELD_VALUES.ELECTRONICANDTELECOMMEQUIPMENT,
			value: 'Electronic and Telecomm Equipment',
		},
		{
			key: FIELD_VALUES.ENERGYANDRESOURCES,
			value: 'Energy and Resources',
		},
		{
			key: FIELD_VALUES.ENERGYEQUIPMENT,
			value: 'Energy Equipment',
		},
		{
			key: FIELD_VALUES.FINANCIALANDBUSINESSSERVICES,
			value: 'Financial and Business Services',
		},
		{
			key: FIELD_VALUES.FOOD,
			value: 'Food',
		},
		{
			key: FIELD_VALUES.FORESTRYANDTIMBER,
			value: 'Forestry and Timber',
		},
		{
			key: FIELD_VALUES.GAMESANDSOFTWARE,
			value: 'Games and Software',
		},
		{
			key: FIELD_VALUES.GOLDANDPRECIOUSMATERIALS,
			value: 'Gold and Precious Materials',
		},
		{
			key: FIELD_VALUES.INDUSTRIALCOMPONENTS,
			value: 'Industrial Components',
		},
		{
			key: FIELD_VALUES.INSURANCE,
			value: 'Insurance',
		},
		{
			key: FIELD_VALUES.INTERNET,
			value: 'Internet',
		},
		{
			key: FIELD_VALUES.IRONANDSTEEL,
			value: 'Iron and Steel',
		},
		{
			key: FIELD_VALUES.ISLAMICFINANCE,
			value: 'Islamic Finance',
		},
		{
			key: FIELD_VALUES.METALGOODSANDENGINEERING,
			value: 'Metal Goods and Engineering',
		},
		{
			key: FIELD_VALUES.MISCELLANOUSMATERIALS,
			value: 'Miscellanous Materials',
		},
		{
			key: FIELD_VALUES.MULTIINDUSTRY,
			value: 'Multi-Industry',
		},
		{
			key: FIELD_VALUES.NONFERROUSMETALS,
			value: 'Non-Ferrous Metals',
		},
		{
			key: FIELD_VALUES.PHARMACEUTICALSANDPERSONALCARE,
			value: 'Pharmaceuticals and Personal Care',
		},
		{
			key: FIELD_VALUES.PLASTICS,
			value: 'Plastics',
		},
		{
			key: FIELD_VALUES.REALESTATE,
			value: 'Real Estate',
		},
		{
			key: FIELD_VALUES.RECREATIONALANDSPORTSGOODS,
			value: 'Recreational and Sports Goods',
		},
		{
			key: FIELD_VALUES.RETAIL,
			value: 'Retail',
		},
		{
			key: FIELD_VALUES.ROADANDRAIL,
			value: 'Road and Rail',
		},
		{
			key: FIELD_VALUES.SEMICONDUCTORSANDCOMPONENTS,
			value: 'Semiconductors and Components',
		},
		{
			key: FIELD_VALUES.SHIPPING,
			value: 'Shipping',
		},
		{
			key: FIELD_VALUES.TELECOMMUNICATIONSSERVICES,
			value: 'Telecommunications Services',
		},
		{
			key: FIELD_VALUES.TELEVISIONBROADCASTING,
			value: 'Television Broadcasting',
		},
		{
			key: FIELD_VALUES.TEXTILESANDCLOTHING,
			value: 'Textiles and Clothing',
		},
		{
			key: FIELD_VALUES.TOURISMANDLEISURE,
			value: 'Tourism and Leisure',
		},
		{
			key: FIELD_VALUES.UTILITIES,
			value: 'Utilities',
		},
		{
			key: FIELD_VALUES.WHOLESALE,
			value: 'Wholesale',
		},
	],
	Region: [
		{
			key: FIELD_VALUES.SELECT_ALL,
			value: 'Select All',
		},
		{
			key: FIELD_VALUES.EUROPE,
			value: 'Europe',
		},
		{
			key: FIELD_VALUES.ASIA,
			value: 'Asia',
		},
		{
			key: FIELD_VALUES.MIDDLEEAST,
			value: 'Middle East',
		},
		{
			key: FIELD_VALUES.SOUTHANDCENTRALAMERICA,
			value: 'South and Central America',
		},
		{
			key: FIELD_VALUES.AFRICA,
			value: 'Africa',
		},
	],
	Company: [
		{
			key: FIELD_VALUES.SELECT_ALL,
			value: 'Select All',
		},
		{
			key: FIELD_VALUES.DIVIDENDS,
			value: 'Dividends',
		},
		{
			key: FIELD_VALUES.EARNINGSANDFORECASTS,
			value: 'Earnings and Forecasts',
		},
		{
			key: FIELD_VALUES.BANKRUPTCIESCORPORATEFINANCE,
			value: 'Bankruptcies Corporate Finance',
		},
		{
			key: FIELD_VALUES.MERGERSANDACQUISITIONS,
			value: 'Mergers and Acquisitions',
		},
		{
			key: FIELD_VALUES.IPOSLISTINGSANDDELISTINGS,
			value: 'IPO Listings and Delistings',
		},
		{
			key: FIELD_VALUES.MANAGEMENTANNOUNCEMENTS,
			value: 'Management Announcements',
		},
		{
			key: FIELD_VALUES.ANALYSIS,
			value: 'Analysis',
		},
		{
			key: FIELD_VALUES.PRESSRELEASES,
			value: 'Press Releases',
		},
	],
};

const FIELD_NAMES = {
	MARKETS: 'Markets',
	PRESSDIGESTS: 'PressDigests',
	TODAY: 'DocumentDate',
	PAST7DAYS: 'DocumentDate',
	PAST1MONTH: 'DocumentDate',
	ECONOMIC: 'Economic',
	GOVERNMENT: 'Government',
	BONDS: 'Bonds',
	FUNDS: 'Funds',
	COMMODITIES: 'Commodities',
	SECTORS: 'Sector',
	INDUSTRIES: 'Industry',
	REGIONS: 'Region',
	COMPANIES: 'Company',
	SELECT_ALL: 'All',
	GENERAL: 'General',
	GOVERNMENTS: 'Governments',
	MUNIS: 'Munis',
	CORPORATES: 'Corporates',
	CONVERTIBLES: 'Convertibles',
	COLLATERAL_BACKED: 'CollateralBacked',
	CREDIT_RATINGS_AND_CREDIT_MARKET: 'CreditRatingsAndCreditMarket',
};
export const FIELD_VALUE_MAPPING = {
	[FIELD_VALUES.WALL_STREET_JOURNAL]: FIELD_NAMES.PRESSDIGESTS,
	[FIELD_VALUES.US_STOCKS_MID_DAY_REPORTS]: FIELD_NAMES.MARKETS,
	[FIELD_VALUES.US_STOCKS_WEEKLY_OUTLOOK]: FIELD_NAMES.MARKETS,
	[FIELD_VALUES.TODAY]: FIELD_NAMES.TODAY,
	[FIELD_VALUES.PAST7DAYS]: FIELD_NAMES.PAST7DAYS,
	[FIELD_VALUES.PAST1MONTH]: FIELD_NAMES.PAST1MONTH,
	[FIELD_VALUES.ECONOMIC]: FIELD_NAMES.ECONOMIC,
	[FIELD_VALUES.GOVERNMENT]: FIELD_NAMES.GOVERNMENT,
	[FIELD_VALUES.MARKETS]: FIELD_NAMES.MARKETS,
	[FIELD_VALUES.BONDS]: FIELD_NAMES.BONDS,
	[FIELD_VALUES.FUNDS]: FIELD_NAMES.FUNDS,
	[FIELD_VALUES.COMMODITIES]: FIELD_NAMES.COMMODITIES,
	[FIELD_VALUES.PRESSDIGESTS]: FIELD_NAMES.PRESSDIGESTS,
	[FIELD_VALUES.SECTORS]: FIELD_NAMES.SECTORS,
	[FIELD_VALUES.INDUSTRIES]: FIELD_NAMES.INDUSTRIES,
	[FIELD_VALUES.REGIONS]: FIELD_NAMES.REGIONS,
	[FIELD_VALUES.COMPANIES]: FIELD_NAMES.COMPANIES,
	[FIELD_VALUES.SELECT_ALL]: FIELD_NAMES.SELECT_ALL,
	[FIELD_VALUES.GENERAL]: FIELD_NAMES.GENERAL,
	[FIELD_VALUES.GOVERNMENTS]: FIELD_NAMES.GOVERNMENTS,
	[FIELD_VALUES.MUNIS]: FIELD_NAMES.MUNIS,
	[FIELD_VALUES.CORPORATES]: FIELD_NAMES.CORPORATES,
	[FIELD_VALUES.CONVERTIBLES]: FIELD_NAMES.CONVERTIBLES,
	[FIELD_VALUES.COLLATERAL_BACKED]: FIELD_NAMES.COLLATERAL_BACKED,
	[FIELD_VALUES.CREDIT_RATINGS_AND_CREDIT_MARKET]:
		FIELD_NAMES.CREDIT_RATINGS_AND_CREDIT_MARKET,
};
export const TIME_FRAME_DROPDOWN_DATA = [
	{
		key: FIELD_VALUES.TODAY,
		value: 'Today',
	},
	{
		key: FIELD_VALUES.PAST7DAYS,
		value: 'Past 7 Days',
	},
	{
		key: FIELD_VALUES.PAST1MONTH,
		value: 'Past 1 Month',
	},
];
export const CATEGORY_DROPDOWN_DATA = [
	{
		key: FIELD_VALUES.ECONOMIC,
		value: 'Economic',
	},
	{
		key: FIELD_VALUES.GOVERNMENT,
		value: 'Government',
	},
	{
		key: FIELD_VALUES.MARKETS,
		value: 'Markets',
	},
	{
		key: FIELD_VALUES.BONDS,
		value: 'Bonds',
	},
	{
		key: FIELD_VALUES.FUNDS,
		value: 'Funds',
	},
	{
		key: FIELD_VALUES.COMMODITIES,
		value: 'Commodities',
	},
	{
		key: FIELD_VALUES.PRESSDIGESTS,
		value: 'Press Digests',
	},
	{
		key: FIELD_VALUES.SECTORS,
		value: 'Sectors',
	},
	{
		key: FIELD_VALUES.INDUSTRIES,
		value: 'Industries',
	},
	{
		key: FIELD_VALUES.REGIONS,
		value: 'Regions',
	},
	{
		key: FIELD_VALUES.COMPANIES,
		value: 'Companies',
	},
];

export const DATE_FORMAT_DM_CALL = 'YYYY-MM-DD';
export const MAX_RECORDS = 100;
