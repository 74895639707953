import React, { useState, useRef, useEffect } from 'react';
import './multi-select.scss';
import { createUUID } from '../../../utilities/utils';
import { ChevronArrow, Check } from '../../../assets/Icons';
interface MultiSelectProps {
	onChangeHandler?: (event: any[], id: string) => void;
	data: Array<{
		key: any;
		value: string;
	}>;
	label?: string;
	styles?: React.CSSProperties;
	liStyle?: React.CSSProperties;
	id?: string;
	selectedEventsText?: string;
	displayName?: string;
	dataTestId?: string;
}

const MultiSelect = (props: MultiSelectProps) => {
	const [containerStyle] = useState({});
	const [isListOpen, setIsListOpen] = useState<boolean>(false);
	const { data, id } = props;
	const refDropDown = useRef<any>();
	const [selectedValue, setSelectedValue] = useState<any[]>([]);

	useEffect(() => {
		if (props.selectedEventsText) {
			if (props.selectedEventsText) {
				const eventsList: any = [];
				const value = props.selectedEventsText.split(',');
				value.forEach((item: any) => {
					if (item) {
						eventsList.push(item);
					}
				});
				setSelectedValue(eventsList);
			}
		}
	}, [props.selectedEventsText]);

	let liIndex = -1;
	const span =
		props.label && props.label.length > 0 ? <span>{props.label} </span> : null;
	const guid = id ?? createUUID();

	bindEvents();

	function bindEvents() {
		document.addEventListener('click', handleClickOutside);
	}

	function openCloseMenu(e?: any) {
		e?.stopPropagation();
		setIsListOpen(!isListOpen);
	}

	function handleKeyOpenClose(e?: any) {
		if (e.key === 'Enter') {
			e?.stopPropagation();
			setIsListOpen(!isListOpen);
		}
	}

	function handleKeys(e?: any) {
		const sibling = e.currentTarget.nextElementSibling;
		const renderedLi = sibling?.querySelectorAll('li');
		const lengthrenderedLi = (renderedLi && renderedLi.length - 1) || 2;
		if (e.key === 'Enter') {
			handleEnterEvent(renderedLi, e);
		} else {
			const keyCode = e.which;
			renderedLi?.forEach((item: any) => {
				item.classList.remove('li-hover');
			});
			if (keyCode === 40) {
				liIndex++;
				if (liIndex > lengthrenderedLi) liIndex = 0;
			} else if (keyCode === 38) {
				liIndex--;
				if (liIndex < 0) liIndex = lengthrenderedLi;
			}
			const ci = renderedLi?.[liIndex];
			ci?.classList.add('li-hover');
		}
	}

	function handleEnterEvent(renderedLi: any, e: any) {
		const value = renderedLi[liIndex]
			?.getAttribute('data-value')
			?.toLowerCase();
		const content = [...selectedValue];
		if (value) {
			if (selectedValue?.find((x) => x === value)) {
				const index = content.indexOf(value);
				content.splice(index, 1);
			} else {
				content.push(value);
			}
		}
		setSelectedValue(content);
		if (props.onChangeHandler) {
			props.onChangeHandler(content, guid);
		}
		openCloseMenu(e);
	}

	function handleChangeEvent(e: any) {
		const value =
			e.currentTarget?.getAttribute('data-value')?.toLowerCase() || '';
		const content = [...selectedValue];
		if (value) {
			if (selectedValue.find((x) => x === value)) {
				const index = content.indexOf(value);
				content.splice(index, 1);
			} else {
				content.push(value);
			}
		}
		setSelectedValue(content);
		if (props.onChangeHandler) {
			props.onChangeHandler(content, guid);
		}
		if (e.key === 'Enter') {
			handleKeys();
		} else {
			openCloseMenu(e);
		}
	}

	const renderDDLOption = (item: any) => {
		const isSelected =
			selectedValue.find((x) => x === item.value.toLowerCase()) ===
			item.value.toLowerCase();
		return (
			<React.Fragment key={'li_' + item.key}>
				<li
					tabIndex={0}
					onClick={handleChangeEvent}
					onKeyDown={handleChangeEvent}
					className={`${isListOpen ? '' : 'hide'} ${
						isSelected ? 'selected' : ''
					}`}
					data-value={item.value}
					data-key={item.key}
					id={id ?? ''}
				>
					<span className={'check-icon'}>{isSelected && <Check />}</span>
					{item.icon && <span>{item.icon}</span>}
					<span className={'name'} title={item.value}>
						{item.value}
					</span>
				</li>
			</React.Fragment>
		);
	};
	function bindDropDownValues(dropdownData: any): React.ReactNode {
		const dropdownBody =
			dropdownData && dropdownData.length > 0
				? dropdownData.map((item: any) => renderDDLOption(item))
				: null;
		return dropdownBody;
	}

	function handleClickOutside(e: any) {
		if (isListOpen && !refDropDown?.current?.contains(e.target)) {
			openCloseMenu();
		}
	}

	return (
		<div className={'dropDownMain'} data-type="multi-select">
			{span}
			<ul
				className={`basicDropDownContainer
          ${isListOpen ? 'open' : ''} DropDownUL`}
				style={props.styles}
				id={guid}
				data-testid={props.dataTestId ?? 'dropDownId'}
				ref={refDropDown}
				onKeyDown={handleKeyOpenClose}
			>
				<li
					tabIndex={0}
					onClick={openCloseMenu}
					onKeyDown={handleKeys}
					id="DropdownLabel"
					className={'selected-value'}
				>
					{
						<React.Fragment>
							<span className={'name'}>{props.displayName}</span>
							<span className={'caret-icon'}>
								<ChevronArrow type={isListOpen ? 'up' : 'down'} />
							</span>
						</React.Fragment>
					}
				</li>
				<li
					className={`${
						isListOpen ? '' : 'hide'
					} basicDropDownValueContainer dropDownList`}
					style={{ ...containerStyle, ...props.liStyle }}
				>
					<ul>{bindDropDownValues(data)}</ul>
				</li>
			</ul>
		</div>
	);
};

export default MultiSelect;
