import React from 'react';
import {
	OptionChainTableKeys,
	OPTIONS_ACTIONS,
} from '../../../../../options/options-constants';
import { generateColValue } from '../../../../../../components/quotes-news/portfolio/portfolio-utils';
import {
	ICustomTableMapping,
	IOptionQuoteItem,
	IUnderlyingQuote,
} from '../../../../../../types/interfaces';
import { useOptionsContext } from '../../../../../snapshots/snapshot-container';
import {
	getDeviceType,
	handleEnterKeyPress,
} from '../../../../../../utilities/utils';
import { E_DeviceType } from '../../../../../../constants/appConstants';
import { getXrefDataByXids } from '../../../../../../services';
import { Label, LabelLink } from '../../../../../../@core-ui';
import OptionDetails from './../../../../option-details-popover/option-details';
import './options-chain-table.scss';
import { getOptionProfileData } from '../../../../options-utils';

export interface IOptionChainTableProps {
	data: IOptionQuoteItem[];
	symbol: string;
	tableMapping: ICustomTableMapping[];
	isCall: boolean;
	inMoney: boolean;
	expiryDate: string;
	showHeader: boolean;
	underlyingQuote?: IUnderlyingQuote;
	excludeStrike?: boolean;
}

const OptionChainTable: React.FC<IOptionChainTableProps> = ({
	data,
	symbol,
	tableMapping,
	underlyingQuote,
	isCall,
	inMoney,
	excludeStrike,
	expiryDate,
	showHeader,
}) => {
	const { setMasterStateMemo } = useOptionsContext();
	const isIpadView = getDeviceType() === E_DeviceType.Ipad;
	const isMobileView = getDeviceType() === E_DeviceType.Mobile;

	const fetchOptionSymbolData = async (xid: number) => {
		const response = await getXrefDataByXids([xid]);
		return response?.data?.data?.items?.[0]?.symbol;
	};

	const onClickSymbol = async (item: IOptionQuoteItem) => {
		const symbol = await fetchOptionSymbolData(item.xid);
		setMasterStateMemo({
			action: OPTIONS_ACTIONS.VIEW_OPTION_PROFILE,
			data: {
				item,
				expiryDate,
				isCall,
				symbol,
				underlyingQuote,
				profileData: getOptionProfileData(item, expiryDate, underlyingQuote),
			},
		});
	};

	const generateCallsOrPutsRow = () => {
		if (!showHeader) return null;
		return (
			<Label
				isDarkGrey
				text={isCall ? 'Calls' : 'Puts'}
				size="ms"
				className="calls-puts-row "
			/>
		);
	};

	const handleRows = (
		idx: number,
		item: IOptionQuoteItem,
		isMobileStrike: boolean,
	) => {
		return (
			<div className="option-symbol" key={`row_${idx}`}>
				{!isMobileStrike ? (
					<OptionDetails
						data={getOptionProfileData(item, expiryDate, underlyingQuote)}
						displayName={symbol?.toUpperCase()}
						xid={item.xid}
						onClick={() => onClickSymbol(item)}
					/>
				) : (
					<LabelLink
						text={item?.strikePrice?.toString() ?? ''}
						isHideTitle={true}
						size={'s'}
						onClick={() => onClickSymbol(item)}
						onKeyDown={(e) => handleEnterKeyPress(e, () => onClickSymbol(item))}
					/>
				)}
			</div>
		);
	};

	const getCallPutColor = () => (inMoney && isCall) || (!inMoney && !isCall);

	const mappedTable = (quoteItem: any, item: IOptionQuoteItem) => {
		return (
			<div className="common-row" key={`row_${item.xid}`}>
				{tableMapping?.map((mapping: ICustomTableMapping, idx: number) => {
					const isMobileStrike =
						isMobileView && mapping.key === OptionChainTableKeys.StrikePrice;
					if (mapping.isCalculated || isMobileStrike) {
						mapping.onClick = () => {
							onClickSymbol(item);
						};

						if (!isIpadView) {
							return handleRows(idx, item, isMobileStrike);
						}
					}
					if (
						excludeStrike &&
						mapping.key === OptionChainTableKeys.StrikePrice
					) {
						return null;
					}

					return (
						<div key={`col_${mapping.key}`} className={mapping.className}>
							{generateColValue(
								mapping,
								quoteItem[mapping.key as keyof typeof quoteItem] as string,
								null,
							)}
						</div>
					);
				})}
			</div>
		);
	};

	const getCallPutData = () => {
		const showColor = getCallPutColor();
		return (
			<>
				{generateCallsOrPutsRow()}
				<div
					className={`option-chain-table-content ${
						showColor ? 'in-money' : ''
					}`}
				>
					{data?.map((item: IOptionQuoteItem) => {
						const quoteItem = {
							symbol: symbol?.toUpperCase(),
							last: item?.quote?.lastTrade?.last,
							change: underlyingQuote?.lastTrade?.change,
							volume: item?.quote?.volume?.last,
							bid: item?.quote?.bid?.price,
							ask: item?.quote?.ask?.price,
							openInterest: item?.quote?.openInterest,
							strikePrice: item?.strikePrice,
						};
						return mappedTable(quoteItem, item);
					})}
				</div>
			</>
		);
	};

	return <div className="option-chain-table-wrapper">{getCallPutData()}</div>;
};

export default OptionChainTable;
