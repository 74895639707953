/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import './overview-page.scss';
import { DEFAULT_CHARTS_PRICE_OBJ } from '../../../../shared/chartsPrice/ChartsPriceConstants';
import ChartsPrice from '../../../../shared/chartsPrice/ChartsPrice';
import QuoteBar from '../../../shared/quote-bar/quote-bar';
import {
	Heading,
	Label,
	LabelLink,
	Loader,
	Separator,
} from '../../../../@core-ui';
import {
	CompanyProfileHeader,
	CompanyProfileMapping,
	SelectedChartPeriod,
	OverviewTitle,
} from './overview-page-constants';
import {
	delayedMessage,
	getDeviceType,
	handleEnterKeyPress,
	handleWindowResize,
} from '../../../../utilities/utils';
import Profile from '../../../../shared/profile/profile';
import {
	getCurrentDividends,
	getEquitiesCurrentRatios,
	getLipperFundData,
	getPricingCurrentEndOfDayStats,
	getQuotes,
	getHistoricalDividends,
} from '../../../../services';
import { DATE_FORMATS } from '../../../../utilities/date-time-formatter';
import {
	ERROR_MESSAGES,
	E_DeviceType,
	T_DeviceType,
} from '../../../../constants/appConstants';
import {
	convertUtcDttmToET,
	getConvertedDate,
} from '../../../../utilities/date-time-formatter-tz';
import { E_SecurityTypes } from '../../../shared/search/symbol-search-modal/symbol-search-modal-constants';
import { E_SecurityType } from '../../snapshot-constants';
import NoData from '../../../../shared/NoData/NoData';
import { setCompanyProfileData } from './overview-page-utils';

interface OverviewPageProps {
	venueXid: number;
	entityXid: number;
	classificationID: number;
	isMF: boolean;
	isEquity: boolean;
	fundNo: number;
	isQuickQuote: boolean;
	isMMF?: boolean;
}

const OverviewPage: React.FC<OverviewPageProps> = ({
	venueXid,
	entityXid,
	classificationID,
	isMF,
	isEquity,
	fundNo,
	isQuickQuote = false,
	isMMF = false,
}) => {
	const [chartConfig, setChartConfig] = useState<any>(null);
	const [chartPeriod, setChartPeriod] = useState<string>(
		SelectedChartPeriod.Day,
	);
	const [chartDate, setChartDate] = useState<string>();
	const [isChart, setIsChart] = useState<boolean>(true);
	const [device, setDevice] = useState<T_DeviceType>(getDeviceType());
	const [profileData, setProfileData] = useState<any>();
	const [isReady, setIsReady] = useState<boolean>(false);
	const [mfQuoteBarData, setMfQuoteBarData] = useState<any>();
	const isMobile = device === E_DeviceType.Mobile;
	const [selectedDelayedMinutes, setSelectedDelayedMinutes] = useState();
	const [selectedDelayedDateTime, setSelectedDelayedDateTime] = useState('--');

	useEffect(() => {
		setIsReady(false);
		setChartPeriod(SelectedChartPeriod.Day);
		setChartConfig(getChartConfig(SelectedChartPeriod.Day));
		getProfileData(entityXid, fundNo);
	}, [venueXid, fundNo, entityXid]);

	useEffect(() => {
		handleWindowResize(() => {
			setDevice(getDeviceType());
		});
	}, []);

	const getSecurityType = () => {
		if (isEquity) {
			return E_SecurityType.equity;
		} else if (isMF) {
			return E_SecurityType.mf;
		} else {
			return E_SecurityType.etf;
		}
	};

	const type = getSecurityType();

	const getChartHeight = () => {
		if (device === E_DeviceType.Mobile) {
			return 133;
		} else if (device === E_DeviceType.Ipad) {
			return 152;
		} else {
			return 172;
		}
	};

	const getChartWidth = () => {
		if (device === E_DeviceType.Mobile) {
			return 270;
		} else if (device === E_DeviceType.Ipad) {
			return 307;
		} else {
			return isQuickQuote ? 300 : 399;
		}
	};

	const getChartConfig = (selectedPeriod: string) => {
		const chartInfo: any = JSON.parse(JSON.stringify(DEFAULT_CHARTS_PRICE_OBJ));
		chartInfo['instruments'][0].venueXid = venueXid;
		chartInfo['panels'][0].height = getChartHeight();
		chartInfo['panels'][0].axisY.label.baseLine = true;
		chartInfo['panels'][0].axisY.label.magnitudeFormatting = true;
		chartInfo['width'] = getChartWidth();
		if (isMF) {
			chartInfo.data['days'] = 30;
			chartInfo.data['period'] = 'day';
		} else if (selectedPeriod === SelectedChartPeriod.Week) {
			chartInfo.data['days'] = 5;
		}
		const chartGradientColor =
			chartInfo?.styles?.markers?.price?.primary?.fill?.normalHours?.colorIndex;
		if (chartGradientColor)
			chartInfo.styles.markers.price.primary.fill.normalHours.colorIndex = 6;
		return chartInfo;
	};

	const getProfileData = async (entityXid: number, fundNo: number) => {
		let currentRatiosData;
		let dividendsData;
		let historicalData;
		let quoteData;
		let fundData;
		let pricingEndOfStats;
		let mmfData;
		let lastDistributionDate;

		if (!isMF) {
			setIsReady(false);
			const [
				currentRatiosResponse,
				dividendsResponse,
				historicalResponse,
				quoteResponse,
			] = await Promise.all([
				getEquitiesCurrentRatios(entityXid),
				getCurrentDividends(venueXid),
				getHistoricalDividends(venueXid),
				getQuotes([venueXid]),
			]);
			currentRatiosData = currentRatiosResponse?.data?.data?.ratios;
			dividendsData = dividendsResponse?.data?.data;
			historicalData = historicalResponse;
			quoteData = quoteResponse?.data?.data?.quotes?.[0];
			if (classificationID === E_SecurityTypes.ETFs) {
				pricingEndOfStats = await getPricingCurrentEndOfDayStats(venueXid);
			}
		} else {
			setIsReady(false);
			const [fundsResponse, quoteResponse, mmfQuoteResponse] =
				await Promise.all([
					getLipperFundData(fundNo),
					getQuotes([venueXid], false, true),
					isMMF ? getQuotes([venueXid]) : Promise.resolve(null),
				]);
			fundData = fundsResponse?.data?.data[0];
			quoteData = quoteResponse?.data?.data?.quotes?.[0];
			lastDistributionDate = getConvertedDate(fundData?.Date_Inc_Div, {
				format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
				isMsDate: true,
			});
			mmfData = mmfQuoteResponse?.data?.data?.quotes?.[0]?.data;

			setMfQuoteBarData({
				yield: isMMF ? mmfData?.sec7DayYield?.subsidized : '--',
				yieldAsof: quoteData?.data?.nav?.date
					? convertUtcDttmToET(quoteData?.data.nav.date, {
							format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
						})
					: '--',
				nav: quoteData?.data?.lastTrade?.last,
				navAsOf: convertUtcDttmToET(quoteData?.data?.lastTrade?.date, {
					format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
				}),
				change: quoteData?.data?.lastTrade?.change,
				changePercent: quoteData?.data?.changePercent?.today,
			});
		}

		const companyProfileData = setCompanyProfileData({
			quoteData,
			currentRatiosData,
			dividendsData,
			pricingEndOfStats,
			historicalData,
			fundData,
			lastDistributionDate,
			type,
		});
		setProfileData(companyProfileData);
		setIsReady(true);
	};

	const chartPeriodHandler = (period: string) => {
		setChartPeriod(period);
		setChartConfig(getChartConfig(period));
	};

	const chartPriceHandler = (data: any) => {
		setIsChart(data !== null);
		if (isMF) {
			const asOfDate = getConvertedDate(data?.data?.AsOfDate, {
				isMsDate: true,
				format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
			});
			let pastMonDate = getConvertedDate(asOfDate, { onlyDate: true });
			pastMonDate = pastMonDate.subtract(30, 'day');
			const dateString = `${getConvertedDate(pastMonDate, {
				format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
			})} - ${asOfDate}`;
			setChartDate(dateString);
		} else {
			setChartDate(
				getConvertedDate(data?.data?.AsOfDate, {
					isMsDate: true,
					format: DATE_FORMATS.CHART_DATE,
				}),
			);
		}
	};

	const getDelayedMessage = () => {
		return !isEquity ? delayedMessage(selectedDelayedMinutes) : '';
	};

	const overviewContainerBody = () => {
		return (
			<div className="overview-container">
				<div className="first-row">
					<div className="left-side">
						<div
							className={`${'charts-price'} ${
								!isChart && 'no-chart-container'
							}`}
						>
							{chartConfig && (
								<ChartsPrice
									inputObj={chartConfig}
									chartHandler={chartPriceHandler}
								/>
							)}
						</div>
						<div
							className={`${'chart-details'} ${
								chartPeriod === SelectedChartPeriod.Week
									? 'justified-details'
									: ''
							} ${isEquity ? 'chart-aligned-details' : ''}`}
						>
							<div
								className={`${
									chartPeriod !== SelectedChartPeriod.Week || isMF
										? 'chart-date-show'
										: 'chart-date-hide'
								}`}
							>
								{chartDate}
							</div>
							{!isMF && (
								<div className="chart-period">
									<LabelLink
										text={SelectedChartPeriod.Day}
										className={`${'labelLink-s'} ${
											chartPeriod !== SelectedChartPeriod.Day
												? 'labelLink-active'
												: ''
										}`}
										onClick={() =>
											chartPeriod !== SelectedChartPeriod.Day &&
											chartPeriodHandler(SelectedChartPeriod.Day)
										}
										onKeyDown={(e) =>
											handleEnterKeyPress(
												e,
												chartPeriod !== SelectedChartPeriod.Day &&
													chartPeriodHandler(SelectedChartPeriod.Day),
											)
										}
									/>
									<div className="seperator">|</div>
									<LabelLink
										text={SelectedChartPeriod.Week}
										className={`${'labelLink-s'} ${
											chartPeriod !== SelectedChartPeriod.Week
												? 'labelLink-active'
												: ''
										}`}
										onClick={() =>
											chartPeriod !== SelectedChartPeriod.Week &&
											chartPeriodHandler(SelectedChartPeriod.Week)
										}
										onKeyDown={(e) =>
											handleEnterKeyPress(
												e,
												chartPeriod !== SelectedChartPeriod.Week &&
													chartPeriodHandler(SelectedChartPeriod.Week),
											)
										}
									/>
								</div>
							)}
						</div>
					</div>
					<div className="right-side">
						<Heading content={OverviewTitle[type]} type={'h3'} />
						<QuoteBar
							venueXid={venueXid}
							mobileView={true}
							isMF={isMF}
							mfData={mfQuoteBarData}
							setDelayedMinutes={setSelectedDelayedMinutes}
							setDelayedDateTime={setSelectedDelayedDateTime}
						/>
						{!isMF && (
							<Label
								isGrey
								align="left"
								size="s"
								text={`Data as of ${convertUtcDttmToET(
									selectedDelayedDateTime,
									{
										format: DATE_FORMATS.ET_VARIANT_DATE_TIME_MON_NAME_FORMAT,
									},
								)}.${'<br />'}${delayedMessage(selectedDelayedMinutes)}`}
								className="delayed-info"
								isHtmlContent
							/>
						)}
					</div>
				</div>
				<div className={'separate'}>
					<Separator />
				</div>
				<div className="overview-profile">
					{isReady ? (
							<Profile
								profileData={profileData[type]}
								header={CompanyProfileHeader[type]}
								mapping={CompanyProfileMapping[type]}
								numColumns={2}
								externalLine={isEquity}
								delayedText={getDelayedMessage()}
							/>
					) : (
						!isMobile && <Loader />
					)}
				</div>
			</div>
		);
	};

	return (
		<>
			{!venueXid ? (
				<NoData text={ERROR_MESSAGES.DEFAULT_NO_DATA} applyMinHeight />
			) : (
				overviewContainerBody()
			)}
		</>
	);
};

export default OverviewPage;
