import './error-message-dialog.scss';
import React, { useState, useEffect, useRef } from 'react';
import { Heading } from '../Heading/heading';
import { escapeKeyHandler, handleEnterKeyPress } from '../../utilities/utils';
import { CloseIcon } from '../../assets/Icons';
import MessageBox, { MessageBoxStateType } from '../MessageBox/message-box';
import Button, { ButtonVariant } from '../Buttons/Button/button';

export interface ErrorMessageDialogProps {
	title?: string;
	onClose?: () => void;
	message?: string;
	id?: string;
	isPrimaryVariant?: boolean;
}

const ErrorMessageDialog: React.FC<ErrorMessageDialogProps> = ({
	title,
	onClose,
	message,
	id = '',
	isPrimaryVariant = false,
}) => {
	const [isOpen, setIsOpen] = useState(true);
	const modalRef = useRef<HTMLDivElement>(null);

	const handleKeyDown = (event: any, islastElementClicked = false) => {
		escapeKeyHandler(event, handleClose);
		if (event.key === 'Tab' && modalRef.current) {
			const firstElement = modalRef.current.querySelector('h3') as HTMLElement;
			const lastElement = modalRef.current.querySelector(
				'button#lastFocusableElement',
			) as HTMLElement;
			if (event.shiftKey && document.activeElement === firstElement) {
				lastElement.focus();
				event.preventDefault();
			} else if (!event.shiftKey && islastElementClicked) {
				firstElement.focus();
				event.preventDefault();
			}
		}
	};

	useEffect(() => {
		if (isOpen) {
			document.addEventListener('keydown', handleKeyDown);
		} else {
			document.removeEventListener('keydown', handleKeyDown);
		}

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [isOpen]);

	const handleClose = () => {
		setIsOpen(false);
		onClose?.();
	};

	return (
		<>
			{isOpen && (
				<div className="modal-overlay" id={id} ref={modalRef} data-testid={id}>
					<div className="modal-container">
						<div className="modal-header">
							<Heading type="h3" content={title} />
							<button
								className="close-button"
								data-testid="modalCloseBtn"
								onClick={handleClose}
								onKeyDown={(e) => handleEnterKeyPress(e, handleClose)}
							>
								<CloseIcon />
							</button>
						</div>
						<div className="modal-body">
							<MessageBox
								content={message}
								state={MessageBoxStateType.Fail}
								enableCloseIcon={false}
								autoHide={false}
							/>
						</div>
						<div className="dismiss">
							<Button
								onClick={handleClose}
								onKeyDown={(e) => handleEnterKeyPress(e, handleClose)}
								variant={
									isPrimaryVariant
										? ButtonVariant.Primary
										: ButtonVariant.Secondary
								}
							>
								Dismiss
							</Button>
						</div>
					</div>
					<button
						id="lastFocusableElement"
						style={{ position: 'absolute', top: '-100px' }}
						onClick={handleClose}
						onKeyDown={(e) => handleEnterKeyPress(e, handleClose)}
					/>
				</div>
			)}
		</>
	);
};

export default ErrorMessageDialog;
