export const INTRADAY_HEADER = 'Intraday Charts';

export const SELECTED_VALUES = [
	{
		DROPDOWN_DEFAULT: 584290,
		PREVIOUS_CLOSE: true,
	},
	{
		DROPDOWN_DEFAULT: 599362,
		PREVIOUS_CLOSE: true,
	},
	{
		DROPDOWN_DEFAULT: 579435,
		PREVIOUS_CLOSE: true,
	},
];

export const LABELS = {
	LAST_PRICE: 'Last',
	CHANGE: '$ Chg',
	CHANGEPERCENT: '% Chg',
};

export const LABELS_CONFIG = [
	{
		key: LABELS.LAST_PRICE,
		isBold: true,
	},
	{
		key: LABELS.CHANGE,
		preFix: true,
		isNumber: true,
		showColor: true,
		isBold: true,
	},
	{
		key: LABELS.CHANGEPERCENT,
		preFix: true,
		isNumber: true,
		showColor: true,
		isBold: true,
	},
];

export const NUMBER_CHARTS_DESKTOP = 3;
export const NUMBER_CHARTS_MOBILE = 1;
export const TIME_ZONE = 'ET';
export const DROP_DOWN_TEST_ID = 'intraday_drop_down';
export const INTRADAY_CHART_SELECTIONS: { [key: string]: number } = {
	intraday_drop_down_0: SELECTED_VALUES[0].DROPDOWN_DEFAULT,
	intraday_drop_down_1: SELECTED_VALUES[1].DROPDOWN_DEFAULT,
	intraday_drop_down_2: SELECTED_VALUES[2].DROPDOWN_DEFAULT,
};
