import React, { useEffect, useState } from 'react';
import './checkbox.scss';

interface CheckboxProps {
	value: string;
	label?: string;
	checked?: boolean;
	onChange?: (isChecked: boolean, selectedValue?: string) => void;
	disabled?: boolean;
	isLabelFirst?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
	value,
	label,
	checked,
	onChange,
	disabled,
	isLabelFirst,
}) => {
	const [isChecked, setIsChecked] = useState(checked);

	useEffect(() => {
		setIsChecked(checked);
	}, [checked]);

	const handleCheckboxChange = () => {
		const newChecked = !isChecked;
		setIsChecked(newChecked);
		onChange?.(newChecked, value);
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === ' ' || event.key === 'Enter') {
			event.preventDefault();
			handleCheckboxChange();
		}
	};

	const checkboxLabel = <label htmlFor={value}>{label}</label>;

	return (
		<span className="checkbox-wrapper">
			{isLabelFirst && checkboxLabel}
			<input
				type="checkbox"
				checked={isChecked}
				id={value}
				data-testid="checkbox"
				onChange={handleCheckboxChange}
				onKeyDown={handleKeyPress}
				disabled={disabled}
				aria-describedby={value}
			/>
			<label className="custom-checkbox" htmlFor={value}>
				{' '}
			</label>
			{!isLabelFirst && checkboxLabel}
		</span>
	);
};

export default Checkbox;
