import DataRequest from './dataRequest';
import URLS from '../constants/urlConstants';
import { IHistoricalSplitsResponse } from '../types/interfaces';
import { resolvePromiseWithError } from '../utilities/utils';
import { E_HTTP_METHOD } from '../constants/appConstants';

export const getHistoricalSplits = async (
	venueXid: number,
	startDate: string,
	endDate: string,
): Promise<IHistoricalSplitsResponse> => {
	if (!venueXid) {
		return await resolvePromiseWithError();
	}
	return await DataRequest.execute(
		`${URLS.PLATFORM.CORPORATEACTIONS_SPLITS}/` +
			venueXid +
			`/historical-events`,
		{
			method: E_HTTP_METHOD.GET,
			params: {
				startDate: startDate,
				endDate: endDate,
				include1For1Splits: true,
			},
		},
	);
};
