import { IOptionQuoteItem, IUnderlyingQuote } from '../../types/interfaces';
import {
	OptionChainTableKeys,
	OptionChainTableHeader,
	OptionChainTableMapping,
	IOptionProfile,
} from './options-constants';
import { DATE_FORMATS, getDate } from '../../utilities/date-time-formatter';
import { TEXT_NULL_VALUE, formatNumber } from '../../utilities/formatter';
import { convertUtcDttmToET } from '../../utilities/date-time-formatter-tz';

export const mobileView = (userPreferences: string[]) => {
	const filteredHeader = OptionChainTableHeader.filter((header) => {
		return (
			header.key === OptionChainTableKeys.StrikePrice ||
			userPreferences?.includes(header?.key ?? '')
		);
	});

	const filteredMapping = OptionChainTableMapping.filter((mapping) => {
		return (
			mapping.key === OptionChainTableKeys.StrikePrice ||
			userPreferences?.includes(mapping.key)
		);
	});

	const strikePriceHeaderIndex = filteredHeader.findIndex(
		(header) => header.key === OptionChainTableKeys.StrikePrice,
	);
	const strikePriceMappingIndex = filteredMapping.findIndex(
		(mapping) => mapping.key === OptionChainTableKeys.StrikePrice,
	);
	if (strikePriceHeaderIndex !== -1 && strikePriceHeaderIndex > 0) {
		const strikePriceHeader = filteredHeader.splice(
			strikePriceHeaderIndex,
			1,
		)[0];
		filteredHeader.splice(0, 0, strikePriceHeader);
	}

	if (strikePriceMappingIndex !== -1 && strikePriceMappingIndex > 0) {
		const strikePriceMapping = filteredMapping.splice(
			strikePriceMappingIndex,
			1,
		)[0];
		filteredMapping.splice(0, 0, strikePriceMapping);
	}

	return { newHeader: filteredHeader, newMapping: filteredMapping };
};

export const moveStrikePriceToSecondPosition = (userPreferences?: string[]) => {
	if (userPreferences?.length) return mobileView(userPreferences);

	const newHeader = [...OptionChainTableHeader];
	const newMapping = [...OptionChainTableMapping];

	// Relocate StrikePrice Column to 2nd place
	const strikePriceHeaderIndex = newHeader.findIndex(
		(header) => header.key === OptionChainTableKeys.StrikePrice,
	);
	const strikePriceMappingIndex = newMapping.findIndex(
		(mapping) => mapping.key === OptionChainTableKeys.StrikePrice,
	);
	if (strikePriceHeaderIndex !== -1 && strikePriceHeaderIndex > 1) {
		const strikePriceHeader = newHeader.splice(strikePriceHeaderIndex, 1)[0];
		newHeader.splice(1, 0, strikePriceHeader);
	}

	if (strikePriceMappingIndex !== -1 && strikePriceMappingIndex > 1) {
		const strikePriceMapping = newMapping.splice(strikePriceMappingIndex, 1)[0];
		newMapping.splice(1, 0, strikePriceMapping);
	}

	return { newHeader, newMapping };
};

export const getOptionProfileData = (
	item: IOptionQuoteItem,
	expiryDate: string,
	underlyingQuote?: IUnderlyingQuote,
) => {
	const profileData: IOptionProfile = {
		ExpirationDate: expiryDate
			? getDate(new Date(expiryDate), 'MMMM D, YYYY')
			: TEXT_NULL_VALUE,
		StrikePrice: item?.strikePrice,
		IntrinsicValue: item?.calculation?.intrinsicValue,
		TenDayVolume: underlyingQuote?.volume?.average10Day ?? '--',
		SharesPerContract: formatNumber(item?.lotSize, { precision: 0 })?.value,
		TodaysHighLow: `${formatNumber(item?.quote?.lastTrade?.high).value} to ${
			formatNumber(item?.quote?.lastTrade?.low).value
		}`,
		PreviousHighLow: `(${convertUtcDttmToET(underlyingQuote?.lastTrade?.date, {
			format: DATE_FORMATS.DATE_SHORT_YEAR_FORMAT,
		})}) ${formatNumber(underlyingQuote?.lastTrade?.high)?.value} to ${
			formatNumber(underlyingQuote?.lastTrade?.low)?.value
		}`,
		PreviousNetChange: underlyingQuote?.changePercent?.today ?? '--',
		PreviousClosePrice: underlyingQuote?.lastTrade?.close ?? '--',
		ContractHighLow: `${
			formatNumber(underlyingQuote?.price52Week?.high)?.value
		} to ${formatNumber(underlyingQuote?.price52Week?.low)?.value}`,
		LastTrade: convertUtcDttmToET(underlyingQuote?.lastTrade?.date, {
			format: DATE_FORMATS.DATE_SHORT_YEAR_FORMAT,
		}),
	};
	return profileData;
};
