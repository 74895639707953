import React from 'react';
import './glossary.scss';
import { Label } from '../../@core-ui';

interface IGlossaryDataProps {
	glossaryData: Array<{ termType: string; term: string; def: string }>;
}

const TermList = (props: IGlossaryDataProps) => {
	function bindTerms(item: any): React.ReactNode {
		return (
			<div className={'glossary-term'} key={`glossary ${item.term}`}>
				<div className="headline">
					<Label isGrey={false} text={`${item.term}`} isBold={true} />
				</div>
				<div className="term-defination">
					<Label isGrey={true} text={`${item.def}`} />
				</div>
			</div>
		);
	}

	function bindGlossaryContent() {
		return <div>{props.glossaryData.map((item: any) => bindTerms(item))}</div>;
	}
	return <div className={'glossary-term-list'}>{bindGlossaryContent()}</div>;
};

export default TermList;
