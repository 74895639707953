import React from 'react';
import { IBasicIconProps } from '../IBasicIconProps';

const MountainChartIcon: React.FC<IBasicIconProps> = ({
	width = 21,
	height = 17,
	color = '#000',
}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 14.9364277 15.0001263"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g transform="translate(-845.000000, -433.000000)" fill={color}>
				<polygon points="844.99909 447.002346 852.996521 438.999933 856 441.998594 865.000971 432.993755 865.012742 450 845 450"></polygon>
			</g>
		</g>
	</svg>
);

export default MountainChartIcon;
