import { DEFAULT_NEWS_INPUTS } from '../../../constants/appConstants';
import { addQBIDArgument } from '../../../utilities/utils';
import { TYPE_MAPPING } from '../news-constants';

export const buildNewsInputs = (symbol: string) => {
	const newsInputs: any = { ...DEFAULT_NEWS_INPUTS };
	newsInputs.limit = 5;
	newsInputs.arguments = [
		addQBIDArgument(TYPE_MAPPING.BridgeSymbols, [symbol]),
	];
	return newsInputs;
};

export const MOST_RECENT_NEWS = 'Most Recent News';
