import React, { useState, useEffect } from 'react';
import Checkbox from '../Checkbox/checkbox';
import './multi-checkbox.scss';

interface MultiCheckboxProps {
	options: { value: string; label: string }[];
	onChange?: (selectedValues: string[]) => void;
	maxSelection?: number;
	selectedValues?: string[];
}

const MultiCheckbox: React.FC<MultiCheckboxProps> = ({
	options,
	onChange,
	maxSelection,
	selectedValues: initialSelectedValues,
}) => {
	const [selectedValues, setSelectedValues] = useState<string[]>(
		initialSelectedValues || [],
	);

	useEffect(() => {
		if (initialSelectedValues) {
			setSelectedValues(initialSelectedValues);
		}
	}, [initialSelectedValues]);

	const handleCheckboxChange = (value: string) => {
		const newSelectedValues = selectedValues.includes(value)
			? selectedValues.filter((val) => val !== value)
			: [...selectedValues, value];

		if (maxSelection && newSelectedValues.length > maxSelection) {
			return;
		}

		setSelectedValues(newSelectedValues);
		onChange?.(newSelectedValues);
	};

	return (
		<div className="multi-checkbox-wrapper">
			{options.map((option) => (
				<Checkbox
					key={`multi-chk_${option.label}`}
					checked={selectedValues.includes(option.value)}
					onChange={() => handleCheckboxChange(option.value)}
					value={option.value}
					label={option.label}
					isLabelFirst
					disabled={
						maxSelection !== undefined &&
						selectedValues.length >= maxSelection &&
						!selectedValues.includes(option.value)
					}
				/>
			))}
		</div>
	);
};

export default MultiCheckbox;
