import React, { useEffect, useState } from 'react';
import {
	E_OptionChainDisplayDropdownOptions,
	OptionChainTableHeader,
	OptionChainTableMapping,
	OptionChainTableKeys,
} from '../../../options-constants';
import OptionChainTable from './options-chain-table/options-chain-table';
import { IOptionChainWithQuotesData } from '../../../../../types/interfaces';
import { formatNumber } from '../../../../../utilities/formatter';
import { getDeviceType } from '../../../../../utilities/utils';
import {
	ERROR_MESSAGES,
	E_DeviceType,
} from '../../../../../constants/appConstants';
import NoData from './../../../../../shared/NoData/NoData';
import { Label } from './../../../../../@core-ui';
import './options-chain-table-container.scss';
import { moveStrikePriceToSecondPosition } from './../../../../../components/options/options-utils';
import { generateColValue } from './../../../../../components/quotes-news/portfolio/portfolio-utils';

interface OptionsChainTableContainerProps {
	symbol: string;
	displayOption: E_OptionChainDisplayDropdownOptions;
	optionChainData?: IOptionChainWithQuotesData;
	mobileColumns?: string[];
}

const OptionsChainTableContainer: React.FC<OptionsChainTableContainerProps> = ({
	symbol,
	displayOption,
	optionChainData,
	mobileColumns,
}) => {
	const [tableHeader, setTableHeader] = useState(OptionChainTableHeader);
	const [tableMapping, setTableMapping] = useState(OptionChainTableMapping);
	const underlyingQuote = optionChainData?.underlyingQuote;
	const optionItems = optionChainData?.items?.[0]?.options;
	const last = formatNumber(underlyingQuote?.lastTrade?.last ?? 0)?.value;
	const expiryDate = optionChainData?.items?.[0]?.expirationDate ?? '';
	const lastRowJsx = <Label text={`Last: ${last}`} className="last"></Label>;
	const isIpadView = getDeviceType() === E_DeviceType.Ipad;
	const isMobileView = getDeviceType() === E_DeviceType.Mobile;

	useEffect(() => {
		if (
			isIpadView ||
			isMobileView ||
			displayOption !== E_OptionChainDisplayDropdownOptions.CallsAndPuts
		) {
			const { newHeader, newMapping } =
				moveStrikePriceToSecondPosition(mobileColumns);
			setTableHeader(newHeader);
			setTableMapping(newMapping);
		} else {
			setTableHeader(OptionChainTableHeader);
			setTableMapping(OptionChainTableMapping);
		}
	}, [displayOption, mobileColumns]);

	const generateTableHeader = (excludeStrike?: boolean) => {
		return (
			<div className="common-row header">
				{tableHeader?.map((item) => {
					if (excludeStrike && item.key === OptionChainTableKeys.StrikePrice) {
						return null;
					}
					return (
						<span className={`${item.className ?? ''}`} key={`row_${item.key}`}>
							{generateColValue(item, item.label)}
						</span>
					);
				})}
			</div>
		);
	};

	const renderChainTable = (
		isCall: boolean,
		inMoney: boolean,
		showHeader: boolean,
		excludeStrike?: boolean,
	) => {
		const last = underlyingQuote?.lastTrade?.last ?? 0;
		const callPut = optionItems?.flatMap((x) => {
			const option = isCall ? x.call : x.put;
			const strikePrice = x.strikePrice;
			return { ...option, strikePrice };
		});
		const inTheMoneyData = callPut?.filter((x) => x?.strikePrice < last) ?? [];
		const outTheMoneyData =
			callPut?.filter((x) => x?.strikePrice >= last) ?? [];
		const data = inMoney ? inTheMoneyData : outTheMoneyData;

		return (
			<div
				className={`option-table-wrapper ${
					isCall ? 'calls-table-wrapper' : 'puts-table-wrapper'
				}`}
			>
				<OptionChainTable
					data={data}
					symbol={symbol}
					tableMapping={tableMapping}
					underlyingQuote={underlyingQuote}
					expiryDate={expiryDate}
					isCall={isCall}
					inMoney={inMoney}
					excludeStrike={excludeStrike}
					showHeader={showHeader}
				/>
			</div>
		);
	};

	const getTableContent = () => {
		if (!optionItems?.length) {
			return <NoData text={ERROR_MESSAGES.DEFAULT_NO_DATA} />;
		}
		if (displayOption === E_OptionChainDisplayDropdownOptions.CallsAndPuts) {
			if (
				getDeviceType() === E_DeviceType.Ipad ||
				getDeviceType() === E_DeviceType.Mobile
			) {
				return (
					<>
						<div className="header-container">{generateTableHeader()}</div>
						<div className="scrollable-content">
							<div className="calls">
								{renderChainTable(true, true, true)}
								{lastRowJsx}
								{renderChainTable(true, false, false)}
							</div>
							<div className="puts">
								{renderChainTable(false, true, true)}
								{lastRowJsx}
								{renderChainTable(false, false, false)}
							</div>
						</div>
					</>
				);
			}
			return (
				<>
					<div className="header-container">
						{generateTableHeader()}
						{generateTableHeader(true)}
					</div>
					<div className="scrollable-content">
						<div className="itm">
							{renderChainTable(true, true, true)}
							{renderChainTable(false, true, true, true)}
						</div>
						{lastRowJsx}
						<div className="otm">
							{renderChainTable(true, false, false)}
							{renderChainTable(false, false, false, true)}
						</div>
					</div>
				</>
			);
		} else if (
			displayOption === E_OptionChainDisplayDropdownOptions.CallsOnly
		) {
			return (
				<>
					<div className="header-container">{generateTableHeader()}</div>
					<div className="scrollable-content">
						{renderChainTable(true, true, true)}
						{lastRowJsx}
						{renderChainTable(true, false, false)}
					</div>
				</>
			);
		} else if (displayOption === E_OptionChainDisplayDropdownOptions.PutsOnly) {
			return (
				<>
					<div className="header-container">{generateTableHeader()}</div>
					<div className="scrollable-content">
						{renderChainTable(false, true, true)}
						{lastRowJsx}
						{renderChainTable(false, false, false)}
					</div>
				</>
			);
		} else if (displayOption === E_OptionChainDisplayDropdownOptions.ITMCalls) {
			return (
				<>
					<div className="header-container">{generateTableHeader()}</div>
					<div className="scrollable-content">
						{renderChainTable(true, true, true)}
						{lastRowJsx}
					</div>
				</>
			);
		} else if (displayOption === E_OptionChainDisplayDropdownOptions.OTMCalls) {
			return (
				<>
					<div className="header-container">{generateTableHeader()}</div>
					<div className="scrollable-content">
						{renderChainTable(true, false, true)}
						{lastRowJsx}
					</div>
				</>
			);
		} else if (displayOption === E_OptionChainDisplayDropdownOptions.ITMPuts) {
			return (
				<>
					<div className="header-container">{generateTableHeader()}</div>
					<div className="scrollable-content">
						{renderChainTable(false, true, true)}
						{lastRowJsx}
					</div>
				</>
			);
		} else if (displayOption === E_OptionChainDisplayDropdownOptions.OTMPuts) {
			return (
				<>
					<div className="header-container">{generateTableHeader()}</div>
					<div className="scrollable-content">
						{renderChainTable(false, false, true)}
						{lastRowJsx}
					</div>
				</>
			);
		}
	};

	return (
		<div
			className={`option-chain-table-container ${
				displayOption === E_OptionChainDisplayDropdownOptions.CallsAndPuts
					? 'both-calls-puts'
					: ''
			} ${!isIpadView && !isMobileView ? 'desktop-view' : ''}`}
		>
			{getTableContent()}
		</div>
	);
};

export default OptionsChainTableContainer;
