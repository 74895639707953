import React, { useState } from 'react';
import './overlay-modal.scss';
import {
	Button,
	ButtonVariant,
	Textbox,
	Modal,
	MessageBox,
	MessageBoxStateType,
} from '../../../../../@core-ui';
import { OVERLAY_OPTIONS } from '../../indices-charts-constants';

const OverlayModal = (props: any) => {
	const overlay = OVERLAY_OPTIONS.find((x) => x.key == props.overlayName);
	const [period, setPeriod] = useState(overlay?.period);
	const [errorMessage, setErrorMessage] = useState('');

	const restoreHandler = () => {
		onClose();
		props.restoreChart();
	};
	const updateOverlayChart = () => {
		if (period && period > 0) {
			props.updateOverlayData(overlay?.key, period);
		} else {
			setErrorMessage('Missing required values.');
			return false;
		}
	};

	const onCrossClick = () => {
		setErrorMessage('');
	};

	const updatePeriod = (e: any) => {
		let value = e.target.value;
		if (parseInt(value) > 500) {
			value = 500;
		}
		setPeriod(value);
	};
	const onClose = () => {
		setPeriod(0);
		props.closeOverlay(false);
	};

	return (
		<Modal onClose={onClose} title={overlay?.value} id="overlayModal">
			<div className="overlay-container">
				<div className="overlay-period">
					<span>Period</span>
					<Textbox
						type="number"
						testId={`overlay-period-textbox`}
						className={`textbox`}
						value={`${period}`}
						onChange={updatePeriod}
					/>
				</div>
				<div className="overlay-description">{overlay?.description}</div>
				<div className="overlay-handlers">
					<Button
						onClick={restoreHandler}
						variant={ButtonVariant.Secondary}
						testId={'restore-chart'}
					>
						Restore Default
					</Button>
					<Button
						onClick={updateOverlayChart}
						variant={ButtonVariant.Primary}
						testId={'update-chart'}
					>
						Update Chart
					</Button>
				</div>
				{errorMessage.length > 0 && (
					<MessageBox
						content={errorMessage}
						state={MessageBoxStateType.Fail}
						enableCloseIcon={true}
						onClose={onCrossClick}
						autoHide={true}
						timeAutoHide={3000}
					/>
				)}
			</div>
		</Modal>
	);
};

export default OverlayModal;
