import React, { useEffect, useState } from 'react';
import { getOptionsExpirationDates } from '../../../services';
import OptionsChain, {
	IOptionChainPreSelectedData,
} from './options-chain/options-chain';
import OptionQuoteContainer from '../option-quote-container/option-quote-container';
import {
	MobileChangeViewOptions,
	OPTIONS_ACTIONS,
	OptionQuoteKeys,
} from '../options-constants';
import QuoteBar from '../../../components/shared/quote-bar/quote-bar';
import {
	Button,
	ButtonVariant,
	Heading,
	Label,
	MultiCheckbox,
} from '../../../@core-ui';
import './options-container.scss';
import {
	DATE_FORMATS,
	formatDateTime,
} from '../../../utilities/date-time-formatter';
import { E_DeviceType } from '../../../constants/appConstants';
import {
	getDeviceType,
	handleEnterKeyPress,
	delayedMessage,
} from '../../../utilities/utils';
import { CloseIcon } from '../../../assets/Icons';
import { convertUtcDttmToET } from '../../../utilities/date-time-formatter-tz';
import { useOptionsContext } from '../../snapshots/snapshot-container';
import { Loader } from './../../../@core-ui/Loader/loader';

interface OptionsContainerProps {
	venueXid: number;
	symbol: string;
}

const OptionsContainer: React.FC<OptionsContainerProps> = ({
	venueXid,
	symbol,
}) => {
	const [expiryDates, setExpiryDates] = useState<
		{ key: string; value: string }[]
	>([]);
	const { masterStateMemo, setMasterStateMemo } = useOptionsContext();
	const [selectedDelayedDateTime, setSelectedDelayedDateTime] = useState('--');
	const [selectedDelayedMinutes, setSelectedDelayedMinutes] = useState();
	const [preSelectedData, setPreSelectedData] =
		useState<IOptionChainPreSelectedData>();
	const isChangeView =
		masterStateMemo.action === OPTIONS_ACTIONS.OPEN_MOBILE_CHANGE_VIEW;
	const isMobile = getDeviceType() === E_DeviceType.Mobile;

	const [toggleChangeView, setToggleChangeView] = useState<boolean>();
	const [selectedMobileViewOptions, setSelectedMobileViewOptions] = useState(
		isMobile ? [OptionQuoteKeys.Bid, OptionQuoteKeys.Ask] : [],
	);
	const [multiCheckBoxSelections, setMultiCheckBoxSelections] = useState(
		isMobile ? [OptionQuoteKeys.Bid, OptionQuoteKeys.Ask] : [],
	);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		fetchData();
	}, [venueXid]);

	const fetchData = () => {
		fetchExpiryDates();
	};

	useEffect(() => {
		setMasterStateMemo({
			action: toggleChangeView
				? OPTIONS_ACTIONS.OPEN_MOBILE_CHANGE_VIEW
				: OPTIONS_ACTIONS.OPTIONS_HOME_PAGE,
		});

		document.body.style.overflowY = toggleChangeView ? 'hidden' : 'auto';
	}, [toggleChangeView]);

	const onChangeViewApply = () => {
		setToggleChangeView(false);
		setSelectedMobileViewOptions([...multiCheckBoxSelections]);
	};

	const onChangeViewCancel = () => {
		setToggleChangeView(false);
		setSelectedMobileViewOptions([...selectedMobileViewOptions]);
	};

	const fetchExpiryDates = async () => {
		if (venueXid) {
			setIsLoading(true);
			const optionExpiryDatesResponse =
				await getOptionsExpirationDates(venueXid);
			const expiryDatesResult =
				optionExpiryDatesResponse?.data?.data?.dates?.map((d) => {
					return {
						key: d.expirationDate,
						value: formatDateTime(d.expirationDate, {
							format: DATE_FORMATS.SHORT_MONTH_DATE_YEAR_FORMAT,
						}),
					};
				});
			setExpiryDates(expiryDatesResult);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (masterStateMemo?.action === OPTIONS_ACTIONS.OPTIONS_HOME_PAGE) {
			setToggleChangeView(false);
		}
	}, [masterStateMemo?.action]);

	const renderView = () => {
		if (isLoading) {
			return <Loader />;
		}

		if (masterStateMemo.action === OPTIONS_ACTIONS.VIEW_OPTION_PROFILE) {
			return <OptionQuoteContainer data={masterStateMemo.data} />;
		}

		return (
			<>
				<div className={`quote-bar-container`}>
					<QuoteBar
						venueXid={venueXid}
						setDelayedDateTime={setSelectedDelayedDateTime}
						setDelayedMinutes={setSelectedDelayedMinutes}
					/>
					<Label
						isGrey
						align={isMobile ? 'left' : 'center'}
						size="s"
						text={`As of ${convertUtcDttmToET(selectedDelayedDateTime, {
							format: DATE_FORMATS.ET_VARIANT_DATE_TIME_SEC_FORMAT,
						})}.${isMobile ? '<br />' : ''}${delayedMessage(
							selectedDelayedMinutes,
						)}`}
						className="delayed-info"
						isHtmlContent={isMobile}
					></Label>
				</div>
				{expiryDates?.length > 0 && (
					<OptionsChain
						symbol={symbol}
						expiryDates={expiryDates}
						venueXid={venueXid}
						preSelectedData={preSelectedData}
						setPreSelectedData={setPreSelectedData}
						mobileColumns={selectedMobileViewOptions}
						onMobileChangeViewLinkClick={onMobileChangeViewLinkClick}
						delayedMinutes={selectedDelayedMinutes}
					/>
				)}
			</>
		);
	};

	const onMobileChangeViewLinkClick = () => {
		setToggleChangeView(!toggleChangeView);
	};

	const onMultiCheckboxChange = (selectedValues: string[]) => {
		setMultiCheckBoxSelections([...selectedValues]);
	};

	const getMobileChangeView = () => {
		return (
			<div
				className={`mobile-change-view-drawer ${
					toggleChangeView ? 'open' : 'close'
				}`}
			>
				<div className="wrapper">
					<div className="row header">
						<Heading content="Change View" type="h5" />
						<button
							className="close-button"
							data-testid="modalCloseBtn"
							onClick={onMobileChangeViewLinkClick}
							onKeyDown={(e) =>
								handleEnterKeyPress(e, onMobileChangeViewLinkClick)
							}
						>
							<CloseIcon height={15} width={15} />
						</button>
					</div>
					<Label
						text="Choose max of 2 to display in the table"
						isDarkGrey
						className="max-limit-note"
					/>
					<MultiCheckbox
						options={MobileChangeViewOptions}
						selectedValues={selectedMobileViewOptions}
						onChange={onMultiCheckboxChange}
						maxSelection={2}
					/>
					<div className="row footer">
						<Button
							onClick={onChangeViewCancel}
							variant={ButtonVariant.Secondary}
						>
							Cancel
						</Button>
						<Button
							onClick={onChangeViewApply}
							disabled={multiCheckBoxSelections.length === 0}
						>
							Apply
						</Button>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className={`options-container ${isChangeView ? 'overlay-on' : ''}`}>
			{isChangeView && <div className={'mobile-overlay'}></div>}
			{renderView()}
			{toggleChangeView && getMobileChangeView()}
		</div>
	);
};

export default OptionsContainer;
