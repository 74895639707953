import React, { useState, useRef } from 'react';
import OptionDetailsPopover from './option-details-popover';
import { Popover, LabelLink } from './../../../@core-ui';
import './option-details.scss';
import { handleEnterKeyPress } from '../../../utilities/utils';

interface OptionDetailsPropsType {
	displayName: string;
	data?: any;
	xid: number;
	onClick?: () => void;
}
const OptionDetails = (props: OptionDetailsPropsType) => {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const ref = useRef(null);
	let debounceTimeout: any = false;

	const togglePopover = () => {
		debounceTimeout = setTimeout(() => {
			setIsPopoverOpen(!isPopoverOpen);
		}, 500);
	};

	const closePopover = () => {
		clearTimeout(debounceTimeout);
		setIsPopoverOpen(false);
	};

	return (
		<div className="option-details-container">
			<div className="option-details-label">
				<LabelLink
					text={props.displayName}
					isHideTitle={true}
					size={'s'}
					ellipsisEnabled={true}
					onFocus={togglePopover}
					onMouseOver={togglePopover}
					onMouseOut={closePopover}
					onBlur={closePopover}
					ref={ref}
					onClick={props.onClick}
					onKeyDown={(e) => handleEnterKeyPress(e, () => props.onClick)}
				/>
			</div>
			{isPopoverOpen && (
				<Popover
					id="popover"
					closePopover={togglePopover}
					body={<OptionDetailsPopover data={props.data} xid={props.xid} />}
					referenceElement={ref.current}
					placement={'auto'}
				/>
			)}
		</div>
	);
};

export default OptionDetails;
