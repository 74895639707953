import React, { forwardRef } from 'react';
import './LabelLink.scss';

interface LabelLinkProps {
	size?: 's' | 'm' | 'l';
	text: string;
	testId?: string;
	className?: string;
	onClick?: (
		event: React.MouseEvent<HTMLAnchorElement>,
		content: string,
	) => void;
	onKeyDown?: React.KeyboardEventHandler<HTMLAnchorElement>;
	onKeyUp?: React.KeyboardEventHandler<HTMLAnchorElement>;
	onFocus?: () => void;
	onMouseOver?: () => void;
	onMouseOut?: () => void;
	onBlur?: () => void;
	dataKey?: string;
	isBold?: boolean;
	isHideTitle?: boolean;
	ellipsisEnabled?: boolean;
	icon?: React.ReactNode;
	isCompanyNameOrSymbol?: boolean;
	tabIndex?: number;
	iconDir?: 'right' | 'left';
}

// eslint-disable-next-line react/display-name
export const LabelLink = forwardRef<HTMLAnchorElement, LabelLinkProps>(
	(props: LabelLinkProps, ref) => {
		const {
			size,
			text,
			dataKey = '',
			testId = '',
			onClick,
			onKeyDown,
			onFocus,
			onMouseOver,
			onMouseOut,
			onBlur,
			onKeyUp,
			isBold,
			isHideTitle,
			ellipsisEnabled,
			icon,
			isCompanyNameOrSymbol,
			iconDir,
			...rest
		} = props;
		const sizeClass = `labelLink-${size ?? 'm'}`;
		const boldClass = isBold ? `bold-link` : '';
		const titleText = isHideTitle ? '' : text;
		const ellipsisClass = ellipsisEnabled ? 'ellipsis' : '';
		const companyNameClass = isCompanyNameOrSymbol ? 'company-name' : '';

		function handleClick(event: React.MouseEvent<HTMLAnchorElement>): void {
			const dataValue =
				event.currentTarget.getAttribute('data-value') ??
				event.currentTarget.innerText;
			onClick?.(event, dataValue);
		}

		return (
			<a
				tabIndex={0}
				role="button"
				className={`labelLink ${sizeClass} ${
					props.className ?? ''
				} ${boldClass} ${ellipsisClass} ${companyNameClass}`}
				data-testid={testId}
				title={titleText}
				data-key={dataKey}
				onClick={handleClick}
				onKeyDown={onKeyDown}
				onKeyUp={onKeyUp}
				onFocus={onFocus}
				onMouseOver={onMouseOver}
				onMouseOut={onMouseOut}
				onBlur={onBlur}
				ref={ref}
				{...rest}
			>
				{(icon && iconDir === 'left') && <span className="labelLink-icon">{icon}</span>}
				{text}
				{(icon && iconDir === 'right') && <span className="labelLink-icon">{icon}</span>}
			</a>
		);
	},
);
