import React, { useState, useEffect } from 'react';
import { Label, NumberLabel, Loader } from './../../../@core-ui';
import { getQuotes } from '../../../services';
import { ICompanyDetails } from '../../../types/interfaces/ICompanyDetails';
import NoData from '../../../shared/NoData/NoData';
import './quote-bar.scss';
import { E_DeviceType, T_DeviceType } from '../../../constants/appConstants';
import { getDeviceType, handleWindowResize } from '../../../utilities/utils';
import { ImfQuoteBar } from '../../snapshots/snapshot-tabs/overview-tab/overview-page-constants';

type QuoteBarProps = {
	venueXid: number;
	setDelayedDateTime?: any;
	setDelayedMinutes?: any;
	mobileView?: boolean;
	isMF?: boolean;
	mfData?: ImfQuoteBar;
};

const QuoteBar = (props: QuoteBarProps) => {
	const [isLoading, setIsLoading] = useState(true);
	const [symbolData, setSymbolData] = useState<ICompanyDetails>();
	const [device, setDevice] = useState<T_DeviceType>(getDeviceType());
	const isMobileView = props.mobileView || device === E_DeviceType.Mobile;

	useEffect(() => {
		const handleResize = () => {
			const deviceType = getDeviceType();
			setDevice(deviceType);
		};
		handleWindowResize(handleResize);
	}, []);

	useEffect(() => {
		setIsLoading(true);
		props.venueXid && getQuotesData(props.venueXid);
	}, [props.venueXid]);

	const getQuotesData = async (venueXid: number) => {
		const response = await getQuotes([venueXid]);
		const responseData = response?.data?.data?.quotes[0]?.data;
		setSymbolData({
			venueXid: venueXid,
			last: responseData?.lastTrade?.last,
			change: responseData?.lastTrade?.change,
			changePercent: responseData?.changePercent?.today,
			volume: responseData?.volume?.last,
			high: responseData?.lastTrade?.high,
			low: responseData?.lastTrade?.low,
			dateTime: responseData?.lastTrade?.date,
			open: responseData?.lastTrade?.open,
			close: responseData?.lastTrade?.close,
		} as ICompanyDetails);
		props.setDelayedDateTime?.(responseData?.lastTrade?.date);
		props.setDelayedMinutes?.(responseData?.quoteDelayMinutes);
		setIsLoading(false);
	};

	const mfFirstRow = () => {
		return (
			<React.Fragment>
				<div className="quote-col">
					<div className={`${'quote-label-key'} ${'mf-label-key'}`}>
						<Label text={'YIELD'} size={'m'} isBold={true} />
					</div>
					<div className={`${'quote-label-key'} ${'mf-sublabel-key'}`}>
						<Label text={`As of ${props.mfData?.yieldAsof}`} size={'m'} />
					</div>
					<div className={`${'quote-label-value'} ${'mf-label-value'}`}>
						<NumberLabel
							value={`${props.mfData?.yield}`}
							size={'l'}
							numberConfig={{
								precision: 2,
							}}
						/>
					</div>
				</div>
				<div className="quote-col">
					<div className={`${'quote-label-key'} ${'mf-label-key'}`}>
						<Label text={'NAV'} size={'m'} isBold={true} />
					</div>
					<div className={`${'quote-label-key'} ${'mf-sublabel-key'}`}>
						<Label text={`As of ${props.mfData?.navAsOf}`} size={'m'} />
					</div>
					<div className={`${'quote-label-value'} ${'mf-label-value'}`}>
						<NumberLabel
							value={`${props.mfData?.nav}`}
							size={'l'}
							numberConfig={{
								precision: 2,
							}}
						/>
					</div>
				</div>
				<div className="quote-col">
					<div className={`${'quote-label-key'} ${'mf-label-key'}`}>
						<Label text={'CHANGE'} size={'m'} isBold={true} />
					</div>
					<div className={`${'quote-label-value'} ${'mf-label-value'}`}>
						<NumberLabel
							value={`${props.mfData?.change}`}
							size={'l'}
							numberConfig={{
								showColor: true,
								precision: 2,
							}}
						/>
					</div>
					<div className={`${'quote-label-value'} ${'mf-label-value'}`}>
						<NumberLabel
							value={`${props.mfData?.changePercent}`}
							size={'l'}
							numberConfig={{
								showColor: true,
								precision: 2,
								postFixValue: '%',
								parentheses: true,
							}}
						/>
					</div>
				</div>
			</React.Fragment>
		);
	};

	const firstRow = () => {
		return (
			<React.Fragment>
				<div className="quote-col">
					<div className="quote-label-key">
						<Label text={'LAST'} size={'m'} isBold={true} />
					</div>
					<div className="quote-label-value">
						<NumberLabel
							value={`${symbolData?.last}`}
							numberConfig={{
								precision: 2,
							}}
						/>
					</div>
				</div>
				<div className="quote-col">
					<div className="quote-label-key">
						<Label text={'CHANGE'} size={'m'} isBold={true} />
					</div>
					<div className="quote-label-value">
						<NumberLabel
							value={`${symbolData?.change}`}
							numberConfig={{
								showColor: true,
								precision: 2,
							}}
						/>
					</div>
					<div className="quote-label-value">
						<NumberLabel
							value={`${symbolData?.changePercent}`}
							numberConfig={{
								showColor: true,
								precision: 2,
								postFixValue: '%',
								parentheses: true,
							}}
						/>
					</div>
				</div>
				<div className="quote-col">
					<div className="quote-label-key">
						<Label text={'VOLUME'} size={'m'} isBold={true} />
					</div>
					<div className="quote-label-value">
						<NumberLabel
							value={`${symbolData?.volume}`}
							numberConfig={{
								commaSeparated: true,
							}}
						/>
					</div>
				</div>
				{isMobileView === false && secondRow()}
			</React.Fragment>
		);
	};

	const secondRow = () => {
		return (
			<React.Fragment>
				<div className="quote-col">
					<div className="quote-label-key">
						<Label text={'PREV CLOSE'} size={'m'} isBold={true} />
					</div>
					<div className="quote-label-value">
						<NumberLabel
							value={`${symbolData?.close}`}
							numberConfig={{
								precision: 2,
							}}
						/>
					</div>
				</div>
				<div className="quote-col">
					<div className="quote-label-key">
						<Label text={'OPEN'} size={'m'} isBold={true} />
					</div>
					<div className="quote-label-value">
						<NumberLabel
							value={`${symbolData?.open}`}
							numberConfig={{
								precision: 2,
							}}
						/>
					</div>
					<div className="quote-label-value" />
				</div>
				<div className="quote-col">
					<div className="quote-label-key">
						<Label text={`DAY'S HIGH/LOW`} size={'m'} isBold={true} />
					</div>
					<div className={'quote-label-value'}>
						<NumberLabel
							value={`${symbolData?.high}`}
							numberConfig={{
								precision: 2,
							}}
						/>
					</div>
					<div className={'quote-label-value'}>
						<NumberLabel
							value={`${symbolData?.low}`}
							numberConfig={{
								precision: 2,
							}}
						/>
					</div>
				</div>
			</React.Fragment>
		);
	};

	const quotesBody = () => {
		return (
			<div data-attribute={isMobileView} data-testid={'quoteBarContainer'}>
				<div className={'quote-body'}>
					{isMobileView ? (
						<div className={'quote-mobile-view'}>
							<div className={'quote-first-row'}>
								{props.isMF ? mfFirstRow() : firstRow()}
							</div>
							<div className={'quote-second-row'}>
								{!props.isMF && secondRow()}
							</div>
						</div>
					) : (
						<React.Fragment>{firstRow()}</React.Fragment>
					)}
				</div>
			</div>
		);
	};

	const htmlBody = () => {
		return symbolData ? (
			quotesBody()
		) : (
			<div className="no-data-container">
				<NoData />
			</div>
		);
	};

	return (
		<React.Fragment>{!isLoading ? htmlBody() : <Loader />}</React.Fragment>
	);
};

export default QuoteBar;
