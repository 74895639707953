import DataRequest from './dataRequest';
import URLS from '../constants/urlConstants';
import {
	ICurrentDividendsResponse,
	IHistoricalDividendsResponse,
} from '../types/interfaces';
import { resolvePromiseWithError } from '../utilities/utils';
import { E_HTTP_METHOD } from '../constants/appConstants';

export const getCurrentDividends = async (
	venueXid: number,
): Promise<ICurrentDividendsResponse> => {
	if (!venueXid) {
		return await resolvePromiseWithError();
	}
	return await DataRequest.execute(
		`${URLS.PLATFORM.CORPORATEACTIONS_DIVIDENDS}/` + venueXid + `/current`,
		{
			method: E_HTTP_METHOD.GET,
			params: {
				outputCurrency: 'USD',
				adjustForSpinoffs: true,
			},
		},
	);
};

export const getHistoricalDividends = async (
	venueXid: number,
	startDate?: string,
	endDate?: string,
): Promise<IHistoricalDividendsResponse> => {
	let paramBody;
	if (startDate && endDate) {
		paramBody = {
			exDateStart: startDate,
			exDateEnd: endDate,
			convertToUSD: true,
			sortDirection: 'desc',
			numberOfResults: 1000,
		};
	} else {
		paramBody = {
			convertToUSD: true,
			sortDirection: 'desc',
			numberOfResults: 1,
		};
	}
	if (!venueXid && !startDate && !endDate) {
		return await resolvePromiseWithError();
	}
	return await DataRequest.execute(
		`${URLS.PLATFORM.CORPORATEACTIONS_DIVIDENDS}/` +
			venueXid +
			`/historical-events`,
		{
			method: E_HTTP_METHOD.GET,
			params: paramBody,
		},
	);
};
