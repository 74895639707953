import React from 'react';
import { IBasicIconProps } from '../IBasicIconProps';

const DescendingArrowIcon: React.FC<IBasicIconProps> = ({
	width = 16,
	height = 12,
}) => (
	<svg xmlns="http://www.w3.org/2000/svg"
		width={width}
		height={height}
		viewBox="0 0 7 15"
		version="1.1">
		<title>Descending Icon</title>
		<g id="icon-desc" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="sort-icon--desc" transform="translate(0.6671, 0.8518)" fill="#2B6D9F" fillRule="nonzero">
				<path d="M3.59882358,13.127059 L3.54941182,13.1764708 C3.45058829,13.2917649 3.30235299,13.3576473 3.13764711,13.3576473 C2.97294122,13.3576473 2.82470592,13.2917649 2.70941181,13.1764708 L0.172941179,10.5082355 C-0.0576470597,10.2611766 -0.0576470597,9.8658825 0.172941179,9.61882367 C0.403529418,9.37176485 0.782352953,9.37176485 1.01294119,9.61882367 L2.54470592,11.2164708 L2.54470592,0.592941185 C2.54470592,0.263529416 2.80823534,0 3.13764711,0 C3.46705888,0 3.73058829,0.263529416 3.73058829,0.592941185 L3.73058829,11.2164708 L5.26235302,9.61882367 C5.49294126,9.37176485 5.87176479,9.37176485 6.10235303,9.61882367 C6.33294127,9.8658825 6.33294127,10.2611766 6.10235303,10.5082355 L3.59882358,13.127059 Z" id="Path"/>
			</g>
		</g>
	</svg>
);

export default DescendingArrowIcon;
