/* eslint-disable indent */
import React, {
	useState,
	useEffect,
	useRef,
	useMemo,
	createContext,
	useContext,
} from 'react';
import {
	CREATE_PORTFOLIO_BUTTON,
	CREATE_PORTFOLIO,
	PORTFOLIO_HEADER,
	PORTFOLIO_TRACKER_TABLE_DATA_MAPPING,
	PORTFOLIO_TRACKER_HEADER,
	PORTFOLIO_ACTIONS,
	CREATE_EDIT_PORTFOLIO_TYPE,
	ERROR_MESSAGE_STATE_TYPE,
	PORTFOLIO_MESSAGE,
	PORTFOLIO_TRACKER_KEYS,
	PORTFOLIO_TABLE_LAST_ROW,
	MAX_PORTFOLIO,
	PORTFOLIO_TRACKER_ACTION_ITEMS,
} from './portfolio-constants';
import './portfolio-container.scss';
import {
	Heading,
	Button,
	Loader,
	ConfirmationDialog,
	Textarea,
	MessageBoxStateType,
	MessageBox,
	SingleSelect,
	Container,
} from '../../../@core-ui';
import CreateEditPortfolio from './create-edit-portfolio/create-edit-portfolio';
import {
	getAllPortfolios,
	getPortfoliosInfo,
	deletePortfolio,
	updatePortfolio,
} from '../../../services';
import {
	IDeletePortfolioResponse,
	IUpdatePortfolioResponse,
	IPortfolioAnalysisResponse,
} from '../../../types/interfaces';
import { getDate, DATE_FORMATS } from '../../../utilities/date-time-formatter';
import { generateColValue, bindData, checkIfAPIError } from './portfolio-utils';
import { PrinterIcon, HelpIcon, IBasicIconProps } from '../../../assets/Icons';
import { getAccessToken, getDeviceType } from '../../../utilities/utils';
import NoData from '../../../shared/NoData/NoData';
import {
	E_DeviceType,
	PRINT_WAIT_LOAD_TIME,
} from '../../../constants/appConstants';
import PortfolioTracker from './portfolio-tracker/portfolio-tracker';
import BackLink from './../../../shared/back-link/back-link';
import { InfoIcon } from './../../../assets/Icons';

const PortfolioTabContext = createContext<any>(null);
export const usePortfolioTabContext = () => useContext(PortfolioTabContext);

type ActionObjectType = {
	action?: string;
	doPrint?: string;
	data?: CREATE_EDIT_PORTFOLIO_TYPE;
};

type PortfolioContainerPropsTypes = {
	onLoad?: () => void;
	actionObject?: ActionObjectType;
	porfolioIdState?: string;
	setPortfolioState?: React.Dispatch<React.SetStateAction<string>>;
};

const PortfolioContainer = (props: PortfolioContainerPropsTypes) => {
	const [portfoliosData, setPortfoliosData] = useState<any>(null);
	const [activeTab, setActiveTab] = useState(null);
	const [containerState, setContainerState] = useState(
		getInitialContainerState(),
	);

	const [actionState, setActionState] =
		useState<ActionObjectType>(getActionState());

	const [masterActionState, setMasterActionState] = useState<ActionObjectType>(
		getActionState(props.actionObject?.action, props.actionObject?.data),
	);

	const masterStateMemo = useMemo(() => {
		return masterActionState;
	}, [masterActionState]);

	const isMobile = getDeviceType() === E_DeviceType.Mobile;
	const [combinedPortfolio, setCombinedPortfolio] = useState<any>(null);
	const [errorMessage, setErrorMessage] = useState<ERROR_MESSAGE_STATE_TYPE>(
		getErrorState(''),
	);
	const [isActionTriggered, setIsActionTriggered] = useState(false);
	const [selectedPortfolio, setSelectedPortfolio] = useState<string>(
		props.actionObject?.data?.id ?? '',
	);
	const [singleSelectOpenedId, setSingleSelectOpenedId] = useState('');
	const refNotes = useRef<any>(null);

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		const isLoading: any = containerState?.isLoading;
		const doPrint: any = props?.actionObject?.doPrint;

		if (doPrint == 'true' && !isLoading) {
			setTimeout(() => {
				fireOnLoadPrint();
			}, PRINT_WAIT_LOAD_TIME);
		}
	}, [containerState]);

	const fireOnLoadPrint = () => {
		props.onLoad?.();
	};

	function backLinkHandler() {
		setActiveTab(null);
		setMasterActionState({
			action: PORTFOLIO_ACTIONS.CANCEL,
		});
		props?.porfolioIdState && props?.setPortfolioState?.('');
	}

	function getInitialContainerState() {
		return {
			isLoading: true,
			isError: false,
		};
	}

	function getActionState(action?: string, data?: CREATE_EDIT_PORTFOLIO_TYPE) {
		return {
			action: action,
			data: data,
		};
	}

	function getErrorState(message: string, type?: MessageBoxStateType) {
		return {
			message: message,
			status: type ?? MessageBoxStateType.Fail,
		};
	}

	const setMasterErrorState = () => {
		setErrorMessage(getErrorState(PORTFOLIO_MESSAGE.GENERIC_ERROR));
		setContainerState({
			isError: true,
			isLoading: false,
		});
	};

	const fetchData = () => {
		setSingleSelectOpenedId('');
		setContainerState(getInitialContainerState());
		setPortfoliosData(null);
		setCombinedPortfolio(null);
		let combinedPortfolioState: any = {};
		const portfolioData: any = [];
		const portfoliosDataCallBack = (response: any) => {
			if (checkIfAPIError(response)) {
				setMasterErrorState();
			} else {
				const masterResult = response?.data?.data?.items;
				prepareMasterData(masterResult, portfolioData, portfoliosInfoCallBack);
			}
		};

		const portfoliosInfoCallBack = (response: IPortfolioAnalysisResponse) => {
			const completeData = response?.data?.data;
			const todaysDate = new Date();
			const yesterdayDate = getDate(
				new Date(todaysDate.setDate(todaysDate.getDate() - 1)),
				DATE_FORMATS.YEAR_MONTH_DATE,
			);
			if (checkIfAPIError(response)) {
				setMasterErrorState();
			} else {
				let minDate = new Date();
				if (completeData.containers) {
					completeData.containers.items?.forEach((item: any, idx: number) => {
						const currentMinDate = new Date(portfolioData[idx]?.inceptionDate);
						minDate = currentMinDate < minDate ? currentMinDate : minDate;
						const inceptionDate = portfolioData[idx]?.['inceptionDate'];

						portfolioData[idx] = {
							...calculateGainLoss(
								item.value?.[0]?.value,
								item,
								inceptionDate,
								yesterdayDate,
							),
							...portfolioData[idx],
						};
					});
				}

				combinedPortfolioState = calculateGainLoss(
					completeData.all?.value?.[0]?.value,
					completeData.all,
					getDate(minDate, DATE_FORMATS.YEAR_MONTH_DATE),
					yesterdayDate,
				);
				combinedPortfolioState[PORTFOLIO_TRACKER_KEYS.TOTALS] =
					PORTFOLIO_TRACKER_KEYS.TOTALS;
			}

			setCombinedPortfolio(combinedPortfolioState);
			setPortfoliosData(portfolioData);
			//Open view portfolio if previously user opened any portfolio
			props?.porfolioIdState &&
				setActionOnClick(
					PORTFOLIO_ACTIONS.VIEW_PORTFOLIO,
					props?.porfolioIdState,
					true,
					portfolioData,
				);
			setContainerState({
				isLoading: false,
				isError: false,
			});
		};
		getAllPortfolios(portfoliosDataCallBack);
	};

	const calculateGainLoss = (
		value: any,
		item: any,
		inceptionDate = '',
		yesterday = '',
	) => {
		const valueObj = {
			value: value,
			gain: 0.0,
			chgToday: 0.0,
			pctChgToday: 0.0,
		};
		if (item) {
			const todaysData = item.unrealizedGain?.find(
				(e: any) => e.beginDate === yesterday,
			);
			if (todaysData) {
				valueObj.chgToday = todaysData['gain'];
				valueObj.pctChgToday = todaysData['percent'];
			}
			const inceptionData = item.unrealizedGain?.find(
				(e: any) => e.beginDate === inceptionDate,
			);
			if (inceptionData) {
				valueObj.gain = inceptionData['gain'];
			}
		}
		return valueObj;
	};

	const prepareMasterData = (
		masterResult: any,
		portfolioData: any,
		portfoliosInfoCallBack: (response: IPortfolioAnalysisResponse) => void,
	) => {
		const todaysDate = new Date();
		if (masterResult?.length) {
			const datesArray: any[] = [];
			const containerIds: string[] = [];
			masterResult.forEach((item: any) => {
				const inceptionDate = getDate(
					new Date(item.inceptionDate),
					DATE_FORMATS.YEAR_MONTH_DATE,
				);
				portfolioData.push({
					name: item.name,
					description: item.description,
					inceptionDate: inceptionDate,
					id: item.id,
				});
				containerIds.push(item.id);
				datesArray.push({
					beginDate: inceptionDate,
					endDate: getDate(todaysDate, DATE_FORMATS.YEAR_MONTH_DATE),
				});
			});
			datesArray.push({
				endDate: getDate(todaysDate, DATE_FORMATS.YEAR_MONTH_DATE),
				beginDate: getDate(
					new Date(todaysDate.setDate(todaysDate.getDate() - 1)),
					DATE_FORMATS.YEAR_MONTH_DATE,
				),
			});
			getPortfoliosInfo(containerIds, datesArray, portfoliosInfoCallBack);
		} else {
			setContainerState({
				isLoading: false,
				isError: false,
			});
		}
	};

	const deletePortfolioCallBack = (response: IDeletePortfolioResponse) => {
		setIsActionTriggered(false);
		let fetchAgain = false;
		let wipeMasterState = false;
		if (checkIfAPIError(response)) {
			setErrorMessage(getErrorState(PORTFOLIO_MESSAGE.GENERIC_ERROR));
		} else {
			setErrorMessage(
				getErrorState('Portfolio Deleted', MessageBoxStateType.Success),
			);
			fetchAgain = true;
			wipeMasterState = true;
		}
		wipeMessage(fetchAgain, wipeMasterState);
	};

	const notesPortfolioCallBack = (response: IUpdatePortfolioResponse) => {
		setIsActionTriggered(false);
		if (checkIfAPIError(response)) {
			setErrorMessage(getErrorState(PORTFOLIO_MESSAGE.GENERIC_ERROR));
		} else {
			const portfolioData = portfoliosData;
			const item = portfolioData.find(
				(e: any) => e.id === actionState.data?.id,
			);
			if (item) {
				item[PORTFOLIO_TRACKER_KEYS.DESCRIPTION] =
					actionState.data?.description?.trim();
				setPortfoliosData(portfolioData);
			}
			setErrorMessage(
				getErrorState(
					PORTFOLIO_MESSAGE.NOTES_UPDATED,
					MessageBoxStateType.Success,
				),
			);
		}
		wipeMessage();
	};

	const onCrossClick = () => {
		setErrorMessage(getErrorState(''));
	};

	const setActionOnClick = (
		action: string,
		portfolioId: string,
		isMasterAction: boolean,
		currentPortfolioData?: any,
	) => {
		const currentPortfolioInfo = (currentPortfolioData || portfoliosData).find(
			(e: any) => e.id === portfolioId,
		);

		let setActionStateMethod = setActionState;

		if (isMasterAction) {
			setActionStateMethod = setMasterActionState;
			setSelectedPortfolio(portfolioId);
		}
		let isPortfolioView = false;

		if (
			action === PORTFOLIO_ACTIONS.VIEW_PORTFOLIO ||
			masterActionState?.data?.isPortfolioView
		) {
			isPortfolioView = true;
		}

		if (currentPortfolioInfo) {
			setActionStateMethod(
				getActionState(action, {
					name: currentPortfolioInfo.name,
					id: portfolioId,
					description: currentPortfolioInfo.description,
					inceptionDate: currentPortfolioInfo.inceptionDate,
					isPortfolioView: isPortfolioView,
				}),
			);
			props.setPortfolioState?.(portfolioId);
		}
	};

	const onClickIcon = (e: any) => {
		const action = e.currentTarget.getAttribute('data-action');
		const portfolioId = e.currentTarget.getAttribute('data-key');
		const isMasterAction = e.currentTarget.getAttribute('data-master-action');
		setActionOnClick(action, portfolioId, isMasterAction === 'true');
	};

	const performAction = () => {
		if (!isActionTriggered) {
			const description = refNotes?.current?.value;
			if (actionState?.data) {
				actionState.data.description = description;
			}
			switch (actionState.action) {
				case PORTFOLIO_ACTIONS.DELETE_PORTFOLIO:
					deletePortfolio(actionState.data?.id ?? '', deletePortfolioCallBack);
					break;
				case PORTFOLIO_ACTIONS.EDIT_NOTES:
					updatePortfolio(actionState.data?.id ?? '', notesPortfolioCallBack, {
						name: actionState.data?.name ?? '',
						description: description,
					});
					break;
			}
			setIsActionTriggered(true);
		}
	};

	const wipeMessage = (fetchAgain?: boolean, wipeMasterState?: boolean) => {
		setActionState(getActionState());
		setTimeout(() => {
			setErrorMessage(getErrorState(''));
			if (fetchAgain) {
				wipeMasterState && setMasterActionState(getActionState());
				fetchData();
			}
		}, 2000);
	};

	function handleClick(
		action?: string,
		fetchAgain?: boolean,
		checkLength?: boolean,
		data?: CREATE_EDIT_PORTFOLIO_TYPE | null,
	) {
		if (checkLength && portfoliosData?.length >= MAX_PORTFOLIO) {
			setErrorMessage(getErrorState(PORTFOLIO_MESSAGE.MAX_PORTFOLIO));
		} else {
			setMasterActionState(getActionState(action, data || undefined));
			props.setPortfolioState?.(data?.id ?? '');
		}
		if (fetchAgain) {
			fetchData();
		}
	}

	const openHelpGlossary = () => {
		setMasterActionState(getActionState(PORTFOLIO_ACTIONS.HELP_GLOSSARY));
	};

	const printPortfolioTable = (isTrackerView?: boolean) => {
		if (!portfoliosData || portfoliosData.length < 1) {
			setErrorMessage(getErrorState('No portfolios available'));
		} else {
			const accessToken = window.btoa(getAccessToken());
			let url = `${window.MD?.WEB_APP_BASE_URL}print-portfolio?t=${accessToken}`;
			if (isTrackerView) {
				const tempMasterActionState: any = {
					...masterActionState,
					doPrint: 'true',
				};
				url += `&state=${window.btoa(JSON.stringify(tempMasterActionState))}`;
				if (activeTab) {
					url += `&activeTab=${activeTab}`;
				}
			}
			setActionState(getActionState());
			window.open(url, '');
		}
	};

	const getColumnValue = (
		item: any,
		data: any,
		idx: number,
		noColor?: boolean,
	) => {
		const newItem: any = { ...item };
		if (item.isCalculated) {
			newItem.dataKey = data?.id;
			newItem.onClick = (e: any) => onClickIcon(e);
		}
		if (
			!noColor &&
			item.key === PORTFOLIO_TRACKER_KEYS.DESCRIPTION &&
			data?.[item.key]?.length
		) {
			newItem.color = '#2B6D9F';
		}

		const text = data?.[item.key] ? data[item.key] : data;
		return (
			<React.Fragment key={`col_${idx}`}>
				{generateColValue(newItem, text)}
			</React.Fragment>
		);
	};

	const getPortfolioRows = (data: any, ix: number, mobileFirstRow: any[]) => {
		return (
			<div
				className={`${isMobile ? 'single-porfolio' : 'common-row'}`}
				key={`row_${ix}`}
			>
				{PORTFOLIO_TRACKER_TABLE_DATA_MAPPING.map((item, idx: number) => {
					if (!isMobile) {
						return getColumnValue(item, data, idx);
					} else if (item.showInSingleRow) {
						mobileFirstRow.push(getColumnValue(item, data, idx + 2));
					} else {
						return (
							<div className={'common-row'} key={`row_${item.key}`}>
								{getColumnValue(
									PORTFOLIO_TRACKER_HEADER[idx],
									PORTFOLIO_TRACKER_HEADER[idx].label,
									idx + 1,
								)}
								{getColumnValue(item, data, idx + 2)}
							</div>
						);
					}
				})}
				{isMobile && (
					<div className={'top-row-portfolio common-row'}>{mobileFirstRow}</div>
				)}
			</div>
		);
	};

	const bindTable = () => {
		const portfolioData = portfoliosData?.map((data: any, ix: number) => {
			const mobileFirstRow: any[] = [];
			return getPortfolioRows(data, ix, mobileFirstRow);
		});

		/**
		 *This configuration is used for rendering combined view of portfolio container
		 *For first label color is black and weight is bold.
		 *The remaining label are grey and not bold
		 */

		const combinedLabelsConfig = { ...PORTFOLIO_TABLE_LAST_ROW[0] };
		combinedLabelsConfig.isGrey = false;
		const combinedData = (
			<div
				className={`${
					isMobile
						? 'single-porfolio combined-portfolios'
						: 'common-row last-row'
				}`}
			>
				{combinedPortfolio &&
					PORTFOLIO_TABLE_LAST_ROW.map((item: any) => {
						if (!isMobile) {
							return (
								<React.Fragment key={`col_${item.key}`}>
									{generateColValue(item, combinedPortfolio[item.key])}
								</React.Fragment>
							);
						} else if (!item.isMobileHide) {
							if (!item.noValue) {
								combinedLabelsConfig.isBold = false;
								combinedLabelsConfig.isGrey = true;
							}
							return (
								<div className={'common-row'} key={`col_${item.key}`}>
									{generateColValue(combinedLabelsConfig, item.label)}
									{!item.noValue &&
										generateColValue(item, combinedPortfolio[item.key])}
								</div>
							);
						}
					})}
			</div>
		);

		return (
			<React.Fragment>
				{portfolioData}
				{combinedData}
			</React.Fragment>
		);
	};

	const getPrintHelpIcons = (
		onClick: (e: any) => void,
		text: string,
		Icon: React.FC<IBasicIconProps>,
		className?: string,
	) => {
		return (
			<button className={`print-help-button ${className}`} onClick={onClick}>
				<Icon height={24} width={24} />
				<Heading content={text} type={'h6'} />
			</button>
		);
	};

	const bindPortfolioTracker = () => {
		if (!containerState.isError && !portfoliosData?.length) {
			return (
				<div className={'no-data-container'}>
					<NoData text={PORTFOLIO_MESSAGE.NO_PORTFOLIO} />
				</div>
			);
		}
		return (
			<React.Fragment>
				{!isMobile &&
					bindData(
						PORTFOLIO_TRACKER_HEADER,
						'header',
						'portfolio-table-header common-row',
					)}
				{bindTable()}
			</React.Fragment>
		);
	};

	const bindActions = () => {
		if (actionState.action == PORTFOLIO_ACTIONS.DELETE_PORTFOLIO) {
			const message = (
				<p>
					{`Are you sure you want to delete `}
					<b>{actionState.data?.name}?</b>
					{` This portfolio can not be recovered.`}
				</p>
			);
			return (
				<ConfirmationDialog
					id={'edit-delete-container'}
					onCancel={() => {
						setIsActionTriggered(false);
						setActionState(getActionState());
					}}
					onConfirm={performAction}
					dataClass={'edit-delete-container'}
				>
					<MessageBox content={message} />
				</ConfirmationDialog>
			);
		} else if (actionState.action == PORTFOLIO_ACTIONS.EDIT_NOTES) {
			return (
				<ConfirmationDialog
					onCancel={() => {
						setIsActionTriggered(false);
						setActionState(getActionState());
					}}
					onConfirm={performAction}
					title={`${actionState.data?.name} Notes`}
					confirmButtonText={'Save'}
					dataClass={'edit-delete-container'}
					id={PORTFOLIO_ACTIONS.EDIT_NOTES}
				>
					<Textarea value={actionState.data?.description} ref={refNotes} />
				</ConfirmationDialog>
			);
		} else if (actionState.action == PORTFOLIO_ACTIONS.PRINT_PORTFOLIO) {
			printPortfolioTable(true);
		}
	};

	const changeDropDown = (event: any) => {
		const portfolioId = event.getAttribute('data-key');
		if (portfolioId !== selectedPortfolio) {
			const currentPortfolioInfo = portfoliosData.find(
				(e: any) => e.id === portfolioId,
			);

			setSelectedPortfolio(portfolioId);
			if (currentPortfolioInfo) {
				setMasterActionState(
					getActionState(PORTFOLIO_ACTIONS.VIEW_PORTFOLIO, {
						name: currentPortfolioInfo.name,
						id: portfolioId,
						description: currentPortfolioInfo.description,
						inceptionDate: currentPortfolioInfo.inceptionDate,
						isPortfolioView: true,
					}),
				);
				props.setPortfolioState?.(portfolioId);
			}
		}
	};

	const changeActionItem = (event: any, portfolioId: string) => {
		const key = event.getAttribute('data-key');
		const action = key.split('_')?.[0];
		const isMasterAction = key.split('_')?.[1];
		setActionOnClick(action, portfolioId, isMasterAction === 'true');
	};

	const bindPortfolioTrackerActions = () => {
		if (isMobile) {
			return (
				<SingleSelect
					id={'dropdownActionItem'}
					isListOpen={singleSelectOpenedId === 'dropdownActionItem'}
					setSingleSelectOpenedId={setSingleSelectOpenedId}
					data={PORTFOLIO_TRACKER_ACTION_ITEMS?.map((e: any) => {
						const masterAction = e.masterAction;
						return {
							key: `${e.action}_${masterAction}`,
							value: e.text,
							icon: e.icon,
						};
					})}
					changeHandler={(e) =>
						changeActionItem(e, masterActionState?.data?.id ?? '')
					}
					icon={<InfoIcon />}
				/>
			);
		}
		const actions = PORTFOLIO_TRACKER_ACTION_ITEMS.map(
			(item: any, idx: number) => {
				return getColumnValue(
					item,
					actionState.data || masterActionState.data,
					idx,
					true,
				);
			},
		);
		return <React.Fragment>{actions}</React.Fragment>;
	};

	const bindPortfolioTrackerHeader = () => {
		const dropDownData = portfoliosData?.map((e: any) => {
			return {
				key: e.id,
				value: e.name,
			};
		});
		return (
			<div className="portfolio-item-header">
				<SingleSelect
					isListOpen={false}
					setSingleSelectOpenedId={setSingleSelectOpenedId}
					id={'dropdownPortfolioList'}
					data={dropDownData}
					changeHandler={changeDropDown}
					selectedValue={selectedPortfolio}
				/>
				<div className="portfolio-item-actions">
					{bindPortfolioTrackerActions()}
				</div>
			</div>
		);
	};
	const getTrackerHeader = () => {
		let firstElement = (
			<Button
				className={'create-portfolio-button'}
				testId={CREATE_PORTFOLIO}
				onClick={() =>
					handleClick(PORTFOLIO_ACTIONS.EDIT_PORTFOLIO, false, true)
				}
			>
				{CREATE_PORTFOLIO_BUTTON}
			</Button>
		);
		let secondElement = (
			<React.Fragment>
				{getPrintHelpIcons(printPortfolioTable, 'Print', PrinterIcon)}
				{getPrintHelpIcons(openHelpGlossary, 'Help', HelpIcon, 'help-icon')}
			</React.Fragment>
		);

		if (isMobile) {
			[firstElement, secondElement] = [secondElement, firstElement];
		}

		return (
			<header className={'header'}>
				<div className={'left-header'}>
					<Heading content={PORTFOLIO_HEADER} type={'h1'} />
					{firstElement}
				</div>
				<div className={'right-header'}>{secondElement}</div>
			</header>
		);
	};

	const value = useMemo(
		() => ({
			activeTab,
			setActiveTab,
		}),
		[activeTab],
	);

	const renderView = () => {
		if (masterStateMemo.action === PORTFOLIO_ACTIONS.VIEW_PORTFOLIO) {
			return (
				<>
					{bindPortfolioTrackerHeader()}
					<PortfolioTabContext.Provider value={value}>
						<PortfolioTracker
							data={masterStateMemo.data as CREATE_EDIT_PORTFOLIO_TYPE}
							onLoad={fireOnLoadPrint}
						/>
					</PortfolioTabContext.Provider>
				</>
			);
		}
		return (
			<React.Fragment>
				<div id="portfolio-container" className="portfolio-container">
					{getTrackerHeader()}
				</div>
				{containerState.isLoading ? (
					<div className={'loader-container'}>
						<Loader />
					</div>
				) : (
					<div id={'portfolio_tracker_table'}>{bindPortfolioTracker()}</div>
				)}
			</React.Fragment>
		);
	};

	const renderEditPortfolioView = () => {
		return (
			<React.Fragment>
				{renderErrorMessage()}
				<CreateEditPortfolio
					data={masterStateMemo.data}
					handleClick={handleClick}
					isGlossary={
						masterStateMemo.action === PORTFOLIO_ACTIONS.HELP_GLOSSARY
					}
				/>
			</React.Fragment>
		);
	};

	const renderErrorMessage = () => {
		return (
			errorMessage.message.length > 0 && (
				<MessageBox
					content={errorMessage.message}
					state={errorMessage.status}
					enableCloseIcon={true}
					onClose={onCrossClick}
					autoHide={true}
					timeAutoHide={3000}
				/>
			)
		);
	};

	return (
		<>
			{masterActionState.action === PORTFOLIO_ACTIONS.VIEW_PORTFOLIO && (
				<BackLink onClick={backLinkHandler} />
			)}
			{masterStateMemo.action != PORTFOLIO_ACTIONS.EDIT_PORTFOLIO &&
				masterStateMemo.action != PORTFOLIO_ACTIONS.HELP_GLOSSARY && (
					<Container
						id={'portfolio-tracker-container'}
						applyPadding
						applyBorder
						applyWhiteBackground
					>
						<div
							className={`portfolio-master ${
								containerState.isLoading && 'portfolio-master-disabled'
							}`}
						>
							{<div>{bindActions()}</div>}

							{renderErrorMessage()}
							{renderView()}
						</div>
					</Container>
				)}

			{(masterStateMemo.action === PORTFOLIO_ACTIONS.EDIT_PORTFOLIO ||
				masterStateMemo.action === PORTFOLIO_ACTIONS.HELP_GLOSSARY) &&
				renderEditPortfolioView()}
		</>
	);
};

export default PortfolioContainer;
