import PropTypes from 'prop-types';
import { useState, useRef, useEffect } from 'react';
import {
	SYMBOL_SEARCH_TYPE_DROPDOWN_OPTIONS,
	SECURITY_TYPES_DROPDOWN_OPTIONS,
	E_SearchTypes,
	NoSearchResultsFoundByName,
	NoSearchResultsFound,
	EmptySymbolSearched,
	E_SecurityTypes,
} from './symbol-search-modal-constants';
import {
	handleEnterKeyPress,
	isAlphaNumeric,
	sortByKey,
} from '../../../../utilities/utils';
import {
	IXrefResponseItem,
	IXrefPredictiveVenueRequest,
} from './../../../../types/interfaces';
import {
	Modal,
	Button,
	SingleSelect,
	Textbox,
	Loader,
	LabelLink,
	MessageBox,
} from '../../../../@core-ui';
import {
	getXrefDataBySymbol,
	getXrefDataByPredictiveSearch,
} from '../../../../services/xref-data-request-methods';
import './symbol-search-modal.scss';
import SymbolSearchResults from '../symbol-search-results/symbol-search-results';
import Symbology from '../symbology/symbology';
import { ISymbolSearchResultTable } from '../symbol-search-results/symbol-search-results-constants';

interface SymbolSearchModalProps {
	onAddSymbol: (item: IXrefResponseItem) => void;
	incorrectSymbol?: string;
	onClose: () => void;
	title?: string;
}

const SymbolSearchModal: React.FC<SymbolSearchModalProps> = (props) => {
	const { onAddSymbol, incorrectSymbol, onClose } = props;
	const [loadingState, setLoadingState] = useState({
		isLoading: false,
		isReady: false,
		noResults: false,
	});
	const [searchResults, setSearchResults] = useState<IXrefResponseItem[]>([]);
	const [searchValue, setSearchValue] = useState(incorrectSymbol ?? '');
	const [selectedSearchType, setSelectedSearchType] = useState(
		E_SearchTypes.Symbol,
	);
	const [selectedSecurity, setSelectedSecurity] = useState(E_SecurityTypes.All);
	const [currentPage, setCurrentPage] = useState(1);
	const searchInputRef = useRef<HTMLInputElement>(null);

	const [isActionTriggered, setIsActionTriggered] = useState(false);
	const [securityTypes, setSecurityTypes] = useState(
		SECURITY_TYPES_DROPDOWN_OPTIONS,
	);
	const [searchTypes, setSearchTypes] = useState(
		SYMBOL_SEARCH_TYPE_DROPDOWN_OPTIONS,
	);
	const [singleSelectOpenedId, setSingleSelectOpenedId] = useState('');
	const [emptySymbolSearched, setEmptySymbolSearched] = useState(false);

	useEffect(() => {
		if (incorrectSymbol) setNoResultsFoundState();
	}, [incorrectSymbol]);

	const getSymbolSearchResultTableData = (): ISymbolSearchResultTable[] => {
		return searchResults.map((x) => ({
			Name: (
				<LabelLink
					text={x.name}
					data-type={x?.classification?.name}
					onClick={() => onAddSymbol(x)}
				></LabelLink>
			),
			Symbol: x.symbol,
		}));
	};

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = event.target.value;
		if (isAlphaNumeric(inputValue)) {
			setSearchValue(inputValue);
		}
	};

	const setNoResultsFoundState = () => {
		setSearchResults([]);
		setLoadingState({ ...loadingState, noResults: true });
		if (searchInputRef.current) {
			searchInputRef.current.focus();
		}
	};

	const setInitialState = () => {
		setEmptySymbolSearched(false);
		setCurrentPage(1);
		setSearchResults([]);
		setLoadingState({ isLoading: true, isReady: false, noResults: false });
	};

	const fetchXrefBySymbolSearch = async () => {
		const response = await getXrefDataBySymbol(searchValue?.trim());
		if (
			response?.data?.data &&
			(response.data.data.classification?.id === Number(selectedSecurity) ||
				selectedSecurity === E_SecurityTypes.All) &&
			response.data.data.classification?.id !== E_SecurityTypes.Index
		) {
			onAddSymbol(response.data.data);
			onClose();
			setLoadingState({
				isLoading: false,
				noResults: false,
				isReady: true,
			});
		} else {
			setNoResultsFoundState();
		}
	};

	const fetchXrefByPredictiveSearch = async () => {
		const requestParams: IXrefPredictiveVenueRequest = {
			term: searchValue?.trim(),
			limit: 15,
			...(Number(selectedSecurity) !== E_SecurityTypes.All && {
				classificationIds: [selectedSecurity],
			}),
		};
		const response = await getXrefDataByPredictiveSearch(requestParams);
		if (response?.data?.data?.items?.length) {
			const sortedResults = [...response.data.data.items].sort(
				sortByKey('name'),
			);
			const filteredResults = sortedResults.filter(
				(item) => item?.classification?.id !== E_SecurityTypes.Index,
			);
			setSearchResults(filteredResults);
			setLoadingState({ isLoading: false, noResults: false, isReady: true });
		} else {
			setSearchResults([]);
			setNoResultsFoundState();
		}
	};

	const searchTypeChangeHandler = (e: any) => {
		const selected = e.getAttribute('data-key');
		if (selected === E_SearchTypes.Name || selected === E_SearchTypes.CUSIP) {
			setSecurityTypes(
				securityTypes.filter((x) => x.key !== E_SecurityTypes.Options),
			);
		} else {
			setSecurityTypes(SECURITY_TYPES_DROPDOWN_OPTIONS);
		}
		setSelectedSearchType(e.getAttribute('data-key'));
	};

	const securityTypeChangeHandler = (e: any) => {
		const selected = e.getAttribute('data-key');
		if (selected === E_SecurityTypes.Options?.toString()) {
			setSearchTypes(searchTypes.filter((x) => x.key === E_SearchTypes.Symbol));
		} else {
			setSearchTypes(SYMBOL_SEARCH_TYPE_DROPDOWN_OPTIONS);
		}
		setSelectedSecurity(e.getAttribute('data-key'));
	};

	const onSearch = async () => {
		if (!searchValue) {
			setEmptySymbolSearched(true);
			return;
		}
		setInitialState();
		if (!isActionTriggered) {
			setIsActionTriggered(true);
			if (selectedSearchType === E_SearchTypes.Symbol) {
				await fetchXrefBySymbolSearch();
			} else {
				await fetchXrefByPredictiveSearch();
			}
			setIsActionTriggered(false);
		}
	};

	const getMessageBoxContent = () => {
		if (emptySymbolSearched) return EmptySymbolSearched;
		if (selectedSearchType !== E_SearchTypes.Name)
			return NoSearchResultsFoundByName;

		return NoSearchResultsFound;
	};

	return (
		<div className="symbol-search-container">
			<Modal
				title={`${props.title ?? 'Search'}`}
				onClose={onClose}
				id="mdlSymbolSearch"
			>
				<div className="search-section">
					<div className="search-row first-row">
						<div className="search-textbox">
							<Textbox
								placeholder={'Enter Symbol'}
								testId={'symbol-search'}
								onChange={handleInputChange}
								onKeyDown={(e) => handleEnterKeyPress(e, onSearch)}
								value={searchValue}
								ref={searchInputRef}
							/>
						</div>
						<div className="search-type-dropdown">
							<SingleSelect
								data={searchTypes}
								changeHandler={searchTypeChangeHandler}
								dataTestId={'drpSearchType'}
								setSingleSelectOpenedId={setSingleSelectOpenedId}
								isListOpen={singleSelectOpenedId === 'drpSearchType'}
								id={'drpSearchType'}
							/>
						</div>
					</div>
					<div className="search-row second-row">
						<div className="security-type-dropdown">
							<SingleSelect
								data={securityTypes}
								changeHandler={securityTypeChangeHandler}
								dataTestId={'drpSecurityType'}
								setSingleSelectOpenedId={setSingleSelectOpenedId}
								isListOpen={singleSelectOpenedId === 'drpSecurityType'}
								id={'drpSecurityType'}
							/>
						</div>
						<div className="search-button">
							<Button testId={`model-search-button`} onClick={onSearch}>
								Search
							</Button>
						</div>
					</div>
				</div>
				<div className="search-results-and-symbology">
					{loadingState.isLoading && <Loader size="small" />}
					{loadingState.noResults || emptySymbolSearched ? (
						<MessageBox content={getMessageBoxContent()} />
					) : (
						loadingState.isReady && (
							<SymbolSearchResults
								data={getSymbolSearchResultTableData()}
								currentPage={currentPage}
								setCurrentPage={setCurrentPage}
							/>
						)
					)}
					<Symbology onClose={onClose} />
				</div>
			</Modal>
		</div>
	);
};

SymbolSearchModal.propTypes = {
	onAddSymbol: PropTypes.func.isRequired,
	incorrectSymbol: PropTypes.string,
	onClose: PropTypes.func.isRequired,
	title: PropTypes.string,
};

export default SymbolSearchModal;
