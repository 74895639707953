import React, { useState, useEffect } from 'react';
import {
	IHoldingsWithTransactions,
	ITableHolding,
	PORTFOLIO_TRAKER_KEYS,
	ITableConstant,
	PortfolioTrackerMessage,
} from '../portfolio-tracker-constants';
import './portfolio-tracker-table.scss';
import {
	generateColValue,
	bindData,
	IPadMobileHTMLbody,
} from '../../portfolio-utils';
import {
	TEXT_NULL_VALUE,
	getFormattedCellValue,
} from '../../../../../utilities/formatter';
import {
	handleCSVExport,
	getDeviceType,
	scrollIntoView,
	delayedMessage,
} from '../../../../../utilities/utils';
import {
	T_DeviceType,
	E_DeviceType,
} from '../../../../../constants/appConstants';
import { Loader } from '../../../../../@core-ui';
import NoData from '../../../../../shared/NoData/NoData';
import {
	dispatchNewsCustomEvent,
	dispatchSymbolChangedCustomEvent,
} from '../../../../../utilities/events';
import { PORTFOLIO_TRACKER_TAB_NAMES } from '../../portfolio-constants';
import { getPortfolioFundamentalData } from '../../../../../services';
import { formatDateTime } from '../../../../../utilities/date-time-formatter';
import { IPortfolioFundamentalsInputs } from '../../../../../types/interfaces';
interface PortfolioTrackerTableProps {
	portfolioData: IHoldingsWithTransactions;
	constants: ITableConstant;
	showTransactions: boolean;
	isExportData?: boolean;
	activeSelectedTab: string;
	isExportComplete?: () => void;
	loadDivAndRatiosData?: boolean;
	setLoadDivAndRatiosData?: (arg0: boolean) => void;
}

const PortfolioTrackerTable = (props: PortfolioTrackerTableProps) => {
	const {
		constants,
		showTransactions,
		activeSelectedTab,
		isExportData,
		isExportComplete,
		loadDivAndRatiosData,
		setLoadDivAndRatiosData,
	} = props;

	const [portfolioData, setPortfolioData] = useState<IHoldingsWithTransactions>(
		props.portfolioData,
	);
	const [expandedRow, setExpandedRow] = useState<number>(-1);
	const [isExportCsvData, setIsExportCsvData] = useState(false);
	const [device] = useState<T_DeviceType>(getDeviceType());
	const [isLoading, setIsLoading] = useState(false);

	const isIpadOrMobile = device !== E_DeviceType.Desktop;
	const csvData: string[][] = [];
	const holdingsPerColumn = device === E_DeviceType.Mobile ? 1 : 2;

	useEffect(() => {
		getEpsAndDivData(activeSelectedTab);
	}, [activeSelectedTab]);

	useEffect(() => {
		setIsExportCsvData(isExportData ?? false);
	}, [isExportData]);

	const getEpsAndDivData = async (activeSelectedTab: any) => {
		if (
			activeSelectedTab == PORTFOLIO_TRACKER_TAB_NAMES.Fundamentals &&
			loadDivAndRatiosData
		) {
			setIsLoading(true);
			const xrefData = portfolioData?.holdings?.map(
				(holding: any) => holding.xrefData,
			);
			const fundamentalsInputs: IPortfolioFundamentalsInputs[] = [];
			xrefData.forEach((item) => {
				fundamentalsInputs.push({
					entityXid: item?.xids?.entity,
					venueXid: item?.xids?.venue,
				});
			});

			const response = await getPortfolioFundamentalData(fundamentalsInputs);

			const dividendsRes = response?.data?.data?.corporateDividends;
			const equityRes = response?.data?.data?.currentRatios;

			portfolioData?.holdings?.forEach((holding, idx) => {
				const dividendData: any = dividendsRes?.find(
					(dividend: any) => dividend?.metaData?.venueXid === holding?.xid,
				);

				holding.divExpiryDate = getDividendDates(
					PORTFOLIO_TRAKER_KEYS.DIV_EXPIRY_DATE,
					dividendData,
				);
				holding.divPayDate = getDividendDates(
					PORTFOLIO_TRAKER_KEYS.DIV_PAY_DATE,
					dividendData,
				);
				holding.divYield = getDividendDates(
					PORTFOLIO_TRAKER_KEYS.DIV_YIELD,
					dividendData,
				);

				const equityData = equityRes[idx]?.ratios;

				holding.epsTtm =
					equityData?.perShare?.epsIncludingExtraordinaryItemsTtm ??
					TEXT_NULL_VALUE;
				holding.peRatio =
					equityData?.valuation?.peExcludingExtraordinaryItemsTtm ??
					TEXT_NULL_VALUE;
			});

			// update portfolio data with dividend details
			setPortfolioData({ ...portfolioData });
			setIsLoading(false);
			setLoadDivAndRatiosData?.(false);
		}
	};

	const getDividendDates = (value: string, dividendData: any) => {
		if (value === PORTFOLIO_TRAKER_KEYS.DIV_EXPIRY_DATE) {
			return dividendData?.currentDividend?.exDate
				? formatDateTime(dividendData.currentDividend.exDate)
				: TEXT_NULL_VALUE;
		}
		if (value === PORTFOLIO_TRAKER_KEYS.DIV_PAY_DATE) {
			return dividendData?.currentDividend?.paymentDate
				? formatDateTime(dividendData?.currentDividend?.paymentDate)
				: TEXT_NULL_VALUE;
		}
		if (value === PORTFOLIO_TRAKER_KEYS.DIV_YIELD) {
			return dividendData?.annualizedDividend?.yieldGross ?? TEXT_NULL_VALUE;
		}
	};

	const handleRowClick = (index: number) => {
		if (expandedRow === index) {
			setExpandedRow(-1);
		} else {
			setExpandedRow(index);
		}

		const transactionGroup = document.querySelector(
			`#transaction_group_${index}`,
		) as HTMLElement;
		if (transactionGroup) {
			scrollIntoView(transactionGroup);
		}
	};

	useEffect(() => {
		if (isExportCsvData) {
			handleCSVExport(
				csvData,
				`${portfolioData?.name}_${activeSelectedTab}_Portfolio.csv`,
			);
			setIsExportCsvData(isExportData ?? false);
			setTimeout(() => {
				isExportComplete?.();
				setIsExportCsvData(false);
			}, 500);
		}
	}, [isExportCsvData]);

	const noData = () => {
		let noDataNode: React.ReactNode | null = null;
		if (!portfolioData?.holdings?.length) {
			noDataNode = (
				<div className={'no-data-container'}>
					<NoData text={PortfolioTrackerMessage.NoDataExist} isHtmlContent />
				</div>
			);
		}
		return noDataNode;
	};

	const handleNewsItem = (
		item: any,
		isExpanded: boolean,
		hasTransactions: boolean,
		holding: ITableHolding,
		holdingIndex: number,
	) => {
		const newItem = { ...item };
		if (item.isCalculated) {
			newItem.isExpanded = isExpanded;
			newItem.hasTransactions = hasTransactions;
			newItem.hasRecentNews = holding.hasRecentNews;
			newItem.onClick = () =>
				dispatchSymbolChangedCustomEvent(holding.xrefData);
			newItem.onExpandIconClick = () => handleRowClick(holdingIndex);
			newItem.onNotesIconClick = () => {
				dispatchNewsCustomEvent(holding.xrefData);
			};
		}
		return newItem;
	};

	const processRowJSX = (holding: ITableHolding, ix: number) => {
		const hasTransactions =
			showTransactions &&
			holding.transactions &&
			holding.transactions.length > 1;
		const isExpanded = expandedRow === ix;
		const csvHoldingRow: string[] = [];
		const csvTransData: string[][] = [];

		const rowJSX = (
			<React.Fragment key={`row_${holding.id}`}>
				<div className="common-row">
					{constants.mapping.map((item) => {
						const newItem = handleNewsItem(
							item,
							isExpanded,
							hasTransactions as boolean,
							holding,
							ix,
						);
						csvHoldingRow.push(getFormattedCellValue(holding, item));
						return (
							<React.Fragment key={`col_${item.key}`}>
								{generateColValue(
									newItem,
									holding[item.key],
									null,
									isLoading ? '...' : null,
								)}
							</React.Fragment>
						);
					})}
				</div>

				{hasTransactions && (
					<div className="transaction-rows">
						{holding?.transactions?.map((transaction) => {
							const csvTransactionData: string[] = [];
							const transactionRowJSX = (
								<div
									className="transaction-row common-row"
									key={`transaction_${transaction.id}`}
								>
									{constants.mapping.map((item) => {
										csvTransactionData.push(
											getFormattedCellValue(transaction, item),
										);
										return (
											<React.Fragment key={`col_${item.key}`}>
												{generateColValue(item, transaction[item.key])}
											</React.Fragment>
										);
									})}
								</div>
							);
							csvTransData.push(csvTransactionData);
							return isExpanded && transactionRowJSX;
						})}
					</div>
				)}
			</React.Fragment>
		);

		csvData.push(csvHoldingRow);
		csvTransData.forEach((csvTransRow) => csvData.push(csvTransRow));
		return rowJSX;
	};

	const processTableData = (holdingData: IHoldingsWithTransactions) => {
		return holdingData?.holdings.map((holding: ITableHolding, ix: number) => {
			return processRowJSX(holding, ix);
		});
	};

	const bindTable = (holdingData: IHoldingsWithTransactions) => {
		const noDataContent = noData();
		if (noDataContent) {
			return noDataContent;
		}

		const headerRow: string[] = [];
		constants.header.forEach((item) => {
			headerRow.push(item.label ?? '');
		});
		csvData.push(headerRow);

		const tableData = processTableData(holdingData);

		const combinedCsvRow: string[] = [];
		const combinedData = (
			<div className={'common-row last-row'}>
				{portfolioData &&
					constants.lastRow?.map((item: any) => {
						const newItem = { ...item };
						const value = portfolioData[item.key];
						newItem.isCalculated = true;
						combinedCsvRow.push(getFormattedCellValue(portfolioData, item));

						return (
							<React.Fragment key={`col_${item.key}`}>
								{generateColValue(newItem, value)}
							</React.Fragment>
						);
					})}
			</div>
		);

		csvData.push(combinedCsvRow);
		return (
			<React.Fragment>
				{tableData}
				{combinedData}
			</React.Fragment>
		);
	};

	const tableAndCombinedData = bindTable(portfolioData);

	const isLastHoldingColumn = (holdingsCount: number, holdingIndex: number) => {
		return holdingsPerColumn === 2 && holdingsCount % 2 === 0
			? holdingIndex === holdingsCount - 1 || holdingIndex === holdingsCount - 2
			: holdingIndex === holdingsCount - 1;
	};

	const getLabel = (item: any) => {
		return constants.header.find((header) => header.key === item.key)?.label;
	};

	const transactionRow = (transaction: any) => {
		return constants.mapping.map((item) => {
			return (
				item.key !== PORTFOLIO_TRAKER_KEYS.NAME && (
					<div className="transaction-row common-row" key={`col_${item.key}`}>
						<div className="display-label">{getLabel(item)}</div>
						<div className="value">
							{generateColValue(item, transaction[item.key])}
						</div>
					</div>
				)
			);
		});
	};

	const handleRows = (holdingGroup: ITableHolding[], holdingsCount: number) => {
		const holdingGroupJSX = holdingGroup.map(
			(holding: ITableHolding, ix: number) => {
				const holdingGroupIndex = ix;
				const hasTransactions =
					showTransactions &&
					holding.transactions &&
					holding.transactions.length > 1;
				const holdingIndex = portfolioData?.holdings?.findIndex(
					(h) => h.id === holding.id,
				);
				const isExpanded = expandedRow === holdingIndex;
				const holdingRowsJSX = constants.mapping.map((item) => {
					const newItem = handleNewsItem(
						item,
						isExpanded,
						hasTransactions as boolean,
						holding,
						holdingIndex,
					);
					return (
						<div
							className="holding-row common-row"
							key={`holding_${holdingGroupIndex}_${item.key}`}
						>
							{item.key !== PORTFOLIO_TRAKER_KEYS.NAME && (
								<div className="display-label">{getLabel(item)}</div>
							)}
							<div className="value">
								{generateColValue(newItem, holding[item.key])}
							</div>
						</div>
					);
				});

				const transactionRowsJSX =
					hasTransactions &&
					isExpanded &&
					holding?.transactions?.map((transaction) => {
						const transactionRowJSX = transactionRow(transaction);
						return (
							<div
								className="transaction-column"
								key={`transaction_${holdingGroupIndex}_${transaction.id}`}
							>
								{transactionRowJSX}
							</div>
						);
					});

				const isLastHoldingCol = isLastHoldingColumn(
					holdingsCount,
					holdingIndex,
				);
				return (
					<div
						className={`holding-column ${isLastHoldingCol ? 'last-col' : ''}`}
						key={`group_${holdingGroupIndex}`}
					>
						{holdingRowsJSX}
						<div
							className="transaction-group"
							id={`transaction_group_${holdingIndex}`}
						>
							{transactionRowsJSX}
						</div>
					</div>
				);
			},
		);
		return holdingGroupJSX;
	};

	const bindIndividualPortfolioTracker = () => {
		if (isLoading) {
			return (
				<div className={'loader-container'}>
					<Loader />
				</div>
			);
		}
		if (device !== E_DeviceType.Desktop) {
			const noDataContent = noData();
			if (noDataContent) {
				return noDataContent;
			}

			const holdingsCount = portfolioData?.holdings.length;
			const holdingGroups = [];

			for (let i = 0; i < holdingsCount; i += holdingsPerColumn) {
				const holdingGroup = portfolioData?.holdings.slice(
					i,
					i + holdingsPerColumn,
				);
				const holdingGroupJSX = handleRows(holdingGroup, holdingsCount);
				holdingGroups.push(holdingGroupJSX);
			}
			return IPadMobileHTMLbody(
				holdingGroups,
				constants,
				portfolioData,
				isLoading,
			);
		} else {
			return (
				<React.Fragment>
					{bindData(
						constants.header,
						'header',
						'portfolio-table-header common-row',
					)}
					{tableAndCombinedData}
					<div className="market-delayed-info">{delayedMessage()}</div>
				</React.Fragment>
			);
		}
	};

	const activeTabClassName = `${activeSelectedTab.toLowerCase()}_tab`;
	const tableClassName = isIpadOrMobile
		? `${activeTabClassName} single-column`
		: activeTabClassName;

	return (
		<div className={tableClassName}>
			{isExportCsvData && (
				<div className={'loader-container'}>
					<Loader />
				</div>
			)}
			{!isExportCsvData && bindIndividualPortfolioTracker()}
		</div>
	);
};

export default PortfolioTrackerTable;
