import { DEFAULT_NEWS_INPUTS } from '../../../constants/appConstants';
import { addQBIDArgument } from '../../../utilities/utils';
import { INewsArticle } from '../../../types/interfaces';
import {
	NEWS_CATEGORY_MAPPING,
	NEWS_TYPE_MAPPING,
	News_Time_Period,
	TIME_PERIOD_MAPPING,
} from './news-map-constants';
import {
	convertUtcDttmToET,
	getNewDate,
} from '../../../utilities/date-time-formatter-tz';
import {
	DATE_FORMATS,
	formatDateTime,
} from '../../../utilities/date-time-formatter';
import {
	TEXT_NULL_VALUE,
	checkInvalidValue,
} from '../../../utilities/formatter';

type NewsByCategoryData = {
	headline: string;
	articleId: string;
	provider: string;
	timestamp: string;
	documentDate: string;
	documentDateTime: string;
};

export const buildNewsInputs = (
	categoryValue: string,
	timeFrameValue: string,
	subCategoryValue: string,
) => {
	const today = getNewDate();
	const end = formatDateTime(today, {
		format: DATE_FORMATS.DOCUMENT_DATE_FORMAT,
	});
	const start = formatDateTime(
		today.subtract(TIME_PERIOD_MAPPING[timeFrameValue], 'day'),
		{
			format: DATE_FORMATS.DOCUMENT_DATE_FORMAT,
		},
	);

	const newsInputs: any = { ...DEFAULT_NEWS_INPUTS };
	newsInputs.limit = 5000;
	newsInputs.arguments = [
		addQBIDArgument(NEWS_TYPE_MAPPING[categoryValue], [
			NEWS_CATEGORY_MAPPING[subCategoryValue],
		]),
		addQBIDArgument(
			NEWS_TYPE_MAPPING[timeFrameValue],
			[start],
			'GreaterThanEqualTo',
		),
		addQBIDArgument(
			NEWS_TYPE_MAPPING[timeFrameValue],
			[end],
			'LessThanEqualTo',
		),
	];
	return newsInputs;
};

export const prepareNewsData = (
	newsArray: INewsArticle[],
	dateFormat?: string,
) => {
	const content: any = [];
	newsArray.forEach((item) => {
		const date = convertUtcDttmToET(item.DocumentDate, {
			format: dateFormat ?? DATE_FORMATS.DEFAULT_DATE_FORMAT,
		});

		const newsObj = {} as NewsByCategoryData;
		newsObj.headline = item.Headline;
		newsObj.timestamp = getTimeStamp(item.DocumentDate);
		newsObj.articleId = item.articleId;
		newsObj.provider = item.SourceCode;
		newsObj.documentDate = date;
		newsObj.documentDateTime = item.DocumentDate;
		content.push(newsObj);
	});
	return content;
};

export const getTimeStamp = (timestamp: string) => {
	if (!checkInvalidValue(timestamp)) {
		return convertUtcDttmToET(timestamp, {
			format: DATE_FORMATS.ET_VARIANT_DATE_TIME_FORMAT,
		});
	}
	return TEXT_NULL_VALUE;
};

export const getChartXLabel = (label: any = '', timeFrame: any = '') => {
	return timeFrame == News_Time_Period.Day ? label.replace(' ', '') : label;
};

export const getChartXLabelwithET = (label: any = '', timeFrame: any = '') => {
	return timeFrame == News_Time_Period.Day
		? `${label.replace(' ', '')} ET`
		: label;
};
