import React, { useState, useEffect } from 'react';
import QuoteBar from '../../shared/quote-bar/quote-bar';
import { Container, SingleSelect, Loader } from '../../../@core-ui';
import {
	AMERICAN_INDICES,
	WORLD_INDICES,
	SWISS_MARKET,
	E_DeviceType,
	T_DeviceType,
	OP_USER_SETTINGS,
} from '../../../constants/appConstants';
import { getDeviceType, handleWindowResize } from '../../../utilities/utils';
import './indices-charts.scss';
import ChartContainer from './chart-container/chart-container';
import { getUserPreferences, setUserPreferences } from '../../../services';

const IndicesCharts: React.FC = () => {
	const INTRADAY_DROPDOWN_DATA = [
		...AMERICAN_INDICES,
		...WORLD_INDICES,
		...SWISS_MARKET,
	];

	const [selectedValue, setSelectedValue] = useState<number | null>(null);
	const [device, setDevice] = useState<T_DeviceType>(getDeviceType());
	const [selectedDelayedDateTime, setSelectedDelayedDateTime] = useState('--');
	const [selectedDelayedMinutes, setSelectedDelayedMinutes] = useState();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setIsLoading(true);
		const handleResize = () => {
			const deviceType = getDeviceType();
			setDevice(deviceType);
		};
		handleWindowResize(handleResize);
		fetchData();
		setIsLoading(false);
	}, []);

	const fetchData = async () => {
		const preferenceResponse = await getUserPreferences(
			OP_USER_SETTINGS.OP_USER_INDICES_CHART_SELECTION,
		);
		let newValues: number | null = null;
		if (preferenceResponse?.data?.data?.value) {
			const preferenceValue = parseInt(
				JSON.parse(preferenceResponse.data?.data?.value),
			);
			if (preferenceValue) {
				newValues = preferenceValue;
				setSelectedValue(newValues);
			}
		}
		if (!newValues) {
			setSelectedValue(INTRADAY_DROPDOWN_DATA[0].key);
		}
	};

	const changeDropDown = (event: any) => {
		const dataKey = event.getAttribute('data-key');
		const value = parseInt(dataKey);
		setSelectedValue(value);
		setUserPreferences(
			OP_USER_SETTINGS.OP_USER_INDICES_CHART_SELECTION,
			dataKey,
		);
	};

	return (
		<Container id="chart-indices-container">
			{isLoading ? (
				<Loader />
			) : (
				selectedValue && (
					<React.Fragment>
						<div className={'chart-indices-dropdown'}>
							<SingleSelect
								data={INTRADAY_DROPDOWN_DATA}
								label={`${device === E_DeviceType.Desktop ? 'Index' : ''}`}
								changeHandler={changeDropDown}
								id="chart-indices-dropdown"
								selectedValue={selectedValue}
								dataTestId="chartIndicesIndexDropdown"
							/>
						</div>
						<QuoteBar
							venueXid={selectedValue}
							setDelayedDateTime={setSelectedDelayedDateTime}
							setDelayedMinutes={setSelectedDelayedMinutes}
						/>
						<ChartContainer
							venueXid={selectedValue}
							delayedDateTime={selectedDelayedDateTime}
							delayedMinutes={selectedDelayedMinutes}
							isEnabledSaveSettings={false}
						/>
					</React.Fragment>
				)
			)}
		</Container>
	);
};

export default IndicesCharts;
