import React from 'react';
import { IBasicIconProps } from '../IBasicIconProps';

const PerformanceUpIcon: React.FC<IBasicIconProps> = ({
	width = 30,
	height = 21,
	color = '#358734',
}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 30 28"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				transform="translate(-1328.000000, -238.000000)"
				fill={color}
				fillRule="nonzero"
			>
				<g id="Watchlist" transform="translate(947.000000, 177.000000)">
					<g transform="translate(20.000000, 61.000000)">
						<g id="chart-up-icon" transform="translate(361.000000, 0.000000)">
							<g transform="translate(14.997975, 13.997955) scale(1, -1) translate(-14.997975, -13.997955) translate(4.995950, 7.995910)">
								<path d="M19.00405,5.00409 C18.73885,5.00409 18.48455,5.10949 18.29695,5.29699 C18.10945,5.48449 18.00405,5.73889 18.00405,6.00409 L18.00405,8.59409 L11.71405,2.29409 C11.62115,2.20036 11.51055,2.12597 11.38865,2.0752 C11.26685,2.02443 11.13615,1.99829 11.00405,1.99829 C10.87205,1.99829 10.74135,2.02443 10.61955,2.0752 C10.49765,2.12597 10.38705,2.20036 10.29405,2.29409 L7.00409,5.59409 L1.71409,0.29409 C1.52579,0.10579 1.27039,0 1.00409,0 C0.73779,0 0.48239,0.10579 0.29409,0.29409 C0.10579,0.4824 0,0.73779 0,1.00409 C0,1.27039 0.10579,1.52579 0.29409,1.71409 L6.29409,7.71409 C6.38705,7.80779 6.49766,7.88219 6.61951,7.93299 C6.74137,7.98379 6.87208,8.00989 7.00409,8.00989 C7.1361,8.00989 7.26681,7.98379 7.38867,7.93299 C7.51053,7.88219 7.62113,7.80779 7.71409,7.71409 L11.00405,4.41409 L16.59405,10.00409 L14.00405,10.00409 C13.73885,10.00409 13.48455,10.10949 13.29695,10.29699 C13.10945,10.48449 13.00405,10.73889 13.00405,11.00409 C13.00405,11.26929 13.10945,11.52369 13.29695,11.71119 C13.48455,11.89869 13.73885,12.00409 14.00405,12.00409 L19.00405,12.00409 C19.13475,12.00249 19.26385,11.97529 19.38405,11.92409 C19.62845,11.82259 19.82265,11.62849 19.92405,11.38409 C19.97535,11.26389 20.00255,11.13479 20.00405,11.00409 L20.00405,6.00409 C20.00405,5.73889 19.89875,5.48449 19.71115,5.29699 C19.52365,5.10949 19.26935,5.00409 19.00405,5.00409 Z"></path>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default PerformanceUpIcon;
