/* eslint-disable indent */
import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { Collapsible, Container, Heading, Loader, Tabs } from '../../@core-ui';
import { IOptionQuoteItem, IXrefResponseItem } from '../../types/interfaces';
import {
	ISnapshotHeaderDetails,
	SnapshotTabNames,
	SNAPSHOT_TAB_SELECTED,
	MMFTypeConstants,
} from './snapshot-constants';
import { E_SecurityTypes } from '../shared/search/symbol-search-modal/symbol-search-modal-constants';
import SnapshotHeader from './snapshot-header/snapshot-header';
import OverviewPage from './snapshot-tabs/overview-tab/overview-page';
import ChartsPage from './snapshot-tabs/charts-tab/charts-page';
import NewsPage from './snapshot-tabs/news-tab/news-page';
import { OptionsContainer } from '../options';
import Peers from '../peers/peers';
import CompanyNews from '../news/company-news/company-news';
import NewsByCategory from '../news/news-tab/news-by-category/news-by-category';
import { searchNewsByCategory } from '../quotes-news/news/news-map-constants';
import { BackLink } from '../../shared';
import { OPTIONS_ACTIONS } from '../options/options-constants';
import {
	getDeviceType,
	getSessionStorageItem,
	setSessionStorage,
} from '../../utilities/utils';
import { E_DeviceType } from '../../constants/appConstants';
import './snapshot-container.scss';
import OptionQuoteContainer, {
	OptionQuoteContainerProps,
} from '../options/option-quote-container/option-quote-container';
import { getQuotes, getSingleOptionWithGreeks } from '../../services';
import { getOptionProfileData } from '../options/options-utils';

interface SnapshotProps {
	xrefResponse: IXrefResponseItem;
	watchlistHandler: (show: boolean) => void;
	tab?: string;
	setTab?: (tab: string) => void;
	isHidePeers?: boolean;
	isRefreshClicked?: boolean;
	isQuickQuote?: boolean;
	setIsRefreshClicked?: (isRefreshClicked: boolean) => void;
	setBackButtonVisible?: React.Dispatch<React.SetStateAction<boolean>>;
	enableAddToWatchlist?: boolean;
}

type MasterStateType = {
	action: string;
	data?: any;
};

const OptionsContext = createContext<any>(null);
export const useOptionsContext = () => useContext(OptionsContext);

const SnapshotContainer: React.FC<SnapshotProps> = ({
	xrefResponse,
	watchlistHandler,
	tab,
	setTab,
	isHidePeers = false,
	isRefreshClicked = false,
	setIsRefreshClicked,
	isQuickQuote = false,
	setBackButtonVisible,
	enableAddToWatchlist = true,
}) => {
	const [activeSelectedTab, setActiveSelectedTab] = useState(
		tab ?? SnapshotTabNames.Overview,
	);
	const [optionsQuoteView, setOptionsQuoteView] = useState<boolean>(false);
	const [repno, setRepno] = useState('');
	const [fundNo, setFundNo] = useState(0);
	const classificationID = xrefResponse?.classification?.id;
	const isOptionSymbol =
		classificationID === E_SecurityTypes.Options &&
		xrefResponse?.xids?.optionUnderlyingVenue;
	const isMf =
		classificationID === E_SecurityTypes.MFs ||
		classificationID === E_SecurityTypes.Insurance;
	const isETF =
		classificationID === E_SecurityTypes.ETMF ||
		classificationID === E_SecurityTypes.ETFs;

	const isEquity = !isMf && !isETF;
	const venueXid = xrefResponse?.xids?.venue;
	const entityXid = xrefResponse?.xids?.entity;
	const symbol = xrefResponse?.symbol;
	const isMMF = xrefResponse?.supplementalData?.some(
		(x) =>
			x.name === MMFTypeConstants.name && x.value === MMFTypeConstants.value,
	);
	const [masterStateMemo, setMasterStateMemo] = useState<MasterStateType>({
		action: OPTIONS_ACTIONS.OPTIONS_HOME_PAGE,
	});
	const headerDetails: ISnapshotHeaderDetails = {
		companyName: xrefResponse?.name,
		exchange: xrefResponse?.exchange?.name,
		symbol: xrefResponse?.symbol,
	};
	const isMobile = getDeviceType() === E_DeviceType.Mobile;
	const [optionData, setOptionData] = useState<OptionQuoteContainerProps>();

	const masterstateValue = useMemo(
		() => ({
			masterStateMemo,
			setMasterStateMemo,
		}),
		[masterStateMemo, setMasterStateMemo],
	);

	useEffect(() => {
		if (isOptionSymbol) {
			getOptionDetails(xrefResponse);
			return;
		}
		classificationID !== E_SecurityTypes.ETFs && getAdditionalNo(xrefResponse);
		setMasterStateMemo({
			action: OPTIONS_ACTIONS.OPTIONS_HOME_PAGE,
		});
	}, [xrefResponse]);

	useEffect(() => {
		setActiveSelectedTab(tab ?? SnapshotTabNames.Overview);
		!isRefreshClicked &&
			setSessionStorage(
				SNAPSHOT_TAB_SELECTED,
				tab ?? SnapshotTabNames.Overview,
			);
	}, [xrefResponse?.classification?.id]);

	useEffect(() => {
		setOptionsQuoteView(
			masterStateMemo.action === OPTIONS_ACTIONS.VIEW_OPTION_PROFILE,
		);
		setBackButtonVisible?.(
			masterStateMemo?.action !== OPTIONS_ACTIONS.VIEW_OPTION_PROFILE,
		);
	}, [masterStateMemo?.action]);

	useEffect(() => {
		if (isRefreshClicked) {
			setActiveSelectedTab(
				getSessionStorageItem(SNAPSHOT_TAB_SELECTED) ??
					SnapshotTabNames.Overview,
			);
			setIsRefreshClicked?.(false);
		}
	}, []);

	const tabChangeHandler = (k: any) => {
		setTab?.(k);
		setActiveSelectedTab(k);
		setSessionStorage(SNAPSHOT_TAB_SELECTED, k);
	};

	const getAdditionalNo = (xrefResponse: any) => {
		setRepno('');
		setFundNo(0);
		const supplementData = xrefResponse?.supplementalData;
		supplementData?.forEach((item: { name: string; value: any }) => {
			if (item.name === 'multexrepno') {
				setRepno(item.value);
			} else if (item.name === 'ldfsnumber') {
				setFundNo(item.value);
			}
		});
	};

	const backLinkHandler = () => {
		setOptionsQuoteView(false);
		setMasterStateMemo({
			action: OPTIONS_ACTIONS.OPTIONS_HOME_PAGE,
		});
	};

	const renderUpperBody = () => {
		return (
			<div className={'tab-headers'} data-attribute={optionsQuoteView}>
				<Tabs
					id={'snapshot-tabs'}
					ariaLabel={activeSelectedTab}
					activeTab={activeSelectedTab}
					onClick={(k: any) => tabChangeHandler(k)}
				>
					<div
						key="Overview"
						data-label="Overview"
						style={{ paddingLeft: '0' }}
					>
						<OverviewPage
							venueXid={venueXid}
							entityXid={entityXid}
							classificationID={classificationID}
							isMF={isMf}
							isEquity={isEquity}
							fundNo={fundNo}
							isQuickQuote={isQuickQuote}
							isMMF={isMMF}
						/>
					</div>
					<div key="Charts" data-label="Charts">
						<ChartsPage
							venueXid={venueXid}
							symbol={symbol}
							tabChangeHandler={tabChangeHandler}
							activeSelectedTab={activeSelectedTab}
						/>
					</div>
					{(!classificationID || isEquity) && (
						<div key="News" data-label="News">
							<NewsPage venueXid={venueXid} symbol={symbol} />
						</div>
					)}
					{!isMf && (
						<div key="Options" data-label="Options">
							<OptionsContainer venueXid={venueXid} symbol={symbol} />
						</div>
					)}
				</Tabs>
			</div>
		);
	};

	const getNewsByCategory = () => {
		return (
			<div className="third-row">
				<Container
					id="news-by-category-container"
					applyPadding={!isMobile}
					applyBorder={!isMobile}
					applyWhiteBackground
				>
					{!isMobile && (
						<div className="container-margin">
							<Heading content={searchNewsByCategory} type={'h2'} />
						</div>
					)}
					<NewsByCategory />
				</Container>
			</div>
		);
	};

	const getLowerSection = () => {
		if (isHidePeers) {
			return (
				<div className="peer-container">
					{isEquity && (
						<Peers
							repno={repno}
							symbol={xrefResponse?.symbol}
							companyName={xrefResponse.name}
						/>
					)}
				</div>
			);
		} else if (isEquity) {
			return (
				<div className="third-row">
					<div className="left-side">
						<Peers
							repno={repno}
							symbol={xrefResponse?.symbol}
							companyName={xrefResponse.name}
						/>
					</div>
					<div className="right-side">
						<CompanyNews symbol={symbol} />
					</div>
				</div>
			);
		} else {
			return isMobile ? (
				<Collapsible title={searchNewsByCategory}>
					{getNewsByCategory()}
				</Collapsible>
			) : (
				getNewsByCategory()
			);
		}
	};

	const renderLowerBody = () => {
		switch (activeSelectedTab) {
			case SnapshotTabNames.Overview:
			case SnapshotTabNames.Charts:
				return getLowerSection();
			case SnapshotTabNames.News:
				return <CompanyNews symbol={symbol} />;
			default:
				return null;
		}
	};

	const isOptionSymbolCall = (putCall = ''): boolean =>
		putCall === 'C' || putCall.startsWith('C');

	const getOptionDetails = async (xrefItem: IXrefResponseItem) => {
		const response = await getQuotes([
			xrefItem.xids.venue,
			xrefItem.xids.optionUnderlyingVenue ?? 0,
		]);
		const optionQuoteDate = response?.data?.data?.quotes?.[0]?.data;
		const primaryQuoteData = response?.data?.data?.quotes?.[1]?.data;
		const optionQuoteItem: IOptionQuoteItem = {
			xid: xrefItem?.xids?.venue,
			lotSize: optionQuoteDate?.contractSize,
			quote: optionQuoteDate,
			strikePrice: optionQuoteDate.strikePrice ?? 0,
		};
		const isCall = isOptionSymbolCall(optionQuoteDate?.putCall);
		const data: OptionQuoteContainerProps = {
			data: {
				item: optionQuoteItem,
				expiryDate: optionQuoteDate?.expirationDate ?? '',
				isCall: isCall,
				symbol: xrefItem?.symbol,
				profileData: getOptionProfileData(
					optionQuoteItem,
					optionQuoteDate?.expirationDate ?? '',
					primaryQuoteData,
				),
				underlyingQuote: primaryQuoteData,
			},
		};
		if (
			optionQuoteDate?.lastTrade?.last &&
			optionQuoteDate?.strikePrice &&
			optionQuoteDate.expirationDate &&
			primaryQuoteData?.lastTrade?.date
		) {
			const inputs = {
				isPut: !isCall,
				optionPrice: optionQuoteDate?.lastTrade?.last,
				strikePrice: optionQuoteDate?.strikePrice,
				underlyingOptionPrice: primaryQuoteData?.lastTrade?.last,
				tradeDate: primaryQuoteData?.lastTrade?.date,
				expirationDate: optionQuoteDate.expirationDate,
			};
			const singleOptionResponse = await getSingleOptionWithGreeks(inputs);
			const intrinsicvalue = singleOptionResponse?.data?.data?.intrinsicValue;
			data.data.profileData.IntrinsicValue = intrinsicvalue;
		}
		setOptionData(data);
	};

	const renderOptionSymbolPage = () => {
		if (!optionData) return <Loader />;
		return (
			<Container
				id="snapshot-container"
				applyPadding
				applyBorder
				applyWhiteBackground
			>
				<OptionQuoteContainer data={optionData?.data} />
			</Container>
		);
	};

	const renderBackToOptionsLink = () => {
		if (masterStateMemo.action === OPTIONS_ACTIONS.VIEW_OPTION_PROFILE)
			return <BackLink onClick={backLinkHandler} text="Back to Options" />;
	};

	return (
		<OptionsContext.Provider value={masterstateValue}>
			{classificationID && !isOptionSymbol ? (
				<div
					className="snapshot-main-container"
					data-testid="snapshot-main-container"
				>
					{renderBackToOptionsLink()}
					<Container
						id="snapshot-container"
						applyPadding
						applyBorder
						applyWhiteBackground
					>
						<div
							className="snapshot-upper-section"
							data-attribute={optionsQuoteView}
						>
							<SnapshotHeader
								venueXid={venueXid}
								headerDetails={headerDetails}
								watchlistHandler={watchlistHandler}
								isMF={isMf}
								enableAddToWatchlist={enableAddToWatchlist}
							/>
							{renderUpperBody()}
						</div>
					</Container>
					<div className="lower-container">{renderLowerBody()}</div>
				</div>
			) : (
				renderOptionSymbolPage()
			)}
		</OptionsContext.Provider>
	);
};

export default SnapshotContainer;
