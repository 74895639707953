import React, { useState, useEffect } from 'react';
import {
	INTRADAY_HEADER,
	SELECTED_VALUES,
	NUMBER_CHARTS_DESKTOP,
	NUMBER_CHARTS_MOBILE,
	DROP_DOWN_TEST_ID,
	INTRADAY_CHART_SELECTIONS,
} from './intraday-charts-constants';
import './intraday-charts-container.scss';
import { Container, Heading, Loader, Label } from '../../../@core-ui';
import IntradayCharts from './intraday-charts';
import { getDeviceType, delayedMessage } from '../../../utilities/utils';
import {
	E_DeviceType,
	OP_USER_SETTINGS,
} from '../../../constants/appConstants';
import { getUserPreferences, setUserPreferences } from '../../../services';
const IntradayChartsContainer: React.FC = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [chartsDefault, setChartsDefault] = useState<{
		[key: string]: number;
	} | null>(null);
	const [apiStateData, setApiStateData] = useState<{
		[key: string]: number;
	} | null>(null);
	const isMobileView = getDeviceType() === E_DeviceType.Mobile;

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		setIsLoading(true);
		const preferenceResponse = await getUserPreferences(
			OP_USER_SETTINGS.OP_USER_INTRADAY_CHARTS_SELECTION,
		);
		const newValues: { [key: string]: number } = {};
		if (preferenceResponse?.data?.data?.value) {
			const preferenceValue = JSON.parse(preferenceResponse.data?.data?.value);

			if (preferenceValue) {
				const valuesArr = preferenceValue.split('|');
				if (valuesArr) {
					for (let i = 0; i < NUMBER_CHARTS_DESKTOP; i++) {
						newValues[`intraday_drop_down_${i}`] =
							parseInt(valuesArr[i]) || SELECTED_VALUES[i].DROPDOWN_DEFAULT;
					}
					setChartsDefault({ ...newValues });
					setApiStateData({ ...newValues });
				}
			}
		}
		if (Object.keys(newValues).length === 0) {
			setChartsDefault(INTRADAY_CHART_SELECTIONS);
			setApiStateData(INTRADAY_CHART_SELECTIONS);
		}
		setIsLoading(false);
	};

	const numberOfCharts =
		getDeviceType() === E_DeviceType.Mobile
			? NUMBER_CHARTS_MOBILE
			: NUMBER_CHARTS_DESKTOP;

	const changeHandler = (event: any, dataTestId: string) => {
		const updateChartDefaults = {
			...apiStateData,
			[dataTestId]: parseInt(event?.getAttribute('data-key')),
		};
		setApiStateData(updateChartDefaults);
		setUserPreferences(
			OP_USER_SETTINGS.OP_USER_INTRADAY_CHARTS_SELECTION,
			Object.values(updateChartDefaults).join('|'),
		);
	};

	const bindCharts = () => {
		const content: React.ReactNode[] = [];
		if (chartsDefault) {
			for (let i = 0; i < numberOfCharts; i++) {
				const ddValue = chartsDefault[`intraday_drop_down_${i}`];
				content.push(
					<div className="chart-main" key={ddValue} data-testid="chart-main">
						<IntradayCharts
							selectedDropDownValue={ddValue}
							enablePreviousClose={SELECTED_VALUES[i].PREVIOUS_CLOSE}
							dataTestId={`${DROP_DOWN_TEST_ID}_${i}`}
							changeHandler={changeHandler}
						/>
					</div>,
				);
			}
		}
		return content;
	};
	return (
		<Container id="intraday-charts-comp-container">
			<header className={'header'}>
				<Heading testId={'intraday-charts'} content={INTRADAY_HEADER} />
				{!isMobileView && <Label text={delayedMessage()} isGrey />}
			</header>
			{isLoading ? (
				<div className="loader-container" data-testid="loader">
					<Loader />
				</div>
			) : (
				<main className={'charts-container'}>{bindCharts()}</main>
			)}
			{isMobileView && !isLoading && <Label text={delayedMessage()} isGrey />}
		</Container>
	);
};

export default IntradayChartsContainer;
