import React from 'react';
import './news-chart.scss';
const HighChartComponent = React.lazy(
	() => import('../../../../shared/high-chart/high-chart-component'),
);
import { formatNumber } from '../../../../utilities/formatter';
import { getDeviceType } from '../../../../utilities/utils';
import { E_DeviceType } from '../../../../constants/appConstants';
import {
	DATE_FORMATS,
	formatDateTime,
} from '../../../../utilities/date-time-formatter';
import { getChartXLabel } from '../news-utils';
import {
	News_Time_Period,
	TIME_PERIOD_WEEK_TICK_MAPPING,
} from '../news-map-constants';
import { Loader } from '../../../../@core-ui';

interface NewsChartProps {
	newsBarData: number[];
	priceLineData: (number | null)[];
	labelData: string[];
	clickHandler?: (event: Highcharts.PointClickEventObject) => void;
	chartInputs?: any;
}

const shouldHideLabel = (index: number) => {
	return index % 5 === 0;
};

const getStoryCountFormatter = (timeFrame: string) => {
	return function (this: Highcharts.TooltipFormatterContextObject) {
		const storyCount = this.y ?? 0;
		return `<div class="custom-tooltip" onclick="clickHandler">
                    <div class="first-line">
                        ${this.y} ${storyCount < 2 ? 'Story ' : 'Stories '}for ${getChartXLabel(
	this.x,
	timeFrame, // Use the passed timeFrame here
)}
                    </div>
                    <div class="second-line">Click to View Above</div>
                </div>`;
	};
};

const getXAxisFormatter = (
	timeFrame: string,
	isMobile: boolean,
	labelData: string[],
) => {
	return function (this: Highcharts.AxisLabelsFormatterContextObject): string {
		const index = this.pos;
		const label = this.value?.toString() || '';
		// Custom xlabel position handling
		if (timeFrame === News_Time_Period.Day) {
			// For Day
			return label?.replace(/\s/g, '');
		} else if (timeFrame === News_Time_Period.Month && !isMobile) {
			// For Month
			if (labelData.length > 7) {
				return shouldHideLabel(index) ? label : '';
			}
		}

		if (isMobile) {
			const tempDate = formatDateTime(label, {
				format: DATE_FORMATS.ONLY_DAY,
			});

			const formattedDate = formatDateTime(label, {
				format: 'MMM D',
			});

			return tempDate === '1' ? formattedDate : tempDate;
		}

		return label;
	};
};

const getYAxisFormatter = () => {
	return function (this: Highcharts.AxisLabelsFormatterContextObject) {
		if (typeof this.value !== 'undefined') {
			return formatNumber(this.value.toString(), { precision: 0 }).value;
		} else {
			return ''; // Handle the case when value is undefined
		}
	};
};

const getValueFormatter = () => {
	return function (this: Highcharts.AxisLabelsFormatterContextObject) {
		if (typeof this.value !== 'undefined') {
			return formatNumber(this.value.toString(), {
				commaSeparated: true,
			}).value;
		} else {
			return ''; // Handle the case when value is undefined
		}
	};
};

const NewsChart: React.FC<NewsChartProps> = ({
	newsBarData,
	priceLineData,
	labelData,
	clickHandler,
	chartInputs,
}) => {
	const isMobile = getDeviceType() === E_DeviceType.Mobile;

	const chartOptions: Highcharts.Options = {
		chart: {
			type: 'column',
			height: '240px',
		},
		credits: {
			enabled: false,
		},
		title: {
			text: '',
		},
		tooltip: {
			useHTML: true,
			backgroundColor: '#F6FBFF',
			style: {
				boxShadow: '0 1px 5px 0 rgba(56, 81, 100, 0.5)',
			},
			formatter: getStoryCountFormatter(chartInputs.timeFrame),
		},
		legend: {
			enabled: true,
		},
		plotOptions: {
			line: {
				zIndex: 0,
				enableMouseTracking: false,
				zones: [
					{
						className: 'zone-1',
					},
				],
			},
			series: {
				animation: false,
				connectNulls: true,
			},
			column: {
				zIndex: 1,
				cursor: 'pointer',
				pointWidth: 7.02,
				borderRadius: 2,
				borderWidth: 0,
				point: {
					events: {
						click: clickHandler,
					},
				},
				zones: [
					{
						value: 6,
						color: '#629AC4',
					},
					{
						value: 16,
						color: '#37729E',
					},
					{
						value: 26,
						color: '#1D4F75',
					},
					{
						color: '#002B4C',
					},
				],
			},
		},
		accessibility: {
			enabled: true,
			keyboardNavigation: {
				enabled: true,
			},
		},
		xAxis: {
			categories: labelData,
			tickmarkPlacement: 'on',
			tickColor: '#E7E7E7',
			tickWidth: 1,
			tickLength: 5,
			...([News_Time_Period.Day, News_Time_Period.Week].includes(
				chartInputs.timeFrame,
			) && {
				tickPositioner: function () {
					const ticks = [];
					let startingTickerPos = 0;
					let maxTickerIntervals = 1;

					// Each news bar should have ticker if x-axis labels are less then 10
					if (labelData?.length > 10) {
						if (chartInputs?.timeFrame === News_Time_Period.Week) {
							startingTickerPos = TIME_PERIOD_WEEK_TICK_MAPPING.NEWSBAR_POS;
							maxTickerIntervals =
								TIME_PERIOD_WEEK_TICK_MAPPING.MAX_TIMEINTERVAL_FOR_DAY;
						} else {
							maxTickerIntervals = setNewsBarInterval(labelData?.length);
						}
					}
					for (
						let i = startingTickerPos;
						i <= labelData?.length;
						i += maxTickerIntervals
					) {
						ticks.push(i);
					}
					return ticks;
				},
			}),
			labels: {
				formatter: getXAxisFormatter(
					chartInputs.timeFrame,
					isMobile,
					labelData,
				),
				style: {
					textAnchor: 'middle',
					textOverflow: 'none',
					whiteSpace: 'nowrap',
					fontWeight: 'bold',
				},
			},
		},
		yAxis: [
			{
				title: {
					text: '# news stories',
				},
				labels: {
					formatter: getYAxisFormatter(),
				},
				accessibility: {
					description: 'Number of news stories for the time period',
				},
			},
			{
				title: {
					text: `${chartInputs?.exchange || ''}`,
					rotation: -90,
					offset: priceLineData?.length > 0 ? 90 : 20,
				},
				labels: {
					formatter: getValueFormatter(),
				},
				opposite: true,
			},
		],
		series: [
			{
				type: 'column',
				name: 'column',
				showInLegend: false,
				data: newsBarData,
				yAxis: 0,
			},
			{
				type: 'line',
				name: 'line',
				showInLegend: false,
				marker: {
					enabled: false,
				},
				data: priceLineData,
				yAxis: 1,
			},
		],
		loading: {
			style: {
				position: 'absolute',
				backgroundColor: '#ffffff',
				opacity: 0.5,
				textAlign: 'center',
			},
		},
	};

	const setNewsBarInterval = (labelCount: number) => {
		let interval = 0;
		if (labelCount) {
			// For Desktop, if price lables are more then 131 then bar interval would be 2, else 1
			// For non Desktop (Tab), if price lables are more then 120 then bar interval would be 3, else 1
			interval = Math.floor(labelCount / 120);
		}
		return (interval + 1) * 12;
	};
	return (
		<div className="news-chart">
			<React.Suspense fallback={<Loader />}>
				<HighChartComponent options={chartOptions} />
			</React.Suspense>
		</div>
	);
};

export default NewsChart;
