/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
	CHARTCOLORS,
	MAX_LIMIT_MESSAGE,
	ERROR_DIALOG_TITLE,
	DEFAULT_INDICATOR,
} from '../../indices-charts-constants';
import './charts.scss';
import { Loader, ErrorMessageDialog } from '../../../../../@core-ui';
import {
	getAccessToken,
	handleEnterKeyPress,
} from '../../../../../utilities/utils';
import ChartPanel from '../chart-panel/chart-panel';
import { savechartSettings } from '../../../../../services/chart-data-request-methods';
import {
	changeChartIndicator,
	changeLowerIndicator,
	removeUpperIndicator,
	removeEvents,
	getSavedChartData,
	addPriceIndicator,
	getChartShadowRoot,
	buildNewsInputs,
} from '../charts-utils';
import { ComparisonType } from '../../../../../types/interfaces/IChartSettings';
import ChartEvents from '../chart-events/chart-events';
import { getNews } from '../../../../../services';
import { prepareNewsData } from '../../../../quotes-news/news/news-utils';
import {
	DATE_FORMATS,
	formatDateTime,
} from '../../../../../utilities/date-time-formatter';

const Charts = (props) => {
	const [chart, setChart] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [reset, setReset] = useState(false);
	const [upperIndicator, setUpperIndicator] = useState([]);
	const [selectedLowerIndicator, setSelectedLowerIndicator] = useState(
		props.lowerIndicator,
	);
	const [savedChartList, setSavedChartList] = useState([]);
	const [symbolList, setSymbolList] = useState([]);
	const [isErrorDialog, setIsErrorDialog] = useState(false);
	const [newsData, setNewsData] = useState();
	const xid = props.venueXid;
	const chartUpperPanelHeight = 220;
	const numberOfDays = props.timeFrame;

	// display dividend yield for snapshot chart only
	const displayDividendYield = props.isEnabledSaveSettings || false;
	const lowerIndicatorList = displayDividendYield
		? {
				volume: 'Volume',
				macd: 'MACD',
				rsi: 'RSI',
				dividendyield: 'Dividend Yield',
			}
		: {
				volume: 'Volume',
				macd: 'MACD',
				rsi: 'RSI',
			};

	useEffect(() => {
		refreshChartData(true);
	}, []); // props.venueXid

	useEffect(() => {
		refreshChartData(true);
	}, [props.venueXid]);

	useEffect(() => {
		refreshChartData(true);
	}, [props.activeSelectedTab]);

	useEffect(() => {
		refreshChartData(false);
	}, [props.loadExistingChart]);

	useEffect(() => {
		getSavedChart();
	}, [JSON.stringify(savedChartList)]);

	useEffect(() => {
		refreshChartData();
	}, [props.timeFrame]);

	useEffect(() => {
		if (chart && props.timeFrame) {
			setIsLoading(true);
			const dataPeriod = parseInt(props.timeFrame) > 5 ? 'Day' : 'Hour';
			chart.setDays(props.timeFrame);
			chart.setDataPeriod(dataPeriod);
			chart.setDataInterval(1);
			chart.loadData();
			setTimeout(() => {
				handleChartEventSelection(props.eventsType);
				setIsLoading(false);
			}, 2000);
		}
	}, [props.timeFrame, chart]);

	useEffect(() => {
		if (chart && props.chartType) {
			handleChartTypeSelection(props.chartType);
		}
	}, [props.chartType, chart]);

	useEffect(() => {
		if (chart && props.eventsType) {
			handleLoadStart();
			handleChartEventSelection(props.eventsType);
		}
	}, [JSON.stringify(props.eventsType), chart]);

	useEffect(() => {
		if (chart && props.saveChart != '') {
			saveNewChart(props.saveChart);
		}
	}, [props.saveChart]);

	useEffect(() => {
		if (props.isChartReset) {
			resetChart();
			chartUpperIndicators();
		}
	}, [props.isChartReset]);

	const refreshChartData = (isInit) => {
		if (props.venueXid) {
			if (getChartShadowRoot('#chart-dom-element')) {
				InitChart(isInit);
				getChartNews();
			}
			getSavedChart();
		}
	};

	const getSymbolDataList = (symbolListData) => {
		if (props.comparisonList?.length == 0) {
			setSymbolList([]);
		} else {
			props.comparisonList?.forEach((item, idx) => {
				if (item.venueXid) {
					symbolListData.push({
						uid: item.name,
						name: item.name,
						venueXid: item.venueXid,
						colorCode: CHARTCOLORS[5 + idx],
					});
				} else {
					props.comparisonList.length <= props.comparisonLimit &&
						changeChartIndicator(chart, item.name, 'upper', item);
				}
			});
			setSymbolList(symbolListData);
		}
	};

	useEffect(() => {
		if (chart) {
			if (props.comparisonLimit > 0) {
				const symbolListData = [];
				const lastAdded =
					props.comparisonList?.length > 0 &&
					props.comparisonList[props.comparisonList.length - 1];
				getSymbolDataList(symbolListData);
				if (props.comparisonList?.length <= props.comparisonLimit) {
					if (
						props.loadExistingChart ||
						lastAdded?.type == ComparisonType.Symbol
					) {
						addPriceIndicator(chart, symbolListData);
						chart?.loadData();
					}
				} else {
					props.removeComparison(lastAdded?.name);
					setIsErrorDialog(true);
				}
			} else {
				props.comparisonList?.find(
					(x) => x.name == props.selectedOverlayData?.name,
				) &&
					changeChartIndicator(
						chart,
						props.selectedOverlayData?.name,
						'upper',
						props.selectedOverlayData,
					);
			}
		}
	}, [JSON.stringify(props.comparisonList), chart]);

	const closeErrorDialog = () => {
		setIsErrorDialog(false);
	};

	const addPanels = (events, lowerIndicator) => {
		const panels = [
			{
				indicators: [
					{
						id: 'price',
						markerType: props?.chartType,
						style: {
							color: CHARTCOLORS[0],
							width: 1,
						},
					},
				],
				events: events || props.eventsType,
			},
		];
		panels.push({
			indicators: [
				{
					id: lowerIndicator || props.lowerIndicator,
					markerType: 'posneg',
					style: {
						color: CHARTCOLORS[0],
						negative: {
							color: CHARTCOLORS[9],
						},
						neutral: {
							color: CHARTCOLORS[11],
						},
						positive: {
							color: CHARTCOLORS[10],
						},
						width: 2,
						seriesBarDistance: 12,
					},
				},
			],
		});
		return panels;
	};

	const chartConfig = (events, lowerIndicator) => {
		return {
			params: {
				days: numberOfDays,
				dataPeriod: parseInt(numberOfDays) > 5 ? 'Day' : 'Hour',
				symbol: xid,
				zoomEnabled: false,
				panelHeightUpper: chartUpperPanelHeight,
				panelHeightLower: 150,
				apiPath: window?.MD?.MOD_CHART_URL,
				showFlags: true,
				style: {
					panel: {
						padding: { right: 60 },
						border: {
							default: { color: null },
							bottom: { color: 'rgb(0, 0, 0, .3)' },
						},
						grid: {
							color: 'transparent',
							horizontal: {
								color: 'rgb(0, 0, 0, .15)',
								lineDash: '',
							},
							vertical: {
								alt: { color: 'transparent' },
							},
						},
						margin: { bottom: 50 },
						xAxis: {
							label: {
								textAlign: 'center',
								fontSize: '12px',
								color: '#000000',
								padding: {
									top: 10,
								},
							},
						},
						yAxis: {
							label: {
								fontSize: '12px',
								color: '#000000',
								padding: {
									left: 10,
								},
							},
						},
					},
				},
			},
			panels: addPanels(events, lowerIndicator),
		};
	};

	const removeChartOverlays = (id, type) => {
		if (type == ComparisonType.Symbol) {
			chart?.panels[0]?.indicators
				?.filter((item) => item.params?.symbol == id)
				.forEach((param) => {
					param?.params?.uid && removeUpperIndicator(chart, param.params.uid);
				});
			const symbolData = symbolList.find((x) => x.venueXid == id);
			props.removeComparison(symbolData.name);
			chart?.loadData();
		} else {
			removeUpperIndicator(chart, id);
		}
	};

	const chartUpperIndicators = () => {
		return (
			<ChartPanel
				panelData={upperIndicator}
				removeChartOverlays={removeChartOverlays}
				symbolList={symbolList}
			/>
		);
	};

	const chartEventIcons = () => {
		if (chart) {
			props.eventsType?.length == 0 && handleLoadStart();
			return (
				<ChartEvents
					venueXid={props.venueXid}
					chart={chart}
					reset={reset}
					days={props.timeFrame}
					tabChangeHandler={props.tabChangeHandler}
				/>
			);
		}
	};
	const toggle = (isIndicatorDropdownOpened) => {
		const listElement = getChartShadowRoot('.indicesVolumDropdownList');
		if (listElement) {
			if (!isIndicatorDropdownOpened) {
				listElement.style.display = 'none';
			} else {
				listElement.style.display =
					listElement.style.display && listElement.style.display !== 'none'
						? 'none'
						: 'block';
			}
		}
	};

	const bindEvents = () => {
		document.addEventListener('click', () => toggle(false));
		const chartElement = getChartShadowRoot('.modcharts-rootmouse');
		chartElement?.addEventListener('click', () => toggle(false));
	};

	const getDropDownOptions = (ddlId, id) => {
		return `<div class="panelLegend" id='indicesVolumDropdown'>
					<span class="selected-indicator" tabIndex='0'>${lowerIndicatorList[id]}</span>
					<ul id="${ddlId}" class="indicesVolumDropdownList">
						<li tabIndex='0' >Volume</li>
						<li tabIndex='0'>MACD</li>
						<li tabIndex='0'>RSI</li>
						${displayDividendYield ? `<li tabIndex='0'>Dividend Yield</li>` : ''}
					</ul>
				</div>`;
	};

	const getLowerIndicatorID = (indicator) => {
		if (indicator === 'relative strength index') {
			return 'rsi';
		} else if (indicator === 'dividend yield') {
			return 'dividendyield';
		} else {
			return indicator;
		}
	};
	const handlePanelLegendUpdate = (
		legendElement,
		indicators,
		panel,
		chartObject,
	) => {
		const indicator = indicators[0];
		if (indicator && indicator.params.id !== 'price') {
			const id = getLowerIndicatorID(indicator.params.name.toLowerCase());
			const ddlId = `removeBtn_${id}`;
			if (legendElement && panel) {
				legendElement.innerHTML = getDropDownOptions(ddlId, id);
				const element = getChartShadowRoot('.selected-indicator');
				element?.addEventListener('click', (e) => {
					toggle(true);
					e?.stopPropagation();
				});
				element?.addEventListener('keydown', (e) => {
					handleEnterKeyPress(e, () => toggle(true));
					e?.stopPropagation();
				});
				bindEvents();
			}
			const indicatorRemoveBtn = getChartShadowRoot('#' + ddlId);
			setRemoveBtnEvent(indicatorRemoveBtn, chartObject);
		}
	};

	const setRemoveBtnEvent = (indicatorRemoveBtn, chartObject) => {
		if (indicatorRemoveBtn) {
			indicatorRemoveBtn.addEventListener('click', (e) => {
				const lowerIndicator = changeLowerIndicator(chartObject, e);
				setSelectedLowerIndicator(lowerIndicator);
			});
			indicatorRemoveBtn.addEventListener('keydown', (e) => {
				const lastIndicator = displayDividendYield
					? lowerIndicatorList.dividendyield.toLowerCase()
					: lowerIndicatorList.rsi.toLowerCase();
				if (e?.keyCode === 13) {
					const lowerIndicator = changeLowerIndicator(chartObject, e);
					setSelectedLowerIndicator(lowerIndicator);
				} else if (
					e?.keyCode === 9 &&
					e?.target?.textContent?.toLowerCase() === lastIndicator.toLowerCase()
				) {
					toggle();
				}
			});
		}
	};

	const handleIndicatorUpperAdd = (indicator) => {
		if (upperIndicator.length <= props.comparisonLimit) {
			setUpperIndicator((prevState) => {
				const updatedState = prevState.filter(
					(x) => x?.params?.id !== indicator?.params?.id,
				);
				return [...updatedState, indicator];
			});
		}
	};

	const handleIndicatorUpperRemove = (indicator) => {
		setUpperIndicator((prevState) => {
			return prevState.filter((i) => i.params.uid !== indicator.params.uid);
		});
		props.removeComparison(indicator?.params?.id);
	};

	const handleEventRemove = (eventType) => {
		removeEvents(eventType);
	};

	const handleLoadStart = () => {
		removeEvents('dividends');
		removeEvents('custom');
		removeEvents('splits');
	};

	const bindChartEvents = (chartObject) => {
		chartObject.eventEmitter.on(
			'PANEL_LEGEND_UPDATE',
			({ legendElement, indicators, panel }) => {
				handlePanelLegendUpdate(legendElement, indicators, panel, chartObject);
			},
		);
		chartObject.eventEmitter.on('INDICATOR_UPPER_ADD', ({ indicator }) => {
			handleIndicatorUpperAdd(indicator);
		});
		chartObject.eventEmitter.on('INDICATOR_UPPER_REMOVE', ({ indicator }) => {
			handleIndicatorUpperRemove(indicator);
		});
		chartObject.eventEmitter.on('EVENT_REMOVE', ({ eventType }) => {
			handleEventRemove(eventType);
		});
		chartObject.eventEmitter.on('DATA_LOAD_START', () => {
			setIsLoading(true);
		});
		chartObject.eventEmitter.on('DATA_LOAD_STOP', () => {
			setIsLoading(false);
		});
	};

	const InitChart = async (isDefaultIndicator) => {
		setSymbolList([]);
		setUpperIndicator([]);
		const authToken = getAccessToken();
		const { ModchartsInteractive } = await import('@markit/modcharts');
		let chartObject = new ModchartsInteractive();
		chartObject.setAuthToken(authToken);
		if (getChartShadowRoot('#chart-dom-element')) {
			let el = getChartShadowRoot('#chart-dom-element');
			el.innerHTML = '';
		}
		chartObject.mount(getChartShadowRoot('#chart-dom-element'));
		if (isDefaultIndicator) {
			chartObject.load(chartConfig([], DEFAULT_INDICATOR), chartLoadComplete);
		} else {
			chartObject.load(chartConfig([]), chartLoadComplete);
		}
		bindChartEvents(chartObject);
	};

	const chartLoadComplete = (data) => {
		setChart(data);
		setUpperIndicator([]);
		setReset(!reset);
		setIsLoading(false);
	};

	const resetChart = () => {
		InitChart(true);
	};

	const getLabels = () => {
		const dates = [];
		chart?.panels[0]?.prevDateLabels?.days?.forEach((dt) => {
			dates.push(
				formatDateTime(dt?.date, { format: DATE_FORMATS.YEAR_MONTH_DATE }),
			);
		});
		chart?.panels[0]?.prevDateLabels?.months?.forEach((dt) => {
			dates.push(
				formatDateTime(dt?.date, { format: DATE_FORMATS.YEAR_MONTH_DATE }),
			);
		});
		chart?.panels[0]?.prevDateLabels?.weeks?.forEach((dt) => {
			dates.push(
				formatDateTime(dt?.date, { format: DATE_FORMATS.YEAR_MONTH_DATE }),
			);
		});
		chart?.panels[0]?.prevDateLabels?.years?.forEach((dt) => {
			dates.push(
				formatDateTime(dt?.date, { format: DATE_FORMATS.YEAR_MONTH_DATE }),
			);
		});
		return dates;
	};

	const getChartNews = async () => {
		if (!props.symbol) return;
		const newsInputs = buildNewsInputs(props.symbol);
		const response = await getNews(newsInputs);
		const newsArray = response?.data?.data?.items;
		if (newsArray && newsArray.length > 0) {
			setNewsData([
				...prepareNewsData(newsArray, DATE_FORMATS.YEAR_MONTH_DATE),
			]);
		}
	};

	const mapCustomNewsEvents = () => {
		let groupedData = {};
		const customDates = getLabels();
		customDates?.forEach((date) => {
			const dailyGroupedData = newsData?.filter((x) => x.documentDate === date);
			if (dailyGroupedData?.length > 0) {
				groupedData[date] = dailyGroupedData;
			}
		});

		return Object.keys(groupedData).map((event) => {
			return {
				...event,
				date: event,
				story: groupedData[event].length,
			};
		});
	};

	const addCustomNewsEvents = (chart) => {
		chart?.panels[0]?.addEvent('custom', {
			name: 'News Event',
			dataset: mapCustomNewsEvents(),
		});
	};

	const handleChartEventSelection = (events) => {
		let chartObj = chart;
		if (chartObj?.panels[0] && events.length > 0) {
			chartObj.panels[0].events = [];
			events.forEach((event) => {
				let id = event.id.toLowerCase();
				if (id === 'news') {
					addCustomNewsEvents(chartObj);
				} else if (id === 'dividends' || id === 'splits') {
					chartObj.panels[0].addEvent(id);
				}
			});
			chartObj?.loadData();
		}
	};

	const handleChartTypeSelection = (chartType) => {
		chart?.setPriceMarkerType(chartType || 'line');
		const priceIndicator = chart?.panels[0].indicators[0];

		if (chartType === 'fill') {
			if (priceIndicator) {
				priceIndicator.setStyle('fillColor', 'rgba(0,68,128, 0.25)');
				priceIndicator.setStyle('fillColorStop', 'rgba(0,68,128, 0.25)');
			}
		} else if (chartType === 'candlestick' && priceIndicator['params']) {
			priceIndicator['params'].candleFillType = 'filled';
		} else if (chartType === 'hlc') {
			priceIndicator['params'].candleFillType = 'hlc';
		} else {
			priceIndicator['params'].candleFillType = 'line';
		}
		chart?.loadData();
	};

	const getSavedChart = async () => {
		if (props.useSavedPrefernces) {
			const parseData = await getSavedChartData();
			setSavedChartList(parseData);
			props.loadSavedChartData(parseData);
		}
	};

	const saveNewChart = async (chartName) => {
		if (chartName) {
			let chartObj = {};
			chartObj.chartName = chartName;
			chartObj.isDefault = false;
			chartObj.timeFrame = props.timeFrame;
			chartObj.chartType = props.chartType;
			chartObj.eventsType = props.eventsType;
			chartObj.comparisonList = props.comparisonList;
			chartObj.lowerIndicator = selectedLowerIndicator;

			let res = await chart?.save(false, chartName);
			const getSavedChartList = await getSavedChartData();

			if (res.id) {
				chartObj.id = res.id;
				const newChartList = [...(getSavedChartList || []), ...[chartObj]];
				setSavedChartList(newChartList);
				savechartSettings(newChartList);
			}
		}
	};

	return (
		<div className={'modcharts_container'}>
			{chart ? chartUpperIndicators() : ''}
			{isLoading && <Loader />}

			<div
				data-testid="chart-dom-element"
				id="chart-dom-element"
				style={{ left: isLoading ? '-5000px' : 'auto' }}
			></div>
			{props.useSavedPrefernces && chartEventIcons()}
			{isErrorDialog && (
				<ErrorMessageDialog
					title={ERROR_DIALOG_TITLE}
					message={MAX_LIMIT_MESSAGE}
					onClose={closeErrorDialog}
					id="error-dialog"
				/>
			)}
		</div>
	);
};

export default Charts;
Charts.propTypes = {
	venueXid: PropTypes.number.isRequired,
	symbol: PropTypes.string,
	chartType: PropTypes.string,
	eventsType: PropTypes.array,
	timeFrame: PropTypes.number,
	saveChart: PropTypes.string,
	selectedOverlayData: PropTypes.object,
	loadSavedChartData: PropTypes.func,
	loadExistingChart: PropTypes.string,
	isChartReset: PropTypes.bool,
	comparisonLimit: PropTypes.number,
	comparisonList: PropTypes.array,
	removeComparison: PropTypes.func,
	isEnabledSaveSettings: PropTypes.bool,
	useSavedPrefernces: PropTypes.bool,
	tabChangeHandler: PropTypes.func,
	lowerIndicator: PropTypes.string,
	activeSelectedTab: PropTypes.string,
};
