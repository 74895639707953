import './NoData.scss';
import { Label } from '../../@core-ui';
import {
	DISPLAY_ACCESS_TOKEN_ERROR_MESSAGE,
	ERROR_MESSAGES,
} from '../../constants/appConstants';

interface NoDataProps {
	text?: string;
	error?: any;
	size?: 's' | 'm' | 'l';
	isHtmlContent?: boolean;
	applyMinHeight?: boolean;
}

const NoData = (props: NoDataProps) => {
	const {
		text,
		error,
		size = 'm',
		isHtmlContent,
		applyMinHeight = false,
	} = props;

	function checkIfTokenError() {
		if (
			error &&
			DISPLAY_ACCESS_TOKEN_ERROR_MESSAGE &&
			(error.code === '401' || error.message?.includes('ACCESS_TOKEN'))
		) {
			return true;
		}
		return false;
	}
	return (
		<div className={`no-data ${applyMinHeight && 'height'}`}>
			<Label
				text={text ?? ERROR_MESSAGES.DEFAULT_NO_DATA}
				align={'center'}
				size={size}
				isHtmlContent={isHtmlContent}
			/>
			{checkIfTokenError() && (
				<Label
					text={ERROR_MESSAGES.ACCESS_TOKEN_ERROR}
					size={'s'}
					align={'center'}
				/>
			)}
		</div>
	);
};

export default NoData;
