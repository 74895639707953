import React, { useEffect, useState } from 'react';
import './snapshot-header.scss';
import {
	ConfirmationDialog,
	Label,
	LabelLink,
	MessageBox,
	MessageBoxStateType,
} from '../../../@core-ui';
import {
	addHoldings,
	addWatchlist,
	getWatchlist,
	getWatchlistHoldings,
} from '../../../services';
import {
	WatchlistConstants,
	ISnapshotHeaderDetails,
} from '../snapshot-constants';
import { handleEnterKeyPress } from '../../../utilities/utils';
import { WatchlistSavedSuccessfullMessage } from '../../watchlists/customize-watchlist/customize-watchlist-constants';
import { Check, WatchlistIcon } from '../../../assets/Icons';
import { dispatchAddToWatchlistCustomEvent } from '../../../utilities/events';

interface SnapshotHeaderProps {
	venueXid: number;
	headerDetails: ISnapshotHeaderDetails;
	watchlistHandler: (show: boolean) => void;
	isMF: boolean;
	enableAddToWatchlist?: boolean;
}

const SnapshotHeader: React.FC<SnapshotHeaderProps> = ({
	venueXid,
	headerDetails,
	watchlistHandler,
	isMF,
	enableAddToWatchlist = true,
}) => {
	const [holdingsCount, setHoldingsCount] = useState<number>(0);
	const [watchlistHoldings, setWatchlistHoldings] = useState<any>();
	const [watchlistId, setWatchlistId] = useState<string>('');
	const [overflowAlert, setOverflowAlert] = useState<boolean>(false);
	const [alreadyAdded, setAlreadyAdded] = useState<boolean>(false);
	const [alertInfo, setAlertInfo] = useState({
		showAlert: false,
		content: WatchlistSavedSuccessfullMessage,
		state: MessageBoxStateType.Success,
	});
	const [isWatchListLoading, setIsWatchListLoading] = useState<boolean>(true);

	useEffect(() => {
		setAlreadyAdded(false);

		if (enableAddToWatchlist) {
			fetchData();
		}
	}, [venueXid]);

	const fetchData = async () => {
		setIsWatchListLoading(true);
		let watchlistId = '';
		const { data } = await getWatchlist();

		if (data?.data?.items?.length) {
			watchlistId = data.data.items[0].id;
		} else {
			const { data } = await addWatchlist();
			watchlistId = data?.data?.id;
		}
		if (watchlistId) {
			setWatchlistId(watchlistId);
			const holdingsResponse = await getWatchlistHoldings(watchlistId);
			if (holdingsResponse && holdingsResponse.data?.data?.items?.length > 0) {
				const holdings = holdingsResponse.data?.data?.items;
				setWatchlistHoldings(holdings);
				setHoldingsCount(holdings.length);
				if (holdings?.some((item: { xid: number }) => item.xid === venueXid)) {
					setAlreadyAdded(true);
				}
			}
		}
		setIsWatchListLoading(false);
	};

	const onAlertCloseClick = () => {
		setAlertInfo({ ...alertInfo, showAlert: false });
	};

	const watchlistClickHandler = async () => {
		if (!isWatchListLoading) {
			if (holdingsCount >= 50) {
				setOverflowAlert(true);
			} else if (
				!watchlistHoldings?.some(
					(item: { xid: number }) => item.xid === venueXid,
				)
			) {
				const holdings = [
					{
						xid: venueXid,
						description: headerDetails['symbol'],
					},
				];
				await addHoldings(watchlistId, holdings);
				dispatchAddToWatchlistCustomEvent();
				setAlreadyAdded(true);
				setAlertInfo({
					content: `${headerDetails['companyName']} ${WatchlistConstants.SuccessMessage}`,
					state: MessageBoxStateType.AdditionalSuccess,
					showAlert: true,
				});
				fetchData();
			}
		}
	};

	return (
		<div className="snapshot-header-container" id="snapshot-header">
			<div className="name-container">
				{headerDetails?.symbol && (
					<>
						<span className="company-name">
							{headerDetails['companyName']} -{' '}
						</span>
						<span className="symbol-type" data-testid="symbol-type">
							{!isMF && headerDetails['exchange']}
							{!isMF && ': '}
							{headerDetails['symbol']}
						</span>
					</>
				)}
			</div>
			<div className="watchlist-label-container">
				<div className="watchlist-toast-container">
					{alertInfo.showAlert && (
						<MessageBox
							content={alertInfo.content}
							state={alertInfo.state}
							onClose={onAlertCloseClick}
							autoHide={true}
						/>
					)}
				</div>
				<div className="watchlist-alert-container">
					{overflowAlert && (
						<ConfirmationDialog
							cancelButtonText={WatchlistConstants.Dismiss}
							confirmButtonText={WatchlistConstants.CustomizeWatchlist}
							onCancel={() => {
								setOverflowAlert(false);
							}}
							onConfirm={() => {
								watchlistHandler(true);
							}}
							title={WatchlistConstants.WatchlistAlert}
							dataClass={'watchlist-alert'}
						>
							<MessageBox content={WatchlistConstants.AlertMessage} />
						</ConfirmationDialog>
					)}
				</div>

				{enableAddToWatchlist && !!venueXid && (
					<div className="watchlist-label">
						{alreadyAdded ? (
							<React.Fragment>
								<span className="check-icon">
									<Check height={11} />
								</span>
								<Label
									text={WatchlistConstants.AlreadyAdded}
									className={'label neue-bold darkGreyLabel'}
									testId="added-to-watchlist"
								/>
							</React.Fragment>
						) : (
							<React.Fragment>
								<button
									aria-label={WatchlistConstants.AddToWatchlistText}
									className={
										!isWatchListLoading ? 'watchlist-icon' : 'disabled'
									}
									tabIndex={!isWatchListLoading ? 0 : -1}
									onClick={watchlistClickHandler}
									onKeyDown={(e) =>
										handleEnterKeyPress(e, watchlistClickHandler)
									}
								>
									<WatchlistIcon
										color={isWatchListLoading ? '#C4C4C4' : '#2B6D9F'}
									/>
								</button>
								<LabelLink
									text={WatchlistConstants.AddToWatchlistText}
									className={`labelLink ${
										isWatchListLoading ? 'disabled' : ''
									}`}
									onClick={watchlistClickHandler}
									onKeyDown={(e) =>
										handleEnterKeyPress(e, watchlistClickHandler)
									}
									testId="add-to-watchlist"
									tabIndex={!isWatchListLoading ? 0 : -1}
								/>
							</React.Fragment>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default SnapshotHeader;
