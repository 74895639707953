import React from 'react';
import './portfolio-tracker-chart.scss';

const HighChartComponent = React.lazy(
	() => import('../../../../../shared/high-chart/high-chart-component'),
);
import { IAssetAllocation } from '../portfolio-tracker-constants';
import { buildHoverTable } from '../../portfolio-utils';
import ReactDOMServer from 'react-dom/server';
import { Heading, Loader } from '../../../../../@core-ui';
import { getDeviceType } from '../../../../../utilities/utils';
import { E_DeviceType } from '../../../../..//constants/appConstants';

interface PortfolioTrackerChartProps {
	assetAllocationsData?: IAssetAllocation;
}

const PortfolioTrackerChart: React.FC<PortfolioTrackerChartProps> = ({
	assetAllocationsData,
}) => {
	const isMobile = getDeviceType() === E_DeviceType.Mobile;

	const chartOptions: Highcharts.Options = {
		chart: {
			type: 'pie',
			height: isMobile ? '240px' : '250px',
		},
		title: {
			text: '',
		},
		subtitle: {
			useHTML: true,
			text: '<div>Total Asset <div style="text-align: center">Allocation</div></div>',
			style: {
				color: '#575757',
				fontSize: '14px',
				fontFamily: 'HelveticaNeue-Regular',
				fontWeight: 'bold',
				lineHeight: '17px',
			},
			verticalAlign: 'middle',
			y: 25,
		},
		tooltip: {
			useHTML: true,
			padding: 1,
			backgroundColor: '#F6FBFF',
			style: {
				boxShadow: '0 1px 5px 0 rgba(56, 81, 100, 0.5)',
				width: 477,
			},
			formatter: () => {
				return ReactDOMServer.renderToString(
					buildHoverTable(isMobile, assetAllocationsData),
				);
			},
			outside: true,
		},
		credits: {
			enabled: false,
		},
		plotOptions: {
			pie: {
				states: {
					inactive: {
						opacity: 1,
					},
					hover: {
						enabled: false,
					},
				},
				size: '100%',
				innerSize: '77%',
				dataLabels: {
					enabled: false,
				},
			},
		},
		colors: assetAllocationsData?.colors,
		series: [
			{
				type: 'pie',
				data: assetAllocationsData?.data,
			},
		],
	};

	return (
		<div className="chart-container" data-testid="chart-container">
			{assetAllocationsData ? (
				<React.Suspense fallback={<Loader />}>
					<HighChartComponent
						options={chartOptions}
						id={'portfolio-tracker-pie-high-chart'}
					/>
				</React.Suspense>
			) : (
				<div className="no-data-pie-chart">
					<Heading content="Asset Allocation" type={'h6'} />
					<Heading content="not available" type={'h6'} />
				</div>
			)}
		</div>
	);
};

export default React.memo(PortfolioTrackerChart);
