import React, { useState, useEffect } from 'react';
import './options-chain.scss';
import {
	SingleSelect,
	Checkbox,
	Heading,
	Label,
	Loader,
	LabelLink,
} from './../../../../@core-ui';
import {
	OptionChainDisplayDropdownOptions,
	E_AdjustmentOption,
	E_OptionChainDisplayDropdownOptions,
	InTheMoneyIndicator,
	StrikesMobileDropdownOptions,
	OPTIONS_ACTIONS,
} from '../../options-constants';
import { getOptionChainWithQuotes } from '../../../../services';
import {
	delayedMessage,
	getDeviceType,
	handleEnterKeyPress,
	setStateOnDropdownChange,
} from '../../../../utilities/utils';
import OptionsChainTableContainer from './options-chain-table-container/options-chain-table-container';
import { IOptionChainWithQuotesData } from '../../../../types/interfaces';
import { E_DeviceType } from '../../../../constants/appConstants';
import { IOptionChainParams } from '../../../../services/options-data-request-methods';
import { useOptionsContext } from '../../../snapshots/snapshot-container';

export interface IOptionChainPreSelectedData {
	expiryDate?: string;
	displayType?: E_OptionChainDisplayDropdownOptions;
	isAdjusted?: boolean;
	isSelectedAll?: boolean;
	strikeCount?: string;
}
interface OptionsChainProps {
	venueXid: number;
	symbol: string;
	expiryDates: { key: string; value: string }[];
	preSelectedData?: IOptionChainPreSelectedData;
	setPreSelectedData?: (preSelectedData: IOptionChainPreSelectedData) => void;
	mobileColumns?: string[];
	onMobileChangeViewLinkClick?: () => void;
	delayedMinutes?: number;
}

const OptionsChain: React.FC<OptionsChainProps> = ({
	expiryDates = [],
	venueXid,
	symbol,
	preSelectedData,
	setPreSelectedData,
	mobileColumns,
	onMobileChangeViewLinkClick,
	delayedMinutes,
}) => {
	const { masterStateMemo } = useOptionsContext();
	const [chainData, setChainData] = useState<IOptionChainWithQuotesData>();
	const [adjustedData, setAdjustedData] =
		useState<IOptionChainWithQuotesData>();
	const [selectedExpiryDate, setSelectedExpiryDate] = useState<string>(
		expiryDates?.[0]?.key,
	);
	const [selectedStrike, setSelectedStrike] = useState<string>(
		preSelectedData?.strikeCount ?? StrikesMobileDropdownOptions?.[0]?.key,
	);
	const [loadingState, setLoadingState] = useState({
		isLoading: true,
	});
	const [selectedDisplayType, setSelectedDisplayType] =
		useState<E_OptionChainDisplayDropdownOptions>(
			preSelectedData?.displayType ??
				E_OptionChainDisplayDropdownOptions.CallsAndPuts,
		);
	const [isAdjusted, setIsAdjusted] = useState<boolean>(
		preSelectedData?.isAdjusted ?? false,
	);
	const [isSelectedAll, setIsSelectedAll] = useState<boolean>(
		preSelectedData?.isSelectedAll ?? false,
	);
	const isMobile = getDeviceType() === E_DeviceType.Mobile;

	useEffect(() => {
		fetchData();
		if (masterStateMemo?.action === OPTIONS_ACTIONS.OPTIONS_HOME_PAGE) {
			setPreSelectedData?.({
				...preSelectedData,
				displayType: selectedDisplayType,
				expiryDate: selectedExpiryDate,
				isSelectedAll: isSelectedAll,
				strikeCount: (isMobile ? selectedStrike : 20)?.toString(),
			});
		}
	}, [
		selectedExpiryDate,
		selectedDisplayType,
		isSelectedAll,
		selectedStrike,
		venueXid,
	]);

	useEffect(() => {
		isAdjusted && fetchAdjustedData();
		if (masterStateMemo?.action === OPTIONS_ACTIONS.OPTIONS_HOME_PAGE) {
			setPreSelectedData?.({
				...preSelectedData,
				isAdjusted: isAdjusted,
			});
		}
	}, [isAdjusted]);

	const fetchData = async () => {
		const data = await fetchOptionChainQuotesData();
		setChainData(data);
	};

	const isAdjustedOnChange = async (selected: boolean) => {
		setIsAdjusted(selected);
	};

	const isSelectedAllChange = async (selected: boolean) => {
		setIsSelectedAll(selected);
	};

	const fetchAdjustedData = async () => {
		const data = await fetchOptionChainQuotesData(true);
		setAdjustedData(data);
	};

	const fetchOptionChainQuotesData = async (isAdjustedType?: boolean) => {
		setLoadingState({ isLoading: true });
		const params: IOptionChainParams = {
			venueXid: venueXid,
			expirationDates: [selectedExpiryDate],
			callsPuts: selectedDisplayType,
			adjustment: isAdjustedType
				? E_AdjustmentOption.Adjusted
				: E_AdjustmentOption.NonAdjusted,
			strikeCount: getStrikeCount(),
		};
		const response = await getOptionChainWithQuotes(params);
		setLoadingState({ isLoading: false });
		return response?.data?.data;
	};
	const getStrikeCount = () => {
		if (isMobile) {
			return !isNaN(Number(selectedStrike))
				? parseInt(selectedStrike) / 2
				: selectedStrike;
		} else {
			return isSelectedAll ? 'all' : 20;
		}
	};
	const getDesktopView = () => {
		if (loadingState.isLoading) return <Loader />;
		if (isAdjusted) {
			return (
				<>
					<OptionsChainTableContainer
						symbol={symbol}
						displayOption={selectedDisplayType}
						mobileColumns={mobileColumns}
						optionChainData={chainData}
					/>
					<div className="adjusted-options">
						<div className="adjusted-options-header">Adjusted Options:</div>
						<OptionsChainTableContainer
							symbol={symbol}
							displayOption={selectedDisplayType}
							optionChainData={adjustedData}
							mobileColumns={mobileColumns}
						/>
					</div>
				</>
			);
		}

		return (
			<div className="desktop-view">
				<OptionsChainTableContainer
					symbol={symbol}
					displayOption={selectedDisplayType}
					optionChainData={chainData}
					mobileColumns={mobileColumns}
				/>
			</div>
		);
	};

	return (
		<div className="options-chain">
			<div className="options-chain-header-row">
				<div className="options-header">
					<Heading content="Company Options Chain" type={'h3'} />
				</div>
				<div className="options-controls">
					<div className="display-type-container">
						<Label text="Display" />
						<SingleSelect
							data={OptionChainDisplayDropdownOptions}
							dataTestId={`ddlDisplayType`}
							changeHandler={(e) =>
								setStateOnDropdownChange(e, setSelectedDisplayType)
							}
							selectedValue={selectedDisplayType}
						/>
					</div>
					<div className="expiry-date-container">
						<Label text={`${isMobile ? 'That' : 'that'} expire on`} />
						<SingleSelect
							data={expiryDates}
							dataTestId={`ddlExpiryDate`}
							selectedValue={selectedExpiryDate}
							changeHandler={(e) =>
								setStateOnDropdownChange(e, setSelectedExpiryDate)
							}
						/>
					</div>
					{isMobile && (
						<div className="expiry-date-container">
							<Label text="Show Strikes" />
							<SingleSelect
								data={StrikesMobileDropdownOptions}
								selectedValue={selectedStrike}
								changeHandler={(e) =>
									setStateOnDropdownChange(e, setSelectedStrike)
								}
							/>
						</div>
					)}
					<div className="is-adjusted-strikes-container">
						<div>
							<Checkbox
								onChange={isAdjustedOnChange}
								checked={isAdjusted}
								value="isAdjusted"
							/>
							<Label text="Show Adjusted" />
						</div>
						{!isMobile && (
							<div>
								<Checkbox
									onChange={isSelectedAllChange}
									checked={isSelectedAll}
									value="isSelectedAll"
								/>
								<Label text="All Strike Prices" />
							</div>
						)}

						{isMobile && (
							<LabelLink
								text="Change View"
								testId="changeView"
								onClick={onMobileChangeViewLinkClick}
								onKeyDown={(event) =>
									handleEnterKeyPress(event, onMobileChangeViewLinkClick)
								}
							/>
						)}
					</div>
				</div>
			</div>
			{getDesktopView()}
			<div className="indicator-container">
				<div className="in-the-money-label">{InTheMoneyIndicator}</div>
				<Label text={delayedMessage(delayedMinutes)} isGrey size="s" />
			</div>
		</div>
	);
};

export default OptionsChain;
