export const HEADLINES_HEADER = 'Headline News';

const FIELD_VALUES = {
	WALL_STREET_JOURNAL: 'WallStreetJournal',
	US_STOCKS_MID_DAY_REPORTS: 'USStocks_MidDayReports',
	US_STOCKS_WEEKLY_OUTLOOK: 'USStocks_WeeklyOutlook',
};

const FIELD_NAMES = {
	MARKETS: 'Markets',
	PRESS_DIGESTS: 'PressDigests',
};

export const HEADLINES_DROPDOWN_DATA = [
	{
		key: FIELD_VALUES.WALL_STREET_JOURNAL,
		value: 'Wall Street Journal - Daily Press Digest',
	},
	{
		key: FIELD_VALUES.US_STOCKS_MID_DAY_REPORTS,
		value: 'Most Recent US Stock Market Reports',
	},
	{
		key: FIELD_VALUES.US_STOCKS_WEEKLY_OUTLOOK,
		value: 'Wall Street Week Ahead',
	},
];

export const FIELD_VALUE_MAPPING = {
	[FIELD_VALUES.WALL_STREET_JOURNAL]: FIELD_NAMES.PRESS_DIGESTS,
	[FIELD_VALUES.US_STOCKS_MID_DAY_REPORTS]: FIELD_NAMES.MARKETS,
	[FIELD_VALUES.US_STOCKS_WEEKLY_OUTLOOK]: FIELD_NAMES.MARKETS,
};
