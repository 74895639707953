import React from 'react';
import { Table } from '../../../@core-ui';
import {
	IIndices
} from '../../../types/interfaces/IMarketsIndices';
import { ISortingInfo } from '../../../@core-ui/Table/table';
import { SORTING_DIRECTION } from '../../../constants/appConstants';
import { ITableColumn } from '../../../types/interfaces/ICommon';

interface IIndicesProps {
	data: IIndices[];
	sortingInfo: ISortingInfo;
	sortDataHandler: (colName: string, sortDirection: SORTING_DIRECTION) => void;
}
const IndicesTable: React.FC<IIndicesProps> = ({
	data = [],
	sortingInfo,
	sortDataHandler,
}) => {
	const columns: ITableColumn<IIndices>[] = [
		{ header: 'Index', accessor: 'name', sortDir: 'asc', key: 'name' },
		{ header: 'Last', accessor: 'last', sortDir: 'desc', key: 'last' },
		{ header: '$ Chg', accessor: 'change', sortDir: 'desc', key: 'change' },
		{
			header: '% Chg',
			accessor: 'changePercent',
			sortDir: 'desc',
			key: 'changePercent',
		},
	];

	return (
		<Table
			data={data}
			columns={columns}
			showVerticalBorder={false}
			showHorizontalBorder={true}
			isSortable={true}
			sortingInfo={sortingInfo}
			sortDataHandler={sortDataHandler}
		/>
	);
};

export default IndicesTable;
