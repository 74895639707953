import React, { useState } from 'react';
import './charts-page.scss';
import QuoteBar from '../../../shared/quote-bar/quote-bar';
import ChartContainer from '../../../markets/indices-charts/chart-container/chart-container';
import NoData from '../../../../shared/NoData/NoData';
import {
	ERROR_MESSAGES,
	E_DeviceType,
} from '../../../../constants/appConstants';
import { Label } from '../../../../@core-ui';
import { convertUtcDttmToET } from '../../../../utilities/date-time-formatter-tz';
import { DATE_FORMATS } from '../../../../utilities/date-time-formatter';
import { delayedMessage, getDeviceType } from '../../../../utilities/utils';

interface ChartsPageProps {
	venueXid: number;
	symbol: string;
	tabChangeHandler: (k: any) => void;
	activeSelectedTab: string;
}

const ChartsPage: React.FC<ChartsPageProps> = ({
	venueXid,
	symbol,
	tabChangeHandler,
	activeSelectedTab,
}) => {
	const [selectedDelayedDateTime, setSelectedDelayedDateTime] = useState('--');
	const [selectedDelayedMinutes, setSelectedDelayedMinutes] = useState();
	const isMobile = getDeviceType() === E_DeviceType.Mobile;

	return (
		<>
			{!venueXid ? (
				<NoData text={ERROR_MESSAGES.DEFAULT_NO_DATA} applyMinHeight />
			) : (
				<div className="charts-tab-container">
					<div className="quote-bar-container">
						<QuoteBar
							venueXid={venueXid}
							setDelayedDateTime={setSelectedDelayedDateTime}
							setDelayedMinutes={setSelectedDelayedMinutes}
						/>
						<Label
							isGrey
							align="center"
							size="s"
							text={`As of ${convertUtcDttmToET(selectedDelayedDateTime, {
								format: DATE_FORMATS.ET_VARIANT_DATE_TIME_SEC_FORMAT,
							})}.${isMobile ? '<br />' : ' '}${delayedMessage(
								selectedDelayedMinutes,
							)}`}
							className="delayed-info"
							isHtmlContent={isMobile}
						/>
					</div>
					<ChartContainer
						venueXid={venueXid}
						symbol={symbol}
						delayedDateTime={selectedDelayedDateTime}
						delayedMinutes={selectedDelayedMinutes}
						isCompareEnabled={true}
						isEnabledSaveSettings={true}
						useSavedPrefernces={true}
						tabChangeHandler={tabChangeHandler}
						activeSelectedTab={activeSelectedTab}
					/>
				</div>
			)}
		</>
	);
};
export default ChartsPage;
