import React from 'react';
import PropTypes from 'prop-types';
import { FilterTag } from '../../../../../@core-ui';
import { OVERLAY_OPTIONS, CHARTCOLORS } from '../../indices-charts-constants';
import './chart-panel.scss';

export default function ChartPanel({
	panelData,
	removeChartOverlays,
	symbolList,
}) {
	return (
		<div className="symbolComparisonConatiner">
			{symbolList.map((symbolData) => {
				return (
					<FilterTag
						key={symbolData?.name}
						color={symbolData?.colorCode}
						label={symbolData?.name}
						onClose={() => removeChartOverlays(symbolData?.venueXid, 'symbol')}
					/>
				);
			})}
			{panelData?.map(({ params }) => {
				const colorCode =
					OVERLAY_OPTIONS.filter((x) => x.key == params?.id)[0]?.color ||
					CHARTCOLORS[0];
				if (params?.id !== 'price') {
					return (
						<FilterTag
							key={params?.uid}
							color={params?.colorCode || colorCode}
							label={params?.name}
							onClose={() => removeChartOverlays(params?.uid, 'indicator')}
						/>
					);
				}
			})}
		</div>
	);
}

ChartPanel.propTypes = {
	panelData: PropTypes.array,
	removeChartOverlays: PropTypes.func,
	symbolList: PropTypes.array,
};
