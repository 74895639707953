import './collapsible.scss';
import { MinusIcon, PlusIcon } from '../../assets/Icons';
import { Heading } from '../../@core-ui';

interface CollapsibleProps {
	title: string;
	children: React.ReactNode | string;
	testId?: string;
	open?: boolean;
}

export const Collapsible = (props: CollapsibleProps) => {
	const { title, children, testId = '', open = false } = props;

	return (
		<div className={'collapsible-container'} data-testid={testId}>
			<details open={open}>
				<summary>
					<MinusIcon /> <PlusIcon /> <Heading content={title} type="h4" />
				</summary>
				<div className={'content-container'}>{children}</div>
			</details>
		</div>
	);
};
