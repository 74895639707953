import React from 'react';
import { IBasicIconProps } from '../IBasicIconProps';

const CandlestickIcon: React.FC<IBasicIconProps> = ({
	width = 21,
	height = 17,
	color = '#000',
}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 14.9364277 15.0001263"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g transform="translate(-819.000000, -430.000000)" fill={color}>
				<path
					d="M821.65,434 L821.65,438 L823,438 L823,448 L821.65,448 L821.65,450 L820.35,450 L820.35,448 L819,448 L819,438 L820.35,438 L820.35,434 L821.65,434 Z M829.65,430 L829.65,431 L831,431 L831,440 L829.65,440 L829.65,443 L828.35,443 L828.35,440 L827,440 L827,431 L828.35,431 L828.35,430 L829.65,430 Z"
					id="Combined-Shape"
				></path>
			</g>
		</g>
	</svg>
);

export default CandlestickIcon;
