/* eslint-disable indent */
import React from 'react';
import { TYPES_OF_ELEMENTS } from '../../../constants/appConstants';
import {
	EditIcon,
	NotesIcon,
	TrashIcon,
	DeleteIcon,
	IBasicIconProps,
	MinusIcon,
	PlusIcon,
	NewsIcon,
	PrinterIcon,
} from '../../../assets/Icons';
import {
	Label,
	NumberLabel,
	LabelLink,
	Textbox,
	SymbolDetails,
} from '../../../@core-ui';
import { delayedMessage, handleEnterKeyPress } from '../../../utilities/utils';
import {
	STOCKS_TYPE as ASSET_TYPE,
	PORTFOLIO_TRAKER_KEYS,
	HOVER_TABLE_HEADER,
	IAllocationItem,
	HOVER_TABLE_DATA_MAPPING,
} from './portfolio-tracker/portfolio-tracker-constants';
import { TRANSACTIONS_KEYS } from './portfolio-constants';

export const generateColValue = (
	item: { [key: string]: any },
	text: string,
	style?: any,
	numberDefaultValue?: any,
) => {
	let node: React.ReactNode;
	switch (item.type) {
		case TYPES_OF_ELEMENTS.LABEL:
			node = (
				<Label
					text={text}
					size={item.size || 'm'}
					isBold={item.isBold}
					isGrey={item.isGrey}
					ellipsisEnabled={item.showEllipsis}
					style={style}
					isDarkGrey={item.isDarkGrey}
					className={item.className}
					fontType={item.fontType}
				/>
			);
			break;
		case TYPES_OF_ELEMENTS.LINK_LABEL:
			node = getNameNode(item, text);
			break;
		case TYPES_OF_ELEMENTS.SYMBOl_DETAILS_LABEL:
			node = (
				<SymbolDetails
					venueXid={item?.venueXid}
					displayName={text}
					isCompanyNameOrSymbol={true}
				/>
			);
			break;
		case TYPES_OF_ELEMENTS.NUMBER:
			node = (
				<NumberLabel
					value={text}
					isBold={item.isBold}
					numberConfig={{
						showColor: item.showColor,
						preFix: item.preFix,
						postFixValue: item.postFix,
						showPercent: item.showPercent,
						precision: item.precision,
						internationalCurrency: item.internationalCurrency,
						commaSeparated: item.commaSeparated,
						defaultValue: numberDefaultValue,
					}}
					style={style}
				/>
			);
			break;
		case TYPES_OF_ELEMENTS.INPUT:
			node = (
				<Textbox
					placeholder={item.placeholder}
					type={item.typeParam}
					min={item.min}
					max={item.max}
					name={item.key}
					value={item.key === TRANSACTIONS_KEYS.FEES ? text || item.min : text}
					onChange={item.onChange}
					data-key={item.dataKey}
					data-id={item.dataId}
					data-error={item.highlight}
				/>
			);
			break;
		case TYPES_OF_ELEMENTS.NOTES_ICON:
			node = getIconNode(NotesIcon, item);
			break;
		case TYPES_OF_ELEMENTS.DELETE_ICON:
			node = getIconNode(DeleteIcon, item);
			break;
		case TYPES_OF_ELEMENTS.EDIT_ICON:
			node = getIconNode(EditIcon, item);
			break;
		case TYPES_OF_ELEMENTS.TRASH_ICON:
			node = getIconNode(TrashIcon, item);
			break;
		case TYPES_OF_ELEMENTS.PRINT_ICON:
			node = getIconNode(PrinterIcon, item);
			break;
		default:
			node = <span>{text}</span>;
	}
	return node;
};

export const bindData = (
	TABLE_HEADERS: any,
	type?: string,
	className?: string,
	text?: string | null,
	style?: any,
	nodeStyle?: any,
) => {
	return (
		<div className={`${className}`} style={style}>
			{TABLE_HEADERS?.map((item: any) => {
				return (
					<React.Fragment key={`${type}_${item.label}`}>
						{generateColValue(item, text ?? item.label, nodeStyle)}
					</React.Fragment>
				);
			})}
		</div>
	);
};

const getIconNode = (
	Icon: React.FC<IBasicIconProps>,
	item: { [key: string]: any },
) => {
	return (
		<div>
			<button
				className={item.className}
				data-key={item.dataKey}
				data-action={item.action}
				data-id={item.dataId}
				data-testid={item.testid}
				onClick={item.onClick}
				data-master-action={!!item.masterAction}
			>
				<Icon width={item.width} height={item.height} color={item.color} />
				{item.text ? <Label text={item.text} size={'m'} /> : null}
			</button>
		</div>
	);
};

const getNameNode = (item: { [key: string]: any }, text: any) => {
	const symbolDetails = {
		xid: text?.props?.venueXid,
		name: text?.props?.displayName,
	};
	return (
		<span className="name-col">
			<span className="expandable-symbol">
				{item.hasTransactions && (
					<button
						className="expand-icon"
						onClick={item.onExpandIconClick}
						onKeyDown={() => item.onExpandIconClick}
					>
						{item.isExpanded ? (
							<MinusIcon color={'#2B6D9F'} />
						) : (
							<PlusIcon color={'#2B6D9F'} />
						)}
					</button>
				)}
				{React.isValidElement(text) ? (
					<span className={`${item.hasRecentNews && 'company-name-column'}`}>
						<SymbolDetails
							venueXid={symbolDetails?.xid || 0}
							displayName={symbolDetails?.name || ''}
							isClickable
							isCompanyNameOrSymbol
						/>
					</span>
				) : (
					<LabelLink
						text={text}
						size={item.size || 'm'}
						isBold={item.isBold}
						ellipsisEnabled={item.ellipsisEnabled}
						onClick={item.onClick}
						data-key={item.dataKey}
						data-action={item.action}
						data-master-action={!!item.masterAction}
						onKeyDown={(e) => handleEnterKeyPress(e, item.onClick)}
						isHideTitle={item.isHideTitle}
					/>
				)}
			</span>
			{item.hasRecentNews && (
				<button
					className="notes-icon"
					onClick={item.onNotesIconClick}
					onKeyDown={() => item.onNotesIconClick}
				>
					<NewsIcon height={22} width={20} />
				</button>
			)}
		</span>
	);
};

export const IPadMobileHTMLbody = (
	holdingGroups: any,
	constants: any,
	portfolioData: any,
	isLoading: any,
) => {
	return (
		<div className="portfolio-wrapper">
			{holdingGroups}
			<div className="combined-row">
				{constants.lastRow?.map((item: any) => {
					const newItem = { ...item };
					const value = portfolioData[item.key];
					newItem.isCalculated = true;
					if (newItem.type !== '')
						return (
							<div className="combined-col" key={`col_${item.key}`}>
								<div className="display-label">
									<div className="label">{item.label}</div>
								</div>
								{item.key !== PORTFOLIO_TRAKER_KEYS.COMBINED_TOTALS && (
									<div className="value">
										{generateColValue(
											newItem,
											value,
											null,
											isLoading ? '...' : null,
										)}
									</div>
								)}
							</div>
						);
				})}
			</div>
			<div className="market-delayed-info">
				{portfolioData?.holdings?.length > 0 ? delayedMessage() : ''}
			</div>
		</div>
	);
};

export const checkIfAPIError = (response: any) => {
	return response?.error || response?.data?.data?.errors?.length;
};

export const getStockAssetClass = (marketCap: number | string) => {
	let assetClass = '';
	if (marketCap > 8000000000) {
		assetClass = ASSET_TYPE.LARGE_CAP_STOCKS;
	} else if (marketCap >= 2000000000) {
		assetClass = ASSET_TYPE.MID_CAP_STOCKS;
	} else if (marketCap > 0) {
		assetClass = ASSET_TYPE.SMALL_CAP_STOCKS;
	}
	return assetClass;
};

export const buildHoverTable = (
	isMobile: boolean,
	assetAllocationsData: any,
) => {
	const hoverParentStyle = {
		display: 'flex',
		flexDirection: 'column' as const,
		zIndex: '2',
		background: '#F6FBFF',
		padding: isMobile ? '7px 8px' : '7px 13px',
	};

	const commonRow = {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '4px 0px',
		gap: '5px',
		alignItems: 'center',
	};

	const labelStyle = {
		height: 'auto',
		minWidth: isMobile ? '80px' : '100px',
		textAlign: 'right',
	};

	const labelStyleFirstElement = {
		textAlign: 'left',
	};

	const headerStyle = {
		...{
			borderBottom: '1px solid #F0F0F0',
			padding: '6px 0px 2px 0px',
		},
		...commonRow,
	};

	return (
		<div style={hoverParentStyle}>
			<h6
				style={{
					fontSize: '14px',
					fontFamily: 'Palatino-Regular',
					color: '#000',
					letterSpacing: '0.29px',
					lineHeight: '19px',
					margin: '10px 0',
				}}
			>
				{'Performance By Asset Allocations'}
			</h6>
			<div style={headerStyle}>
				{HOVER_TABLE_HEADER?.map((item: any, idx: number) => {
					return (
						<React.Fragment key={`header_${item.label}`}>
							{generateColValue(
								item,
								item.label,
								idx === 0
									? { ...labelStyle, ...labelStyleFirstElement }
									: labelStyle,
							)}
						</React.Fragment>
					);
				})}
			</div>
			<div className="hover-table-data">
				{assetAllocationsData?.allocations?.map((data: IAllocationItem) => {
					return (
						<div
							className="common-row"
							key={`row_${data.name}`}
							style={commonRow}
						>
							{HOVER_TABLE_DATA_MAPPING.map((item: any, idx: number) => {
								return (
									<div
										key={`col_${item.key}`}
										style={{
											width: '25%',
											textAlign: idx === 0 ? 'left' : 'right',
										}}
									>
										{generateColValue(item, data[item.key])}
									</div>
								);
							})}
						</div>
					);
				})}
			</div>
		</div>
	);
};
