/* eslint-disable indent */
import dayjs from 'dayjs';
import { TEXT_NULL_VALUE, checkInvalidValue } from './formatter';

export enum DATE_FORMATS {
	DEFAULT_TIME_FORMAT = 'hh:mm:ssa',
	ET_DATE_TIME_FORMAT = 'h:mma [ET] MM/DD/YYYY',
	ET_VARIANT_DATE_TIME_FORMAT = 'h:mma [ET] MMMM DD, YYYY',
	ET_VARIANT_DATE_TIME_MON_NAME_FORMAT = 'h:mma [ET] MMM DD, YYYY',
	ET_VARIANT_DATE_TIME_SEC_FORMAT = 'h:mm:ssa [ET] MM/DD/YYYY',
	ET_VARIANT_DATE_TIME_SEC_SHORT_YEAR_FORMAT = 'h:mm:ssa [ET] MM/DD/YY',
	DEFAULT_DATE_FORMAT = 'MM/DD/YYYY',
	HOUR_MINUTE_FORMAT = 'hh:mma',
	HOUR_MINUTE_SEC_ET_FORMAT = 'hh:mm:ssa [ET]',
	MONTH_DATE_YEAR_FORMAT = 'MMMM DD, YYYY',
	SHORT_MONTH_DATE_YEAR_FORMAT = 'MMM DD, YYYY',
	YEAR_MONTH_DATE = 'YYYY-MM-DD',
	DOCUMENT_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss',
	DATE_SHORT_YEAR_FORMAT = 'MM/DD/YY',
	ONLY_DAY = 'D',
	CHART_DATE = 'dddd, MMMM DD, YYYY',
	SHORT_DATE = 'M/D/YYYY',
}

export const ET_timezone = 'America/New_York';

export const formatDateTime = (date: any, options: any = {}) => {
	let formattedDate: any = TEXT_NULL_VALUE;
	const formatOptions = {
		isDayJSObject: options.isDayJSObject || false,
		format: options.format || DATE_FORMATS.DEFAULT_DATE_FORMAT,
		preFix: options.preFix || '',
		postFix: options.postFix || '',
		timeOnly: options.timeOnly || '',
		timeFormat: options.timeFormat || DATE_FORMATS.DEFAULT_TIME_FORMAT,
	};

	const dayJsObj = handleDateObj(formatOptions, date);

	if (
		!date ||
		!dayJsObj ||
		(dayJsObj && !dayJsObj?.isValid()) ||
		dayJsObj.year() < 1900
	) {
		return formattedDate;
	}

	if (formatOptions.timeOnly) {
		formattedDate = dayJsObj.format(formatOptions.timeFormat);
	} else {
		formattedDate = dayJsObj.format(formatOptions.format);
	}

	if (formatOptions.preFix) {
		formattedDate = `${formatOptions.preFix} ${formattedDate}`;
	}

	if (formatOptions.postFix) {
		formattedDate = `${formattedDate} ${formatOptions.postFix}`;
	}

	return formattedDate;
};

const handleDateObj = (formatOptions: any, date: any) => {
	if (formatOptions.isDayJSObject) {
		return date;
	} else {
		return dayjs(date);
	}
};
/* istanbul ignore next */
export const getDayMonthYear = (dateString: string) => {
	const dateParts = dateString?.split('-');

	const day = parseInt(dateParts?.[2], 10).toString().padStart(2, '0');
	const month = parseInt(dateParts?.[1], 10).toString().padStart(2, '0');
	const fullYear = parseInt(dateParts?.[0], 10).toString();
	const shortYear = fullYear.slice(-2);

	return { day, month, fullYear, shortYear };
};
/* istanbul ignore next */
export const getDateTimeZoneSpecific = (
	dateString: any,
	timeZone = 'America/New_York',
) => {
	if (checkInvalidValue(dateString)) {
		return TEXT_NULL_VALUE;
	}

	return new Date(dateString).toLocaleString('en-US', {
		timeZone,
	});
};

export const getDate = (date: Date, format: string) => {
	if (!date) {
		return TEXT_NULL_VALUE;
	}
	const day = date.getDate();
	const month = date.getMonth();
	const fullYear = date.getFullYear().toString().padStart(4, '0');
	switch (format) {
		case 'YYYY-MM-DD':
			return `${fullYear}-${month < 9 ? '0' : ''}${month + 1}-${
				day < 10 ? '0' : ''
			}${day}`;
		case 'MMMM D':
			return `${getFullMonth(month)} ${day}`;
		case 'MMMM D, YYYY':
			return `${getFullMonth(month)} ${day}, ${fullYear} `;
		default:
			return date.toLocaleString();
	}
};

export const getFullMonth = (month: number) => {
	switch (month) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		case 11:
			return 'December';
	}
};
