import { Label } from '../Label/Label';
import './loader.scss';

interface LoaderProps {
	size?: 'x-small' | 'small' | 'default' | 'large' | 'x-large';
	displayText?: string;
}

export const Loader = (props: LoaderProps) => {
	const { size = 'default', displayText } = props;

	return (
		<div className="loading-wrapper" id="loading-label">
			<div
				className={`progress-circular indeterminate size-${size}`}
				aria-describedby="loading-label"
				aria-busy={true}
			>
				<svg viewBox="0 0 45.714285714285715 45.714285714285715">
					<circle
						className="underlay"
						fill="transparent"
						cx="50%"
						cy="50%"
						r="20"
						strokeWidth="5.714285714285714"
						strokeDasharray="125.66370614359172"
						strokeDashoffset="0"
					></circle>
					<circle
						className="overlay"
						fill="transparent"
						cx="50%"
						cy="50%"
						r="20"
						strokeWidth="5.714285714285714"
						strokeDasharray="125.66370614359172"
						strokeDashoffset="125.66370614359172px"
					></circle>
				</svg>
			</div>
			{displayText && (
				<>
					<br /> <Label text={`${displayText}`}></Label>
				</>
			)}
		</div>
	);
};
