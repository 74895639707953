import React, { useState, useEffect } from 'react';
import OptionQuoteBar from './option-quote-bar/option-quote-bar';
import './option-quote-container.scss';
import {
	IPuBidOptionQuoteItem,
	IOptionProfile,
	OptionProfileHeader,
	OptionProfileMapping,
} from '../options-constants';
import OptionsSymbology from './../../shared/search/symbology/options/options-symbology';
import { LabelLink, Separator, Heading, Label } from './../../../@core-ui';
import Profile from './../../../shared/profile/profile';
import {
	IOptionQuoteItem,
	IUnderlyingQuote,
} from './../../../types/interfaces';
import {
	delayedMessage,
	handleEnterKeyPress,
	scrollToTop,
} from './../../../utilities/utils';
import { TEXT_NULL_VALUE, formatNumber } from './../../../utilities/formatter';
import {
	getDate,
	DATE_FORMATS,
} from './../../../utilities/date-time-formatter';
import { getQuotes } from '../../../services';
import { convertUtcDttmToET } from './../../../utilities/date-time-formatter-tz';

export interface OptionQuoteContainerProps {
	data: {
		item: IOptionQuoteItem;
		expiryDate: string;
		isCall: boolean;
		profileData: IOptionProfile;
		symbol: string;
		underlyingQuote?: IUnderlyingQuote;
	};
}

const OptionQuoteContainer: React.FC<OptionQuoteContainerProps> = ({
	data,
}) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const [delayedMinutes, setDelayedMinutes] = useState<number>();
	const { item, expiryDate, isCall, profileData, symbol, underlyingQuote } =
		data;
	const strike = formatNumber(item?.strikePrice)?.value;
	const expiryText = expiryDate
		? getDate(new Date(expiryDate), 'MMMM D')
		: TEXT_NULL_VALUE;

	useEffect(() => {
		scrollToTop();
	}, []);

	useEffect(() => {
		getQuoteData();
	}, [symbol]);

	const quoteData: IPuBidOptionQuoteItem = {
		lastTradePrice: item.quote?.lastTrade?.last,
		lastTradeTime: convertUtcDttmToET(underlyingQuote?.lastTrade?.date, {
			format: DATE_FORMATS.HOUR_MINUTE_SEC_ET_FORMAT,
		}),
		lastTradeDate: convertUtcDttmToET(underlyingQuote?.lastTrade?.date, {
			format: DATE_FORMATS.DATE_SHORT_YEAR_FORMAT,
		}),
		dollarChange: item.quote?.lastTrade?.change,
		percentChange: item.quote?.changePercent?.today,
		volume: item.quote?.volume?.last,
		bid: item.quote?.bid?.price,
		ask: item.quote?.ask?.price,
		openPrice: item.quote?.lastTrade?.open,
		openInterest: item.quote?.openInterest,
	};

	const getQuoteData = async () => {
		const quoteResponse = await getQuotes([item?.xid]);
		const delayMin =
			quoteResponse?.data?.data?.quotes[0]?.data?.quoteDelayMinutes;
		setDelayedMinutes(delayMin);
	};

	const toggle = () => {
		setIsExpanded(!isExpanded);
	};

	return (
		<div className="option-quote-container">
			<div className="option-quote-container-header">
				<Heading
					content="Options Quote"
					type={'h3'}
					testId="options-quote-header"
				/>
				<div className="quote-header-info">
					<div className="each-col">
						<Label
							fontType="neue-bold"
							text={`${symbol}  - &nbsp; `}
							isHtmlContent
							className="option-symbol-name"
							testId="option-symbol-name"
						/>
						<Label text={`${item?.xid}`} />
					</div>
					<div className="col-group">
						<div className="each-col">
							<Label text={`Expires:&nbsp; `} isHtmlContent />
							<Label fontType="neue-bold" text={expiryText} />
						</div>
						<div className="each-col">
							<Label text={`Strike Price:&nbsp; `} isHtmlContent />
							<Label text={strike} fontType="neue-bold" />
						</div>
						<div className="each-col">
							<Label text={`Type:&nbsp; `} isHtmlContent />
							<Label text={isCall ? 'Call' : 'Put'} fontType="neue-bold" />
						</div>
					</div>
				</div>
			</div>
			<OptionQuoteBar quoteData={quoteData} />
			<div className="symbology-toggle">
				<LabelLink
					testId={'show-hide-symbology'}
					onClick={toggle}
					text={
						isExpanded ? 'Hide  Options Symbology' : 'Show Options Symbology'
					}
					onKeyDown={(e) => handleEnterKeyPress(e, toggle)}
				/>
			</div>
			{isExpanded && <OptionsSymbology />}
			<Separator />
			<Profile
				profileData={profileData}
				header={OptionProfileHeader}
				mapping={OptionProfileMapping}
				numColumns={2}
				delayedText={delayedMessage(delayedMinutes)}
			/>
		</div>
	);
};

export default OptionQuoteContainer;
