import React, { useState, useEffect } from 'react';
import NoData from '../../shared/NoData/NoData';
import { getQuotes, DataRequest } from '../../services';
import URLS from '../../constants/urlConstants';
import './symbol-details-popover.scss';
import { ICompanyDetails } from '../../types/interfaces/ICompanyDetails';
import { Label, Heading, NumberLabel, Loader } from '../../@core-ui';
import {
	formatNumber,
	FormatType,
	getFormattedValuesInMeasures,
} from '../../utilities/formatter';
import { DATE_FORMATS } from '../../utilities/date-time-formatter';
import { convertUtcDttmToET } from '../../utilities/date-time-formatter-tz';

interface SymbolDetailsPropsType {
	venueXid: number;
}

const SymbolDetailsPopover = (props: SymbolDetailsPropsType) => {
	const [isLoading, setIsLoading] = useState(false);
	const [symbolData, setSymbolData] = useState<ICompanyDetails>();

	useEffect(() => {
		if (props.venueXid) {
			setIsLoading(true);
			props.venueXid && getXrefData(props.venueXid);
		}
	}, [props.venueXid]);

	const getXrefData = async (venueXid: number) => {
		const outputXref = await DataRequest.execute(
			`${URLS.PLATFORM.XREF_GET_BY_VENUEXID}`,
			{ params: { venueXids: venueXid } },
		);
		const responseData = outputXref?.data?.data?.items;
		getSymbolData(venueXid, responseData?.[0].symbol, responseData?.[0].name);
	};

	const getSymbolData = async (
		venueXid: number,
		symbol: string,
		description: string,
	) => {
		const response = await getQuotes([venueXid]);
		const responseData = response?.data?.data?.quotes[0]?.data;
		setSymbolData({
			venueXid: venueXid,
			symbol: symbol,
			name: description,
			last: responseData?.lastTrade?.last,
			change: responseData?.lastTrade?.change,
			changePercent: responseData?.changePercent?.today,
			volume: responseData?.volume?.last,
			dateTime: responseData?.lastTrade?.date,
		} as ICompanyDetails);
		setIsLoading(false);
	};

	const formattedVolume = (volume: string) => {
		if (volume && volume !== '--') {
			if (parseFloat(volume) > 0) {
				return formatNumber(
					`${getFormattedValuesInMeasures(
						volume?.toString(),
						FormatType.MILLION,
					)}`,
					{ precision: 2, postFixValue: 'M' },
				).value;
			} else {
				return formatNumber(volume)?.value;
			}
		} else {
			return '--';
		}
	};

	const getSymbolBody = () => {
		return (
			<div className="company-details" data-testid="symbolDetailsPopover">
				<Heading
					type="h4"
					content={`${symbolData?.name ?? '--'} - ${
						symbolData?.symbol ?? '--'
					}`}
				/>
				<div className="company-value">
					<div className="company-value-text">
						<NumberLabel value={formatNumber(`${symbolData?.last}`)?.value} />
					</div>
					<div className="company-value-text">
						<NumberLabel
							value={formatNumber(`${symbolData?.change}`)?.value}
							numberConfig={{
								showColor: true,
								currencyPreFixValue: '$',
							}}
						/>{' '}
						<NumberLabel
							value={formatNumber(`${symbolData?.changePercent}`)?.value}
							numberConfig={{
								showColor: true,
								postFixValue: '%',
								parentheses: true,
							}}
						/>
					</div>
					<div className="company-value-text">
						<Label text={formattedVolume(`${symbolData?.volume}`)} />
					</div>
				</div>
				<div className="company-key">
					<div className="company-value-text">Last</div>
					<div className="company-value-text">Change</div>
					<div className="company-value-text">Volume</div>
				</div>
				<div className="company-footer">
					<Label
						text={`As of ${convertUtcDttmToET(symbolData?.dateTime, {
							format: DATE_FORMATS.ET_DATE_TIME_FORMAT,
						})}`}
					/>
				</div>
			</div>
		);
	};

	const renderHTML = () => {
		if (isLoading) {
			return <Loader />;
		}

		if (!symbolData) {
			return <NoData />;
		}

		if (symbolData) {
			return getSymbolBody();
		}
	};

	return <div className={'symbol-container'}>{renderHTML()}</div>;
};

export default SymbolDetailsPopover;
