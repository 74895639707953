import React from 'react';
import { IBasicIconProps } from '../IBasicIconProps';

const WatchlistIcon: React.FC<IBasicIconProps> = ({
	width = 21,
	height = 20,
	color = '#2B6D9F',
}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 21 20"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
	>
		<defs>
			<path
				d="M6.82,6.82 C6.55,6.82 6.29,6.74 6.06,6.59 C5.84,6.44 5.66,6.23 5.56,5.98 C5.46,5.73 5.43,5.46 5.48,5.19 C5.53,4.93 5.66,4.68 5.85,4.49 C6.04,4.3 6.28,4.17 6.55,4.12 C6.81,4.07 7.09,4.09 7.34,4.2 C7.59,4.3 7.8,4.48 7.95,4.7 C8.1,4.92 8.18,5.19 8.18,5.46 C8.18,5.82 8.04,6.17 7.78,6.42 C7.52,6.68 7.18,6.82 6.82,6.82 L6.82,6.82 Z M6.82,2.73 C6.28,2.73 5.75,2.89 5.3,3.19 C4.85,3.49 4.5,3.92 4.3,4.41 C4.09,4.91 4.04,5.46 4.14,5.99 C4.25,6.52 4.51,7.01 4.89,7.39 C5.27,7.77 5.76,8.03 6.29,8.14 C6.82,8.25 7.37,8.19 7.87,7.98 C8.37,7.77 8.79,7.42 9.09,6.98 C9.39,6.53 9.55,6 9.55,5.46 C9.55,4.74 9.26,4.04 8.75,3.53 C8.24,3.02 7.54,2.73 6.82,2.73 L6.82,2.73 Z M6.82,9.55 C4.66,9.55 2.61,7.99 1.43,5.46 C2.62,2.93 4.66,1.36 6.82,1.36 C8.98,1.36 11.03,2.92 12.21,5.45 C11.03,7.98 8.98,9.54 6.82,9.54 L6.82,9.55 Z M13.59,5.18 C12.21,1.99 9.62,0 6.82,0 C4.02,0 1.43,1.99 0.06,5.18 C0.02,5.27 0,5.36 0,5.45 C0,5.54 0.02,5.64 0.06,5.72 C1.44,8.92 4.03,10.9 6.83,10.9 C9.63,10.9 12.22,8.91 13.6,5.72 C13.64,5.63 13.66,5.54 13.66,5.45 C13.66,5.36 13.64,5.26 13.6,5.18 L13.59,5.18 Z"
				id="path-1"
			></path>
		</defs>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g transform="translate(-137.000000, -745.000000)">
				<g transform="translate(0.000000, 730.000000)">
					<g transform="translate(137.000000, 11.000000)">
						<g id="Watchlist-Icon" transform="translate(0.000000, 4.000000)">
							<g id="Clipped">
								<mask id="mask-2" fill="white">
									<use xlinkHref="#path-1"></use>
								</mask>
								<g id="Shape"></g>
								<rect
									fill={color}
									fillRule="nonzero"
									mask="url(#mask-2)"
									x="-0.68"
									y="-0.68"
									width="15.01"
									height="12.28"
								></rect>
							</g>
							<path
								d="M20.35,13.04 L1.02,13.04 C0.71,13.04 0.45,12.79 0.45,12.47 C0.45,12.15 0.7,11.9 1.02,11.9 L20.35,11.9 C20.66,11.9 20.92,12.15 20.92,12.47 C20.92,12.79 20.67,13.04 20.35,13.04 Z"
								id="Path"
								fill={color}
								fillRule="nonzero"
							></path>
							<path
								d="M20.26,16.16 L0.92,16.16 C0.61,16.16 0.35,15.91 0.35,15.59 C0.35,15.27 0.6,15.02 0.92,15.02 L20.25,15.02 C20.56,15.02 20.82,15.27 20.82,15.59 C20.82,15.91 20.57,16.16 20.25,16.16 L20.26,16.16 Z"
								id="Path"
								fill={color}
								fillRule="nonzero"
							></path>
							<path
								d="M20.35,19.27 L1.02,19.27 C0.71,19.27 0.45,19.02 0.45,18.7 C0.45,18.38 0.7,18.13 1.02,18.13 L20.35,18.13 C20.66,18.13 20.92,18.38 20.92,18.7 C20.92,19.02 20.67,19.27 20.35,19.27 Z"
								id="Path"
								fill={color}
								fillRule="nonzero"
							></path>
							<path
								d="M20.35,10.12 L13.65,10.12 C13.34,10.12 13.08,9.87 13.08,9.55 C13.08,9.23 13.33,8.98 13.65,8.98 L20.35,8.98 C20.66,8.98 20.92,9.23 20.92,9.55 C20.92,9.87 20.67,10.12 20.35,10.12 Z"
								id="Path"
								fill={color}
								fillRule="nonzero"
							></path>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default WatchlistIcon;
