import React, { useState, useEffect } from 'react';
import {
	DEFAULT_CHARTS_PRICE_OBJ,
	CHART_PRICE_TEST_ID,
} from './ChartsPriceConstants';
import { DataRequest } from '../../services';
import URLS from '../../constants/urlConstants';
import { Loader } from '../../@core-ui';
import NoData from '../NoData/NoData';
import { ERROR_MESSAGES, E_HTTP_METHOD } from '../../constants/appConstants';
import './ChartsPrice.scss';

interface ChartsPricePropsType {
	inputObj?: { [key: string]: any };
	chartHandler?: (data: any) => void;
	hideXAxis?: boolean;
}

const ChartsPrice = (props: ChartsPricePropsType) => {
	const { hideXAxis = false } = props;
	const [isLoading, setIsLoading] = useState(true);
	const [chartsPriceData, setChartsPriceData] = useState<any>(null);

	useEffect(() => {
		setIsLoading(true);
		getChartsData();
	}, [props.inputObj]);

	const getChartsData = async () => {
		let dataInput: any = DEFAULT_CHARTS_PRICE_OBJ;
		if (props.inputObj && Object.keys(props.inputObj).length) {
			dataInput = { ...dataInput, ...props.inputObj };
		}
		const response: any = await DataRequest.execute(
			URLS.PLATFORM.CHARTS_PRICE,
			{
				method: E_HTTP_METHOD.POST,
				postData: dataInput,
			},
			{ 'Content-type': 'application/json; charset=UTF-8' },
		);

		if (response?.data) {
			const imageData = response?.data?.data?.image;
			if (imageData) {
				setChartsPriceData(imageData);
			}
		} else {
			setChartsPriceData(response);
		}
		props.chartHandler && props.chartHandler(response.data);
		setIsLoading(false);
	};

	const height = `${props.inputObj?.panels?.[0]?.height}px`;
	const styleChart: any = {
		height: height || 'auto',
	};

	const getNoData = () => {
		return (
			<div className={'no-data-container'}>
				<NoData
					text={ERROR_MESSAGES.CHART_NOT_AVAILABLE}
					error={chartsPriceData?.error}
				/>
			</div>
		);
	};

	const getChartBody = () => {
		return hideXAxis ? (
			<div
				data-testid={CHART_PRICE_TEST_ID}
				style={{
					height: '75px',
					background: `url(${chartsPriceData}) 0 0`,
				}}
			></div>
		) : (
			<img
				src={chartsPriceData}
				alt="chart-price"
				data-testid={CHART_PRICE_TEST_ID}
			/>
		);
	};

	const getChartHTML = () => {
		return chartsPriceData && !chartsPriceData.error
			? getChartBody()
			: getNoData();
	};

	return (
		<div className={'chart'} {...(!hideXAxis && { style: styleChart })}>
			{!isLoading ? getChartHTML() : <Loader />}
		</div>
	);
};

export default ChartsPrice;
