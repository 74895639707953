import React, { useState } from 'react';
import './render-news.scss';
import { LabelLink, Label } from '../../../@core-ui';
import ViewNewsModal from '../../../shared/view-news/view-news-modal';
import { handleEnterKeyPress } from '../../../utilities/utils';
interface NewsContentProps {
	newsData?: [];
}
const RenderNews: React.FC<NewsContentProps> = (props) => {
	const { newsData } = props;
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [modalData, setModalData] = useState<any>();
	function bindProvider(item: any): React.ReactNode {
		return (
			<React.Fragment>
				<Label isGrey={true} text={`${item.provider}`} isBold={true} />
				<span className={'dividerProvider'}>|</span>
				<Label isGrey={true} text={`${item.timestamp}`} />
			</React.Fragment>
		);
	}

	function showNewsArticle(item: any) {
		setModalData(item);
		setIsModalOpen(true);
		document.body.style.overflowY = 'hidden';
	}

	function hideNewsArticle() {
		setIsModalOpen(false);
		document.body.style.overflowY = 'auto';
	}

	function bindNews(item: any) {
		return (
			<div className={'news-content'} key={`news ${item.articleId}`}>
				<div className="headline">
					<LabelLink
						text={item.headline}
						dataKey={item.articleId}
						onClick={() => showNewsArticle(item)}
						onKeyDown={(e) =>
							handleEnterKeyPress(e, () => showNewsArticle(item))
						}
					/>
				</div>
				<div className="provider">{bindProvider(item)}</div>
			</div>
		);
	}

	function bindNewsContent(): any {
		return newsData?.map((item: any) => {
			return bindNews(item);
		});
	}

	return (
		<div
			className="news-content-container"
			data-testid="news-content-container"
		>
			{bindNewsContent()}
			{isModalOpen && (
				<ViewNewsModal
					articleId={modalData?.articleId}
					provider={modalData?.provider}
					timeStamp={modalData?.timestamp}
					onClose={hideNewsArticle}
				/>
			)}
		</div>
	);
};

export default RenderNews;
