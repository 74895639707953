import React from 'react';
import { IBasicIconProps } from '../IBasicIconProps';

const AscendingArrowIcon: React.FC<IBasicIconProps> = ({
	width = 16,
	height = 12,
}) => (
	<svg xmlns="http://www.w3.org/2000/svg"
		width={width}
		height={height}
		viewBox="0 0 7 15"
		version="1.1">
		<title>Ascending Icon</title>
		<g id="icon-asc" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="sort-icon–-asc" transform="translate(0.7165, 0.8353)" fill="#2B6D9F" fillRule="nonzero">
				<path d="M6.10235303,3.73882359 C5.87176479,3.98588241 5.49294126,3.98588241 5.26235302,3.73882359 L3.73058829,2.1411765 L3.73058829,12.7647061 C3.73058829,13.0941178 3.46705888,13.3576473 3.13764711,13.3576473 C2.80823534,13.3576473 2.54470592,13.0941178 2.54470592,12.7647061 L2.54470592,2.1411765 L1.01294119,3.73882359 C0.782352953,3.98588241 0.403529418,3.98588241 0.172941179,3.73882359 C-0.0576470597,3.49176476 -0.0576470597,3.09647063 0.172941179,2.84941181 L2.66000004,0.230588239 C2.66000004,0.230588239 2.66000004,0.230588239 2.67647063,0.21411765 L2.70941181,0.181176473 C2.70941181,0.181176473 2.70941181,0.181176473 2.70941181,0.181176473 C2.80823534,0.0658823539 2.95647063,0 3.12117652,0 C3.2858824,0 3.4341177,0.0658823539 3.54941182,0.181176473 C3.54941182,0.181176473 3.54941182,0.181176473 3.54941182,0.181176473 C3.54941182,0.181176473 3.54941182,0.181176473 3.54941182,0.181176473 C3.54941182,0.181176473 3.54941182,0.181176473 3.54941182,0.181176473 L6.08588244,2.84941181 C6.33294127,3.09647063 6.33294127,3.49176476 6.10235303,3.73882359 Z" id="Path"/>
			</g>
		</g>
	</svg>
);

export default AscendingArrowIcon;
