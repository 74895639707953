import URLS from '../constants/urlConstants';
import { resolvePromiseWithError } from '../utilities/utils';
import {
	IOptionChainWithQuotes,
	IOptionExpiryDatesResponse,
	ISingleOptionInputs,
	ISingleOptionResponse,
} from '../types/interfaces';
import DataRequest from './dataRequest';
import {
	E_OptionChainDisplayDropdownOptions,
	E_AdjustmentOption,
	E_ContractType,
} from '../components/options/options-constants';
import { E_HTTP_METHOD } from '../constants/appConstants';

export interface IOptionChainParams {
	venueXid: number;
	expirationDates: string[];
	callsPuts?: E_OptionChainDisplayDropdownOptions;
	adjustment?: E_AdjustmentOption;
	strikeCount?: number | string;
	contractTerm?: E_ContractType;
	realTimeQuotes?: boolean;
	preMarketClear?: boolean;
	quoteReportIdentifier?: string;
}

export const getOptionChainWithQuotes = async (
	params: IOptionChainParams,
): Promise<IOptionChainWithQuotes> => {
	const {
		venueXid,
		expirationDates,
		callsPuts = E_OptionChainDisplayDropdownOptions.CallsAndPuts,
		adjustment = E_AdjustmentOption.All,
		strikeCount = 20,
		contractTerm = E_ContractType.All,
		realTimeQuotes = false,
		preMarketClear = true,
		quoteReportIdentifier = '',
	} = params;

	if (!venueXid || !expirationDates.length) {
		return await resolvePromiseWithError();
	}

	let callPutsArg =
		callsPuts === E_OptionChainDisplayDropdownOptions.CallsAndPuts
			? E_OptionChainDisplayDropdownOptions.CallsAndPuts
			: E_OptionChainDisplayDropdownOptions.CallsOnly;
	if (
		[
			E_OptionChainDisplayDropdownOptions.ITMPuts,
			E_OptionChainDisplayDropdownOptions.OTMPuts,
			E_OptionChainDisplayDropdownOptions.PutsOnly,
		].includes(callsPuts)
	) {
		callPutsArg = E_OptionChainDisplayDropdownOptions.PutsOnly;
	}

	return await DataRequest.execute(
		`${URLS.PLATFORM.OPTIONS_CHAIN_WITH_QUOTES}/${venueXid}`,
		{
			params: {
				callsPuts: callPutsArg,
				expirationDates,
				adjustment,
				strikeCount,
				realTimeQuotes,
				preMarketClear,
				contractTerm,
				quoteReportIdentifier,
			},
		},
	);
};

export const getOptionsExpirationDates = async (
	venueXid: number,
): Promise<IOptionExpiryDatesResponse> => {
	if (!venueXid) {
		return await resolvePromiseWithError();
	}
	return await DataRequest.execute(
		`${URLS.PLATFORM.OPTION_CHAINS_EXPIRATION_DATES}/${venueXid}`,
		{
			params: {
				contractTerm: E_ContractType.All,
			},
		},
	);
};

export const getSingleOptionWithGreeks = async (
	inputs: ISingleOptionInputs,
): Promise<ISingleOptionResponse> => {
	const data = {
		'option.isPut': inputs.isPut,
		'option.price': inputs.optionPrice,
		'option.strikePrice': inputs.strikePrice,
		'underlying.price': inputs.underlyingOptionPrice,
		tradeDate: inputs.tradeDate,
		'option.expirationDate': inputs.expirationDate,
	};
	return await DataRequest.execute(
		`${URLS.PLATFORM.SINGLE_OPTION_WITH_GREEKS}`,
		{
			method: E_HTTP_METHOD.POST,
			postData: data,
		},
	);
};
