import React, { useState } from 'react';
import './news-map.scss';
import { Collapsible } from '../../../@core-ui';
import {
	News_Category,
	News_Correlate,
	News_Time_Period,
	News_Type,
	News_Map,
} from './news-map-constants';
import { getDeviceType } from '../../../utilities/utils';
import NewsContainer from './news-chart-container/news-container';
import { E_DeviceType } from '../../../constants/appConstants';

interface Option {
	type: string;
	category: string;
	duration: string;
	correlate: string;
}

const NewsMap: React.FC = () => {
	const isMobile = getDeviceType() === E_DeviceType.Mobile;
	const [options, setOptions] = useState<Option>({
		type: News_Type.Economic,
		category: News_Category.All,
		duration: isMobile ? News_Time_Period.Month : News_Time_Period.Day,
		correlate: News_Correlate.NYSE,
	});

	const getContent = () => {
		return (
			<div className="news-map-container" data-testid={'news-map-container'}>
				<NewsContainer
					setOptions={setOptions}
					isMobile={isMobile}
					selectedType={options.type}
					timeFrame={options.duration}
					selectedCategory={options.category}
					selectedExchange={options.correlate}
				/>
			</div>
		);
	};

	return isMobile ? (
		<div className={'news-map-mobile'}>
			<Collapsible title={News_Map} open={true}>
				{getContent()}
			</Collapsible>
		</div>
	) : (
		getContent()
	);
};

export default NewsMap;
