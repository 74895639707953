import React, { useState, useEffect, useRef } from 'react';
import {
	Button,
	ButtonVariant,
	LabelLink,
	Popover,
	MultiSelect,
	SingleSelect,
	ErrorMessageDialog,
} from '../../../../../@core-ui';
import {
	CHART_TIMEFRAMES,
	CHART_TIMEFRAMES_MOBILE,
	EVENTS_TYPE,
	CHART_TYPE,
	OVERLAY_OPTIONS,
	CHART_TYPE_ERROR,
	DUPLICATE_CHART_ERROR,
} from '../../indices-charts-constants';
import './chart-settings.scss';
import {
	PlusIcon,
	CandlestickIcon,
	LineChartIcon,
	MountainChartIcon,
	HLCChartIcon,
	NewsIcon,
	SplitsIcon,
	DividendsIcon,
} from '../../../../../assets/Icons';
import OverlayPopover from '../overlay/overlay-popover';
import OverlayModal from '../overlay/overlay-modal';
import {
	E_DeviceType,
	T_DeviceType,
} from '../../../../../constants/appConstants';
import {
	handleEnterKeyPress,
	getDeviceType,
	handleWindowResize,
	toggleAttachedEvent,
	removeAttachedEvent,
} from '../../../../../utilities/utils';
import SymbolSearchModal from '../../../../shared/search/symbol-search-modal/symbol-search-modal';
import { IOverlay, ISymbolComparison } from '../../../../../types/interfaces';
import {
	ComparisonType,
	IChartError,
} from '../../../../../types/interfaces/IChartSettings';
import { isDuplicateComparison } from '../charts-utils';

const ChartSettings = (props: any) => {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const [isOverlayOpen, setIsOverlayOpen] = useState(false);
	const [selectedOverlay, setSelectedOverlay] = useState('');
	const [selectedOverlayData, setSelectedOverlayData] = useState<IOverlay>();
	const [selectedChartType, setSelectedChartType] = useState(CHART_TYPE[0].key);
	const [selectedEvents, setSelectedEvents] = useState<any[]>([]);
	const [isHideSettings, setIsHideSettings] = useState(false);
	const [selectedTimeFrame, setSelectedTimeFrame] = useState(
		CHART_TIMEFRAMES[5].key,
	);
	const [selectedEventsText, setSelectedEventsText] = useState('');
	const ref = useRef(null);
	const wrapperRef = useRef<any>();
	const [device, setDevice] = useState<T_DeviceType>(getDeviceType());
	const [comparisonList, setComparisonList] = useState<ISymbolComparison[]>([]);
	const [showSymbolSearchPopup, setShowSymbolSearchPopup] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [comparisonErrorDialog, setComparisonErrorDialog] = useState({
		isError: false,
		message: '',
	} as IChartError);
	const isMobile = device === E_DeviceType.Mobile;

	useEffect(() => {
		removeComparison(props.removeComparisonItem);
	}, [props.removeComparisonItem]);

	const searchSymbolPopupHandler = () => {
		setShowSymbolSearchPopup(true);
	};

	const onClose = () => {
		setSearchValue('');
		setShowSymbolSearchPopup(false);
	};

	useEffect(() => {
		if (isPopoverOpen) {
			toggleAttachedEvent('click', handleClickOutside);
		} else {
			removeAttachedEvent('click', handleClickOutside);
		}
	}, [isPopoverOpen]);

	useEffect(() => {
		const handleResize = () => {
			const deviceType = getDeviceType();
			setDevice(deviceType);
		};
		handleWindowResize(handleResize);
	}, []);

	const toggleSettings = () => {
		setIsHideSettings(!isHideSettings);
	};

	const togglePopover = (e?: any) => {
		e?.stopPropagation();
		toggleAttachedEvent('click', handleClickOutside);
		setIsPopoverOpen(true);
	};

	const togglePopoverBlur = () => {
		removeAttachedEvent('click', handleClickOutside);
		setIsPopoverOpen(false);
	};

	const getSelectedOverlay = (overlayData: string) => {
		setSelectedOverlay(overlayData);
		setIsPopoverOpen(false);
		setIsOverlayOpen(true);
	};

	const closeOverlay = () => {
		setSelectedOverlay('');
		setIsPopoverOpen(false);
		setIsOverlayOpen(false);
	};

	useEffect(() => {
		setSelectedTimeFrame(props.timeFrame);
		setSelectedChartType(props.chartType);
		setSelectedEvents(props.chartEvents);
		setComparisonList(props.comparisonList);
	}, [
		props.timeFrame,
		props.chartType,
		JSON.stringify(props.chartEvents),
		JSON.stringify(props.comparisonList),
	]);

	useEffect(() => {
		if (!props.isDefault) {
			setSelectedTimeFrame(CHART_TIMEFRAMES[5].key);
			setSelectedChartType(CHART_TYPE[0].key);
			setSelectedEvents([]);
			setComparisonList([]);
		}
	}, [props.venueXid]);

	useEffect(() => {
		let data = 'event';
		selectedEvents?.forEach((item: any) => {
			data += item?.id.toLowerCase() + ',';
		});
		setSelectedEventsText(data);
	}, [JSON.stringify(selectedEvents)]);

	useEffect(() => {
		props.getChartSetting(
			selectedChartType,
			selectedTimeFrame,
			selectedEvents,
			selectedOverlayData,
			comparisonList,
		);
	}, [
		selectedChartType,
		selectedTimeFrame,
		selectedEvents,
		selectedOverlayData,
		comparisonList,
	]);

	const CHART_EVENTS = [
		{
			key: EVENTS_TYPE.NEWS,
			value: 'News',
			icon: <NewsIcon />,
		},
		{
			key: EVENTS_TYPE.DIVIDENDS,
			value: 'Dividends',
			icon: <DividendsIcon />,
		},
		{
			key: EVENTS_TYPE.SPLITS,
			value: 'Splits',
			icon: <SplitsIcon />,
		},
	];

	const CHART_STYLE = [
		{
			key: 'line',
			value: 'Line Chart',
			icon: LineChartIcon,
		},
		{
			key: 'candlestick',
			value: 'Candlestick ',
			icon: CandlestickIcon,
		},
		{
			key: 'fill',
			value: 'Mountain Chart',
			icon: MountainChartIcon,
		},
		{
			key: 'hlc',
			value: 'HLC',
			icon: HLCChartIcon,
		},
	];

	function handleClickOutside(e: any) {
		if (isPopoverOpen && !wrapperRef?.current?.contains(e.target)) {
			togglePopoverBlur();
		}
	}

	const changeTimeFrameHandler = (dataValue: string) => {
		setSelectedTimeFrame(parseInt(dataValue));
	};

	const changeTimeFrameMobileHandler = (event: any) => {
		const dataValue = event.getAttribute('data-key');
		setSelectedTimeFrame(parseInt(dataValue));
	};

	const onChartTypeMobileHandler = (event: any) => {
		const dataValue = event.getAttribute('data-key');
		onChartTypeHandler(dataValue);
	};

	const onOverlayMobileHandler = (event: any) => {
		const dataValue = event.getAttribute('data-key');
		setSelectedOverlay(dataValue);
		setIsOverlayOpen(true);
	};

	const renderEvents = () => {
		if ((!isHideSettings && isMobile) || device !== E_DeviceType.Mobile) {
			return (
				<MultiSelect
					data={CHART_EVENTS}
					onChangeHandler={onEventsHandler}
					id="chart-indices-dropdown"
					displayName="Events"
					dataTestId="chart-indices-dropdown"
					selectedEventsText={selectedEventsText}
				/>
			);
		}
	};
	const onChartTypeHandler = (chartType: string) => {
		if (
			comparisonList.length > 0 &&
			chartType !== CHART_TYPE[0].key &&
			chartType !== CHART_TYPE[2].key &&
			props.isEnabledSaveSettings
		) {
			setComparisonErrorDialog({
				isError: true,
				message: CHART_TYPE_ERROR,
			});
		} else {
			setSelectedChartType(chartType);
		}
	};

	const onEventsHandler = (events: any) => {
		const eventsList: any = [];
		const value = events;
		value.forEach((item: any) => {
			if (item) {
				eventsList.push({ id: item.toLowerCase() });
			}
		});
		setSelectedEvents(eventsList);
	};

	const updateOverlayData = (overlayName: string, overlayPeriod: number) => {
		setIsPopoverOpen(false);
		setIsOverlayOpen(false);
		if (
			!isDuplicateComparison(
				comparisonList,
				overlayName,
				ComparisonType.UpperIndicator,
			)
		) {
			if (props.isEnabledSaveSettings) {
				if (
					selectedChartType === CHART_TYPE[0].key ||
					selectedChartType === CHART_TYPE[2].key
				) {
					saveComparisonState(overlayName, overlayPeriod);
				} else {
					setComparisonErrorDialog({
						isError: true,
						message: CHART_TYPE_ERROR,
					});
				}
			} else {
				saveComparisonState(overlayName, overlayPeriod);
			}
		} else {
			setComparisonErrorDialog({
				isError: true,
				message: DUPLICATE_CHART_ERROR,
			});
		}
	};

	const saveComparisonState = async (overlayName: any, overlayPeriod: any) => {
		setSelectedOverlayData({
			name: overlayName,
			period: overlayPeriod,
		});
		setComparisonList((prevState) => [
			...prevState,
			{
				name: overlayName,
				type: ComparisonType.UpperIndicator,
				period: overlayPeriod,
			},
		]);
	};

	const onAddSymbol = async (item: any) => {
		if (
			!isDuplicateComparison(
				comparisonList,
				item.xids.venue,
				ComparisonType.Symbol,
				props.venueXid,
			)
		) {
			if (
				selectedChartType === CHART_TYPE[0].key ||
				selectedChartType === CHART_TYPE[2].key
			) {
				setComparisonList((prevState) => [
					...prevState,
					{
						name: item.symbol,
						type: ComparisonType.Symbol,
						venueXid: item.xids.venue,
					},
				]);
			} else {
				setComparisonErrorDialog({
					isError: true,
					message: CHART_TYPE_ERROR,
				});
			}
		} else {
			setComparisonErrorDialog({
				isError: true,
				message: DUPLICATE_CHART_ERROR,
			});
		}
	};

	const removeComparison = (comparisonKey: string) => {
		setComparisonList((prevState) => {
			return prevState?.filter((i) => i.name !== comparisonKey);
		});
	};

	const restoreChart = () => {
		closeOverlay();
		setSelectedEvents([]);
		setComparisonList([]);
		props.handleResetChart();
	};

	const mobileEventSettingsClassname = () => {
		if (isMobile) {
			if (!isHideSettings) {
				return 'chart-events-settings-controls';
			} else {
				return 'chart-events-ddl';
			}
		}
		return 'chart-events-settings';
	};

	const closeComparisonErrorDialog = () => {
		setComparisonErrorDialog({ isError: false, message: '' });
	};

	const chartTimeFrameHandler = (item: any) => {
		return (
			<LabelLink
				size={'m'}
				testId={`time-frame-${item.value}`}
				key={`time-frame-${item.value}`}
				text={`${item.value}`}
				dataKey={item.value}
				className={selectedTimeFrame == item.key ? 'labelLink-active' : ''}
				onClick={() => {
					changeTimeFrameHandler(item.key);
				}}
				onKeyDown={(event) => {
					handleEnterKeyPress(event, () => changeTimeFrameHandler(item.key));
				}}
			/>
		);
	};

	const getDesktopview = () => {
		return (
			<React.Fragment>
				<div className="chart-time-frames" id="chartTimeFramesContainer">
					{CHART_TIMEFRAMES.map((item: any) => chartTimeFrameHandler(item))}
				</div>
				<div className="chart-types" id="chartTypesDropdown">
					<Button
						testId={`line-button`}
						title={'Line'}
						variant={ButtonVariant.Secondary}
						onClick={() => {
							onChartTypeHandler(CHART_TYPE[0].key);
						}}
						onKeyDown={(event) => {
							handleEnterKeyPress(event, () => {
								onChartTypeHandler(CHART_TYPE[0].key);
							});
						}}
						className={`${
							selectedChartType == CHART_TYPE[0].key ? 'active-chart-type' : ''
						}`}
					>
						<LineChartIcon
							color={
								selectedChartType == CHART_TYPE[0].key ? '#2B6D9F' : '#000000'
							}
						/>
					</Button>
					<Button
						testId={`candlestick-button`}
						variant={ButtonVariant.Secondary}
						title={'Candlestick'}
						onClick={() => {
							onChartTypeHandler(CHART_TYPE[1].key);
						}}
						onKeyDown={(event) => {
							handleEnterKeyPress(event, () => {
								onChartTypeHandler(CHART_TYPE[1].key);
							});
						}}
						className={`${
							selectedChartType == CHART_TYPE[1].key ? 'active-chart-type' : ''
						}`}
					>
						<CandlestickIcon
							color={
								selectedChartType == CHART_TYPE[1].key ? '#2B6D9F' : '#000000'
							}
						/>
					</Button>
					<Button
						testId={`mountain-button`}
						variant={ButtonVariant.Secondary}
						title={'Mountain'}
						onClick={() => {
							onChartTypeHandler(CHART_TYPE[2].key);
						}}
						onKeyDown={(event) => {
							handleEnterKeyPress(event, () => {
								onChartTypeHandler(CHART_TYPE[2].key);
							});
						}}
						className={`${
							selectedChartType == CHART_TYPE[2].key ? 'active-chart-type' : ''
						}`}
					>
						<MountainChartIcon
							color={
								selectedChartType == CHART_TYPE[2].key ? '#2B6D9F' : '#000000'
							}
						/>
					</Button>
					<Button
						testId={`hlc-chart-button`}
						variant={ButtonVariant.Secondary}
						title={'Open-High-Low-Close'}
						onClick={() => {
							onChartTypeHandler(CHART_TYPE[3].key);
						}}
						onKeyDown={(event) => {
							handleEnterKeyPress(event, () => {
								onChartTypeHandler(CHART_TYPE[3].key);
							});
						}}
						className={`${
							selectedChartType == CHART_TYPE[3].key ? 'active-chart-type' : ''
						}`}
					>
						<HLCChartIcon
							color={
								selectedChartType == CHART_TYPE[3].key ? '#2B6D9F' : '#000000'
							}
						/>
					</Button>
				</div>
			</React.Fragment>
		);
	};
	const renderBody = () => {
		return (
			<React.Fragment>
				<div className="chart-settings">
					{isMobile ? (
						<div className="chart-settings-list">
							<div className={'chart-settings-controls'}>
								<LabelLink
									size={'m'}
									testId={`settings-controls`}
									key={`settings-controls`}
									text={`${isHideSettings ? 'Show Controls' : 'Hide Controls'}`}
									dataKey={`settings-controls`}
									onClick={() => {
										toggleSettings();
									}}
									onKeyDown={(e) => handleEnterKeyPress(e, toggleSettings)}
								/>
							</div>

							{!isHideSettings && (
								<React.Fragment>
									<div className="chart-settings-time-frames">
										<SingleSelect
											data={CHART_TIMEFRAMES_MOBILE}
											changeHandler={changeTimeFrameMobileHandler}
											id="chart-settings-time-dropdown"
											selectedValue={selectedTimeFrame}
											dataTestId="chart-settings-time-dropdown"
											displayName="Timeframe"
										/>
									</div>
									<div className="chart-settings-type">
										<SingleSelect
											data={CHART_STYLE?.map((e: any) => {
												return {
													key: e.key,
													value: e.value,
													icon: e.icon,
												};
											})}
											changeHandler={onChartTypeMobileHandler}
											id="chart-settings-type-dropdown"
											selectedValue={selectedChartType}
											dataTestId="chart-settings-type-dropdown"
											displayName="Chart Style"
											isSelectedIcon
										/>
									</div>
									<div className="chart-settings-overlay">
										<SingleSelect
											data={OVERLAY_OPTIONS}
											changeHandler={onOverlayMobileHandler}
											id="chart-settings-overlay-dropdown"
											selectedValue={selectedOverlay}
											dataTestId="chart-settings-overlay-dropdown"
											displayName="Add Overlay"
											hideCheck
										/>
									</div>
									{props.isCompareEnabled && (
										<Button
											onClick={() => searchSymbolPopupHandler()}
											variant={ButtonVariant.Secondary}
											ref={ref}
										>
											Compare
											<PlusIcon color={'#2B6D9F'} />
										</Button>
									)}
								</React.Fragment>
							)}
						</div>
					) : (
						getDesktopview()
					)}
				</div>

				<div className={`${mobileEventSettingsClassname()}`}>
					{device !== E_DeviceType.Mobile && (
						<div className="chart-events" ref={wrapperRef}>
							<Button
								onClick={(e) => togglePopover(e)}
								variant={ButtonVariant.Secondary}
								ref={ref}
								testId="btnAddOverlay"
							>
								<PlusIcon />
								Add Overlay
							</Button>
							{props.isCompareEnabled && (
								<Button
									onClick={() => searchSymbolPopupHandler()}
									variant={ButtonVariant.Secondary}
									ref={ref}
									testId="btnCompare"
								>
									<PlusIcon />
									Compare
								</Button>
							)}
							{isPopoverOpen && (
								<Popover
									id="popoverOverlay"
									closePopover={togglePopover}
									body={
										<OverlayPopover
											getSelectedOverlay={getSelectedOverlay}
											closeOverlayPopover={togglePopoverBlur}
										/>
									}
									referenceElement={ref.current}
									placement={'auto'}
								/>
							)}
						</div>
					)}
					{isOverlayOpen && (
						<OverlayModal
							updateOverlayData={updateOverlayData}
							overlayName={selectedOverlay}
							restoreChart={restoreChart}
							closeOverlay={closeOverlay}
						/>
					)}
					{props.isEnabledSaveSettings && renderEvents()}
				</div>
				{comparisonErrorDialog.isError && (
					<ErrorMessageDialog
						title={'Compare'}
						message={comparisonErrorDialog.message}
						onClose={closeComparisonErrorDialog}
						isPrimaryVariant={true}
						id="comparisonErrorDialog"
					/>
				)}

				{showSymbolSearchPopup && (
					<SymbolSearchModal
						incorrectSymbol={searchValue}
						onClose={onClose}
						onAddSymbol={onAddSymbol}
						title={'Compare'}
					/>
				)}
			</React.Fragment>
		);
	};
	return renderBody();
};

export default ChartSettings;
