import React, { useState, useEffect } from 'react';
import './most-recent-news.scss';
import { getNews } from '../../../services';
import { Heading, Loader } from '../../../@core-ui';
import RenderNews from '../render-news/render-news';
import { NoData } from '../../../shared';
import {
	MOST_RECENT_NEWS,
	buildNewsInputs,
} from './most-recent-news-constants';
import { prepareNewsData } from '../../quotes-news/news/news-utils';

type mostRecentNewsProps = {
	symbol: string;
};

const MostRecentNews: React.FC<mostRecentNewsProps> = ({ symbol }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [newsData, setNewsData] = useState<any>();

	useEffect(() => {
		setIsLoading(false);
		getNewsData();
	}, [symbol]);

	const getNewsData = async () => {
		setIsLoading(true);
		const newsInputs = buildNewsInputs(symbol);
		const response = await getNews(newsInputs);
		const newsArray = response?.data?.data?.items;
		if (newsArray && newsArray.length > 0) {
			setNewsData([...prepareNewsData(newsArray)]);
			setIsLoading(false);
		} else {
			setNewsData(response);
			setIsLoading(false);
		}
	};

	const getRenderNews = () => {
		return newsData?.length > 0 ? (
			<RenderNews newsData={newsData} />
		) : (
			<NoData error={newsData?.error} />
		);
	};

	return (
		<div className={'most-recent-container'} data-testid="most-recent-news">
			<div className="component-header">
				<Heading content={MOST_RECENT_NEWS} type={'h2'} />
			</div>

			<div className={'recent-news'}>
				{isLoading ? (
					<div className={'loader-container'}>
						<Loader />
					</div>
				) : (
					getRenderNews()
				)}
			</div>
		</div>
	);
};

export default MostRecentNews;
