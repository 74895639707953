import { IAPIResponse } from '../types';

import {
	ACCESS_TOKEN_EXPIRE_TIME,
	ACCESS_TOKEN_KEY,
	E_DeviceType,
	T_DeviceType,
} from '../constants/appConstants';

export const loadScript = (
	id: string,
	src: string,
	parent = document.body,
	data: any = {},
	async = false,
) => {
	if (document.getElementById(id) != null) {
		return;
	}

	const script = document.createElement('script');
	script.setAttribute('id', id);
	script.setAttribute('src', src);
	script.setAttribute('type', 'text/javascript');

	if (async) {
		script.setAttribute('async', '');
	}

	Object.keys(data).forEach((key) => (script.dataset[key] = data[key]));

	parent.appendChild(script);

	return script;
};

export const loadStyle = (id: string, href: string, parent = document.head) => {
	if (document.getElementById(id) != null) {
		return;
	}

	const styleElement = document.createElement('link');

	styleElement.setAttribute('id', id);
	styleElement.setAttribute('rel', 'stylesheet');
	styleElement.setAttribute('type', 'text/css');
	styleElement.setAttribute('media', 'all');
	styleElement.setAttribute('href', href);

	parent.appendChild(styleElement);
	return styleElement;
};

export const isInternalEnv = (): boolean => {
	let isInternalEnv = false;
	const host = window.location.host;

	if (host.includes('localhost:') || host.includes('.services.mdgapp.net'))
		isInternalEnv = true;

	return isInternalEnv;
};

export const resolvePromiseWithError = async (
	error: any = null,
	requestInputs: any = null,
): Promise<IAPIResponse> => {
	return await Promise.resolve({
		data: null,
		error,
		requestInputs,
	});
};

export const resolvePromiseWithSuccess = async (
	data: any = null,
	requestInputs = null,
): Promise<IAPIResponse> => {
	return await Promise.resolve({
		data,
		error: null,
		requestInputs,
	});
};

export const setAccessToken = (token = '') => {
	setSessionStorage(ACCESS_TOKEN_KEY, token);
};

export const deleteAccessToken = () => {
	window.sessionStorage.removeItem(ACCESS_TOKEN_KEY);
	window.sessionStorage.removeItem(ACCESS_TOKEN_EXPIRE_TIME);
};

export const getAccessToken = () => {
	return getSessionStorageItem(ACCESS_TOKEN_KEY);
};

export const getSessionStorageItem = (key: string, isJsonValue = false) => {
	let itemValue: any = '';

	try {
		itemValue = window.sessionStorage.getItem(key);

		if (itemValue && isJsonValue) {
			itemValue = JSON.parse(itemValue);
		}
	} catch (error) {
		itemValue = '';
	}

	return itemValue;
};

export const setSessionStorage = (key: string, value: string) => {
	window.sessionStorage.setItem(key, value);
};

/**
 * Method used to get a data request URL
 * @param {String} path [Data API endpoint path with prepended '/']
 * @return {String} [Fully qualified data request URL]
 */
export const geRequestUrlWithParams = (path: string, params: any) => {
	if (!path) return null;

	const baseUrl: string = window.MD?.API_URL;
	if (!baseUrl) return null;

	let dataRequestUrl = `${baseUrl}${path}`;

	// check if we have urls params than add those in the URL
	if (params && params instanceof Object) {
		const tempParams = Object.entries(params)
			.map(([key, val]) => `${key}=${val}`)
			.join('&');
		dataRequestUrl += dataRequestUrl.includes('?') ? '&' : '?' + tempParams;
	}
	return dataRequestUrl;
};

export const createUUID = () => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		const r = (Math.random() * 16) | 0;
		const v = c === 'x' ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
};

export const getDeviceType = (): T_DeviceType => {
	const screenWidth = document.documentElement.clientWidth;

	const userAgent = window.navigator.userAgent;
	let device: E_DeviceType = E_DeviceType.Desktop;
	if (
		/iPad/i.test(userAgent) ||
		(/Macintosh/i.test(userAgent) &&
			/Intel Mac OS X/i.test(userAgent) &&
			screenWidth <= 1024)
	) {
		device = E_DeviceType.Ipad;
	} else if (
		/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
	) {
		device = E_DeviceType.Mobile;
	}

	return device;
};

export const cleanElementId = (elementId: string) => {
	return elementId.replace(/-/g, '_');
};

export const handleEnterKeyPress = (e: any, callback: any) => {
	if (e.keyCode === 13 || e.key === 'Enter') {
		callback(e);
	}
};

export const addQBIDArgument = (
	field: string,
	values: any,
	operator?: string,
) => {
	return {
		field: field,
		conditions: [
			{
				operator: operator ?? 'EqualTo',
				values: values,
			},
		],
	};
};

export const getNewsArgumentValues = (values: string) => {
	const inputs = values.split(',');
	return inputs;
};

export const setStateOnDropdownChange = (
	event: any,
	setStateFn: React.Dispatch<React.SetStateAction<any>>,
) => {
	if (event && setStateFn && typeof setStateFn === 'function')
		setStateFn(event.getAttribute('data-key'));
};

export const isAlphaNumeric = (inputValue: string) => {
	const alphanumericRegex = /^[a-zA-Z0-9.\s]*$/;
	if (alphanumericRegex.test(inputValue) || inputValue === '') {
		return true;
	}

	return false;
};

export const scrollIntoView = (htmlElement: HTMLElement) => {
	htmlElement.scrollIntoView({
		behavior: 'smooth',
		block: 'start',
	});
};

export const scrollToTop = () => {
	window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const escapeKeyHandler = (event: any, onPress: () => void) => {
	if (event.key === 'Escape' && typeof onPress === 'function') {
		onPress?.();
	}
};

export const getHtmlElementFromShadowRoot = (
	wcTagName: string,
	selector: string,
	selectAll = false,
) => {
	const hostElement = document.getElementsByTagName(
		wcTagName,
	)[0] as HTMLElement;
	if (hostElement?.shadowRoot) {
		if (selectAll) {
			return hostElement.shadowRoot.querySelectorAll(selector);
		}
		const element = hostElement.shadowRoot.querySelector(
			selector,
		) as HTMLElement;
		return element;
	}

	if (selectAll) {
		return document.querySelectorAll(selector);
	}

	return document.querySelector(selector);
};

export const handleWindowResize = (
	callBackHandler: (deviceType: T_DeviceType) => void,
) => {
	window.addEventListener('resize', () => {
		callBackHandler(getDeviceType());
	});
};

export const sortByKey = (
	primaryField: string,
	primarySortOrder: 'asc' | 'desc' = 'asc',
	secondaryField?: string,
	secondarySortOrder: 'asc' | 'desc' = 'asc',
) => {
	return function (a: any, b: any) {
		const compareFields = (field: string, sortOrder: 'asc' | 'desc') => {
			if (a[field] < b[field]) {
				return sortOrder === 'asc' ? -1 : 1;
			}
			if (a[field] > b[field]) {
				return sortOrder === 'asc' ? 1 : -1;
			}
			return 0;
		};

		const primaryComparison = compareFields(primaryField, primarySortOrder);

		if (primaryComparison !== 0) {
			return primaryComparison;
		}

		if (secondaryField) {
			const secondaryComparison = compareFields(
				secondaryField,
				secondarySortOrder,
			);
			return secondaryComparison;
		}

		return 0;
	};
};

export const getPerformanceIndicatorColor = (value?: number) => {
	if (!value) return 'black';
	return value > 0 ? 'green' : 'red';
};

export const getAllAlphabets = () => {
	const alphabetsArr: {
		key: string;
		value: string;
	}[] = [];

	for (let i = 65; i <= 90; i++) {
		alphabetsArr.push({
			key: String.fromCharCode(i),
			value: String.fromCharCode(i),
		});
	}
	return alphabetsArr;
};
export const gotoTop = (e: any) => {
	if (!e) return;
	e.preventDefault();
	window.scrollTo({
		top: 0,
		behavior: 'smooth',
	});
};
export const handleCSVExport = (csvData: string[][], filename: string) => {
	const csvContent = csvData
		.map((row) =>
			row
				.map((cell) => {
					if (typeof cell === 'string' && cell.includes(',')) {
						// Remove commas from numeric values
						return cell.replace(/,/g, '');
					}
					return cell;
				})
				.join(','),
		)
		.join('\n');

	const data = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
	const csvURL = URL.createObjectURL(data);
	const tempLink = document.createElement('a');
	tempLink.href = csvURL;
	tempLink.setAttribute('download', filename);
	tempLink.click();
};

export const getComparisonDates = (): {
	todaysDate: string;
	yesterdayDate: string;
	oneMonthAgo: string;
	threeMonthsAgo: string;
	sixMonthsAgo: string;
	oneYearAgo: string;
} => {
	const today = new Date();
	const yesterday = new Date(today);
	yesterday.setDate(today.getDate() - 1);

	const oneMonthAgo = new Date(today);
	oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

	const threeMonthsAgo = new Date(today);
	threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

	const sixMonthsAgo = new Date(today);
	sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

	const oneYearAgo = new Date(today);
	oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

	const formatISODate = (date: Date) => date.toISOString().split('T')[0];

	return {
		todaysDate: formatISODate(today),
		yesterdayDate: formatISODate(yesterday),
		oneMonthAgo: formatISODate(oneMonthAgo),
		threeMonthsAgo: formatISODate(threeMonthsAgo),
		sixMonthsAgo: formatISODate(sixMonthsAgo),
		oneYearAgo: formatISODate(oneYearAgo),
	};
};

export const printWindow = () => {
	const currentWindow = window.self;
	if (currentWindow) {
		currentWindow.onafterprint = () => {
			currentWindow.close();
		};
		currentWindow.print();
	}
};

export const updatePrintWindowStyle = (styleList: styleType[]) => {
	if (styleList?.length > 0) {
		styleList.forEach((item) => {
			if (item.attrValue) {
				const element: HTMLElement | null = document.querySelector(
					`[${item.attr ?? 'data-testid'}="${item.attrValue}"]`,
				);
				if (element) {
					Object.assign(element.style, item.styles);
				}
			}
		});
	}
};

export type styleType = {
	attr?: string;
	attrValue: string;
	styles: Partial<CSSStyleDeclaration>;
};

export const getColorByGlobalName = (globalColor: string) => {
	const color = getComputedStyle(document.documentElement)?.getPropertyValue(
		globalColor,
	);
	return color;
};

export const toggleAttachedEvent = (eventName: string, callback: any) => {
	document.removeEventListener(eventName, callback);
	document.addEventListener(eventName, callback);
};

export const removeAttachedEvent = (eventName: string, callback: any) => {
	document.removeEventListener(eventName, callback);
};

export const delayedMessage = (delay = 20, short?: boolean) => {
	return `Market data is delayed by at least ${delay} ${
		short ? 'min' : 'minutes'
	}.`;
};
