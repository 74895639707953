import React, { useState } from 'react';
import './news-page.scss';
import QuoteBar from '../../../shared/quote-bar/quote-bar';
import { Label } from '../../../../@core-ui';
import { DATE_FORMATS } from '../../../../utilities/date-time-formatter';
import {
	ERROR_MESSAGES,
	E_DeviceType,
} from '../../../../constants/appConstants';
import MostRecentNews from '../../../news/most-recent-news/most-recent-news';
import { delayedMessage, getDeviceType } from '../../../../utilities/utils';
import { convertUtcDttmToET } from '../../../../utilities/date-time-formatter-tz';
import NoData from '../../../../shared/NoData/NoData';

interface NewsPageProps {
	venueXid: number;
	symbol: string;
}

const NewsPage: React.FC<NewsPageProps> = ({ venueXid, symbol }) => {
	const [selectedDelayedDateTime, setSelectedDelayedDateTime] = useState('--');
	const [selectedDelayedMinutes, setSelectedDelayedMinutes] = useState();
	const isMobile = getDeviceType() === E_DeviceType.Mobile;

	return (
		<>
			{!venueXid ? (
				<NoData text={ERROR_MESSAGES.DEFAULT_NO_DATA} applyMinHeight />
			) : (
				<div className="news-tab-container">
					<div className="quote-bar-container">
						<QuoteBar
							venueXid={venueXid}
							setDelayedDateTime={setSelectedDelayedDateTime}
							setDelayedMinutes={setSelectedDelayedMinutes}
						/>
						<Label
							isGrey
							align="center"
							size="s"
							text={`As of ${convertUtcDttmToET(selectedDelayedDateTime, {
								format: DATE_FORMATS.ET_VARIANT_DATE_TIME_SEC_FORMAT,
							})}.${isMobile ? '<br />' : ' '}${delayedMessage(
								selectedDelayedMinutes,
							)}`}
							className="delayed-info"
							isHtmlContent={isMobile}
						></Label>
					</div>
					<MostRecentNews symbol={symbol} />
				</div>
			)}
		</>
	);
};
export default NewsPage;
