import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './automated-tests.scss';
import CompanyNews from '../../components/news/company-news/company-news';
import TodaysPerformers from '../../components/welcome-page/todays-performers/todays-performers';
import IntradayChartsContainer from '../../components/welcome-page/intraday-charts/intraday-charts-container';
import NewsTab from '../../components/news/news-tab/news-tab';
import ChartsPage from '../../components/snapshots/snapshot-tabs/charts-tab/charts-page';
import { SnapshotTabNames } from '../../components/snapshots/snapshot-constants';
import Statistics from '../../components/quotes-news/statistics/statistics';
import Peers from '../../components/peers/peers';
import QuotesNewsContainer from '../../components/quotes-news/quotes-news-container/quotes-news-container';
import NewsByCategory from '../../components/news/news-tab/news-by-category/news-by-category';

function AutomatedTestsPage() {
	return (
		<div className="automated-tests-container">
			<Tabs>
				<TabList>
					<Tab>Company News</Tab>
					<Tab>Todays Performers</Tab>
					<Tab>Statistics</Tab>
					<Tab>Market Indices</Tab>
					<Tab>Intraday Charts</Tab>
					<Tab>News</Tab>
					<Tab>Snapshot Chart</Tab>
					<Tab>Peers</Tab>
					<Tab>News By Category</Tab>
				</TabList>

				<TabPanel>
					<CompanyNews symbol="KO"></CompanyNews>
				</TabPanel>
				<TabPanel>
					<TodaysPerformers showHeading={true} />
				</TabPanel>
				<TabPanel>
					<Statistics key={0} />
				</TabPanel>
				<TabPanel>
					<QuotesNewsContainer />
				</TabPanel>
				<TabPanel>
					<IntradayChartsContainer />
				</TabPanel>
				<TabPanel>
					<NewsTab key={0} />
				</TabPanel>
				<TabPanel>
					<ChartsPage
						venueXid={36276}
						symbol={'AAPL'}
						tabChangeHandler={() => {
							console.log('');
						}}
						activeSelectedTab={SnapshotTabNames.Charts}
					/>
				</TabPanel>
				<TabPanel>
					<Peers repno="05680" symbol="AAPL" companyName="Apple Inc" />
				</TabPanel>
				<TabPanel>
					<NewsByCategory />
				</TabPanel>
			</Tabs>
		</div>
	);
}

export default AutomatedTestsPage;
