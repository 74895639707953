import React, { useState, useEffect } from 'react';
import {
	HEADLINES_HEADER,
	HEADLINES_DROPDOWN_DATA,
	FIELD_VALUE_MAPPING,
} from './headline-news-constants';
import './headline-news.scss';
import { Heading, SingleSelect, Loader } from '../../../@core-ui';
import {
	DEFAULT_NEWS_INPUTS,
	OP_USER_SETTINGS,
} from '../../../constants/appConstants';
import { addQBIDArgument } from '../../../utilities/utils';
import NoData from '../../../shared/NoData/NoData';
import { getNews } from '../../../services/news-data-request-methods';
import RenderNews from '../../../components/news/render-news/render-news';
import { getUserPreferences, setUserPreferences } from '../../../services';
import { prepareNewsData } from '../../quotes-news/news/news-utils';

interface HeadLineNewsProps {
	hideHeader?: boolean;
}
const HeadlineNews: React.FC<HeadLineNewsProps> = (props) => {
	const { hideHeader } = props;
	const [isLoading, setIsLoading] = useState(true);
	const [newsData, setNewsData] = useState<any>();
	const [selectedValue, setSelectedValue] = useState(
		HEADLINES_DROPDOWN_DATA[1].key,
	);

	useEffect(() => {
		selectedValue && getNewsData(selectedValue);
	}, [selectedValue]);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		setIsLoading(true);
		const preferenceResponse = await getUserPreferences(
			OP_USER_SETTINGS.OP_USER_HEADLINE_NEWS_SELECTION,
		);
		if (preferenceResponse?.data?.data?.value) {
			const preferenceValue = JSON.parse(preferenceResponse.data?.data?.value);
			if (preferenceValue) {
				setSelectedValue(preferenceValue);
			}
		}
	};
	const getNewsData = async (value: string) => {
		setIsLoading(true);
		const newsInputs = buildNewsInputs(value);
		const response = await getNews(newsInputs);
		const newsArray = response?.data?.data?.items;

		if (newsArray?.length > 0) {
			setNewsData(prepareNewsData(newsArray));
		} else {
			setNewsData(response);
		}
		setIsLoading(false);
	};

	const buildNewsInputs = (value: string) => {
		const newsInputs: any = { ...DEFAULT_NEWS_INPUTS };
		newsInputs.arguments = [
			addQBIDArgument(FIELD_VALUE_MAPPING[value], [value]),
		];
		return newsInputs;
	};

	const changeDropDown = (event: any) => {
		const dataValue = event.getAttribute('data-key');
		setSelectedValue(dataValue);
		setUserPreferences(
			OP_USER_SETTINGS.OP_USER_HEADLINE_NEWS_SELECTION,
			dataValue,
		);
	};

	const getRenderNews = () => {
		return newsData?.length > 0 ? (
			<RenderNews newsData={newsData} />
		) : (
			<NoData error={newsData?.error} />
		);
	};

	return (
		<div id="headline-news-container" data-testid="headline-news-container">
			{!hideHeader && (
				<header className={'header'}>
					<Heading testId={'headline-news'} content={HEADLINES_HEADER} />
				</header>
			)}

			<main className={'newsContainer'}>
				<div className={'newsDropDown'}>
					<SingleSelect
						data={HEADLINES_DROPDOWN_DATA}
						changeHandler={changeDropDown}
						selectedValue={selectedValue}
					/>
				</div>
				<div className={`news-content-main ${!isLoading ? 'scrollable' : ''}`}>
					{isLoading ? (
						<div className={'loader-container'}>
							<Loader />
						</div>
					) : (
						getRenderNews()
					)}
				</div>
			</main>
		</div>
	);
};

export default HeadlineNews;
