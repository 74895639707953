import React from 'react';
import './container.scss';

interface ContainerProps {
	children: React.ReactNode | string;
	id?: string;
	width?: string;
	height?: string;
	applyPadding?: boolean;
	applyBorder?: boolean;
	applyWhiteBackground?: boolean;
	hasLoader?: boolean;
	margin?: string;
}

export const Container = (props: ContainerProps) => {
	const {
		children,
		id,
		width = 'inherit',
		height = 'inherit',
		applyPadding = false,
		applyBorder = false,
		applyWhiteBackground = false,
		hasLoader = false,
		margin = 'inherit',
	} = props;

	const style = {
		width: width,
		height: height,
		margin: margin,
	};

	let className = '';
	if (applyPadding) {
		className += 'has-padding ';
	}
	if (applyBorder) {
		className += 'has-border ';
	}
	if (applyWhiteBackground) {
		className += 'has-white-bg ';
	}
	if (hasLoader) {
		className += 'has-loader ';
	}
	return (
		<div
			{...(id && { id: id })}
			className={`rc-component-container ${className}`}
			style={{ ...style }}
		>
			{children}
		</div>
	);
};
