import React, { useState, useEffect } from 'react';
import {
	CATEGORY_DROPDOWN_DATA,
	SUB_CATEGORY_DROPDOWN_DATA,
	TIME_FRAME_DROPDOWN_DATA,
	FIELD_VALUE_MAPPING,
	MAX_RECORDS,
} from './news-by-category-constants';
import './news-by-category.scss';
import { SingleSelect, Loader, Button, Container } from '../../../../@core-ui';
import { DEFAULT_NEWS_INPUTS } from '../../../../constants/appConstants';
import { addQBIDArgument, gotoTop } from '../../../../utilities/utils';
import NoData from '../../../../shared/NoData/NoData';
import {
	formatDateTime,
	DATE_FORMATS,
} from '../../../../utilities/date-time-formatter';
import { getNews } from '../../../../services/news-data-request-methods';
import RenderNews from '../../render-news/render-news';
import {
	formatDateTimeTZ,
	getConvertedDate,
	getNewDate,
} from '../../../../utilities/date-time-formatter-tz';
import { prepareNewsData } from '../../../quotes-news/news/news-utils';

const NewsByCategory: React.FC = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [newsData, setNewsData] = useState<any>();
	const [selectedValue, setSelectedValue] = useState(
		CATEGORY_DROPDOWN_DATA[3].key,
	);
	const [selectedPeriodValue, setSelectedPeriodValue] = useState(
		TIME_FRAME_DROPDOWN_DATA[1].key,
	);
	const [selectedSubCategoryValue, setSelectedSubCategoryValue] = useState(
		SUB_CATEGORY_DROPDOWN_DATA[CATEGORY_DROPDOWN_DATA[3].key][0].key,
	);
	const [subCategoryData, setSubCategoryData] = useState(
		SUB_CATEGORY_DROPDOWN_DATA[CATEGORY_DROPDOWN_DATA[3].key],
	);
	const [showMore, setShowMore] = useState(false);
	const [backToTop, setBackToTop] = useState(false);
	const [offset, setOffset] = useState(0);
	const [counter, setCounter] = useState(0);
	const [totalRecords, setTotalRecords] = useState(0);
	const [limit] = useState(10);
	const [isScrollReady, setIsScrollReady] = useState(false);
	useEffect(() => {
		setIsLoading(false);
		setCounter(0);
		setOffset(0);
		setShowMore(false);
		setBackToTop(false);
		setIsScrollReady(false);
		getNewsData(0);
	}, [
		selectedValue,
		selectedPeriodValue,
		selectedSubCategoryValue,
		subCategoryData,
		isScrollReady,
	]);
	const updateCounter = (e: any) => {
		if (!e) return;
		e.preventDefault();
		if (counter < totalRecords) {
			setIsLoading(true);
			getNewsData(offset);
		}
	};
	const moveToTop = (e: any) => {
		gotoTop(e);
		setIsScrollReady(true);
	};
	const getNewsData = async (offset: number) => {
		setIsLoading(true);
		const newsInputs = buildNewsInputs(
			selectedValue,
			selectedPeriodValue,
			selectedSubCategoryValue,
			offset,
		);
		const response = await getNews(newsInputs);
		const newsArray = response?.data?.data?.items;
		const totalItems = response?.data?.data?.pagination?.totalItems;
		setTotalRecords(totalItems);
		setOffset(offset + limit);

		if (newsArray?.length > 0) {
			const preparedData = prepareNewsData(newsArray);
			const today = getNewDate();
			const filteredNews = [];
			for (let i = 0; i < Number(selectedPeriodValue); i++) {
				const date = formatDateTimeTZ(today.set('date', today.date() - i), {
					format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
					timezone: 'America/New_York',
				});

				filteredNews.push(
					...preparedData.filter((ele: any) => ele['documentDate'] === date),
				);
			}

			if (offset === 0) {
				setNewsData([...filteredNews]);
			} else {
				setNewsData([...newsData, ...filteredNews]);
			}
		} else {
			setNewsData(response);
		}

		if (totalItems > offset + limit) {
			setCounter(offset + limit);
			setShowMore(true);
		} else {
			setShowMore(false);
		}
		if (offset + limit >= 100 || offset + limit >= totalItems) {
			setShowMore(false);
			setBackToTop(true);
		}

		setIsLoading(false);
	};

	const buildNewsInputs = (
		categoryValue: string,
		timeFrameValue: string,
		subCategoryValue: string,
		offset: number,
	) => {
		const today = getConvertedDate(getNewDate(), {
			utc: true,
		});
		const end = today;
		const start = today
			.set('hour', 0)
			.set('minute', 0)
			.set('second', 0)
			.subtract(Number(timeFrameValue) - 1, 'day')
			.format(DATE_FORMATS.DOCUMENT_DATE_FORMAT);
		const newsInputs: any = { ...DEFAULT_NEWS_INPUTS };
		newsInputs.offset = offset;
		newsInputs.arguments = [
			addQBIDArgument(FIELD_VALUE_MAPPING[categoryValue], [subCategoryValue]),
			addQBIDArgument(
				FIELD_VALUE_MAPPING[timeFrameValue],
				[start],
				'GreaterThanEqualTo',
			),
			addQBIDArgument(
				FIELD_VALUE_MAPPING[timeFrameValue],
				[formatDateTime(end, { format: DATE_FORMATS.DOCUMENT_DATE_FORMAT })],
				'LessThanEqualTo',
			),
		];
		return newsInputs;
	};

	const changeCategoryDropDown = (event: any) => {
		const categoryKey = event.getAttribute('data-key');
		setSelectedValue(categoryKey);
		setSelectedSubCategoryValue(SUB_CATEGORY_DROPDOWN_DATA[categoryKey][0].key);
		setSubCategoryData([...SUB_CATEGORY_DROPDOWN_DATA[categoryKey]]);
	};
	const changePeriodDropDown = (event: any) => {
		setSelectedPeriodValue(event.getAttribute('data-key'));
	};
	const changeSubCategoryDropDown = (event: any) => {
		setSelectedSubCategoryValue(event.getAttribute('data-key'));
	};

	const getRenderNews = () => {
		return newsData?.length > 0 ? (
			<RenderNews newsData={newsData} />
		) : (
			<NoData error={newsData?.error} />
		);
	};

	return (
		<Container id="news-by-category-container">
			<main className={'newsContainer'}>
				<div className={'newsDropDownContainer'}>
					<div className={'newsDropDown'}>
						<SingleSelect
							data={[...TIME_FRAME_DROPDOWN_DATA]}
							changeHandler={changePeriodDropDown}
							selectedValue={selectedPeriodValue}
							dataTestId="periodDropDown"
						/>
					</div>
					<div className={'newsDropDown'}>
						<SingleSelect
							data={[...CATEGORY_DROPDOWN_DATA]}
							changeHandler={changeCategoryDropDown}
							selectedValue={selectedValue}
							dataTestId="categoryDropDown"
						/>
					</div>
					<div className={'newsDropDown'}>
						<SingleSelect
							data={[...subCategoryData]}
							changeHandler={changeSubCategoryDropDown}
							selectedValue={selectedSubCategoryValue}
							dataTestId="subCategoryDropDown"
						/>
					</div>
				</div>

				<div className={'news-by-category-main'}>
					{isLoading ? (
						<div className={'loader-container'}>
							<Loader />
						</div>
					) : (
						getRenderNews()
					)}
					{showMore && (
						<div className={'show-more-container'}>
							<Button className={`show-more-button`} onClick={updateCounter}>
								<div>{'Show More'}</div>
							</Button>
							<div className={'show-more-records'}>{`Showing 1 - ${offset} of ${
								totalRecords < MAX_RECORDS ? totalRecords : MAX_RECORDS
							} Results`}</div>
						</div>
					)}
					{backToTop && (
						<Button className={`show-more-button`} onClick={moveToTop}>
							<span>Back To Top</span>
						</Button>
					)}
				</div>
			</main>
		</Container>
	);
};

export default NewsByCategory;
