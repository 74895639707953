import DataRequest from './dataRequest';
import URLS from '../constants/urlConstants';
import {
	IPostHoldingRequestItem,
	IGetWatchListHoldingsResponse,
	IGetWatchlistsResponse,
	IPostHoldingsResponse,
	IPostWatchListResponse,
	IDeleteHoldingsResponse,
	IPreferenceResponse,
} from '../types/interfaces';
import {
	WatchlistDisplayOptionPreferenceConstants,
	WatchlistDisplayOption,
} from '../components/watchlists/customize-watchlist/customize-watchlist-constants';
import { createUUID, resolvePromiseWithError } from '../utilities/utils';
import { E_HTTP_METHOD } from '../constants/appConstants';

export const getWatchlist = async (): Promise<IGetWatchlistsResponse> => {
	return await DataRequest.execute(URLS.PLATFORM.WATCHLIST, {});
};

export const addWatchlist = async (): Promise<IPostWatchListResponse> => {
	return await DataRequest.execute(`${URLS.PLATFORM.WATCHLIST}`, {
		method: E_HTTP_METHOD.POST,
		postData: {
			name: `Watchlist_${createUUID()}`,
			description: 'Some description...',
		},
	});
};

export const deleteWatchlist = async (
	watchlistId: string,
): Promise<IPostWatchListResponse> => {
	if (!watchlistId) {
		return await resolvePromiseWithError();
	}
	return await DataRequest.execute(
		`${URLS.PLATFORM.WATCHLIST}/${watchlistId}`,
		{
			method: E_HTTP_METHOD.DELETE,
			params: {
				watchlistId: watchlistId,
			},
		},
	);
};

export const getWatchlistHoldings = async (
	watchlistId: string,
): Promise<IGetWatchListHoldingsResponse> => {
	if (!watchlistId) {
		return await resolvePromiseWithError();
	}
	return await DataRequest.execute(
		`${URLS.PLATFORM.WATCHLIST}/${watchlistId}/holdings`,
	);
};

export const addHoldings = async (
	watchlistId: string,
	holdings: IPostHoldingRequestItem[] = [],
): Promise<IPostHoldingsResponse> => {
	if (!watchlistId || !holdings.length) {
		return await resolvePromiseWithError();
	}
	return await DataRequest.execute(
		`${URLS.PLATFORM.WATCHLIST}/${watchlistId}/holdings`,
		{
			method: E_HTTP_METHOD.POST,
			postData: {
				holdings: holdings,
			},
		},
	);
};

export const deleteHoldings = async (
	watchlistId: string,
	holdingIds: string,
): Promise<IDeleteHoldingsResponse> => {
	if (!holdingIds) {
		return await resolvePromiseWithError();
	}
	return await DataRequest.execute(
		`${URLS.PLATFORM.WATCHLIST}/${watchlistId}/holdings`,
		{
			method: E_HTTP_METHOD.DELETE,
			params: {
				holdingId: holdingIds,
			},
		},
	);
};

export const getWatchlistDisplyOptionPreference =
	async (): Promise<IPreferenceResponse> => {
		return await DataRequest.execute(
			`${URLS.PLATFORM.USER_MGMT_PREFERENCES}/${WatchlistDisplayOptionPreferenceConstants.OP_WATCHLIST_DISPLAY_TYPE}`,
		);
	};

export const setWatchlistDisplyOptionPreference = async (
	displayOption: string,
): Promise<IPreferenceResponse> => {
	const { LAST_CHANGE, RECENT_NEWS, OP_WATCHLIST_DISPLAY_TYPE } =
		WatchlistDisplayOptionPreferenceConstants;
	const displayType =
		displayOption === WatchlistDisplayOption.LastAndChg
			? LAST_CHANGE
			: RECENT_NEWS;
	return await DataRequest.execute(
		`${URLS.PLATFORM.USER_MGMT_PREFERENCES}/${OP_WATCHLIST_DISPLAY_TYPE}`,
		{
			method: E_HTTP_METHOD.POST,
			postData: displayType,
		},
	);
};
