import DataRequest from './dataRequest';
import URLS from '../constants/urlConstants';
import { E_HTTP_METHOD } from '../constants/appConstants';
import { IPostChartResponse } from '../types/interfaces/IChartSettings';

export const savechartSettings = async (
	data: any,
): Promise<IPostChartResponse> => {
	return await DataRequest.execute(URLS.CUSTOM.SET_CHART_SETTING, {
		method: E_HTTP_METHOD.POST,
		postData: data,
	});
};

export const getSavedChartSettings = async (): Promise<IPostChartResponse> => {
	return await DataRequest.execute(URLS.CUSTOM.GET_CHART_SETTING);
};
