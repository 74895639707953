import React from 'react';
import { Label } from '../../../../../@core-ui';
import './chart-events.scss';

interface EventInfoProps {
	eventData: any;
	isSplits?: boolean;
	isDividends?: boolean;
	isNews?: boolean;
}

const EventInfo: React.FC<EventInfoProps> = ({
	eventData,
	isSplits,
	isDividends,
	isNews,
}) => {
	return (
		<div className={'chartEventContainer'}>
			{isDividends && (
				<div className={'dividend-events'}>
					<div className={'row'}>
						<Label text={'Ex-Date'} className="display-block darkGreyLabel " />
						<Label
							text={eventData.exDate}
							className="display-block boldLabel"
						/>
					</div>
					<div className={'row'}>
						<Label
							text={'Paid-Date'}
							className="display-block darkGreyLabel "
						/>
						<Label
							text={eventData.payDate}
							className="display-block boldLabel"
						/>
					</div>
					<div className={'row'}>
						<Label text={'Amount'} className="display-block darkGreyLabel " />
						<Label
							text={eventData.amount}
							className="display-block boldLabel"
						/>
					</div>
				</div>
			)}
			{isSplits && (
				<div className={'row'}>
					<Label text={'Ratio'} className="display-block darkGreyLabel " />
					<Label text={eventData.ratio} className="display-block boldLabel" />
				</div>
			)}
			{isNews && (
				<div className={'news-info'}>
					<Label
						text={`${eventData.story} ${
							eventData.story > 1 ? 'Stories' : 'Story'
						}`}
						className="palatino-regular label-ms"
					/>
					<Label text={'Click to View'} className="darkGreyLabel" />
				</div>
			)}
		</div>
	);
};
export default EventInfo;
