import React, { useEffect, useState } from 'react';
import './news-chart.scss';
import {
	News_Time_Period,
	TIME_PERIOD_MAPPING,
	defaultDate,
	TIME_PERIOD_WEEK_TICK_MAPPING,
} from '../news-map-constants';
import { Loader } from '../../../../@core-ui';
import {
	convertUtcDttmToET,
	formatDateTimeTZ,
	getConvertedDate,
	getNewDate,
} from '../../../../utilities/date-time-formatter-tz';
import {
	DATE_FORMATS,
	ET_timezone,
	formatDateTime,
} from '../../../../utilities/date-time-formatter';
import NoData from '../../../../shared/NoData/NoData';
import NewsChart from './news-chart';
import { getDeviceType } from '../../../../utilities/utils';
import { E_DeviceType } from '../../../../constants/appConstants';

import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrBefore);

interface NewsChartContainerProps {
	newsData: any[];
	priceData: any[];
	clickHandler?: (event: Highcharts.PointClickEventObject) => void;
	chartInputs?: any;
}
type mobileChartOptions = {
	barData: number[];
	labelData: string[];
	priceLineData: (number | null)[];
};
const NewsChartContainer: React.FC<NewsChartContainerProps> = ({
	newsData,
	priceData,
	clickHandler,
	chartInputs,
}) => {
	const [columnData, setColumnData] = useState<any>([]);
	const [labels, setLabels] = useState<any>([]);
	const [lineData, setLineData] = useState<any>([]);
	const [ready, setReady] = useState<boolean>(false);
	const [showNoData, setShowNoData] = useState<boolean>(false);
	const isMobile = getDeviceType() === E_DeviceType.Mobile;
	const [mobileOptions, setMobileOptions] = useState<mobileChartOptions>({
		barData: [],
		labelData: [],
		priceLineData: [],
	});
	const [currentData, setCurrentData] = useState(2);
	const [lastIndex, setLastIndex] = useState(7);

	useEffect(() => {
		setReady(false);
		setShowNoData(false);
		if (newsData && priceData) {
			if (chartInputs?.timeFrame === News_Time_Period.Day) {
				setDayChartData(newsData, priceData);
			} else if (chartInputs?.timeFrame === News_Time_Period.Week) {
				setWeeklyChartData(newsData, priceData);
			} else {
				setFilteredData(newsData, priceData);
			}
		}
	}, []);

	const setFilteredData = (newsData: any[], priceData: any[]) => {
		const totalRecords: any = [];
		const dateLabels: any = [];
		const lastPrice: any = [];
		const today = getNewDate();
		setReady(false);

		for (let i = 0; i < TIME_PERIOD_MAPPING[chartInputs.timeFrame]; i++) {
			const date = formatDateTimeTZ(today.set('date', today.date() - i), {
				format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
				timezone: ET_timezone,
			});

			const newsChartData = newsData.filter(
				(ele) => ele['documentDate'] === date,
			);

			totalRecords.push(newsChartData.length);
			dateLabels.push(formatDateTime(date, { format: 'MMM D' }));

			const priceChartData = priceData.filter(
				(ele) =>
					convertUtcDttmToET(ele['lastPriceDate'], {
						format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
					}) === date,
			);
			if (priceChartData.length > 0) {
				lastPrice.push(priceChartData[0]['lastPrice']);
			} else {
				lastPrice.push(null);
			}
		}

		setLineData(lastPrice.reverse());
		setLabels(dateLabels.reverse());
		setColumnData(totalRecords.reverse());
		isMobile && plusSlides(0, lastPrice, dateLabels, totalRecords);
		setReady(true);
	};

	const setWeeklyChartData = (newsData: any[], priceData: any[]) => {
		const xAxisLabels = [];
		const totalPrice: any = [];
		const totalNews: any = [];
		let pricesByDate: any = [];
		let newsByDate: any = [];
		const maxPriceIntervalsForDay =
			TIME_PERIOD_WEEK_TICK_MAPPING.MAX_TIMEINTERVAL_FOR_DAY; // For 5 min, there could be max 78 price interval possible in a trading day
		const newsBarPosition = TIME_PERIOD_WEEK_TICK_MAPPING.NEWSBAR_POS; // used to set news bar position near to mid day
		let newsCount = 0;

		const today = getNewDate();
		for (let i = TIME_PERIOD_MAPPING[chartInputs.timeFrame] - 1; i >= 0; i--) {
			const formattedTime = formatDateTimeTZ(
				today.set('date', today.date() - i),
				{
					format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
					timezone: ET_timezone,
				},
			);

			// Price
			pricesByDate = [];
			let filteredPrices = [];
			filteredPrices = priceData.filter((ele) => {
				const lastPriceDate = formatDateTimeTZ(ele.lastPriceDate, {
					format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
					timezone: ET_timezone,
				});
				return lastPriceDate === formattedTime;
			});

			if (filteredPrices.length > 0) {
				pricesByDate = [...filteredPrices.map((x) => x.lastPrice)];
			}

			if (pricesByDate.length < maxPriceIntervalsForDay) {
				pricesByDate.push(
					...[
						...Array(maxPriceIntervalsForDay - pricesByDate.length).fill(null),
					],
				);
			}
			totalPrice.push(...pricesByDate);

			// News
			newsCount = 0;
			newsByDate = [];
			newsData.forEach((ele) => {
				const documentDate = getConvertedDate(
					convertUtcDttmToET(ele['documentDateTime'], {}),
					'onlyDate: true,',
				);
				if (documentDate === formattedTime) {
					newsCount++;
					return true;
				}
			});
			pricesByDate.forEach(() => {
				newsByDate.push(null);
			});
			if (newsCount > 0) {
				newsByDate.splice(newsBarPosition, 1, newsCount);
			}
			totalNews.push(...newsByDate);

			// x-axis
			xAxisLabels.push(
				...[
					...Array(pricesByDate.length).fill(
						formatDateTime(formattedTime, { format: 'MMM D' }),
					),
				],
			);
		}

		setLineData(totalPrice);
		setColumnData(totalNews);
		setLabels(xAxisLabels);
		setReady(true);
	};

	const setLastPrice = (filterPriceRecords: any[], lastPrice: any[]) => {
		if (filterPriceRecords.length > 0) {
			filterPriceRecords.forEach((item: any) => {
				lastPrice.push(item['lastPrice']);
			});
			if (filterPriceRecords.length !== 12) {
				for (let i = 0; i < 12 - filterPriceRecords.length; i++) {
					lastPrice.push(null);
				}
			}
		} else {
			for (let i = 0; i < 12; i++) {
				lastPrice.push(null); // before market open
			}
		}
	};

	const handleLastPrice = (
		dateLabels: any,
		newsChartData: any[],
		totalRecords: any[],
		priceChartData: any[],
		timeFormat: string,
		lastPrice: any[],
	) => {
		for (let i = 0; i < dateLabels.length - 1; i++) {
			const date1 = getConvertedDate(`${defaultDate} ${dateLabels[i]}`, {
				onlyDate: true,
			});
			const date2 = getConvertedDate(`${defaultDate} ${dateLabels[i + 1]}`, {
				onlyDate: true,
			});
			//news
			const filteredRecords = newsChartData.filter((ele: any) => {
				const labelTime = getConvertedDate(
					`${defaultDate} ${convertUtcDttmToET(ele['documentDateTime'], {
						timeOnly: true,
						timeFormat: timeFormat,
					})}`,
					{ onlyDate: true },
				);
				return labelTime >= date1 && labelTime < date2;
			});
			totalRecords.push(filteredRecords.length);
			for (let i = 0; i < 11; i++) {
				totalRecords.push(null);
			}
			//price
			const filterPriceRecords = priceChartData.filter((ele: any) => {
				const labelTime = getConvertedDate(
					`${defaultDate} ${convertUtcDttmToET(ele['lastPriceDate'], {
						timeOnly: true,
						timeFormat: timeFormat,
					})}`,
					{ onlyDate: true },
				);
				return labelTime >= date1 && labelTime < date2;
			});
			setLastPrice(filterPriceRecords, lastPrice);
		}
	};

	const handleTotalRecords = (
		dateLabels: any,
		xAxisLabels: any[],
		timeFormat: string,
		newsChartData: any[],
		totalRecords: any[],
		currentTime: any,
		maxDateTime: any,
	) => {
		while (currentTime.isSameOrBefore(maxDateTime)) {
			const formattedTime = convertUtcDttmToET(currentTime, {
				timeOnly: true,
				timeFormat: timeFormat,
			});

			dateLabels.push(formattedTime);
			currentTime = currentTime.add(1, 'hour'); // Increment time 1 hour
		}

		const formattedTime = convertUtcDttmToET(currentTime, {
			timeOnly: true,
			timeFormat: timeFormat,
		});

		dateLabels.push(formattedTime);

		for (let i = 0; i < dateLabels.length - 1; i++) {
			const date1 = getConvertedDate(`${defaultDate} ${dateLabels[i]}`, {
				onlyDate: true,
			});
			const date2 = getConvertedDate(`${defaultDate} ${dateLabels[i + 1]}`, {
				onlyDate: true,
			});

			xAxisLabels.push(dateLabels[i]);

			const filteredRecords = newsChartData.filter((ele: any) => {
				const labelTime = getConvertedDate(
					`${defaultDate} ${convertUtcDttmToET(ele['documentDateTime'], {
						timeOnly: true,
						timeFormat: timeFormat,
					})}`,
					{ onlyDate: true },
				);

				return labelTime >= date1 && labelTime <= date2;
			});

			totalRecords.push(filteredRecords.length);
		}
	};

	const setDayChartData = (newsData: any[], priceData: any[]) => {
		setShowNoData(false);
		setReady(false);
		const totalRecords: number[] = [];
		const dateLabels: any[] = [];
		const lastPrice: any[] = [];
		const xAxisLabels: any[] = [];
		const date = formatDateTimeTZ(getNewDate(), {
			timezone: ET_timezone,
			format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
		});
		const newsChartData = newsData.filter(
			(ele) => ele['documentDate'] === date,
		);

		const priceChartData = priceData.filter(
			(ele) =>
				convertUtcDttmToET(ele['lastPriceDate'], {
					format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
				}) === date,
		);
		const timeFormat = DATE_FORMATS.HOUR_MINUTE_FORMAT.replace('a', ' a');

		if (priceChartData.length > 0) {
			const priceMaxTime = getConvertedDate(
				priceChartData[priceChartData.length - 1]['lastPriceDate'],
				{ utc: true },
			);
			let minTimeDate;
			let maxDateTime;

			if (newsChartData.length > 0) {
				minTimeDate =
					newsChartData[newsChartData.length - 1]['documentDateTime'];
				maxDateTime = getConvertedDate(newsChartData[0]['documentDateTime'], {
					utc: true,
				});
				if (maxDateTime < priceMaxTime) {
					maxDateTime = priceMaxTime;
				}
			} else {
				minTimeDate = priceChartData[0]['lastPriceDate'];
				maxDateTime = priceMaxTime;
			}

			let currentTime = getConvertedDate(minTimeDate, { utc: true });
			while (currentTime <= maxDateTime) {
				const formattedTime = convertUtcDttmToET(currentTime, {
					timeOnly: true,
					timeFormat: timeFormat,
				});
				dateLabels.push(formattedTime);
				currentTime = currentTime.add(1, 'hour'); // Increment time 1 hour
			}
			const formattedTime = convertUtcDttmToET(currentTime, {
				timeOnly: true,
				timeFormat: timeFormat,
			});
			dateLabels.push(formattedTime);

			let xLabelTime = getConvertedDate(minTimeDate, { utc: true });

			while (xLabelTime <= maxDateTime) {
				const formattedTime = convertUtcDttmToET(xLabelTime, {
					timeOnly: true,
					timeFormat: timeFormat,
				});
				xAxisLabels.push(formattedTime);
				xLabelTime = xLabelTime.add(5, 'minute'); // Increment time by 15 minute
			}

			const formattedLabelTime = convertUtcDttmToET(xLabelTime, {
				timeOnly: true,
				timeFormat: timeFormat,
			});
			xAxisLabels.push(formattedLabelTime);
			handleLastPrice(
				dateLabels,
				newsChartData,
				totalRecords,
				priceChartData,
				timeFormat,
				lastPrice,
			);
		} else if (newsChartData.length > 0) {
			let currentTime: any =
				newsChartData[newsChartData.length - 1]['documentDateTime'];
			let maxDateTime: string = newsChartData[0]['documentDateTime'];

			maxDateTime = getConvertedDate(maxDateTime, { utc: true });
			currentTime = getConvertedDate(currentTime, { utc: true });

			handleTotalRecords(
				dateLabels,
				xAxisLabels,
				timeFormat,
				newsChartData,
				totalRecords,
				currentTime,
				maxDateTime,
			);
		} else if (priceChartData.length === 0 && newsChartData.length === 0) {
			setShowNoData(true);
		}
		setLineData(lastPrice);
		setLabels(xAxisLabels);
		setColumnData(totalRecords);
		setReady(true);
	};

	const addMobileChartValues = (
		xlabel: any[],
		bar: any[],
		line: any[],
		xAxis: { [x: string]: any },
		current: number,
		news: { [x: string]: any },
		price: { [x: string]: any },
	) => {
		let i = current;
		for (i; i < current + 7; i++) {
			xlabel.push(xAxis[i]);
			bar.push(news[i]);
			line.push(price[i]);
		}
		return i;
	};

	const plusSlides = (
		n: number,
		price = lineData,
		xAxis = labels,
		news = columnData,
	) => {
		if (
			(n !== -1 || currentData !== 2) &&
			(n !== 1 || lastIndex !== labels.length)
		) {
			const xlabel: any = [];
			const bar: any = [];
			const line: any = [];
			if (n === -1 && currentData !== 2) {
				const current = currentData - 7;
				setCurrentData(current);
				const i = addMobileChartValues(
					xlabel,
					bar,
					line,
					xAxis,
					current,
					news,
					price,
				);
				setLastIndex(i);
			} else if (n === 1) {
				const current = currentData + 7;
				setCurrentData(current);
				const i = addMobileChartValues(
					xlabel,
					bar,
					line,
					xAxis,
					current,
					news,
					price,
				);
				setLastIndex(i);
			} else if (n === 0) {
				for (let i = 2; i < 9; i++) {
					xlabel.push(xAxis[i]);
					bar.push(news[i]);
					line.push(price[i]);
				}
			}
			const mobileChartOptions: mobileChartOptions = {
				barData: bar,
				labelData: xlabel,
				priceLineData: line,
			};
			setMobileOptions(mobileChartOptions);
		}
	};

	const getMobileView = () => {
		return (
			<div className="slideshow-container">
				<div className="mySlides fade">
					<NewsChart
						priceLineData={mobileOptions.priceLineData}
						labelData={mobileOptions.labelData}
						newsBarData={mobileOptions.barData}
						clickHandler={clickHandler}
						chartInputs={chartInputs}
					/>
				</div>
				<div className="carousel">
					<button
						className={`${'prev'} ${currentData === 2 ? 'disable' : ''}`}
						onClick={() => plusSlides(-1)}
						onKeyDown={() => plusSlides(1)}
					>
						&#10094;
					</button>
					<span className="chart-title">
						{`Viewing ${mobileOptions?.labelData?.[0]} - ${
							mobileOptions.labelData[mobileOptions.labelData.length - 1]
						}`}
					</span>
					<button
						className={`${'next'} ${
							lastIndex === labels.length ? 'disable' : ''
						}`}
						onClick={() => plusSlides(1)}
						onKeyDown={() => plusSlides(1)}
					>
						&#10095;
					</button>
				</div>
			</div>
		);
	};

	const getNewsChart = () => {
		return isMobile ? (
			getMobileView()
		) : (
			<NewsChart
				priceLineData={isMobile ? mobileOptions.priceLineData : lineData}
				labelData={isMobile ? mobileOptions.labelData : labels}
				newsBarData={isMobile ? mobileOptions.barData : columnData}
				clickHandler={clickHandler}
				chartInputs={chartInputs}
			/>
		);
	};

	const getHTMLbody = () => {
		return showNoData ? (
			<div className="no-data">
				<NoData size={'l'} />
			</div>
		) : (
			getNewsChart()
		);
	};

	return (
		<div className="news-chart-container" data-testid="news-chart-container">
			{ready ? (
				getHTMLbody()
			) : (
				<div className="loader">
					<Loader />
				</div>
			)}
		</div>
	);
};

export default NewsChartContainer;
