import React, { useEffect, useState } from 'react';
import Profile from './../../../shared/profile/profile';
import {
	OptionPopoverHeader,
	OptionPopoverMapping,
} from '../options-constants';
import { Loader, Label } from './../../../@core-ui';
import { getXrefDataByXids } from './../../../services';
import './option-details-popover.scss';

interface OptionDetailsPopoverProps {
	data: any;
	xid: number;
}

const OptionDetailsPopover = ({ data, xid }: OptionDetailsPopoverProps) => {
	const [isLoading, setIsLoading] = useState(false);
	const [symbol, setSymbol] = useState('');

	useEffect(() => {
		setIsLoading(true);
		xid && fetchOptionSymbolData(xid);
	}, [xid]);

	const fetchOptionSymbolData = async (xid: number) => {
		const response = await getXrefDataByXids([xid]);
		setSymbol(response?.data?.data?.items?.[0]?.symbol);
		setIsLoading(false);
	};

	const getContent = () => {
		if (isLoading) {
			return <Loader />;
		}
		return (
			<>
				<Label text={symbol} className="symbol" isBold />
				<Profile
					profileData={data}
					header={OptionPopoverHeader}
					mapping={OptionPopoverMapping}
					numColumns={1}
					hideHeader
				/>
			</>
		);
	};

	return <div className={'option-popover-container'}>{getContent()}</div>;
};

export default OptionDetailsPopover;
