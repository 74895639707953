import React from 'react';
import { IBasicIconProps } from '../IBasicIconProps';

const SplitsIcon: React.FC<IBasicIconProps> = ({
	width = 21,
	height = 17,
	color = '#000',
}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g
			id="splits-stroke"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<g
				id="splits-transform"
				transform="translate(-803, -556)"
				fill={color}
				fillRule="nonzero"
			>
				<g transform="translate(775, 479)">
					<g id="ic_splits" transform="translate(28, 77)">
						<path
							d="M21.3087288,3 C21.4772317,3 21.6430025,3.04257935 21.7906475,3.12378409 C22.2745682,3.38994047 22.4511011,3.99799807 22.1849447,4.48191875 L12.8762159,21.4068802 C12.7848281,21.5730399 12.6480785,21.7097895 12.4819187,21.8011773 C11.9979981,22.0673337 11.3899405,21.8908008 11.1237841,21.4068802 L1.81505531,4.48191875 C1.73385057,4.33427377 1.69127122,4.16850297 1.69127122,4 C1.69127122,3.44771525 2.13898647,3 2.69127122,3 Z M20.801,4.3 L3.198,4.3 L12,20.304 L20.801,4.3 Z M12.65,16.1810253 C12.65,16.5400104 12.3589851,16.8310253 12,16.8310253 C11.6410149,16.8310253 11.35,16.5400104 11.35,16.1810253 L11.3492712,11.999 L11.3422712,11.312 L8.14927122,8.065 L8.15,9 C8.15,9.32635008 7.90949181,9.59652646 7.59605224,9.64295233 L7.5,9.65 C7.14101491,9.65 6.85,9.35898509 6.85,9 L6.85,6.5 C6.85,6.14101491 7.14101491,5.85 7.5,5.85 L10,5.85 C10.3589851,5.85 10.65,6.14101491 10.65,6.5 L10.6429523,6.59605224 C10.5965265,6.90949181 10.3263501,7.15 10,7.15 L9.07127122,7.149 L11.9962712,10.122 L14.9202712,7.149 L13.9964096,7.15 C13.6700595,7.15 13.3998831,6.90949181 13.3534572,6.59605224 L13.3464096,6.5 C13.3464096,6.14101491 13.6374245,5.85 13.9964096,5.85 L16.4964096,5.85 C16.8553947,5.85 17.1464096,6.14101491 17.1464096,6.5 L17.1464096,9 C17.1464096,9.35898509 16.8553947,9.65 16.4964096,9.65 C16.1374245,9.65 15.8464096,9.35898509 15.8464096,9 L15.8462712,8.061 L12.6492712,11.312 L12.65,16.1810253 Z"
							id="splits"
						></path>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default SplitsIcon;
