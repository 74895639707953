import DataRequest from './dataRequest';
import URLS from '../constants/urlConstants';
import { E_HTTP_METHOD } from '../constants/appConstants';
import { IPreferenceResponse } from '../types/interfaces';

export const getUserPreferences = async (
	key: string,
): Promise<IPreferenceResponse> => {
	return await DataRequest.execute(
		`${URLS.PLATFORM.USER_MGMT_PREFERENCES}/${key}`,
	);
};

export const setUserPreferences = async (
	key: string,
	data: string,
): Promise<IPreferenceResponse> => {
	return await DataRequest.execute(
		`${URLS.PLATFORM.USER_MGMT_PREFERENCES}/${key}`,
		{
			method: E_HTTP_METHOD.POST,
			postData: data,
		},
	);
};
