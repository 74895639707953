export enum ComparisonType {
	UpperIndicator = 'upperIndicator',
	Symbol = 'symbol',
}
export interface IChartError {
	isError: boolean;
	message: string;
}

export interface IChartSettings {
	venueXid: number;
	chartType: string;
	timeFrame: number;
	eventsType: [];
	isDefault: boolean;
	selectedOverlayData: IOverlay;
	comparisonList: ISymbolComparison[];
	lowerIndicator: string;
}

export interface IChartList {
	key: string;
	value: string;
}

export interface IOverlay {
	name: string;
	period: number;
}

export interface ISymbolComparison {
	name: string;
	venueXid?: number;
	type?: ComparisonType;
	period?: number;
}

export interface ISymbolList {
	uid: string;
	name: string;
	venueXid: number;
	colorCode: string;
}
interface IErrorResponse {
	code?: string;
	errors?: {
		message: string;
	}[];
}

export interface ISaveChart {
	id: string;
	chartName: string;
	isDefault: boolean;
	timeFrame: number;
	chartType: string;
	eventsType: [];
	comparisonList: ISymbolComparison[];
	lowerIndicator: string;
}

export interface IPostChartResponse extends IErrorResponse {
	data: {
		value: any;
	};
}
