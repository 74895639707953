
import { ITableColumn } from '../../../types/interfaces/ICommon';
interface IResponse {
	venueXid: number;
	name: React.ReactNode;
	change: React.ReactNode;
	changePercent: React.ReactNode;
}
export const TODAYSPERFORMERS_HEADER = "Today's Performers";
export const MARKETERS= {
	RANKING_SET: {
		key: 'rankingSet',
		dropDownData: [
			{
				key: 'NYSE Stocks',
				value: 'NYSE',
			},
			{
				key: 'DJIA Index',
				value: 'DOW JONES',
			},
			{
				key: 'NASDAQ Stocks',
				value: 'NASDAQ',
			},
			{
				key: 'NYSE American Stocks',
				value: 'AMEX',
			},
		],
	},
	RANKING_TYPE: {
		key: 'rankingType',
		dropDownData: [
			{
				key: 'NetGainers',
				value: 'Gainers',
			},
			{
				key: 'NetLosers',
				value: 'Losers',
			},
			{
				key: 'MostActive',
				value: 'Most Active',
			},
		],
	},
};
export const TABLE_HEADERS = [
	{
		key: 'Name',
		value: 'name',
		isBold: true,
	},
	{
		key: '$ Chg',
		value: 'change',
		isBold: true,
	},
	{
		key: '% Chg',
		value: 'changePercent',
		isBold: true,
	},
];
export const TODAYSPERFORMERS_COLUMN: ITableColumn<IResponse>[] = [
	{
		header: TABLE_HEADERS[0].key,
		accessor: 'name',
		sortDir: 'asc',
		key: TABLE_HEADERS[0].value,
		isLabel: true,
	},
	{
		header: TABLE_HEADERS[1].key,
		accessor: 'change',
		sortDir: 'desc',
		key: TABLE_HEADERS[1].value,
		isLabel: false,
	},
	{
		header: TABLE_HEADERS[2].key,
		accessor: 'changePercent',
		sortDir: 'desc',
		key: TABLE_HEADERS[2].value,
		isLabel: false,
	},
];