export const COLORS = [
	'#FFFFFF',
	'#358734',
	'#EFF4F9',
	'#000000',
	'#C60000',
	'#898989',
	'#c5ddf3',
	'#e7e7e7',
];

export const FONTS = [
	{
		size: 10,
		weight: 'Normal',
	},
];

export const CHART_PRICE_TEST_ID = 'price_chart_image';

export const DEFAULT_CHARTS_PRICE_OBJ = {
	instruments: [
		{
			venueXid: 200688,
		},
	],
	data: {
		days: 1,
		interval: 5,
		period: 'minute',
		realtime: false,
		extendedHours: false,
	},
	panels: [
		{
			height: 300,
			axisX: {
				holidays: false,
				label: {
					strategy: 'standard',
					extra: false,
					twentyFourHour: false,
				},
			},
			axisY: {
				label: {
					zeroLine: true,
					baseLine: false,
				},
			},
			legend: false,
		},
	],
	width: 600,
	asOfDate: false,
	styles: {
		markers: {
			price: {
				primary: {
					colorIndex: 1,
					width: 2,
					fill: {
						normalHours: {
							colorIndex: 2,
							toColorIndex: 0,
						},
					},
				},
			},
		},
		axisX: {
			grid: {
				width: 0,
			},
			label: {
				align: 'center',
				padding: [10, 10, 0, 10],
				colorIndex: 3,
				fontIndex: 0,
				capitalize: true,
			},
		},
		axisY: {
			grid: {
				colorIndex: 7,
				width: 0.6,
			},
			label: {
				align: 'middle',
				padding: [0, 0, 0, 10],
				position: 'right',
				fontIndex: 0,
				colorIndex: 3,
			},
		},
		background: {
			colorIndex: 0,
		},
		colors: COLORS,
		fonts: FONTS,
	},
	markers: {
		price: {
			primary: {
				type: 'mountain',
			},
		},
		volume: false,
	},
	chartDescription: false,
	receiveExport: true,
};
