import DataRequest from './dataRequest';
import URLS from '../constants/urlConstants';
import { IEquitiesCurrentRatiosResponse } from '../types/interfaces';
import { resolvePromiseWithError } from '../utilities/utils';
import { E_HTTP_METHOD } from '../constants/appConstants';

export const getEquitiesCurrentRatios = async (
	entityXid: number,
): Promise<IEquitiesCurrentRatiosResponse> => {
	if (!entityXid) {
		return await resolvePromiseWithError();
	}
	return await DataRequest.execute(
		`${URLS.PLATFORM.EQUITIES_CURRENT_RATIOS}/` + entityXid,
		{
			method: E_HTTP_METHOD.GET,
			requestInputs: { entityXid },
		},
	);
};
