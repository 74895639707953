import React from 'react';
import { generateColValue } from '../../components/quotes-news/portfolio/portfolio-utils';
import {
	ICustomTableMapping,
	ICustomTableHeader,
} from '../../types/interfaces';
import { Heading, Label, Collapsible } from './../../@core-ui';
import { TEXT_NULL_VALUE } from './../../utilities/formatter';
import './profile.scss';
import { getDeviceType } from './../../utilities/utils';
import { E_DeviceType } from './../../constants/appConstants';

type ProfileProps = {
	profileData: any;
	mapping: ICustomTableMapping[];
	header: ICustomTableHeader[];
	hideHeader?: boolean;
	numColumns?: number;
	externalLine?: boolean;
	delayedText?: string;
};

const Profile: React.FC<ProfileProps> = ({
	profileData,
	mapping = [],
	header = [],
	numColumns = 2,
	hideHeader,
	externalLine,
	delayedText,
}) => {
	if (!profileData) {
		return null;
	}
	const isMobileView = getDeviceType() === E_DeviceType.Mobile;
	const numOfCols = isMobileView ? 1 : numColumns;
	const itemsPerColumn = Math.ceil(mapping.length / numOfCols);
	const columns: any[][] = [];

	for (let i = 0; i < numOfCols; i++) {
		const startIdx = i * itemsPerColumn;
		const endIdx = startIdx + itemsPerColumn;
		const columnItems = mapping.slice(startIdx, endIdx);
		columns.push(columnItems);
	}

	const className = numOfCols === 1 ? 'one-column' : 'multi-column';

	const tableItem = (item: any, lastRow: boolean) => {
		const headerItem = header?.find((x) => x.key === item.key);
		return (
			<div
				className={`${'profile-field'} ${
					externalLine && lastRow ? 'last-row' : ''
				}`}
				key={`col_${item.key}`}
			>
				<Label text={headerItem?.label ?? TEXT_NULL_VALUE} isDarkGrey />
				{generateColValue(item, profileData[item.key])}
			</div>
		);
	};

	const getContent = () => {
		return (
			<div className="profile-container" data-testid="profile-container">
				{!isMobileView && !hideHeader && (
					<Heading type="h3" content={'Profile'} align="left" />
				)}
				<div className={`profile-info ${className}`}>
					{columns?.map((columnItems, columnIndex) => {
						const keyIndex = `column_${columnIndex}`;
						const keyIndexClass = `profile-${keyIndex}`;
						return (
							<div
								className={`${'profile-column'} ${keyIndexClass}`}
								key={keyIndex}
							>
								{columnItems?.map((item, idx) => {
									return tableItem(item, idx === columnItems.length - 1);
								})}
							</div>
						);
					})}
				</div>
				{delayedText && delayedText.length > 0 && (
					<div className="delay-info-wrapper">
						<Label
							text={delayedText}
							align={isMobileView ? 'left' : 'right'}
							isGrey
							size="s"
						/>
					</div>
				)}
			</div>
		);
	};

	return (
		<>
			{!isMobileView && getContent()}
			{isMobileView && (
				<div className="profile-collapse-container">
					<Collapsible title="Profile">{getContent()}</Collapsible>
				</div>
			)}
		</>
	);
};

export default Profile;
