import React, { useEffect, useState } from 'react';
import './save-chart-settings.scss';
import {
	Modal,
	Button,
	ButtonVariant,
	Textbox,
	MessageBox,
	MessageBoxStateType,
	Table,
	Loader,
} from '../../../../../@core-ui';
import {
	SAVE_SETTINGS_EMPTY_ERROR,
	SAVE_SETTINGS_HEADER,
	SAVE_SETTINGS_LIMIT_ERROR,
	SAVE_SETTINGS_PLACEHOLDER,
	SAVE_SETTINGS_SUCCESS,
} from '../../indices-charts-constants';
import { getSavedChartData } from '../../chart-container/charts-utils';
import { DeleteIcon } from '../../../../../assets/Icons';
import { savechartSettings } from '../../../../../services/chart-data-request-methods';
import { ISaveChart } from '../../../../../types/interfaces/IChartSettings';
import { handleEnterKeyPress } from '../../../../../utilities/utils';

const SaveChartSettings = (props: any) => {
	const [settingsName, setSettingsName] = useState('');
	const [settingsList, setSettingsList] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [alertInfo, setAlertInfo] = useState({
		showAlert: false,
		content: SAVE_SETTINGS_SUCCESS,
		state: MessageBoxStateType.Success,
	});

	useEffect(() => {
		if (
			settingsList?.length <= 9 &&
			alertInfo.content === SAVE_SETTINGS_LIMIT_ERROR
		) {
			setAlertInfo({ ...alertInfo, showAlert: false, content: '' });
		}
	}, [settingsList]);

	useEffect(() => {
		loadSavedSettings();
	}, []);

	const onAlertCloseClick = () => {
		setAlertInfo({ ...alertInfo, showAlert: false });
	};

	const onClose = () => {
		setSettingsName('');
		props?.closeSettingModal(false);
	};

	const saveSettings = async () => {
		const name = settingsName?.trim();
		if (name) {
			if (settingsList?.length > 9) {
				setAlertInfo({
					content: SAVE_SETTINGS_LIMIT_ERROR,
					state: MessageBoxStateType.Fail,
					showAlert: true,
				});
				return false;
			}
			const saveSettingName = await formattedSettingsName(name);
			props?.saveSettings(saveSettingName);
			setSettingsName('');
			setAlertInfo({
				content: SAVE_SETTINGS_SUCCESS,
				state: MessageBoxStateType.Success,
				showAlert: true,
			});
			setTimeout(() => {
				props?.closeSettingModal(false);
			}, 2000);
		} else {
			setAlertInfo({
				content: SAVE_SETTINGS_EMPTY_ERROR,
				state: MessageBoxStateType.Fail,
				showAlert: true,
			});
			return false;
		}
	};

	const loadSavedSettings = async () => {
		setIsLoading(true);
		const data = await getSavedChartData();
		if (data?.length > 9) {
			setAlertInfo({
				content: SAVE_SETTINGS_LIMIT_ERROR,
				state: MessageBoxStateType.Fail,
				showAlert: true,
			});
		}
		setSettingsList(data);
		setIsLoading(false);
	};

	const formattedSettingsName = async (name: string) => {
		const parseData = await getSavedChartData();
		if (
			parseData?.find(
				(x: any) => x.chartName.toLowerCase() === name.toLowerCase(),
			)
		) {
			const search = new RegExp(name, 'i');
			const duplicateItem = parseData
				.filter((item: any) => search.test(item.chartName))
				.pop();
			const repeatedCount =
				(duplicateItem?.chartName.match(/\(([^)]+)\)/) &&
					duplicateItem?.chartName.match(/\(([^)]+)\)/)[1]) ||
				0;
			const settingName = `${name} (${Number(repeatedCount) + 1})`;
			if (
				parseData?.find(
					(x: any) => x.chartName.toLowerCase() === settingName.toLowerCase(),
				)
			) {
				const newSettingName =
					settingName.charAt(0).toUpperCase() + settingName.slice(1);
				const duplicateSettingNameList = parseData
					.filter((i: any) =>
						i.chartName.includes(`${newSettingName.split('(')[0]}(`),
					)
					.sort((a: any, b: any) => a.chartName.localeCompare(b.chartName));
				const duplicateNewSettingName = duplicateSettingNameList.pop();
				const repeatedCountName =
					(duplicateNewSettingName?.chartName.match(/\(([^)]+)\)/) &&
						duplicateNewSettingName?.chartName.match(/\(([^)]+)\)/)[1]) ||
					0;
				const settingNewName = `${name} (${Number(repeatedCountName) + 1})`;
				return settingNewName.charAt(0).toUpperCase() + settingNewName.slice(1);
			}
			return settingName.charAt(0).toUpperCase() + settingName.slice(1);
		}
		return name.charAt(0).toUpperCase() + name.slice(1);
	};

	const updateSettingName = (e: any) => {
		const value = e.target.value;
		setSettingsName(value);
	};

	const onDeleteSetting = async (deletedSettingId: string) => {
		const updatedSettingsItems = settingsList.filter(
			(item: any) => item?.id !== deletedSettingId,
		);
		setSettingsList(updatedSettingsItems);
		savechartSettings(updatedSettingsItems);
		props.updateSavedChartData(updatedSettingsItems as ISaveChart[]);
	};

	const getTableColHTML = (item: any) => {
		return (
			<button
				className="delete-button"
				onClick={() => onDeleteSetting(item.id as string)}
				onKeyDown={(e) =>
					handleEnterKeyPress(e, () => onDeleteSetting(item.id as string))
				}
				data-testid="delete-holding"
			>
				<DeleteIcon height={14} width={14} />
			</button>
		);
	};

	const getBody = () => {
		if (isLoading) return <Loader />;
		if (settingsList?.length > 0)
			return (
				<Table
					data-testid="testCustomizeWatchlistTable"
					data={settingsList}
					showHorizontalBorder={true}
					columns={[
						{ header: 'Saved Settings', accessor: 'chartName' },
						{
							header: '',
							accessor: getTableColHTML,
						},
					]}
				/>
			);
	};

	const isNotMoreThanLimitRecords =
		alertInfo.content !== SAVE_SETTINGS_LIMIT_ERROR;

	return (
		<Modal
			onClose={onClose}
			title={SAVE_SETTINGS_HEADER}
			id="saveSettingsModal"
		>
			<div className="save-settings-container">
				{isNotMoreThanLimitRecords && (
					<div className="settings-name">
						<Textbox
							testId={`settings-name-textbox`}
							className={`textbox`}
							onChange={updateSettingName}
							placeholder={SAVE_SETTINGS_PLACEHOLDER}
							maxlength={20}
						/>
						<Button
							onClick={saveSettings}
							variant={ButtonVariant.Primary}
							testId={'save-chart-name'}
						>
							Save
						</Button>
					</div>
				)}
				{alertInfo.showAlert && (
					<MessageBox
						content={alertInfo.content}
						state={alertInfo.state}
						enableCloseIcon={true}
						onClose={onAlertCloseClick}
						autoHide={true}
					/>
				)}
				{getBody()}
			</div>
		</Modal>
	);
};

export default SaveChartSettings;
