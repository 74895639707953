import React from 'react';
import { IBasicIconProps } from '../IBasicIconProps';

const LineChartIcon: React.FC<IBasicIconProps> = ({
	width = 21,
	height = 17,
	color = '#000',
}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 14.9364277 15.0001263"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				transform="translate(-785.000000, -433.000000)"
				stroke={color}
				strokeWidth="1.3"
			>
				<polyline points="786.033076 446.062607 794.001969 438 797 441 804 434"></polyline>
			</g>
		</g>
	</svg>
);

export default LineChartIcon;
