import DataRequest from './dataRequest';
import URLS from '../constants/urlConstants';
import { ILipperFund } from '../types/interfaces';
import { resolvePromiseWithError } from '../utilities/utils';

export const getLipperFundData = async (
	FundNo: number,
): Promise<ILipperFund> => {
	if (!FundNo) {
		return await resolvePromiseWithError();
	}
	return await DataRequest.execute(URLS.CUSTOM.LIPPER_FUND, {
		params: {
			FundNo: FundNo,
		},
	});
};
