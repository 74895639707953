import React from 'react';
import { IBasicIconProps } from '../IBasicIconProps';

const DividendsIcon: React.FC<IBasicIconProps> = ({
	width = 21,
	height = 17,
	color = '#000',
}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g
			id="dividends-stroke"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<g
				id="dividends-transform"
				transform="translate(-803, -521)"
				fill={color}
				fillRule="nonzero"
			>
				<g id="dividends-ropdown" transform="translate(775, 479)">
					<g id="ic_dividends" transform="translate(28, 42)">
						<path
							d="M12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 Z M12,2.3 C6.64283793,2.3 2.3,6.64283793 2.3,12 C2.3,17.3571621 6.64283793,21.7 12,21.7 C17.3571621,21.7 21.7,17.3571621 21.7,12 C21.7,6.64283793 17.3571621,2.3 12,2.3 Z M12,5 C12.3589851,5 12.65,5.29101491 12.65,5.65 L12.6506712,6.87644489 C14.7725766,7.05442681 16.15,8.12764204 16.15,10 C16.15,10.3589851 15.8589851,10.65 15.5,10.65 C15.1410149,10.65 14.85,10.3589851 14.85,10 C14.85,8.9518236 14.086599,8.32041574 12.6502178,8.17993539 L12.6516244,11.4207719 C12.8366444,11.4415454 12.932245,11.4525693 13.0127214,11.4627578 L13.1042912,11.4748131 C13.1354479,11.4790562 13.1687028,11.4836997 13.2084497,11.4892707 C15.0892739,11.7528931 16.15,12.4108069 16.15,14 C16.15,15.9117999 14.7936441,16.9542308 12.6512609,17.1249322 L12.65,18.35 C12.65,18.7089851 12.3589851,19 12,19 C11.6410149,19 11.35,18.7089851 11.35,18.35 L11.3501046,17.1240203 C9.22380637,16.9484658 7.85,15.8853438 7.85,14 C7.85,13.6410149 8.14101491,13.35 8.5,13.35 C8.85898509,13.35 9.15,13.6410149 9.15,14 C9.15,15.0575735 9.90923796,15.6823695 11.3499628,15.820667 L11.3506107,12.5769462 C11.1675461,12.5552086 11.0755809,12.5439657 11.0019352,12.5340162 L10.9233708,12.5229415 C10.8841337,12.5171962 10.842129,12.5107807 10.7845976,12.5020818 C8.91751403,12.2197748 7.85,11.5534654 7.85,10 C7.85,8.12361769 9.22526008,7.05336181 11.3498091,6.87625523 L11.35,5.65 C11.35,5.29101491 11.6410149,5 12,5 Z M12.6525221,12.7291468 L12.6510799,15.8221386 C14.1073109,15.6890463 14.85,15.0794354 14.85,14 C14.85,13.2765498 14.3407437,12.9606843 13.0280014,12.7766861 C12.8925329,12.7576984 12.8388759,12.7504078 12.6525221,12.7291468 Z M9.15,10 C9.15,10.6913055 9.67613655,11.0197038 10.9789513,11.2166922 C11.1256485,11.2388731 11.1612494,11.2447358 11.3491583,11.2674958 L11.3507606,8.17960885 C9.91146619,8.31911066 9.15,8.9483834 9.15,10 Z"
							id="dividends"
						></path>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default DividendsIcon;
