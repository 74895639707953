export const INDICES_NAME = ['U.S. Indices', 'World Indices', 'Indices Charts'];

export const INDICES_NAMES = {
	AmericanIndices: 'U.S. Indices',
	WorldIndices: 'World Indices',
	IndicesCharts: 'Indices Charts',
};

export const INDICES_KEY_VALUE_DATA = [
	{ key: 'U.S. Indices', value: 'U.S. Indices' },
	{ key: 'World Indices', value: 'World Indices' },
	{ key: 'Indices Charts', value: 'Indices Charts' },
];

export const MARKET_INDICES_TAB = 'MARKET_INDICES_TAB';
