import React from 'react';
import './separator.scss';

interface SeparatorProps {
	color?: string;
}
const Separator: React.FC<SeparatorProps> = ({ color = 'gray' }) => {
	return <div className={`separator separator-${color}`}></div>;
};

export default Separator;
