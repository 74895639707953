import React from 'react';
import {
	OptionQuoteHeaderMapping,
	OptionQuoteHeader,
	OptionQuoteKeys,
	HeaderGroups,
	IPuBidOptionQuoteItem,
	LastTradePriceLabel,
} from '../../options-constants';
import { generateColValue } from '../../../quotes-news/portfolio/portfolio-utils';
import { Label } from '../../../../@core-ui';
import { TEXT_NULL_VALUE } from '../../../../utilities/formatter';
import './option-quote-bar.scss';

type OptionQuoteBarProps = {
	quoteData: IPuBidOptionQuoteItem;
};

const OptionQuoteBar: React.FC<OptionQuoteBarProps> = ({ quoteData }) => {
	const renderValueCell = (item: any, group?: string) => {
		if (group === HeaderGroups.Change) {
			return (
				<>
					{generateColValue(item, quoteData[OptionQuoteKeys.DollarChange])}
					{generateColValue(item, quoteData[OptionQuoteKeys.PercentChange])}
				</>
			);
		} else if (group === HeaderGroups.LastTradeDate) {
			return (
				<>
					{generateColValue(item, quoteData[OptionQuoteKeys.LastTradeTime])}
					{generateColValue(item, quoteData[OptionQuoteKeys.LastTradeDate])}
				</>
			);
		} else if (group === HeaderGroups.LastTradePrice) {
			return (
				<>
					{generateColValue(item, quoteData[OptionQuoteKeys.LastTradePrice])}
					<Label
						align="center"
						size="s"
						isGrey
						text={LastTradePriceLabel}
						className="trade-price-label"
					></Label>
				</>
			);
		}
		return generateColValue(item, quoteData[item.key]);
	};

	if (quoteData) {
		return (
			<div className="option-quote-bar">
				<div className="quote-info">
					{OptionQuoteHeaderMapping.map((item) => {
						if (item.skipRenderingCell) {
							return null;
						}
						const header = OptionQuoteHeader.find((x) => x.key === item.key);
						return (
							<div className="quote-field" key={`col_${header?.key}`}>
								<Label text={header?.label ?? TEXT_NULL_VALUE} isDarkGrey />
								<div className="value">{renderValueCell(item, item.group)}</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	}

	return null;
};

export default OptionQuoteBar;
