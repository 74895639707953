import React from 'react';
import './back-link.scss';
import { handleEnterKeyPress } from '../../utilities/utils';

type BackLinkProps = {
	onClick: () => void;
	text?: string;
	showIcon?: boolean;
	testId?: string;
};

const BackLink = ({
	onClick,
	text = 'Back to Landing Page',
	showIcon = true,
	testId = '',
}: BackLinkProps) => {
	return (
		<div className="back-link-container">
			<button
				onClick={onClick}
				className="back-link"
				data-testid={testId}
				onKeyDown={(e) => handleEnterKeyPress(e, onClick)}
			>
				{showIcon && <span className="back-icon">{'<'}</span>}
				<span>{text}</span>
			</button>
		</div>
	);
};

export default BackLink;
