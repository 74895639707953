import React from 'react';
import { IBasicIconProps } from '../IBasicIconProps';

const HLCChartIcon: React.FC<IBasicIconProps> = ({
	width = 21,
	height = 17,
	color = '#000',
}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 14.9364277 15.0001263"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				transform="translate(-878.000000, -430.000000)"
				stroke={color}
				strokeWidth="1.3"
			>
				<g id="Group-8" transform="translate(878.000000, 430.000000)">
					<line x1="3" y1="4" x2="3" y2="20" id="Path-6"></line>
					<line x1="11" y1="0" x2="11" y2="13" id="Path-6-Copy"></line>
					<line x1="3" y1="9" x2="6" y2="9" id="Path-7"></line>
					<line x1="11" y1="2" x2="14" y2="2" id="Path-7-Copy-2"></line>
					<line x1="8" y1="10" x2="11" y2="10" id="Path-7-Copy-3"></line>
					<line x1="0" y1="17" x2="3" y2="17" id="Path-7-Copy"></line>
				</g>
			</g>
		</g>
	</svg>
);

export default HLCChartIcon;
