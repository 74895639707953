import React, { useState, useEffect } from 'react';
import {
	getWatchlist,
	getWatchlistHoldings,
	getWatchlistDisplyOptionPreference,
	getNews,
	getQuotes,
	setWatchlistDisplyOptionPreference,
} from '../../../services';
import { getXrefDataByXids } from '../../../services/xref-data-request-methods';
import {
	sortByKey,
	getPerformanceIndicatorColor,
	addQBIDArgument,
	getDeviceType,
	handleWindowResize,
	delayedMessage,
	handleEnterKeyPress,
} from '../../../utilities/utils';
import {
	Table,
	Divider,
	NumberLabel,
	Separator,
	Loader,
	MessageBox,
	Container,
	SymbolDetails,
	LabelLink,
} from './../../../@core-ui';
import {
	NoWatchlistAddedMessage,
	WatchlistDisplayOption,
	WatchlistDisplayOptionLabels,
	WatchlistDisplayOptionPreferenceConstants,
} from '../customize-watchlist/customize-watchlist-constants';
import {
	DEFAULT_NEWS_INPUTS,
	T_DeviceType,
	E_DeviceType,
} from '../../../constants/appConstants';
import {
	PerformanceUpIcon,
	PerformanceDownIcon,
	CloseIcon,
	WatchlistIcon,
} from './../../../assets/Icons';
import './view-watchlists.scss';
import {
	EVENTS_NAME,
	registerCustomEventHandler,
} from '../../../utilities/events';
import ViewNewsModal from '../../../shared/view-news/view-news-modal';
import { getTimeStamp } from '../../../components/quotes-news/news/news-utils';
import { IGetWatchlistsResponse } from '@/types/interfaces';

interface IWatchlistItem {
	holdingId: string;
	xid: number;
	exchangeName: string;
	countryIso: string;
	symbol: string;
	name: string;
	last: number;
	changeInPercent: number;
	changeInDollar: number;
	headline?: string;
	insertDateTime: string;
	articleId?: string;
	timeStamp?: string;
	provider?: string;
}
interface WatchlistsViewProps {
	customizeWatchlist: () => void;
	handleToggleDrawer?: () => void;
	isWatchlistDrawerOpen?: boolean;
}

const NO_NEWS_ARTICLE = 'No recent news articles';

const WatchlistsView: React.FC<WatchlistsViewProps> = ({
	customizeWatchlist,
	handleToggleDrawer,
	isWatchlistDrawerOpen,
}) => {
	const [isLastChangeView, setIsLastChangeView] = useState<boolean>(true);
	const [watchlistItems, setWatchlistItems] = useState<IWatchlistItem[]>([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [loadingState, setLoadingState] = useState({
		isLoading: true,
		isHoldingsRetrieved: false,
		holdingCount: 0,
	});
	const [device, setDevice] = useState<T_DeviceType>(getDeviceType());
	const isDesktopView = device === E_DeviceType.Desktop;
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [modalData, setModalData] = useState<any>();
	const [displayOption, setDisplayOption] = useState(
		WatchlistDisplayOption.LastAndChg,
	);

	useEffect(() => {
		async function getWatchlistPreference() {
			const preferenceResponse = await getWatchlistDisplyOptionPreference();
			if (preferenceResponse?.data?.data?.value) {
				const preferenceValue = JSON.parse(preferenceResponse.data.data.value);
				const displayOption =
					WatchlistDisplayOptionPreferenceConstants.LAST_CHANGE ===
					preferenceValue
						? WatchlistDisplayOption.LastAndChg
						: WatchlistDisplayOption.MostRecentSecurityNews;
				setDisplayOption(displayOption);
			}
		}
		getWatchlistPreference();
	}, []);

	useEffect(() => {
		const handleResize = () => {
			const deviceType = getDeviceType();
			setDevice(deviceType);
		};

		handleWindowResize(handleResize);
		registerCustomEventHandler(EVENTS_NAME.MD_ADD_TO_WATCHLIST_EVENT, () => {
			fetchData();
		});
		fetchData();
	}, [displayOption]);

	function showNewsArticle(item: any) {
		setModalData(item);
		setIsModalOpen(true);
		document.body.style.overflowY = 'hidden';
	}

	function hideNewsArticle() {
		setIsModalOpen(false);
		document.body.style.overflowY = 'auto';
	}

	const buildNewsInputs = (bridgeSymbol: string) => {
		const newsInputs: any = { ...DEFAULT_NEWS_INPUTS };
		newsInputs.limit = 1;
		newsInputs.arguments = [addQBIDArgument('BridgeSymbols', [bridgeSymbol])];
		return newsInputs;
	};

	const getNewsData = async (watchlistHoldingItems: IWatchlistItem[]) => {
		const newsPromises = watchlistHoldingItems.map((item) => {
			const bridgeSymbol = `|${item?.countryIso};${item?.symbol}|`;
			const newsInput = buildNewsInputs(bridgeSymbol);
			return getNews(newsInput);
		});

		const newsResponses = await Promise.all(newsPromises);
		const updatedWatchlistItems = watchlistHoldingItems.map((item, index) => {
			const newsResponse = newsResponses[index];
			if (newsResponse && newsResponse.data?.data?.items?.length > 0) {
				const newsItem = newsResponse.data.data.items[0];
				return {
					...item,
					headline: newsItem?.Headline,
					articleId: newsItem?.articleId,
					provider: newsItem?.SourceCode,
					timeStamp: getTimeStamp(newsItem?.DocumentDate),
				};
			}
			return { ...item, headline: NO_NEWS_ARTICLE };
		});

		setWatchlistItems(updatedWatchlistItems);
		setLoadingState({
			isLoading: false,
			isHoldingsRetrieved: true,
			holdingCount: updatedWatchlistItems.length,
		});
	};

	const prepareWatchlistResponse = async (
		watchlistResponse: IGetWatchlistsResponse,
	) => {
		let items: IWatchlistItem[] = [];
		if (watchlistResponse && watchlistResponse.data?.data?.items?.length > 0) {
			const watchlistId = watchlistResponse.data.data.items[0].id;
			const holdingsResponse = await getWatchlistHoldings(watchlistId);
			if (holdingsResponse && holdingsResponse.data?.data?.items?.length > 0) {
				const holdings = holdingsResponse.data?.data?.items;
				const xids = holdings.map((x: { id: string; xid: number }) => x.xid);
				const [xrefResponse, quoteResponse] = await Promise.all([
					getXrefDataByXids(xids),
					getQuotes(xids),
				]);

				items = holdings
					.map((holding) => {
						const xrefItem = xrefResponse?.data?.data?.items?.find(
							(xrefData) => xrefData?.xids?.venue === holding?.xid,
						);
						const quotes = quoteResponse?.data?.data?.quotes;
						const quoteItem = quotes.find(
							(quote) => quote?.data?.venueXid === holding?.xid,
						)?.data;

						if (xrefItem) {
							return getData(quoteItem, xrefItem, holding);
						} else {
							return {
								holdingId: holding?.id,
								exchangeName: '--',
								countryIso: '--',
								name: '--',
								symbol: holding?.description ?? '--',
								xid: holding?.xid,
								last: quoteItem?.lastTrade?.last ?? '--',
								changeInPercent: quoteItem?.changePercent?.today ?? '--',
								changeInDollar: quoteItem?.lastTrade?.change ?? '--',
								insertDateTime: holding?.insertDateTime,
							};
						}
					})
					.filter(Boolean) as IWatchlistItem[];
			}
		}
		return items;
	};

	const fetchData = async () => {
		let items: IWatchlistItem[] = [];
		const [watchlistResponse, preferenceResponse] = await Promise.all([
			getWatchlist(),
			getWatchlistDisplyOptionPreference(),
		]);
		let isLastChangeFormat = true;
		if (preferenceResponse?.data?.data?.value) {
			const preferenceValue = JSON.parse(preferenceResponse.data.data.value);
			isLastChangeFormat =
				WatchlistDisplayOptionPreferenceConstants.LAST_CHANGE ===
				preferenceValue;
			setIsLastChangeView(isLastChangeFormat);
		}

		items = await prepareWatchlistResponse(watchlistResponse);

		const sortedWatchlistItems = [...items].sort(
			sortByKey('insertDateTime', 'desc', 'symbol', 'asc'),
		);
		setWatchlistItems(sortedWatchlistItems);
		setLoadingState({
			isLoading: !isLastChangeFormat,
			isHoldingsRetrieved: true,
			holdingCount: items.length,
		});
		if (!isLastChangeFormat) {
			getNewsData(sortedWatchlistItems);
		}
	};

	const handleWatchlistPreference = async (value: WatchlistDisplayOption) => {
		if (value !== displayOption) {
			setLoadingState({
				isLoading: true,
				isHoldingsRetrieved: false,
				holdingCount: 0,
			});
		}
		await setWatchlistDisplyOptionPreference(value);
		setDisplayOption(value);
	};

	const getData = (quoteItem: any, xrefItem: any, holding: any) => {
		return {
			holdingId: holding.id,
			exchangeName: xrefItem.exchange?.name ?? '',
			countryIso: xrefItem.exchange?.countryIso ?? '',
			name: xrefItem.name ?? '',
			symbol: xrefItem.symbol ?? '',
			xid: holding.xid,
			last: quoteItem?.lastTrade?.last ?? 0,
			changeInPercent: quoteItem?.changePercent?.today ?? 0,
			changeInDollar: quoteItem?.lastTrade?.change ?? 0,
			insertDateTime: holding?.insertDateTime,
		};
	};

	const renderCellContent = (item: IWatchlistItem) => {
		const performanceIndicatorColorClass = getPerformanceIndicatorColor(
			item.changeInPercent,
		);
		const changeIcon =
			item.changeInPercent > 0 ? (
				<PerformanceUpIcon />
			) : (
				<PerformanceDownIcon />
			);
		if (isLastChangeView) {
			return (
				<div className="last-change-row-wrapper" data-testid="testLastChgRow">
					<div
						className={`performance-indicator-bar ${performanceIndicatorColorClass}`}
					></div>
					<div className="cell-wrapper name-wrapper">
						<button
							className="name"
							onClick={handleToggleDrawer}
							onKeyDown={() => handleToggleDrawer}
						>
							<SymbolDetails
								venueXid={item.xid}
								displayName={item.name}
								isClickable={item.name != '--'}
							/>
						</button>
						<span className="symbol">
							{`${item.exchangeName}: ${item.symbol}`}
						</span>
					</div>
					<div className="cell-wrapper">
						<span className="last">LAST</span>
						<span className="last-value">
							<NumberLabel value={item.last.toString()} isBold={true} />
						</span>
					</div>
					<div className="cell-wrapper">
						<div className="change">
							<span>CHANGE</span>
						</div>
						<div className="change-wrapper">
							<span>
								<NumberLabel
									value={item.changeInDollar.toString()}
									numberConfig={{
										preFix: true,
										showColor: true,
									}}
								/>
							</span>
							<Divider />
							<span>
								<NumberLabel
									value={item.changeInPercent.toString()}
									numberConfig={{
										preFix: true,
										showColor: true,
										showPercent: true,
									}}
								/>
							</span>
						</div>
					</div>
					<div className="cell-wrapper change-icon">
						{item.changeInPercent !== 0 && changeIcon}
					</div>
				</div>
			);
		} else {
			return (
				<div className="news-row-wrapper" data-testid="testNewsRow">
					<div
						className={`performance-indicator-bar ${performanceIndicatorColorClass}`}
					></div>
					<div className="cell-wrapper">
						<div className="symbol-headline-wrapper">
							<div className="symbol-info">
								<button
									className="name"
									onClick={handleToggleDrawer}
									onKeyDown={() => handleToggleDrawer}
								>
									<SymbolDetails
										venueXid={item.xid}
										displayName={item.name}
										isClickable={item.name != '--'}
									/>
								</button>
								<span className="symbol">
									{`${item.exchangeName}: ${item.symbol}`}
								</span>
							</div>
							{item.headline !== NO_NEWS_ARTICLE ? (
								<div title={item.headline} data-testid="news-headline">
									<LabelLink
										text={item.headline as string}
										tabIndex={0}
										className="news-headline"
										onClick={() => showNewsArticle(item)}
										onKeyDown={(e) =>
											handleEnterKeyPress(e, () => showNewsArticle(item))
										}
									/>
								</div>
							) : (
								<div
									className="news-headline no-news"
									data-testid="no-news-headline"
									title={item.headline}
								>
									{item.headline}
								</div>
							)}
						</div>
						{item.changeInPercent !== 0 && changeIcon}
					</div>
				</div>
			);
		}
	};

	const getTableColHTML = (item: IWatchlistItem) => {
		return (
			<>
				<Separator />
				{item && renderCellContent(item)}
				<Separator />
			</>
		);
	};

	const getContent = () => {
		if (loadingState.isHoldingsRetrieved && !loadingState.holdingCount) {
			return <MessageBox content={NoWatchlistAddedMessage} />;
		}
		return (
			<Table
				hideHeader={true}
				columns={[
					{
						header: 'Watchlist',
						accessor: getTableColHTML,
					},
				]}
				data={watchlistItems}
				currentPage={currentPage}
				onPageChange={setCurrentPage}
				showPagination
				showHorizontalBorder={false}
				pageSize={10}
				marketDelayedData={delayedMessage()}
			/>
		);
	};

	const getMobileView = () => {
		const isIpadView = device === E_DeviceType.Ipad;
		const parentClassName = isIpadView ? 'ipad-view' : 'mobile-view';
		return (
			<div
				className={`${parentClassName} ${isWatchlistDrawerOpen ? 'open' : ''}`}
			>
				<div className="watchlist-bottom-drawer">
					<button
						className="row"
						onClick={handleToggleDrawer}
						onKeyDown={() => handleToggleDrawer}
					>
						<WatchlistIcon />
						{!isIpadView && <div className="header">Watchlist</div>}
					</button>
				</div>
				{isWatchlistDrawerOpen && (
					<div className="watchlist-drawer">
						{isIpadView && (
							<button
								className="header-icon"
								onClick={handleToggleDrawer}
								onKeyDown={() => handleToggleDrawer}
							>
								<WatchlistIcon />
							</button>
						)}
						<div className="content">
							<div className="header">
								<div className="header-left">
									{!isIpadView && (
										<button
											className="header-icon"
											onClick={handleToggleDrawer}
											onKeyDown={() => handleToggleDrawer}
										>
											<WatchlistIcon />
										</button>
									)}
									<div className="header-name">Watchlist</div>
									<div className="customize-watchlist-link">
										<button
											data-testid="customizeWatchlist"
											onClick={customizeWatchlist}
											onKeyDown={() => customizeWatchlist}
										>
											Customize
										</button>
									</div>
								</div>

								<div className="header-right">
									<button
										className="close-button"
										onClick={handleToggleDrawer}
										onKeyDown={() => handleToggleDrawer}
									>
										<CloseIcon height={15} width={15} />
									</button>
								</div>
							</div>
							{loadingState.isLoading && <Loader />}
							<div className="display-type-container">
								<LabelLink
									text={WatchlistDisplayOptionLabels.LAST_CHANGE}
									data-testid={WatchlistDisplayOptionLabels.LAST_CHANGE}
									className={`${
										displayOption !== WatchlistDisplayOption.LastAndChg
											? 'labelLink-active'
											: ''
									}`}
									onClick={() =>
										handleWatchlistPreference(WatchlistDisplayOption.LastAndChg)
									}
								/>
								<div className="seperator">|</div>
								<LabelLink
									text={WatchlistDisplayOptionLabels.RECENT_NEWS}
									data-testid={WatchlistDisplayOptionLabels.RECENT_NEWS}
									className={`${
										displayOption !==
										WatchlistDisplayOption.MostRecentSecurityNews
											? 'labelLink-active'
											: ''
									}`}
									onClick={() =>
										handleWatchlistPreference(
											WatchlistDisplayOption.MostRecentSecurityNews,
										)
									}
								/>
							</div>
							{getContent()}
						</div>
					</div>
				)}
			</div>
		);
	};

	const getHeaderView = () => {
		return (
			<div className="header">
				<div className="watchlist-left">
					<div className="watchlist-header">Watchlist</div>
					<div className="customize-watchlist-link">
						<button
							onClick={customizeWatchlist}
							onKeyDown={(e) => {
								handleEnterKeyPress(e, customizeWatchlist);
							}}
							data-testid="customizeWatchlist"
						>
							Customize
						</button>
					</div>
				</div>
				<div className="display-type-container">
					<LabelLink
						text={WatchlistDisplayOptionLabels.LAST_CHANGE}
						data-testid={WatchlistDisplayOptionLabels.LAST_CHANGE}
						className={`${
							displayOption !== WatchlistDisplayOption.LastAndChg
								? 'labelLink-active'
								: ''
						}`}
						onClick={() =>
							handleWatchlistPreference(WatchlistDisplayOption.LastAndChg)
						}
						onKeyDown={() => {
							handleWatchlistPreference(WatchlistDisplayOption.LastAndChg);
						}}
					/>
					<div className="seperator">|</div>
					<LabelLink
						text={WatchlistDisplayOptionLabels.RECENT_NEWS}
						data-testid={WatchlistDisplayOptionLabels.RECENT_NEWS}
						className={`${
							displayOption !== WatchlistDisplayOption.MostRecentSecurityNews
								? 'labelLink-active'
								: ''
						}`}
						onClick={() =>
							handleWatchlistPreference(
								WatchlistDisplayOption.MostRecentSecurityNews,
							)
						}
						onKeyDown={() => {
							handleWatchlistPreference(
								WatchlistDisplayOption.MostRecentSecurityNews,
							);
						}}
					/>
				</div>
			</div>
		);
	};

	const getDesktopView = () => {
		if (loadingState.isLoading) return <Loader />;
		return (
			<>
				{getHeaderView()}
				{getContent()}
			</>
		);
	};

	return (
		<div
			className="view-watchlists-container"
			data-testid={'view-watchlists-container'}
		>
			{!isDesktopView && getMobileView()}

			{isDesktopView && (
				<Container
					applyPadding
					applyBorder
					applyWhiteBackground
					hasLoader={loadingState?.isLoading}
				>
					{getDesktopView()}
				</Container>
			)}
			{isModalOpen && (
				<ViewNewsModal
					articleId={modalData?.articleId}
					provider={modalData?.provider}
					timeStamp={modalData?.timeStamp}
					onClose={hideNewsArticle}
				/>
			)}
		</div>
	);
};

export default WatchlistsView;
