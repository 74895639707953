import './modal.scss';
import React, { useState, useEffect, useRef } from 'react';
import { Heading } from '../Heading/heading';
import { escapeKeyHandler, handleEnterKeyPress } from '../../utilities/utils';
import { CloseIcon } from '../../assets/Icons';

export interface ModalProps {
	title?: string;
	onClose?: () => void;
	children?: React.ReactNode;
	footer?: React.JSX.Element;
	id?: string;
}

const Modal: React.FC<ModalProps> = ({
	title,
	onClose,
	children,
	footer,
	id = '',
}) => {
	const [isOpen, setIsOpen] = useState(true);
	const modalRef = useRef<HTMLDivElement>(null);

	const handleKeyDown = (event: any, islastElementClicked = false) => {
		escapeKeyHandler(event, handleClose);
		if (event.key === 'Tab' && modalRef.current) {
			const firstElement = modalRef.current.querySelector('h3') as HTMLElement;
			const lastElement = modalRef.current.querySelector(
				'button#lastFocusableElement',
			) as HTMLElement;
			if (event.shiftKey && document.activeElement === firstElement) {
				lastElement.focus();
				event.preventDefault();
			} else if (!event.shiftKey && islastElementClicked) {
				firstElement.focus();
				event.preventDefault();
			}
		}
	};

	useEffect(() => {
		if (isOpen) {
			document.addEventListener('keydown', handleKeyDown);
			document.body.classList.add('modal-open');
			const closeButton = modalRef?.current?.querySelector(
				'.close-button',
			) as HTMLButtonElement;
			closeButton?.focus();
		} else {
			document.removeEventListener('keydown', handleKeyDown);
		}

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [isOpen]);

	const handleClose = () => {
		setIsOpen(false);
		onClose?.();
		document.body.classList.remove('modal-open');
	};

	return (
		<>
			{isOpen && (
				<div className="modal-overlay" id={id} ref={modalRef}>
					<div className="modal-container">
						<div className="modal-header">
							<Heading type="h3" content={title} />
							<button
								className="close-button"
								data-testid="modalCloseBtn"
								onClick={handleClose}
								onKeyDown={(e) => handleEnterKeyPress(e, handleClose)}
							>
								<CloseIcon />
							</button>
						</div>
						<div className="modal-body">{children}</div>
						{footer && <div className="modal-footer">{footer}</div>}
					</div>
					<button
						id="lastFocusableElement"
						style={{ position: 'absolute', top: '-100px' }}
						onKeyDown={(e) => handleKeyDown(e, true)}
					/>
				</div>
			)}
		</>
	);
};

export default Modal;
