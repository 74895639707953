import React from 'react';
import './overlay-popover.scss';
import { OVERLAY_OPTIONS } from '../../indices-charts-constants';
import { LabelLink } from '../../../../../@core-ui';
import { handleEnterKeyPress } from '../../../../../utilities/utils';

const OverlayPopover = (props: any) => {
	const overlayHandler = (overlayName: string) => {
		props.getSelectedOverlay(overlayName);
	};

	return (
		<div className="overlay-popup">
			{OVERLAY_OPTIONS.map((item: any) => (
				<div key={item.key} className="overlay-item">
					<LabelLink
						size={'m'}
						key={item.key}
						text={item.value}
						testId={item.key}
						dataKey={item.key}
						onClick={() => {
							overlayHandler(item.key);
						}}
						onKeyDown={(event) => {
							handleEnterKeyPress(event, () => {
								overlayHandler(item.key);
							});
						}}
					/>
				</div>
			))}
		</div>
	);
};

export default OverlayPopover;
