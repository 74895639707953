import React from 'react';
import { CloseIcon } from '../../assets/Icons';
import './filter-tag.scss';
import { handleEnterKeyPress } from '../../utilities/utils';

const FilterTag = (props: any) => {
	return (
		<div className="filterTagContainer">
			<div className="tag-container">
				<div className="tag-box" style={{ background: props.color }}></div>
				<div className="header">{props.label}</div>
			</div>
			<button
				className="closeButton"
				data-testid="closeButton"
				onClick={props.onClose}
				onKeyDown={(event) => {
					handleEnterKeyPress(event, () => {
						props.onClose();
					});
				}}
			>
				<CloseIcon height={15} width={15} />
			</button>
		</div>
	);
};

export default FilterTag;
