import { formatNumber, textFormatter } from '../../../../utilities/formatter';
import { E_SecurityType, SecurityType } from '../../snapshot-constants';
import {
	convertUtcDttmToET,
	getConvertedDate,
} from '../../../../utilities/date-time-formatter-tz';
import {
	DATE_FORMATS,
	formatDateTime,
} from '../../../../utilities/date-time-formatter';
import {
	ICurrentDividends,
	IEquitiesCurrentRatios,
	IHistoricalDividendsResponse,
	ILipperFundData,
	IPricingCurrentEndOfDayResponse,
	IQuote,
} from '../../../../types/interfaces';
import { FinancialStatusMapping } from './overview-page-constants';

interface IcompanyProfile {
	quoteData: IQuote,
	currentRatiosData: IEquitiesCurrentRatios | undefined,
	dividendsData: ICurrentDividends | undefined,
	pricingEndOfStats: IPricingCurrentEndOfDayResponse | undefined,
	historicalData: IHistoricalDividendsResponse | undefined,
	fundData: ILipperFundData | undefined,
	lastDistributionDate: string | undefined,
	type: string,
}

export const setCompanyProfileData = ({quoteData,
	currentRatiosData,
	dividendsData,
	pricingEndOfStats,
	historicalData,
	fundData,
	lastDistributionDate,
	type,}: IcompanyProfile) => {
	return {
		[E_SecurityType.equity]: {
			MarketCap: formatNumber(quoteData?.data?.marketCap, {
				internationalCurrency: true,
			})?.value,
			Revenue: formatNumber(currentRatiosData?.incomeStatement?.revenueTtm, {
				internationalCurrency: true,
			})?.value,
			EPS: formatNumber(
				currentRatiosData?.perShare?.epsIncludingExtraordinaryItemsTtm,
				{
					internationalCurrency: true,
				},
			)?.value,
			UpcomingDividendAmount: formatNumber(
				dividendsData?.currentDividend?.amount,
				{
					currencyPreFixValue: '$',
				},
			)?.value,
			AnnualDividendRate: formatNumber(
				dividendsData?.annualizedDividend?.gross,
				{
					currencyPreFixValue: '$',
				},
			)?.value,
			AnnualDividendYield: formatNumber(
				dividendsData?.annualizedDividend?.yieldGross,
				{
					showPercent: true,
				},
			)?.value,
			PreviousExDate: formatDateTime(dividendsData?.currentDividend?.exDate, {
				format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
			}),
			NextPayDate: formatDateTime(dividendsData?.currentDividend?.paymentDate, {
				format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
			}),
			AverageVolume: formatNumber(quoteData?.data?.volume?.average10Day, {
				commaSeparated: true,
			})?.value,
			PEratio: formatNumber(
				currentRatiosData?.valuation?.peExcludingExtraordinaryItemsTtm,
				{
					postFixValue: 'x',
				},
			)?.value,
			Beta: formatNumber(currentRatiosData?.priceVolume?.beta5Year)?.value,
			WeekHigh:
				quoteData?.data?.price52Week?.highDate ||
				quoteData?.data?.price52Week?.high
					? `(${formatDateTime(quoteData?.data?.price52Week?.highDate, {
						format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
					  })}) ${formatNumber(quoteData?.data?.price52Week?.high)?.value}`
					: '--',
			WeekLow:
				quoteData?.data?.price52Week?.lowDate ||
				quoteData?.data?.price52Week?.low
					? `(${formatDateTime(quoteData?.data?.price52Week?.lowDate, {
						format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
					  })}) ${formatNumber(quoteData?.data?.price52Week?.low)?.value}`
					: '--',
			SharesOutstanding: formatNumber(
				quoteData?.data?.sharesOutstanding * 1000,
				{
					internationalCurrency: true,
				},
			)?.value,
			FinancialStatus:
				FinancialStatusMapping[
					quoteData?.data?.financialStatusIndicator
						? quoteData?.data?.financialStatusIndicator
						: ''
				],
			ProfileDate: `As of ${convertUtcDttmToET(
				quoteData?.data?.lastTrade?.date,
				{
					format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
				},
			)}`,
		},
		[E_SecurityType.etf]: {
			WeekHigh:
				quoteData?.data?.price52Week?.highDate ||
				quoteData?.data?.price52Week?.high
					? `(${formatDateTime(quoteData?.data?.price52Week?.highDate, {
						format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
					  })}) ${formatNumber(quoteData?.data?.price52Week?.high)?.value}`
					: '--',
			WeekLow:
				quoteData?.data?.price52Week?.lowDate ||
				quoteData?.data?.price52Week?.low
					? `(${formatDateTime(quoteData?.data?.price52Week?.lowDate, {
						format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
					  })}) ${formatNumber(quoteData?.data?.price52Week?.low)?.value}`
					: '--',
			Avg10DayVolume: formatNumber(
				pricingEndOfStats?.data?.data?.volume?.['10DaysAvg'],
				{ commaSeparated: true },
			)?.value,
			Beta: formatNumber(pricingEndOfStats?.data?.data?.volatility?.beta3years)
				?.value,
			MarketCap: formatNumber(quoteData?.data?.marketCap, {
				internationalCurrency: true,
			})?.value,
			IssueType: SecurityType[type],
			DistributionFreq: textFormatter(
				dividendsData?.currentDividend?.frequency,
			),
			LastDistribution: `(${formatDateTime(
				historicalData?.data?.data?.dividendEvents[0]?.paymentDate,
				{
					format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
				},
			)}) ${
				formatNumber(dividendsData?.currentDividend?.amountAdjusted)?.value
			}`,
			NextDistribution: 'N/A', // not getting data for ETFs in corporateactions-dividends,
			LastDistributionYield: formatNumber(
				dividendsData?.annualizedDividend?.yieldGross,
			)?.value,
			NextDistributionYield: '--', // not getting data for ETFs in corporateactions-dividends
			SharesOutstanding: formatNumber(
				quoteData?.data?.sharesOutstanding * 1000,
				{
					internationalCurrency: true,
				},
			)?.value,
		},
		[E_SecurityType.mf]: {
			WeekHigh:
				fundData?.date_nav_high || fundData?.nav_high_52wk
					? `(${getConvertedDate(fundData?.date_nav_high, {
						format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
						isMsDate: true,
					  })}) ${formatNumber(fundData?.nav_high_52wk)?.value}`
					: '--',
			WeekLow:
				fundData?.date_nav_low || fundData?.nav_low_52wk
					? `(${getConvertedDate(fundData?.date_nav_low, {
						format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
						isMsDate: true,
					  })}) ${formatNumber(fundData?.nav_low_52wk)?.value}`
					: '--',
			Month1PerChange: fundData?.pfm_4wk,
			Year1PerChange: fundData?.pfm_1yr,
			IssueType: SecurityType[type],
			DistributionFreq: textFormatter(fundData?.div_freq),
			LastDistribution:
				lastDistributionDate !== '--' ? `(${lastDistributionDate})` : '--',
			LastDistributionYield: fundData?.yield,
		},
	};
};
