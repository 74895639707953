import React, { useState, useEffect } from 'react';
import {
	Label,
	Button,
	ButtonVariant,
	SingleSelect,
	Checkbox,
} from '../../../../@core-ui';
import {
	CHART_TIMEFRAMES,
	CHART_TYPE,
	DEFAULT_INDICATOR,
} from '../indices-charts-constants';
import './chart-container.scss';
import Charts from './charts/charts';
import ChartSettings from './chart-settings/chart-settings';
import SaveChartSettings from './chart-settings/save-chart-settings';
import {
	IChartSettings,
	IOverlay,
	IChartList,
	ISaveChart,
	ISymbolComparison,
} from '@/types/interfaces/IChartSettings';
import { DATE_FORMATS } from '../../../../utilities/date-time-formatter';
import { E_DeviceType, T_DeviceType } from '../../../../constants/appConstants';
import { getSavedChartData } from './charts-utils';
import { savechartSettings } from '../../../../services/chart-data-request-methods';
import {
	getDeviceType,
	handleWindowResize,
	delayedMessage,
} from '../../../../utilities/utils';
import { convertUtcDttmToET } from '../../../../utilities/date-time-formatter-tz';

export const DefaultChartSettingName = 'None Selected';

const ChartContainer = (props: any) => {
	const [isSaveSettings, setIsSaveSettings] = useState(false);
	const [isDefault, setIsDefault] = useState(false);
	const [settingName, setSettingName] = useState('');
	const [chartList, setChartList] = useState<IChartList[]>([]);
	const [selectedChartItem, setSelectedChartItem] = useState(
		DefaultChartSettingName,
	);
	const [isChartReset, setIsChartReset] = useState(false);
	const [removeComparisonItem, setRemoveComparisonItem] = useState('');
	const [device, setDevice] = useState<T_DeviceType>(getDeviceType());
	const initialSettings: IChartSettings = {
		venueXid: props.venueXid,
		chartType: CHART_TYPE[0].key,
		timeFrame: CHART_TIMEFRAMES[5].key,
		eventsType: [],
		selectedOverlayData: {} as IOverlay,
		isDefault: isDefault || false,
		comparisonList: [],
		lowerIndicator: '',
	};
	const [settings, setSettings] = useState<IChartSettings>(initialSettings);
	const [selectedLowerIndicator, setSelectedLowerIndicator] =
		useState(DEFAULT_INDICATOR);

	useEffect(() => {
		const handleResize = () => {
			const deviceType = getDeviceType();
			setDevice(deviceType);
		};
		handleWindowResize(handleResize);
	}, []);

	useEffect(() => {
		if (props.venueXid) {
			reloadChartList();
		}
	}, [props.venueXid, props.activeSelectedTab]);

	const getChartSetting = (
		chartType: string,
		timeFrame: number,
		events: [],
		selectedOverlayData: IOverlay,
		comparisonList: ISymbolComparison[],
	) => {
		setSettings({
			venueXid: props.venueXid,
			chartType: chartType,
			timeFrame: timeFrame,
			eventsType: events,
			selectedOverlayData: selectedOverlayData,
			isDefault: isDefault,
			comparisonList: comparisonList,
			lowerIndicator: selectedLowerIndicator,
		});
		setIsChartReset(false);
	};

	const setNonSelectedSettingInitialState = () => {
		setSettings(initialSettings);
		setIsDefault(false);
		setSelectedLowerIndicator(DEFAULT_INDICATOR);
		setSelectedChartItem(DefaultChartSettingName);
		setIsChartReset(true);
	};

	const loadSavedChartData = (savedChartList: ISaveChart[]) => {
		const content: IChartList[] = [{ key: '', value: DefaultChartSettingName }];
		if (savedChartList?.length && props.isEnabledSaveSettings) {
			savedChartList?.forEach((item) => {
				if (selectedChartItem === DefaultChartSettingName && item.isDefault) {
					setSettings({
						venueXid: props.venueXid,
						chartType: item?.chartType,
						timeFrame: item?.timeFrame,
						eventsType: item?.eventsType,
						selectedOverlayData: {} as IOverlay,
						isDefault: item?.isDefault,
						comparisonList: item?.comparisonList,
						lowerIndicator: item?.lowerIndicator,
					});
					setSelectedLowerIndicator(item?.lowerIndicator);
					setIsDefault(true);
					setSelectedChartItem(item?.id);
				}
				content.push({ key: item.id, value: item.chartName });
			});
		}
		setChartList(content);
	};

	const updateSavedChartData = (savedChartList: ISaveChart[]) => {
		setIsDefault(false);
		loadSavedChartData(savedChartList);
		const isDefaultDeleted = savedChartList?.some(
			(x) => x?.id !== selectedChartItem && isDefault,
		);
		if (isDefaultDeleted || !savedChartList?.length) {
			setNonSelectedSettingInitialState();
		}
	};

	const removeComparison = (comparisonKey: string) => {
		setRemoveComparisonItem(comparisonKey);
	};

	const changeChartListDDL = async (event: any) => {
		const settingName = event.getAttribute('data-key');
		changeSelectedChartList(settingName);
	};
	const getSelectedChartList = async (settingName: any) => {
		const data = await getSavedChartData();
		return data?.find((item: any) => item.id == settingName);
	};

	const reloadChartList = async () => {
		const data = await getSavedChartData();
		const defaultSettings = data?.find((item: any) => item.isDefault === true);
		if (defaultSettings && props.isEnabledSaveSettings) {
			setSettings({
				venueXid: props.venueXid,
				chartType: defaultSettings?.chartType,
				timeFrame: defaultSettings?.timeFrame,
				eventsType: defaultSettings?.eventsType,
				selectedOverlayData: {} as IOverlay,
				isDefault: defaultSettings?.isDefault,
				comparisonList: defaultSettings?.comparisonList,
				lowerIndicator: defaultSettings?.lowerIndicator,
			});
			setSelectedLowerIndicator(defaultSettings?.lowerIndicator);
			setIsDefault(defaultSettings?.isDefault || false);
			setSelectedChartItem(defaultSettings?.id);
		} else {
			setNonSelectedSettingInitialState();
		}
	};

	const changeSelectedChartList = async (settingName: any) => {
		if (settingName && props.isEnabledSaveSettings) {
			const parseData = await getSelectedChartList(settingName);
			setSettings({
				venueXid: props.venueXid,
				chartType: parseData?.chartType,
				timeFrame: parseData?.timeFrame,
				eventsType: parseData?.eventsType,
				selectedOverlayData: {} as IOverlay,
				isDefault: parseData?.isDefault,
				comparisonList: parseData?.comparisonList,
				lowerIndicator: parseData?.lowerIndicator,
			});
			setSelectedLowerIndicator(parseData?.lowerIndicator);
			setIsDefault(parseData?.isDefault || false);
			setSelectedChartItem(settingName);
		} else {
			setNonSelectedSettingInitialState();
		}
	};

	const saveSettingsHandler = () => {
		setIsSaveSettings(true);
	};

	const closeSettingModal = () => {
		setIsSaveSettings(false);
	};

	const saveSettings = (name: string) => {
		setSettingName(name);
	};

	const handleDefaultChange = async () => {
		if (selectedChartItem) {
			const data = await getSavedChartData();
			const newSettings = data.map((obj: any) =>
				obj.id === selectedChartItem
					? { ...obj, isDefault: !isDefault }
					: { ...obj, isDefault: false },
			);
			savechartSettings(newSettings);
		}
		setIsDefault(!isDefault);
	};

	const handleResetChart = () => {
		setIsChartReset(true);
	};

	const isDefaultChart = () => {
		return (
			<div className="save-settings-check">
				<Checkbox
					onChange={handleDefaultChange}
					checked={isDefault}
					value="isDefaultSettings"
					disabled={selectedChartItem === DefaultChartSettingName}
				/>
				<Label text="Set as default" />
			</div>
		);
	};

	return (
		<div className="chart-main-container">
			<ChartSettings
				venueXid={props.venueXid}
				getChartSetting={getChartSetting}
				timeFrame={settings.timeFrame}
				chartType={settings.chartType}
				chartEvents={settings.eventsType}
				comparisonList={settings.comparisonList}
				handleResetChart={handleResetChart}
				removeComparisonItem={removeComparisonItem}
				isCompareEnabled={props.isCompareEnabled || false}
				isEnabledSaveSettings={props.isEnabledSaveSettings}
				isDefault={isDefault}
				getSelectedChartList={getSelectedChartList}
				settingName={settingName}
			/>
			<div className="chart-container">
				{settings && (
					<Charts
						venueXid={props.venueXid}
						symbol={props.symbol}
						chartType={settings.chartType}
						timeFrame={settings.timeFrame}
						eventsType={settings.eventsType}
						selectedOverlayData={settings.selectedOverlayData}
						saveChart={settingName}
						loadSavedChartData={loadSavedChartData}
						loadExistingChart={
							selectedChartItem === DefaultChartSettingName
								? ''
								: selectedChartItem
						}
						isChartReset={isChartReset}
						comparisonLimit={5}
						comparisonList={settings.comparisonList}
						removeComparison={removeComparison}
						isEnabledSaveSettings={props.isEnabledSaveSettings}
						useSavedPrefernces={props.useSavedPrefernces}
						tabChangeHandler={props.tabChangeHandler}
						lowerIndicator={selectedLowerIndicator}
						activeSelectedTab={props.activeSelectedTab}
					/>
				)}
			</div>
			<div className="delayed-market-data">
				<Label
					text={`${convertUtcDttmToET(props.delayedDateTime, {
						format: DATE_FORMATS.ET_DATE_TIME_FORMAT,
					})} ${
						device === E_DeviceType.Desktop
							? ` | ${delayedMessage(props.delayedMinutes)}`
							: ''
					}`}
				/>
				{device === E_DeviceType.Mobile && (
					<span> {delayedMessage(props.delayedMinutes)}</span>
				)}
			</div>
			{props.isEnabledSaveSettings && (
				<div className="save-settings">
					<div className="save-settings-fields">
						<Label text="Apply saved settings" />
						<SingleSelect
							data={chartList}
							id="chart-list-dropdown"
							dataTestId="chart-list-dropdown"
							changeHandler={changeChartListDDL}
							selectedValue={selectedChartItem}
						/>
						{device !== E_DeviceType.Mobile && isDefaultChart()}
					</div>
					<div className="save-settings-button">
						{device === E_DeviceType.Mobile && isDefaultChart()}
						<Button
							variant={ButtonVariant.Secondary}
							onClick={() => saveSettingsHandler()}
						>
							Save Settings
						</Button>
					</div>
					{isSaveSettings && (
						<SaveChartSettings
							closeSettingModal={closeSettingModal}
							saveSettings={saveSettings}
							updateSavedChartData={updateSavedChartData}
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default ChartContainer;
