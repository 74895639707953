import DataRequest from './dataRequest';
import URLS from '../constants/urlConstants';
import { IPricingCurrentEndOfDayResponse } from '../types/interfaces';
import { resolvePromiseWithError } from '../utilities/utils';

export const getPricingCurrentEndOfDayStats = async (
	venueXid: number,
): Promise<IPricingCurrentEndOfDayResponse> => {
	if (!venueXid) {
		return await resolvePromiseWithError();
	}
	return await DataRequest.execute(
		`${URLS.PLATFORM.PRICING_CURRENT_END_OF_DAY_STATS}/` + venueXid,
	);
};
