import React, { useState, useEffect } from 'react';
import './company-news.scss';
import {
	CALENDAR_YEAR,
	CompanyNewsHeading,
	TIME_PERIOD_DROPDOWN,
	Time_Period,
	buildNewsInputs,
} from './company-news-constants';
import { getNews } from '../../../services';
import {
	Button,
	ButtonVariant,
	Collapsible,
	Container,
	Heading,
	Loader,
	SingleSelect,
	Textbox,
} from '../../../@core-ui';
import NoData from '../../../shared/NoData/NoData';
import RenderNews from '../render-news/render-news';
import {
	getDeviceType,
	setStateOnDropdownChange,
} from '../../../utilities/utils';
import { ERROR_MESSAGES, E_DeviceType } from '../../../constants/appConstants';
import { prepareNewsData } from '../../quotes-news/news/news-utils';
import {
	DATE_FORMATS,
	formatDateTime,
} from '../../../utilities/date-time-formatter';

type companyNewsProps = {
	symbol: string;
};

const CompanyNews: React.FC<companyNewsProps> = ({ symbol }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [showCalendar, setShowCalendar] = useState(false);
	const [newsData, setNewsData] = useState<any>();
	const isMobileView = getDeviceType() === E_DeviceType.Mobile;
	const [selectedPeriodValue, setSelectedPeriodValue] = useState(
		Time_Period.Month,
	);
	const [startDate, setStartDate] = useState(CALENDAR_YEAR.prevMonth);
	const [endDate, setEndDate] = useState(CALENDAR_YEAR.today);

	useEffect(() => {
		setIsLoading(false);
		getNewsData();
		setShowCalendar(selectedPeriodValue === 'Between');
	}, [selectedPeriodValue, symbol]);

	useEffect(() => {
		setSelectedPeriodValue(Time_Period.Month);
	}, [symbol]);

	const getNewsData = async () => {
		setIsLoading(true);
		const isBetween = selectedPeriodValue === 'Between';
		const newsInputs = buildNewsInputs(
			selectedPeriodValue,
			symbol,
			isBetween ? startDate : '',
			isBetween ? endDate : '',
		);
		const response = await getNews(newsInputs);
		const newsArray = response?.data?.data?.items;
		if (newsArray && newsArray.length > 0) {
			if (startDate === endDate) {
				const preparedData = prepareNewsData(newsArray);
				const date = formatDateTime(endDate, {
					format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
				});
				const filteredNews = preparedData.filter(
					(ele: any) => ele['documentDate'] === date,
				);
				setNewsData([...filteredNews]);
			} else {
				setNewsData([...prepareNewsData(newsArray)]);
			}
			setIsLoading(false);
		} else {
			setNewsData(response);
			setIsLoading(false);
		}
	};

	const getRenderNews = () => {
		return newsData?.length > 0 ? (
			<RenderNews newsData={newsData} />
		) : (
			<NoData error={newsData?.error} />
		);
	};

	const handleStartDate = (e: any) => {
		setStartDate(e.target.value);
	};

	const handleEndDate = (e: any) => {
		setEndDate(e.target.value);
	};

	const getContent = () => {
		return (
			<Container
				id="company-news-container"
				applyBorder={!isMobileView}
				applyPadding={!isMobileView}
				applyWhiteBackground
			>
				<div className={'news-container'}>
					<div className={symbol && 'component-header'}>
						{!isMobileView && (
							<Heading content={CompanyNewsHeading} type={'h2'} />
						)}
					</div>
					{!symbol ? (
						<NoData text={ERROR_MESSAGES.DEFAULT_NO_DATA} applyMinHeight />
					) : (
						<>
							<div className={'period-dropdown'}>
								<SingleSelect
									data={TIME_PERIOD_DROPDOWN}
									changeHandler={(e) => {
										return setStateOnDropdownChange(e, setSelectedPeriodValue);
									}}
									selectedValue={selectedPeriodValue}
									dataTestId="periodDropDown"
								/>
							</div>
							{showCalendar && (
								<div className={'calendar-section'}>
									<div className={'calendar'}>
										<div className={'calendar-item'}>
											<span className={'title'}>From</span>
											<Textbox
												type={'date'}
												name={'dateTime'}
												testId={'startDate'}
												min={CALENDAR_YEAR.startDate}
												max={CALENDAR_YEAR.today}
												defaultValue={CALENDAR_YEAR.prevMonth}
												onChange={handleStartDate}
											/>
										</div>
										<div className="calendar-item">
											<span className={'title'}>To</span>
											<Textbox
												type={'date'}
												min={startDate}
												testId={'endDate'}
												max={CALENDAR_YEAR.today}
												defaultValue={CALENDAR_YEAR.today}
												onChange={handleEndDate}
											/>
										</div>
									</div>
									<Button
										className="button"
										variant={ButtonVariant.Primary}
										onClick={() => getNewsData()}
									>
										Apply
									</Button>
								</div>
							)}

							<div className={'company-news'}>
								{isLoading ? (
									<div className={'loader-container'}>
										<Loader />
									</div>
								) : (
									getRenderNews()
								)}
							</div>
						</>
					)}
				</div>
			</Container>
		);
	};

	return (
		<>
			{!isMobileView && getContent()}
			{isMobileView && (
				<Collapsible title={CompanyNewsHeading}>{getContent()}</Collapsible>
			)}
		</>
	);
};

export default CompanyNews;
