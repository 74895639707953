import React, { useState, useEffect } from 'react';
import HeadlineNews from '../../welcome-page/headline-news/headline-news';
import NewsByCategory from './news-by-category/news-by-category';
import { Collapsible } from '../../../@core-ui';
import { QUOTE_NEWS_CATEGORY } from './news-tab-constants';
import { T_DeviceType, E_DeviceType } from '../../../constants/appConstants';
import { getDeviceType, handleWindowResize } from '../../../utilities/utils';
import './news-tab.scss';
import NewsMap from '../../quotes-news/news/news-map';

const NewsTab: React.FC = () => {
	const [device, setDevice] = useState<T_DeviceType>(getDeviceType());
	const headLineNews = 'Headline News';
	const searchNewsByCategory = 'Search News By Category';

	useEffect(() => {
		const handleResize = () => {
			const deviceType = getDeviceType();
			setDevice(deviceType);
		};
		handleWindowResize(handleResize);
	}, []);

	const getContentDesktopView = () => {
		return <NewsMap />;
	};

	const getContentMobileView = () => {
		return (
			<div className={'quotes-news-mobile'}>
				<div className={'collapsible-section'}>
					<Collapsible title={QUOTE_NEWS_CATEGORY.Headline_News} open={true}>
						<div
							key={QUOTE_NEWS_CATEGORY.Headline_News}
							data-label={headLineNews}
						>
							<HeadlineNews hideHeader={true} />
						</div>
					</Collapsible>
				</div>
				<div className={'collapsible-section'}>
					<Collapsible
						title={QUOTE_NEWS_CATEGORY.Search_News_By_Category}
						open={true}
					>
						<div
							key={QUOTE_NEWS_CATEGORY.Search_News_By_Category}
							data-label={searchNewsByCategory}
						>
							<NewsByCategory />
						</div>
					</Collapsible>
				</div>
				<div className={'collapsible-section'}>
					<NewsMap />
				</div>
			</div>
		);
	};

	return (
		<>
			{device !== E_DeviceType.Mobile && getContentDesktopView()}
			{device === E_DeviceType.Mobile && getContentMobileView()}
		</>
	);
};

export default NewsTab;
