import { OVERLAY_OPTIONS, CHARTCOLORS } from '../indices-charts-constants';
import { getSavedChartSettings } from '../../../../services/chart-data-request-methods';
import {
	addQBIDArgument,
	getHtmlElementFromShadowRoot,
} from '../../../../utilities/utils';
import { RC_WC_MAP } from '../../../../constants/wcConstants';
import { ComparisonType } from '../../../../types/interfaces/IChartSettings';
import { getNewDate } from '../../../../utilities/date-time-formatter-tz';
import {
	DATE_FORMATS,
	formatDateTime,
} from '../../../../utilities/date-time-formatter';
import { TYPE_MAPPING } from '../../../news/news-constants';
import { DEFAULT_NEWS_INPUTS } from '../../../../constants/appConstants';

export const changeChartIndicator = (
	chartObj,
	indicatorName,
	panelType,
	indicatorObj,
) => {
	if (chartObj && indicatorName) {
		let indicatorList = [];
		const colorCode =
			OVERLAY_OPTIONS.filter((x) => x.key == indicatorName)[0]?.color ||
			CHARTCOLORS[0];
		const configurationData = [
			{
				key: indicatorName,
				panel: panelType,
				listItems: [
					{
						display: 'Period',
						chartKey: 'period',
						selectedValue: indicatorObj?.period || 10,
						id: `${indicatorName}_Period'`,
						type: 'textbox',
					},
					{
						display: 'Color',
						chartKey: 'color',
						selectedValue: colorCode,
						id: `${indicatorName}_Color'`,
						type: 'colorpallette',
					},
				],
			},
		];
		handleConfigurationData(configurationData, indicatorList);

		indicatorList.forEach((indicator) => {
			const existingIndicator = chartObj.getIndicatorsById(indicator.Type);
			if (existingIndicator.length === 0) {
				if (indicator.panel === 'lower') {
					chartObj.removePanel(chartObj.panels[1]);
					const newPanel = chartObj.addPanel();
					setAddIndicatorLower(indicator, newPanel);
				} else {
					setAddIndicatorPSAR(indicator, chartObj, colorCode);
				}
			}
		});
		chartObj?.loadData();
	}
};

const handleConfigurationData = (configurationData, indicatorList) => {
	configurationData.forEach(({ key, name, listItems, panel, markerType }) => {
		let indicator = {};
		indicator['id'] = key;
		indicator['Type'] = key;
		indicator['name'] = name;
		indicator['panel'] = panel;
		let params = {};
		let style = {};
		listItems.forEach(({ chartKey, selectedValue, type }) => {
			if (type === 'textbox') {
				params[chartKey] = selectedValue;
			}
			if (type === 'colorpallette') {
				style[chartKey] = selectedValue;
			}
		});
		indicator['Params'] = params;
		indicator['style'] = style;
		if (markerType) {
			indicator['markerType'] = markerType;
		}
		indicatorList.push(indicator);
	});
};

const setAddIndicatorLower = (indicator, newPanel) => {
	if (indicator.Type === 'volume') {
		newPanel.addIndicator('volume', {
			markerType: 'posneg',
			style: {
				color: CHARTCOLORS[0],
				negative: {
					color: CHARTCOLORS[9],
				},
				neutral: {
					color: CHARTCOLORS[11],
				},
				positive: {
					color: CHARTCOLORS[10],
				},
				width: 2,
				seriesBarDistance: 12,
			},
		});
	} else {
		addIndicator(newPanel, indicator);
	}
};

const setAddIndicatorPSAR = (indicator, chartObj, colorCode) => {
	if (indicator.Type === 'psar') {
		const psarObj = {
			id: indicator.Type,
			name: indicator.Type,
			inputs: [
				{ name: 'initialPosition', value: 0 },
				{ name: 'accelerationIncrease', value: 0.02, max: 1 },
				{ name: 'accelerationMaximum', value: 0.2, max: 1 },
			],
			style: { color: colorCode },
			componentSeries: [{ id: 'psar' }],
			isUpper: true,
		};
		chartObj.panels[0].addIndicator('psar', psarObj);
	} else {
		addIndicator(chartObj.panels[0], indicator);
	}
};

export const addPriceIndicator = (chartObj, symbolList) => {
	symbolList.length > 0 &&
		symbolList.forEach((item) => {
			const symbolData = {
				symbol: item.venueXid.toString(),
				style: { color: item.colorCode },
			};
			chartObj?.panels?.[0]?.addIndicator('price', symbolData);
		});
};

export const changeLowerIndicator = (chartObj, event) => {
	const value = event?.target?.textContent?.toLowerCase();
	const lowerIndicatorValue =
		value === 'dividend yield' ? 'dividendyield' : value;
	changeChartIndicator(chartObj, lowerIndicatorValue, 'lower');
	return lowerIndicatorValue;
};

export const removeUpperIndicator = (chart, id) => {
	const indicator = chart.getIndicatorByUid(id);
	if (indicator) {
		chart.panels[0].removeIndicator(indicator);
		chart.render();
	}
};

export const removeEvents = (eventType) => {
	const eventsIcons = getChartShadowRoot(
		`[data-event-icon=${eventType}]`,
		true,
	);
	if (eventsIcons) {
		eventsIcons?.forEach((icon) => {
			let element = getChartShadowRoot('#' + icon.id);
			if (element) {
				element.remove();
			}
		});
	}
};

export const getSavedChartData = async () => {
	const { data } = await getSavedChartSettings();
	if (data?.data) {
		try {
			return data.data;
		} catch (error) {
			console.log('error occured while parsing');
		}
	}
};

const addIndicator = (panel, indicator) => {
	const addedIndicator = panel?.addIndicator(indicator.Type);
	for (let [key, value] of Object.entries(indicator.Params)) {
		addedIndicator?.setInput(key, value);
	}
	if (indicator.style) {
		for (let [key, value] of Object.entries(indicator.style)) {
			addedIndicator?.setStyle(key, value);
		}
	}
};

export const isDuplicateComparison = (
	comparisonList,
	comparedId,
	comparisonType,
	venueXid,
) => {
	if (comparisonType === ComparisonType.UpperIndicator) {
		return (
			comparisonList.filter((items) => items.name === comparedId)?.length > 0
		);
	} else if (venueXid) {
		if (comparisonList.length === 0) {
			return comparedId === venueXid;
		} else {
			return (
				comparisonList.filter(
					(items) =>
						items.venueXid === comparedId ||
						items.venueXid === venueXid ||
						comparedId === venueXid,
				)?.length > 0
			);
		}
	}
	return false;
};

export const getChartShadowRoot = (tagID, selectAll = false) => {
	return (
		getHtmlElementFromShadowRoot(RC_WC_MAP.QuotesNews.name, tagID, selectAll) ||
		getHtmlElementFromShadowRoot(RC_WC_MAP.QuickQuote.name, tagID, selectAll)
	);
};

export const buildNewsInputs = (symbol) => {
	const today = getNewDate();
	const endDate = formatDateTime(today, {
		format: DATE_FORMATS.DOCUMENT_DATE_FORMAT,
	});
	// 1825 for 5 years
	const startDate = formatDateTime(today.subtract(1825, 'day'), {
		format: DATE_FORMATS.DOCUMENT_DATE_FORMAT,
	});
	const newsInputs = { ...DEFAULT_NEWS_INPUTS };
	newsInputs.limit = 5000;
	newsInputs.arguments = [
		addQBIDArgument(TYPE_MAPPING.BridgeSymbols, [symbol]),
		addQBIDArgument(
			TYPE_MAPPING.DocumentDate,
			[startDate],
			'GreaterThanEqualTo',
		),
		addQBIDArgument(TYPE_MAPPING.DocumentDate, [endDate], 'LessThanEqualTo'),
	];
	return newsInputs;
};
