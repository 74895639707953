/* eslint-disable indent */
import { INumberConfig } from '../types';
import { TYPES_OF_ELEMENTS } from '../constants/appConstants';

export const TEXT_NULL_VALUE = '--';
export const DEFAULT_PRECESION = 2;
export const ZERO_VALUE = '0.00';

export const formatNumber = (
	number: number | string | undefined,
	options?: INumberConfig,
) => {
	const convertedNumber =
		typeof number === 'string' ? parseFloat(number) : number;
	if (
		convertedNumber === null ||
		convertedNumber === undefined ||
		isNaN(convertedNumber)
	) {
		return {
			value: options?.defaultValue ?? TEXT_NULL_VALUE,
			color: '',
		};
	}

	let preFix = '';

	let formattedValue: string = convertedNumber
		.toFixed(options?.precision ?? DEFAULT_PRECESION)
		.toString();

	const colorValue = colorClass(formattedValue, options?.showColor);
	if (options?.commaSeparated) {
		formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}

	if (options?.internationalCurrency) {
		formattedValue =
			convertToInternationalCurrencySystem(formattedValue)?.toString();
	}

	if (options?.currencyPreFixValue) {
		preFix += options.currencyPreFixValue;
	}

	if (options?.preFix && formattedValue !== ZERO_VALUE) {
		if (convertedNumber > 0) {
			preFix += '+';
		}
	}

	formattedValue = preFix + formattedValue;

	if (options?.postFixValue) {
		formattedValue += options.postFixValue;
	}

	if (options?.parentheses) {
		formattedValue = '(' + formattedValue + ')';
	}

	if (options?.showPercent) {
		formattedValue = formattedValue + '%';
	}

	return {
		value: formattedValue,
		color: colorValue,
	};
};

const colorClass = (formattedNumber: string, showColor?: boolean) => {
	if (
		showColor &&
		formattedNumber !== TEXT_NULL_VALUE &&
		formattedNumber !== ZERO_VALUE
	) {
		return formattedNumber?.includes('-') ? 'negative' : 'positive';
	}
	return '';
};

export const FormatType = {
	BILLION: 'B',
	MILLION: 'M',
	THOUSAND: 'K',
	MILLION_VALUE: 1000000,
};

export function getFormattedValuesInMeasures(
	value: string,
	measure: string | number,
) {
	if (value) {
		switch (measure) {
			case FormatType.BILLION:
				return parseFloat(value) / 1000000000;
			case FormatType.MILLION:
				return parseFloat(value) / 1000000;
			case FormatType.THOUSAND:
				return parseFloat(value) / 1000;
			default:
				return value;
		}
	}
	return value;
}

export const convertToInternationalCurrencySystem = (
	labelValue: string | number,
) => {
	if (!labelValue) {
		return '--';
	}
	const prefix = Number(labelValue) < 0 ? '-' : '';
	const labelNumber = Math.abs(Number(labelValue));
	// Nine Zeroes for Billions
	if (labelNumber >= 1.0e9) {
		return prefix + (labelNumber / 1.0e9).toFixed(1) + ' B';
	} else if (labelNumber >= 1.0e6) {
		return prefix + (labelNumber / 1.0e6).toFixed(1) + ' M';
	} else if (labelNumber >= 1.0e3) {
		return prefix + (labelNumber / 1.0e3).toFixed(1) + ' K';
	} else {
		return prefix + labelNumber;
	}
};

export const getFormattedCellValue = (data: any, item: any) => {
	if (item.type === TYPES_OF_ELEMENTS.LABEL) {
		return data[item.key];
	} else if (item.type === TYPES_OF_ELEMENTS.LINK_LABEL) {
		return data[item.key]?.props?.displayName;
	} else if (item.type === TYPES_OF_ELEMENTS.NUMBER) {
		return formatNumber(data[item.key], {
			showColor: item.showColor,
			preFix: item.preFix,
			postFixValue: item.postFix,
			showPercent: item.showPercent,
			precision: item.precision,
			internationalCurrency: item.internationalCurrency,
			commaSeparated: item.commaSeparated,
		})?.value;
	}
};

export const checkInvalidValue = (data: any) => {
	if (!data?.length || data === TEXT_NULL_VALUE) {
		return true;
	}
	return false;
};

export const textFormatter = (text: any) => {
	if (!checkInvalidValue(text)) {
		return text;
	}
	return TEXT_NULL_VALUE;
};
