import React, { useState, useEffect } from 'react';
import { LABELS, LABELS_CONFIG, TIME_ZONE } from './intraday-charts-constants';
import './intraday-charts.scss';
import { NumberLabel, Label, SingleSelect } from '../../../@core-ui';
import {
	DATE_FORMATS,
} from '../../../utilities/date-time-formatter';
import { checkInvalidValue } from '../../../utilities/formatter';
import {
	AMERICAN_INDICES,
	WORLD_INDICES,
	SWISS_MARKET,
} from '../../../constants/appConstants';
import ChartsPrice from '../../../shared/chartsPrice/ChartsPrice';
import { DEFAULT_CHARTS_PRICE_OBJ } from '../../../shared/chartsPrice/ChartsPriceConstants';
import { getQuotes } from '../../../services';
import { convertUtcDttmToET } from '../../../utilities/date-time-formatter-tz';

interface IntradayChartsProps {
	selectedDropDownValue: number;
	dataTestId?: string;
	enablePreviousClose?: boolean;
	changeHandler?: any;
}

const IntradayCharts = (props: IntradayChartsProps) => {
	const INTRADAY_DROPDOWN_DATA = [
		...AMERICAN_INDICES,
		...WORLD_INDICES,
		...SWISS_MARKET,
	];
	const [selectedValue, setSelectedValue] = useState(
		props.selectedDropDownValue,
	);
	const [chartConfig, setChartConfig] = useState<any>(null);
	const [quoteData, setQuoteData] = useState<any>(null);

	useEffect(() => {
		setChartConfig(getChartConfig(props.selectedDropDownValue));
	}, []);

	useEffect(() => {
		selectedValue && getQuoteData(selectedValue);
	}, [selectedValue]);

	const getChartConfig = (venueXid: number) => {
		const chartInfo: any = JSON.parse(JSON.stringify(DEFAULT_CHARTS_PRICE_OBJ));
		let width: any = document?.getElementsByClassName(
			'intraday-chart-container',
		)[0]?.clientWidth;

		width =
			width ||
			document
				?.getElementsByTagName('md-intraday-charts')[0]
				?.shadowRoot?.querySelector('div.intraday-chart-container')
				?.clientWidth;

		chartInfo['instruments'][0].venueXid = venueXid;
		chartInfo['panels'][0].height = 100;
		chartInfo['panels'][0].axisY.label.format = '#0';

		if (props.enablePreviousClose) {
			chartInfo['styles'].markers['reference'] = {
				colorIndex: 3,
				dash: [4, 4],
			};

			chartInfo['markers']['reference'] = {
				basis: 'previousClose',
				showLabel: false,
			};
		}
		if (width) {
			chartInfo['width'] = width - 12;
		}
		return chartInfo;
	};

	const getQuoteData = async (venueXid: number, isRealTime?: boolean) => {
		setQuoteData(null);
		const response = await getQuotes([venueXid], isRealTime);
		const quoteContent = response?.data?.data?.quotes;
		const newObject: { [key: string]: any } = {};
		if (quoteContent) {
			newObject[LABELS.LAST_PRICE] = quoteContent[0].data?.lastTrade?.last;
			newObject[LABELS.CHANGE] = quoteContent[0].data?.lastTrade?.change;
			newObject[LABELS.CHANGEPERCENT] =
				quoteContent[0].data?.changePercent?.today;

			newObject['date'] = convertUtcDttmToET(
				quoteContent[0].data?.lastTrade?.date,
			);;
			
			newObject['time'] = convertUtcDttmToET(
				quoteContent[0].data?.lastTrade?.date,{
					format: DATE_FORMATS.HOUR_MINUTE_FORMAT,
				}
			);

			setQuoteData(newObject);
		}
	};

	const changeDropDown = (event: any) => {
		const value = parseInt(event.getAttribute('data-key'));
		setSelectedValue(value);
		setChartConfig(getChartConfig(value));
		props?.changeHandler(event, props.dataTestId);
	};

	const bindQuoteInfo = () => {
		const quoteContent: React.ReactNode[] = [];
		LABELS_CONFIG.forEach((item: any) => {
			quoteContent.push(
				<div className="quote-row" key={`_${item.key}`}>
					<Label text={item.key} size={'m'} />
					<NumberLabel
						value={quoteData[item.key]}
						isBold={item.isBold}
						numberConfig={{
							showColor: item.showColor,
							preFix: item.preFix,
							postFixValue: item.postFixValue,
						}}
					/>
				</div>,
			);
		});
		quoteContent.push(
			<div className="quote-row" key={'timeStamp'}>
				<Label text={quoteData['date']} size={'m'} isGrey={true} />
				<Label
					text={`${quoteData['time']} ${
						!checkInvalidValue(quoteData['time']) ? TIME_ZONE : ''
					}`}
					size={'m'}
					isGrey={true}
				/>
			</div>,
		);
		return quoteContent;
	};

	return (
		<div className="intraday-chart-container">
			{chartConfig && (
				<React.Fragment>
					<div className={'chart-dropdown'}>
						<SingleSelect
							data={INTRADAY_DROPDOWN_DATA}
							changeHandler={changeDropDown}
							id={props.dataTestId}
							selectedValue={selectedValue}
							dataTestId={props.dataTestId}
						/>
					</div>
					<div className="charts-price">
						{<ChartsPrice inputObj={chartConfig} hideXAxis />}
					</div>
					<div className="quote-info">{quoteData && bindQuoteInfo()}</div>
				</React.Fragment>
			)}
		</div>
	);
};

export default IntradayCharts;
