import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './chart-events.scss';
import {
	getHistoricalDividends,
	getHistoricalSplits,
} from '../../../../../services';
import {
	DATE_FORMATS,
	formatDateTime,
} from '../../../../../utilities/date-time-formatter';
import { getNewDate } from '../../../../../utilities/date-time-formatter-tz';
import EventInfo from './events-info';
import {
	TEXT_NULL_VALUE,
	formatNumber,
} from '../../../../../utilities/formatter';
import { getChartShadowRoot } from '../charts-utils';

const ChartEvents = ({ venueXid, chart, reset, days, tabChangeHandler }) => {
	const [chartHover, setChartHover] = useState(false);
	const [chartPopoverPos, setChartPopoverPos] = useState({
		top: '',
		left: '',
		width: '',
	});
	const [chartEvent, setChartEvent] = useState({
		isNews: false,
		isDividend: false,
		isSplit: false,
	});

	const [eventsData, setEventsData] = useState();

	useEffect(() => {
		getEventsData();
	}, [days, reset]);

	useEffect(() => {
		chart.eventEmitter.on('EVENT_RENDER', (event) => {
			const eventType = event.id;
			event.coords.forEach((c, i) => {
				const id = `icon-${i}-${eventType}`;
				let el = getChartShadowRoot('#' + id);
				if (!el) {
					el = document.createElement('div');
					el.className = 'event-icon';
					el.id = id;
					el.className = 'chartEvent';
					el.setAttribute('tabindex', `${0}`);
					el.setAttribute('data-event-icon', eventType);
					if (document.contains(document.getElementById(el.id))) {
						document.getElementById(el.id).remove();
					}
					setChartImage(eventType, el);
					handleChartEvents(el, eventType, c);
					chart.rootMouse.node().appendChild(el);
				}
				const canvas = getChartShadowRoot('.modcharts-panel-root');
				if (canvas) {
					setImagePosition(eventType, el);
				} else {
					el.style.top = `${442}px`;
				}
				el.style.left = `${c.x - 9}px`;
				el.style.visibility = 'visible';
			});
		});
	}, [chart]);

	const getEventsData = async () => {
		const today = getNewDate();
		const endDate = formatDateTime(today, {
			format: DATE_FORMATS.YEAR_MONTH_DATE,
		});
		const startDate = formatDateTime(today.subtract(days, 'day'), {
			format: DATE_FORMATS.YEAR_MONTH_DATE,
		});
		const [dividendsResponse, splitsResponse] = await Promise.all([
			getHistoricalDividends(venueXid, startDate, endDate),
			getHistoricalSplits(venueXid, startDate, endDate),
		]);
		window.corporateEvents = dividendsResponse?.data?.data?.dividendEvents;
		window.splitEvents = splitsResponse?.data?.data?.splitEvents;
	};

	const handleChartEvents = (el, eventType, c) => {
		el.onclick = () => {
			eventType === 'custom' && newsClickEvent();
		};

		el.onkeydown = () => {
			eventType === 'custom' && newsClickEvent();
		};

		el.onmouseover = () => {
			mouseEnterChart(eventType, c);
		};

		el.onmouseout = () => {
			mouseLeaveChart();
		};
		el.ontouchstart = () => {
			mouseEnterChart(eventType, c);
		};
		el.ontouchend = () => {
			mouseLeaveChart();
		};
	};

	const setImagePosition = (eventType, el) => {
		if (eventType === 'dividends') {
			el.style.top = `33%`;
		} else if (eventType === 'custom') {
			el.style.top = `38%`;
		} else {
			el.style.top = `28%`;
		}
	};

	const setChartImage = (eventType, el) => {
		if (eventType === 'splits') {
			el.style.backgroundImage = `url(${
				window?.MD?.WEB_APP_BASE_URL + 'assets/images/ic_splits.svg'
			})`;
		}
		if (eventType === 'custom') {
			el.style.backgroundImage = `url(${
				window?.MD?.WEB_APP_BASE_URL + 'assets/images/ic_news.svg'
			})`;
		}
		if (eventType === 'dividends') {
			el.style.backgroundImage = `url(${
				window?.MD?.WEB_APP_BASE_URL + 'assets/images/ic_dividends.svg'
			})`;
		}
	};

	const newsClickEvent = () => {
		tabChangeHandler('News');
	};

	const getLeftPos = (eventType) => {
		return eventType === 'custom' ? 130 : 180;
	};

	const mouseEnterChart = (eventType, c) => {
		const leftPos = c.x > 450 ? c.x - getLeftPos(eventType) : c.x + 16;
		if (eventType === 'dividends') {
			const chartDividend = window.corporateEvents?.filter(
				(x) =>
					x.exDate ===
					formatDateTime(c.event.date, {
						format: DATE_FORMATS.YEAR_MONTH_DATE,
					}),
			)[0];

			if (chartDividend) {
				setEventsData({
					exDate: formatDateTime(chartDividend.exDate, {
						format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
					}),
					payDate: formatDateTime(chartDividend.paymentDate, {
						format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
					}),
					amount: formatNumber(chartDividend.amount)?.value, //need to confirm
				});
			} else {
				setEventsData({
					exDate: TEXT_NULL_VALUE,
					payDate: TEXT_NULL_VALUE,
					amount: TEXT_NULL_VALUE,
				});
			}

			setChartEvent({ isNews: false, isDividend: true, isSplit: false });
			setChartPopoverPos({
				left: leftPos,
				top: '25%',
				width: '144px',
			});
			// For custom news events
		} else if (eventType === 'custom') {
			const data = c.event;
			if (data) {
				setEventsData({ story: data.story });
			}
			setChartEvent({ isNews: true, isDividend: false, isSplit: false });
			setChartPopoverPos({
				left: leftPos,
				top: '33%',
				width: '93px',
			});
		} else if (eventType === 'splits') {
			const chartSplit = window.splitEvents?.filter(
				(x) =>
					x.exDate ===
					formatDateTime(c.event.date, {
						format: DATE_FORMATS.YEAR_MONTH_DATE,
					}),
			)[0];
			if (chartSplit) {
				setEventsData({
					ratio: c.event.splits,
				});
			} else {
				setEventsData({
					ratio: TEXT_NULL_VALUE,
				});
			}
			setChartEvent({ isNews: false, isDividend: false, isSplit: true });
			setChartPopoverPos({
				left: leftPos,
				top: '27%',
				width: '67px',
			});
		}
		setChartHover(true);
	};

	const mouseLeaveChart = () => {
		setChartHover(false);
	};

	return (
		<div
			data-testid="ChartEventInfoContainer"
			className={'chartEventInfoContainer'}
		>
			{chartHover && (
				<div
					className={'chartPopover'}
					style={{
						top: chartPopoverPos.top,
						left: chartPopoverPos.left,
						width: chartPopoverPos.width,
					}}
				>
					{(chartEvent.isDividend ||
						chartEvent.isSplit ||
						chartEvent.isNews) && (
						<EventInfo
							eventData={eventsData}
							isSplits={chartEvent.isSplit}
							isDividends={chartEvent.isDividend}
							isNews={chartEvent.isNews}
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default ChartEvents;

ChartEvents.propTypes = {
	venueXid: PropTypes.number,
	chart: PropTypes.object,
	reset: PropTypes.bool,
	days: PropTypes.number,
	tabChangeHandler: PropTypes.func,
};
