import React, { useState, useRef, useEffect } from 'react';
import { MinusIcon, PlusIcon } from '../../assets/Icons';
import './toggle-links.scss';
import { scrollIntoView, handleEnterKeyPress } from '../../utilities/utils';

export type ToggleLink = {
	Header: string;
	Component: React.ComponentType;
};

export interface IToggleLinks {
	links: ToggleLink[];
}

const ToggleLinks: React.FC<IToggleLinks> = ({ links = [] }) => {
	const [linkStates, setLinkStates] = useState(
		links.map((link) => ({ ...link, isExpanded: false })),
	);

	const [lastClickedIndex, setLastClickedIndex] = useState(-1);
	const expandedContentRefs = useRef(Array(links.length).fill(null));

	useEffect(() => {
		if (
			lastClickedIndex !== -1 &&
			expandedContentRefs.current[lastClickedIndex]
		) {
			scrollIntoView(expandedContentRefs.current[lastClickedIndex]);
		}
	}, [linkStates]);

	const handleLinkToggle = (index: number) => {
		const newLinkStates = [...linkStates];
		newLinkStates[index].isExpanded = !newLinkStates[index].isExpanded;
		setLinkStates(newLinkStates);
		setLastClickedIndex(index);
	};

	return (
		<div className="toggle-links-view">
			{linkStates.map((link, index) => {
				const { Header, Component, isExpanded } = link;
				return (
					<div
						key={link.Header}
						className="toggle-link-container"
						ref={(ref) => (expandedContentRefs.current[index] = ref)}
					>
						<button
							className="toggle-link-header"
							onClick={() => handleLinkToggle(index)}
							onKeyDown={(e) =>
								handleEnterKeyPress(e, () => handleLinkToggle(index))
							}
						>
							{isExpanded ? <MinusIcon /> : <PlusIcon />}
							{Header}
						</button>
						{isExpanded && (
							<div className="toggle-link-content">
								<Component />
							</div>
						)}
					</div>
				);
			})}
		</div>
	);
};

export default ToggleLinks;
