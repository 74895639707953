import React from 'react';
import { IBasicIconProps } from '../IBasicIconProps';

const DefaultArrowIcon: React.FC<IBasicIconProps> = ({
	width = 16,
	height = 12,
}) => (
	<svg 
		xmlns="http://www.w3.org/2000/svg"
		width={width} 
		height={height} 
		viewBox="0 0 11 11" 
		version="1.1">
		<title>Default Sort Icon</title>
		<g id="icon-default" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="sort-icon–-default" transform="translate(0.6671, 0.8353)" fill="#8E9091" fillRule="nonzero">
				<path d="M9.87169597,2.80635565 C9.69861677,2.99179765 9.41427237,2.99179765 9.24119316,2.80635565 L8.09145274,1.60716403 L8.09145274,9.58117017 C8.09145274,9.82842617 7.89364794,10.026231 7.64639193,10.026231 C7.39913593,10.026231 7.20133113,9.82842617 7.20133113,9.58117017 L7.20133113,1.60716403 L6.05159071,2.80635565 C5.8785115,2.99179765 5.5941671,2.99179765 5.4210879,2.80635565 C5.24800869,2.62091365 5.24800869,2.32420644 5.4210879,2.13876444 L7.28787073,0.173079203 C7.28787073,0.173079203 7.28787073,0.173079203 7.30023353,0.160716403 L7.32495913,0.135990802 C7.39913593,0.0494512009 7.51040113,0 7.63402913,0 C7.75765714,0 7.86892234,0.0494512009 7.95546194,0.135990802 L9.85933317,2.13876444 C10.0447752,2.32420644 10.0447752,2.62091365 9.87169597,2.80635565 Z" id="Path"/>
				<path d="M2.70127185,9.86551457 L2.66418345,9.90260297 C2.59000665,9.98914258 2.47874144,10.0385938 2.35511344,10.0385938 C2.23148544,10.0385938 2.12022024,9.98914258 2.03368064,9.90260297 L0.129809402,7.89982934 C-0.0432698008,7.71438734 -0.0432698008,7.41768013 0.129809402,7.23223813 C0.302888605,7.04679612 0.58723301,7.04679612 0.760312213,7.23223813 L1.91005263,8.43142975 L1.91005263,0.457423608 C1.91005263,0.210167604 2.10785744,0.0123628002 2.35511344,0.0123628002 C2.60236945,0.0123628002 2.80017425,0.210167604 2.80017425,0.457423608 L2.80017425,8.43142975 L3.94991467,7.23223813 C4.12299387,7.04679612 4.40733828,7.04679612 4.58041748,7.23223813 C4.75349668,7.41768013 4.75349668,7.71438734 4.58041748,7.89982934 L2.70127185,9.86551457 Z" id="Path"/>
			</g>
		</g>
	</svg>
);

export default DefaultArrowIcon;
